import moment from "moment-timezone"


// Value that represents insufficient information currently available to render employee selector
export const EMPLOYEE_ID_LOADING = null

// Value that represents an employee selection of 'Any'
export const EMPLOYEE_ID_ANY = "0"

// Consistent state to be used at the beginning of every kind of scrape (get availability/next available date/best price)
function buildInitialScrapeState(token) {
  const state = {
    // API supplied identifier for the scrape
    scrapeId: null,

    // Text scrape status displayed to the user
    scrapeStatus: null,

    // Client generated scrape token used for pusher messaging
    scrapeToken: token,

    // Current scrape status (why two variables?)
    startedScrape: token !== null,
    finishedScrape: false,

    // The currently selected availability
    selectedAvailability: null,

    isFetchingAvailability: token !== null,

    // Find best price fields
    findBestPriceError: false,
    isFetchingBestPrice: false,

    // Find next availability fields
    nextAvailabilityError: false,

  }

  // If token is not null we're actually scraping something and don't want to clobber this value
  if (token === null) {
    state.findBestPriceTime = null
  }

  return state
}

// Consistent state to mark a completed scrape
function buildFinishedScrapeState() {
  return {
    scrapeStatus: null,
    startedScrape: false,
    finishedScrape: true,
    isFetchingBestPrice: false,
    isFetchingAvailability: false,
  }
}

// Consistent state to use before loading service/employee/campaign/review data
function buildInitialInventoryState() {
  return {

    // The service data
    inventoryItem: null,

    // The currently selected employee
    employeeId: EMPLOYEE_ID_LOADING,

    // Currently retrieving service/appointment data from the API?
    isFetchingInventory: false,

    // Currently showing date select calendar
    showServiceCalendar: false,

    // Currently showing employee selector
    employeeSelectOpen: false,

    serviceReviews: [],
    companyReviews: [],
    availabilities: [],
    companiesForService: [],

    // Attempt to get availability returned nothing
    noAvailabilityResults: false,

    // Attempted fetch of service from API returned 404
    fourOhFour: false,

    // Campaigns this service belongs to
    campaigns: []
  }
}

export const initialState = {
  ...buildInitialInventoryState(),
  ...buildInitialScrapeState(null),

  // The currently selected date
  inventoryDate: null,

  // List of available times for this service for the user to choose from
  availabilities: [],
}

const sortAvailabilities = (a, b) => {
  if (moment(a.time.start_time).isBefore(b.time.start_time)) return -1
  if (moment(b.time.start_time).isBefore(a.time.start_time)) return 1
  return 0
}

const firstAvailabilityId = availabilities => {
  const sorted = availabilities.sort(sortAvailabilities)
  return sorted[0] && sorted[0].id
}

// Take array of availabilities and auto-select either the first in the list or the
// attempted best price if there's a match
const buildAvailabilityState = (state, availabilities) => {
  let { findBestPriceTime, selectedAvailability } = state

  // If no availability is selected then at least select the first
  if (!selectedAvailability) {
    selectedAvailability = firstAvailabilityId(availabilities)
  }

  // Waiting on an availability that matches the time we're finding best price for
  if (findBestPriceTime) {
    const availability = availabilities.find(a => a.time.start_time === findBestPriceTime)

    if (availability) {
      selectedAvailability = availability.id
      findBestPriceTime = null
    }
  }

  return {
    ...state,
    availabilities,
    startedScrape: availabilities.length < 1,
    noAvailabilityResults: availabilities.length < 1,
    selectedAvailability,
    findBestPriceTime
  }
}

// Handle incoming websocket message from Pusher
const handleWsMessage = (state, action) => {
  // Ignore all scrape related messages not for the current active scrape
  if (action.payload.as_id && action.payload.as_id !== state.scrapeId) return state

  // Pusher packet carrying availability results from scrape
  if (action.payload.type === "result") {
    const availabilities = state.availabilities.concat(action.payload.res)

    return buildAvailabilityState(state, availabilities)
  }

  // Final pusher packet from availability scrape, indicating no more results to follow
  if (action.payload.type === "complete") {
    return {
      ...state,
      ...buildFinishedScrapeState(),
      noAvailabilityResults: !action.payload.has_aa,
    }
  }

  // Attempt to find the next date succeeded
  if (action.payload.type === "next" && action.payload.success) {
    const { company } = state.inventoryItem

    // If no employees available at all add dates between current and next available to unavailable
    // list, because the server will do the same anyway
    if (state.employeeId === EMPLOYEE_ID_ANY && action.payload.date !== state.inventoryDate) {
      const currentDate = moment(state.inventoryDate)
      const availableDate = moment(action.payload.date)

      while (currentDate.isBefore(availableDate)) {
        if (
          !company.unavailability.dates.includes(
            currentDate.format("YYYY-MM-DD")
          )
        ) company.unavailability.dates.push(currentDate.format("YYYY-MM-DD"))

        currentDate.add(1, "day")
      }
    }

    return {
      ...state,
      inventoryDate: action.payload.date
    }
  }

  // Find next available date failed so finish scrape and show error
  if (action.payload.type === "next" && !action.payload.success) {
    return {
      ...state,
      ...buildFinishedScrapeState(),
      noAvailabilityResults: true,
      nextAvailabilityError: true,
    }
  }

  // Find best price succeeded so save the time, set the new employee and
  // clear availabilities, but leave scrape state open for smooth transition
  // into new availability scrape
  if (action.payload.type === "price" && action.payload.success) {
    return {
      ...state,
      availabilities: [],
      employeeId: action.payload.employee_id,
      isFetchingBestPrice: false
    }
  }

  // Find best price failed so finish scrape and show error
  if (action.payload.type === "price" && !action.payload.success) {
    return {
      ...state,
      ...buildFinishedScrapeState(),
      findBestPriceError: true,
    }
  }

  return state
}

// Handle result from API call to initiate availability scrape
const handleScrapeAvailabilitySuccess = (state, action) => {
  // API has returned a "-1" response indicating the availability data already
  // exists in the database and has been returned in this packet, no further
  // scraping necessary
  if (action.payload.response.id === "-1") {
    const availabilities = action.payload.response.results

    return {
      ...buildAvailabilityState(state, availabilities),
      ...buildFinishedScrapeState(),
    }
  }

  // API has written scrape request to database and returned ID, further
  // data to come from task processors via Pusher packets
  return {
    ...state,
    scrapeId: action.payload.response.id,
  }
}

export default function inventoryView(state = initialState, action) {
  switch (action.type) {
    case "SHOW_SERVICE_CALENDAR":
      return {
        ...state,
        showServiceCalendar: action.payload
      }
    case "FETCH_CAMPAIGNS_FOR_SERVICE_SUCCESS":
      return {
        ...state,
        campaigns: action.payload.response
      }
    case "LOAD_SERVICE_REVIEWS_SUCCESS":
      return {
        ...state,
        serviceReviews: action.payload.response
      }
    case "LOAD_COMPANY_REVIEWS_SUCCESS":
      return {
        ...state,
        companyReviews: action.payload.response
      }
    case "FETCH_COMPANIES_FOR_SERVICE_SUCCESS":
      return {
        ...state,
        companiesForService: action.payload.response.companies
      }
    case "SELECT_SERVICE_ID":
      return {
        ...state,
        employeeId: EMPLOYEE_ID_LOADING,
        availabilities: [],
        isFetchingInventory: true,
        inventoryItem: null
      }
    case "SELECT_AVAILABILITY":
      return {
        ...state,
        selectedAvailability: action.payload.id
      }
    case "SET_INVENTORY_DATE":
      return {
        ...state,
        inventoryDate: action.payload.date,
        selectedAvailability: null
      }
    case "SCRAPE_AVAILABILITY":
      return {
        ...state,
        ...buildInitialScrapeState(action.payload.token),
        scrapeStatus: "Connecting",
        availabilities: [],
        noAvailabilityResults: false,
      }
    case "SCRAPE_NEXT":
      return {
        ...state,
        ...buildInitialScrapeState(action.payload.token),
        scrapeStatus: "Connecting",
      }
    case "SCRAPE_BEST_PRICE":

      const availability = state.availabilities.find(a => a.id === state.selectedAvailability)

      return {
        ...state,
        ...buildInitialScrapeState(action.payload.token),
        employeeId: EMPLOYEE_ID_ANY,
        findBestPriceTime: availability.time.start_time,
        findBestPriceError: false,
        isFetchingBestPrice: true,
        scrapeStatus: "Connecting",
      }
    case "TOGGLE_EMPLOYEE_SELECT":
      return {
        ...state,
        employeeSelectOpen: !state.employeeSelectOpen
      }
    case "SCRAPE_STATUS":
      return {
        ...state,
        scrapeStatus: action.payload.status
      }
    case "SCRAPE_WS_MESSAGE":
      return handleWsMessage(state, action)
    case "SCRAPE_AVAILABILITY_SUCCESS":
      return handleScrapeAvailabilitySuccess(state, action)
    case "SCRAPE_AVAILABILITY_ERROR":
      return {
        ...state,
        ...buildFinishedScrapeState(),
        availabilities: [],
        noAvailabilityResults: true
      }
    case "SET_EMPLOYEE":
      return {
        ...state,
        employeeId: action.payload.employeeId,
        availabilities: [],
        startedScrape: false,
        finishedScrape: false,
        scrapeStatus: null,
      }
    case "FETCH_INVENTORY":
      return {
        ...state,
        ...buildInitialInventoryState(),
        isFetchingInventory: true,
      }
    case "FETCH_INVENTORY_SUCCESS":
      return {
        ...state,
        isFetchingInventory: false,
        inventoryItem: action.payload.response,
      }
    case "FETCH_INVENTORY_ERROR":
      return {
        ...state,
        isFetchingInventory: false,
        fourOhFour:
          action.payload
          && action.payload.error_response
          && action.payload.error_response.response
          && action.payload.error_response.response.status === 404
      }
    // to show new fields in reducer, must reset persisted state.
    case "RESET_INVENTORY_VIEW":
      return {
        ...initialState
      }
    case "RESET_BEST_PRICE_ERROR": {
      return {
        ...state,
        ...buildFinishedScrapeState(),
        findBestPriceError: false,
      }
    }
    default:
      return state
  }
}
