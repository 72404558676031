import React from "react"
import PropTypes from "prop-types"

import {
  useJsApiLoader,
  GoogleMap,
  Marker
} from "@react-google-maps/api"
import styledMap from "../../containers/InventoryFilter/mapTheme"

const { GOOGLE_MAPS_API_KEY } = SITE_CONFIG

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback((map) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  const { lat, lng, height } = props

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={15}
      options={{
        styles: styledMap,
        streetViewControl: false,
        mapTypeControlOptions: { mapTypeIds: [] },
        fullscreenControl: false
      }}
      center={{ lat, lng }}
      mapContainerStyle={{
        width: "100%",
        height
      }}
    >
      <Marker
        position={{ lat, lng }}
        icon={{
          url: "/location-pin.svg",
          scaledSize: new google.maps.Size(40, 40),
        }}
      />

    </GoogleMap>
  ) : null
}

MyMapComponent.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export const CompanyMap = ({ company, height = 400 }) => (
  <MyMapComponent
    lat={parseFloat(company.latitude)}
    lng={parseFloat(company.longitude)}
    height={height}
  />
)

CompanyMap.propTypes = {
  company: PropTypes.object.isRequired,
  height: PropTypes.number
}
