import { createSelector } from "reselect"

const getCartState = state => (Object.prototype.hasOwnProperty.call(state, "cart") ? state.cart : state)
export const getCart = state => (Object.prototype.hasOwnProperty.call(state, "cart") ? state.cart.cart : state)

export const getCartItems = createSelector(
  getCart,
  cartItems => cartItems.items
)

export const getRequiredPatchTestItems = createSelector(
  getCartItems,
  items => {
    if (!items) {
      return null
    }
    const patchTestRequiredItems = items.filter(
      item => item.service && item.service.description.patch_test_required
    )
    if (patchTestRequiredItems) {
      return [
        ...new Map(
          patchTestRequiredItems
            .map(item => item.service)
            .map(service => [service.id, service])
        ).values() // dedup array using service id, cart might contain same service more than once
      ]
    }

    return null
  }
)

// Used for showing a loading spinner in submit to cart button
export const getIsAddingItemToCart = createSelector(
  getCartState,
  cart => cart.addingItemToCart
)
