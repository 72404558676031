import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"
import LoadingBar from "react-redux-loading-bar"
import NavBarContainer from "../containers/NavBar/NavBarContainer"

import { redirectToLogin, storeLastLocation } from "./actions"

const Wrap = styled.div`
  padding: 0px;
  margin: 0px auto;
  max-width: ${props => props.theme.pageMaxWidth};
`


class WrapContainer extends React.Component {
  componentDidMount() {
    this.redirectIfLoggedOut()
    const { onAuth, loggingIn, haveProfile } = this.props
    if (typeof onAuth !== "undefined") {
      if (
        onAuth
        && !loggingIn
        && haveProfile
      ) {
        onAuth()
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.redirectIfLoggedOut()
    const { onAuth, loggingIn, haveProfile } = this.props
    if (typeof onAuth === "function") {
      if (
        loggingIn !== prevProps.loggingIn
        || haveProfile !== prevProps.haveProfile
      ) {
        if (!loggingIn && haveProfile) {
          onAuth()
        }
      }
    }
  }

  redirectIfLoggedOut = () => {
    const {
      redirectToLogin,
      storeLastLocation,
      loginRequired,
      loggingIn,
      haveProfile,
      pathname
    } = this.props
    if (loginRequired) {
      if (!loggingIn && !haveProfile) {
        storeLastLocation(pathname)
        redirectToLogin()
      }
    }
  }

  renderChildren = () => {
    const { children, loginRequired, haveProfile } = this.props
    if (loginRequired && !haveProfile) {
      return null
    }
    return children
  }


  render() {
    const { theme } = this.props
    return (
      <React.Fragment>
        <Wrap id="outer-container">
          <NavBarContainer />
          <main id="page-wrap" style={{ margin: 0, padding: 0 }}>
            <LoadingBar style={{ backgroundColor: theme.colors.primary }} />
            {this.renderChildren()}
          </main>
        </Wrap>
      </React.Fragment>
    )
  }
}

WrapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  haveProfile: PropTypes.bool.isRequired,
  loginRequired: PropTypes.bool.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  storeLastLocation: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  onAuth: PropTypes.func,
  theme: PropTypes.object.isRequired, // injected by "withTheme" from styled-components
}

WrapContainer.defaultProps = {
  onAuth: null
}

const mapStateToProps = state => ({
  loggingIn: state.credentials.loggingIn,
  haveProfile: !!state.profile.profile.id,
  pathname: state.router.location.pathname
})

const mapDispatchToProps = dispatch => ({
  redirectToLogin: () => dispatch(redirectToLogin()),
  storeLastLocation: location => dispatch(storeLastLocation(location)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(WrapContainer))
