import React from "react"
import PropTypes from "prop-types"
import { SubmitInput } from "../../components/forms/Inputs"
import buttonize from "../../components/buttonize"
import { RadioButtonIcon } from "../../components/icons"

export const renderBookingEmployee = (employeeId, item) => {
  let employee
  let fullName = ""
  if (employeeId && item) {
    employee = item.employees.find(e => e.id === employeeId)
  }
  if (employee && employee.first_name) fullName += employee.first_name
  if (employee && employee.last_name) fullName += ` ${employee.last_name}`
  return fullName
}


export const PurchaseButton = ({ disabled, makePurchase, btnText }) => (
  <div style={{ margin: 15 }}>
    {makePurchase && btnText && (
      <SubmitInput
        disabled={disabled}
        onClick={makePurchase}
        style={{ width: "100%" }}
        value={btnText}
      />
    )}
  </div>
)

PurchaseButton.propTypes = {
  makePurchase: PropTypes.func,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
}

export const FlossieRadioButton = ({ label, onClick, selected }) => (
  <div {...buttonize(onClick, { display: "flex", alignItems: "center", whiteSpace: "no-wrap" })}>
    <RadioButtonIcon selected={selected} />
    {label}
  </div>
)

FlossieRadioButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}
