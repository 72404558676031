import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"


import buttonize from "../buttonize"
import PlusIcon from "../icons/plus.svg"
import ArrowDownIcon from "../icons/chevron-down.svg"

export const AccordianContent = ({
  style, children, open, theme = "default",
}) => {
  if (!open) return null
  const themeStyles = theme === "default" ? {} : highlightedContentStyles()
  return <div style={{ ...style, paddingBottom: "10px", ...themeStyles }}>{children}</div>
}

AccordianContent.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  open: PropTypes.bool,
  theme: PropTypes.oneOf(["default", "highlighted"]),
}

const AccordianHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${props => props.theme.colors.headers};
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 15px;
  svg {
    fill: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryAccent : theme.colors.primary)};
    display: block;
    height: ${({ iconHeight }) => iconHeight}px;
    max-width: ${({ iconHeight }) => iconHeight}px;
  }
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const highlightedContentStyles = withTheme(({ theme }) => ({
  border: `solid 2px ${theme.colors.primary}`,
  borderTop: "none",
  padding: "0px 15px 20px 15px",
  paddingTop: 0,
}))

const highlightedHeaderStyles = withTheme(({ isSelected, open, theme }) => {
  const border = `${isSelected ? "solid" : "dashed"} 2px ${theme.colors.primary}`
  return ({
    backgroundColor: isSelected ? theme.colors.primary : theme.colors.primaryAccent,
    opacity: isSelected ? 1 : 0.9,
    color: isSelected ? theme.colors.primaryAccent : theme.colors.headers,
    padding: "10px 15px",
    fontWeight: "bold",
    border,
    borderBottom: (isSelected && open) ? "none" : border,
    cursor: "pointer",
    marginBottom: "0",
  })
})

const accordianIconHeight = 20

export const AccordianHeader = ({
  open, title, onClick, theme = "default", isSelected,
}) => {
  return (
    <AccordianHeaderDiv isSelected={isSelected} iconHeight={accordianIconHeight} {...buttonize(onClick, theme === "default" ? {} : highlightedHeaderStyles({ isSelected, open }))}>
      <div style={{ flex: 1, marginTop: 0 }}>{title}</div>
      <div style={{ textAlign: "right" }}>
        {!open ? (
          <PlusIcon />
        ) : (
          <ArrowDownIcon />
        )}
      </div>
    </AccordianHeaderDiv>
  )
}

AccordianHeader.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  extraStyle: PropTypes.object,
  isSelected: PropTypes.bool, // means is the last clicked
  theme: PropTypes.oneOf(["default", "highlighted"])
}
