import { EventTypes } from "redux-segment"

const {
  FRANCHISE_ID
} = SITE_CONFIG

/* Mapping to inform redux-segment what events to send via segment analytics.js */
const segmentMapping = {
  "@@reduxReactRouter/initRoutes": null,
  "@@reduxReactRouter/routerDidChange": null,
  "@@reduxReactRouter/replaceRoutes": null,
  "@@router/INIT_PATH": null,
  "@@router/UPDATE_PATH": null,

  "@@router/LOCATION_CHANGE": (getState, action) => {
    return {
      eventType: EventTypes.page,
      eventPayload: {
        properties: {
          groupId: FRANCHISE_ID,
        }
      }
    }
  },

  SEGMENT_GROUP: (getState, action) => {
    return {
      eventType: EventTypes.group,
      eventPayload: {
        groupId: FRANCHISE_ID
      }
    }
  },

  FETCH_PROFILE_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: action.payload.response.id,
        traits: {
          groupId: FRANCHISE_ID
        }
      }
    }
  },

  LOG_OUT: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "User Logout",
        properties: {
          groupId: FRANCHISE_ID,
        }
      },
    }
  },

  SUBMIT_LOGIN_FORM_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "User Login",
        properties: {
          groupId: FRANCHISE_ID,
        }
      },
    }
  },

  FETCH_CAMPAIGN_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Campaign View",
        properties: {
          groupId: FRANCHISE_ID,
          campaignId: action.payload.response.id
        }
      },
    }
  },

  SELECT_STEP: (getState, action) => {
    const state = getState()

    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Campaign Step",
        properties: {
          groupId: FRANCHISE_ID,
          campaignId: state.campaignView.campaign.id,
          step: action.payload.step
        }
      },
    }
  },

  FETCH_INVENTORY_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Service View",
        properties: {
          groupId: FRANCHISE_ID,
          serviceId: action.payload.response.id
        }
      },
    }
  },

  FETCH_COMPANY_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Company View",
        properties: {
          groupId: FRANCHISE_ID,
          companyId: action.payload.response.id
        }
      },
    }
  },

  FETCH_ORDER_SUCCESS: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Order View",
        properties: {
          groupId: FRANCHISE_ID,
          orderId: action.payload.response.id
        }
      },
    }
  },

  SCRAPE_AVAILABILITY: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Availability View",
        properties: {
          groupId: FRANCHISE_ID,
          serviceId: action.payload.serviceId,
          date: action.payload.date
        }
      },
    }
  },

  PAYMENT_CONFIRM_UNAVAILABLE: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Appointment Unavailable",
        properties: {
          groupId: FRANCHISE_ID,
          availabilityIds: action.payload.items.map(i => i.id)
        }
      },
    }
  },

  COOKIE_OK: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Cookies Accepted",
        properties: {
          groupId: FRANCHISE_ID,
        }
      },
    }
  },

  COOKIE_DECLINE: (getState, action) => {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: "Cookies Declined",
        properties: {
          groupId: FRANCHISE_ID,
        }
      },
    }
  },
}

export default segmentMapping
