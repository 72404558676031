export const initialState = {
  credentials: {},
  loggedIn: false,
  loggingIn: false
}

export default function credentialsReducer(state = initialState, action) {
  switch (action.type) {
    case "REGISTRATION_SUCCESS":
    case "SUBMIT_LOGIN_FORM_SUCCESS":
      return {
        ...state,
        credentials: action.payload.credentials,
        loggedIn: true,
        loggingIn: true
      }
    case "AUTO_LOGIN_SUCCESS":
      return {
        ...state,
        credentials: action.payload.credentials,
        loggedIn: true
      }
    case "NO_STORED_CREDENTIALS":
    case "FETCH_PROFILE_FAILURE":
      return {
        ...state,
        loggingIn: false
      }
    case "FETCH_PROFILE_SUCCESS":
      return {
        ...state,
        loggingIn: false
      }
    case "LOG_OUT":
      return {
        ...state,
        credentials: {},
        loggingIn: false,
        loggedIn: false
      }
    default:
      return state
  }
}
