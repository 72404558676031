import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SearchInput from "./SearchInput"
import Header from "./Header"
import { TopicLink, QuestionLink } from "./FaqLink"
import FaqsContentHoc from "./FaqsContentHoc"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TopicBox = styled.div`
  max-width: 400px;
  width: 100%;
  text-align: left;
  padding: 15px;
  a {
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const Title = styled.h1`
  text-align: center;
  flex: 1;
`

const FaqsHome = ({
  faqTopics, navToPrevious, navigateTo, match,
}) => {
  const takeFaqsOfTopic = (topic, amount) => {
    const topicFaqs = topic.faqs
    if (topicFaqs.length > amount) {
      return topicFaqs.slice(0, amount)
    }
    return topicFaqs
  }

  const handleClickViewall = (e, slug) => {
    e.preventDefault()
    e.stopPropagation()
    navigateTo(slug)
  }

  return (
    <div>
      <Header navToPrevious={navToPrevious}>
        <Title>How Can We Help You?</Title>
      </Header>
      <SearchInput match={match} navigateTo={navigateTo} />
      <Container>
        <h3 style={{ textAlign: "center", width: "100%" }}>
          You can also browse the topics below to find what you are looking for.
        </h3>
        {faqTopics.map(topic => (
          <TopicBox key={topic.slug}>
            <TopicLink topic={topic} size="h2" navigateTo={navigateTo} />
            {takeFaqsOfTopic(topic, 3).map(faq => (
              <QuestionLink key={faq.slug} topic={topic} faq={faq} size="h4" navigateTo={navigateTo} />
            ))}
            <a href={`/help/${topic.slug}`} onClick={(e) => handleClickViewall(e, `/help/${topic.slug}`)}>View all questions</a>
          </TopicBox>
        ))}
      </Container>
    </div>
  )
}

FaqsHome.defaultProps = {
  faqTopics: [],
}

FaqsHome.propTypes = {
  faqTopics: PropTypes.array,
  navToPrevious: PropTypes.func.isRequired, // from HOC
  navigateTo: PropTypes.func.isRequired, // from HOC
  match: PropTypes.object.isRequired,
}

export default FaqsContentHoc(FaqsHome)
