import React from "react"
import styled from "styled-components"

import PropTypes from "prop-types"
import { TagIcon } from "../icons"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24;
  marginRight: 8;
  marginBottom: 4;
  background-color: ${({ theme }) => theme.colors.darkHover};
  border: 3px solid ${({ theme }) => theme.colors.darkHover};
`
const CampaignTag = ({ campaign }) => {
  return (
    <Wrapper>
      <TagIcon />
      <span
        style={{
          fontWeight: 700,
          padding: 5,
          whiteSpace: "nowrap"
        }}
      >
        {campaign.headline}
      </span>
    </Wrapper>
  )
}

CampaignTag.propTypes = {
  campaign: PropTypes.object,
}


export default CampaignTag
