import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api"

import styledMap from "../../containers/InventoryFilter/mapTheme"

const { GOOGLE_MAPS_API_KEY } = SITE_CONFIG

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)

  const [userAllowedLocation, setUserAllowedLocation] = React.useState(true)
  const [userLatitude, setUserLatitude] = React.useState(null)
  const [userLongitude, setUserLongitude] = React.useState(null)

  const onLoad = React.useCallback((map) => {
    setMap(map)

    fitBounds()
    // Disabled by request: askUserLocation()
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  // const askUserLocation = () => {
  //   const { centerOnUserPosition } = props
  //   // if map needs to be centered on user position we ask for permission
  //   if (centerOnUserPosition) {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         setUserPosition, // success, user allowed position access
  //         setUserAllowedLocation(false)// error or user block position access
  //       )
  //     } else {
  //       // Geo location not supported by browser
  //       setUserAllowedLocation(false)
  //       console.warn("Geolocation is not supported by this browser.")
  //     }
  //   }
  // }

  // const setUserPosition = (position) => {
  //   const newLatitude = position.coords.latitude
  //   const newLongitude = position.coords.longitude
  //   if (userLatitude !== newLatitude && userLongitude !== newLongitude) {
  //     // only update state if user position has changed
  //     setUserLatitude(newLatitude)
  //     setUserLongitude(newLongitude)
  //     setUserAllowedLocation(true)
  //   }
  // }

  const fitBounds = () => {
    if (map === null) {
      return
    }

    const {
      getPoints, mapNeedsPan, setMapNeedsMap, centerOnUserPosition
    } = props

    const newPoints = getPoints()
    if (centerOnUserPosition && userAllowedLocation && isAnyPointVisibleOnUserBounds()) {
      // dont need to change bounds if centering on user position
      return
    }
    if (mapNeedsPan && newPoints.length > 0) {
      const bounds = new google.maps.LatLngBounds()

      newPoints.map(p => (
        bounds.extend(
          new google.maps.LatLng(
            parseFloat(p.latitude),
            parseFloat(p.longitude)
          )
        )
      ))
      setMapNeedsMap(false)

      map.fitBounds(bounds)
    }
  }

  const onMapChange = () => {
    if (map === null) {
      return
    }

    const { setMapZoom } = props
    const d = {}

    d.zoom = map.getZoom()
    setMapZoom(d.zoom)
    fitBounds()
  }


  const getCenter = () => {
    const { points, mapNeedsPan, centerOnUserPosition } = props

    if (centerOnUserPosition && userAllowedLocation && userLatitude && userLongitude) {
      // we center the map on user location only when at least 1 salon is visible on that position bound
      if (isAnyPointVisibleOnUserBounds()) {
        return { lat: parseFloat(userLatitude), lng: parseFloat(userLongitude) }
      }
    }
    // Only recenter map on all points when explicitly told to
    const bounds = new google.maps.LatLngBounds()

    points.forEach(point => {
      bounds.extend(
        new google.maps.LatLng(
          parseFloat(point.latitude),
          parseFloat(point.longitude)
        )
      )
    })

    return mapNeedsPan
      ? { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
      : null
  }

  /*
  * @returns true: When at least one salon is visible on the user position bounds
  */
  const isAnyPointVisibleOnUserBounds = () => {
    const { getPoints } = props

    const newPoints = getPoints()

    const userBounds = new google.maps.LatLngBounds()
    userBounds.extend(new google.maps.LatLng(userLatitude, userLongitude))

    return newPoints.find(marker => {
      const markerPosition = new google.maps.LatLng(
        parseFloat(marker.latitude),
        parseFloat(marker.longitude)
      )
      return userBounds.contains(markerPosition)
    })
  }

  const {
    mapZoom, markers, height, theme
  } = props

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={{
        width: "100%",
        height,
      }}
      zoom={mapZoom}
      onBoundsChanged={onMapChange}
      onZoomChanged={onMapChange}
      center={getCenter()}
      className="themed-map"
      options={{
        styles: styledMap,
        gestureHandling: "greedy",
        maxZoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      }}
    >
      {markers && (
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
          clusterClass="themed-cluster"
          styles={[
            {
              url: "/circle.svg",
              height: 48,
              width: 48,
              filter: "grayscale(1)",
              anchor: [24, 24],
              textColor: theme.colors.primaryAccent,
              textSize: 14
            }
          ]}
        >
          {(clusterer) => markers.map(marker => {
            const markerPosition = new google.maps.LatLng(
              parseFloat(marker.latitude),
              parseFloat(marker.longitude)
            )
            return (
              <Marker
                key={`marker${marker.id}`}
                onClick={marker.onClick}
                label={{
                  text: marker.name,
                  className: "themed-label"
                }}
                position={markerPosition}
                clusterer={clusterer}
                icon={{
                  url: "/location-pin.svg",
                  scaledSize: new google.maps.Size(40, 40),
                  labelOrigin: new google.maps.Point(20, 40)
                }}
              />
            )
          })}
        </MarkerClusterer>
      )}
    </GoogleMap>
  ) : null
}

MyMapComponent.propTypes = {
  setMapZoom: PropTypes.func,
  mapZoom: PropTypes.number,
  height: PropTypes.string,
  getPoints: PropTypes.func, // get points which will be converted in markers on map
  mapNeedsPan: PropTypes.bool,
  markers: PropTypes.array, // array of markers to show in map
  setMapNeedsMap: PropTypes.func,
  centerOnUserPosition: PropTypes.bool, // If true map will be centered on user location by default
  points: PropTypes.array, // array with {latitude, longitude} points to show in the map, we use it to zoom out the map till all fit
  theme: PropTypes.object.isRequired, // theme injected by withTheme from styled-components
}

export default withTheme(MyMapComponent)
