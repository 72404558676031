import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"

import { useTranslation } from "react-i18next"
import { clickWhen, clickWhat, clickWhere } from "./actions"
import {
  // extractSelectedCategoryTags,
  extractSelectedDayTags,
  combinedWhereTags,
  combinedWhatTags
} from "../Search/lib"

import buttonize from "../../components/buttonize"
import { thumborizeFitIn } from "../../components/images/thumborize"

import { PlusIcon, CloseIcon } from "../../components/icons"

import "react-multi-carousel/lib/styles.css"

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 10px;
  max-width: 350px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
`

const WrapContainer = styled.div`

  min-height: 280px;
  background-size: ${props => props.theme.wwwBgImageSize};
  background-position: ${props => props.theme.wwwBgImagePosition};
  background-image: ${props => (props.theme.wwwBgImage ? `url('${props.theme.wwwBgImage}')` : "")};
  background-color: ${props => props.theme.wwwBgColor};
  background-blend-mode: ${props => props.theme.wwwBgImageBlendMode};
  padding: 20px 10px;

  @media ${({ theme }) => theme.responsive.extraSmall} {
    background-position: ${props => props.theme.wwwBgImagePositionMobile};
    min-height: 0px;
  }
`

const TagSpan = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
`

const WhatWhenWhereContainer = (props) => {
  const { t } = useTranslation()

  const renderTag = (t, closeAction) => (
    <TagSpan key={`tag${t.id}`}>
      <div style={{ flex: 5 }}>{t.name}</div>
      <div
        {...buttonize(closeAction, {
          flex: 1,
          textAlign: "right",
          cursor: "pointer"
        })}
      >
        <CloseIcon style={{ height: 20 }} />
      </div>
    </TagSpan>
  )

  const renderPlus = () => (
    <TagSpan key="tag-plus">
      <div style={{ flex: 5 }} />
      <div style={{ flex: 1, textAlign: "right", cursor: "pointer" }}>
        <PlusIcon />
      </div>
    </TagSpan>
  )

  const renderTags = (tags, closeAction, plural) => {
    if (!tags || tags.length === 0) return <React.Fragment>{renderPlus()}</React.Fragment>
    if (tags.length === 1) {
      return (
        <React.Fragment>
          {tags.map(t => renderTag(t, () => closeAction(t.originalId, t)))}
        </React.Fragment>
      )
    }
    // if (tags.length > 1 && tags.length < 5 && !props.isMobile) return tags.map(t => renderTag(t, () => closeAction(t.originalId)))
    return (
      <React.Fragment>
        {renderTag(
          { id: 0, name: `${tags.length} ${plural} Selected` },
          () => tags.map(t => closeAction(t.originalId, t))
        )}
      </React.Fragment>
    )
  }

  const whereToggle = (originalId, tag) => {
    const { toggleRegion, selectCompany } = props
    if (tag.type === "region") {
      toggleRegion(originalId)
    } else {
      selectCompany(originalId)
    }
  }

  const whatToggle = (originalId, tag) => {
    const { toggleCategory, toggleCampaign } = props
    if (tag.type === "category") {
      toggleCategory(originalId)
    } else {
      toggleCampaign(originalId)
    }
  }

  const renderCampaign = (campaign) => {
    const { isMobile, toggleCampaign } = props

    const campaignPlacement = isMobile ? "main" : "main_desktop"
    const campaignImage = campaign.images.find(
      i => i.placement === campaignPlacement
    )

    if (!campaignImage) {
      return null
    }

    return (
      <div style={{ cursor: "pointer" }} key={`campaign-${campaign.slug}`} onClick={() => { toggleCampaign(campaign.slug) }}>
        <img
          alt="campaign"
          src={thumborizeFitIn(
            encodeURIComponent(campaignImage.url),
            1080,
            1080
          )}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    )
  }

  const haveAnyFilters = () => {
    const { haveWhatFilters, haveWhenFilters, haveWhereFilters } = props
    return (
      haveWhatFilters
      || haveWhenFilters
      || haveWhereFilters
    )
  }

  const {
    haveWhatFilters,
    whatOpen,
    clickWhat,
    whatTags,
    haveWhenFilters,
    whenOpen,
    clickWhen,
    whenTags,
    selectCalendarDay,
    haveWhereFilters,
    whereOpen,
    clickWhere,
    whereTags,
    theme
  } = props

  const www = [
    {
      label: "What",
      name: "what",
      plural: "",
      filtered: haveWhatFilters,
      open: whatOpen,
      clickAction: clickWhat,
      tags: whatTags,
      tagToggle: whatToggle,
      showBorder: true
    },
    {
      label: "When",
      name: "when",
      plural: "Dates",
      filtered: haveWhenFilters,
      open: whenOpen,
      clickAction: clickWhen,
      tags: whenTags,
      tagToggle: selectCalendarDay,
      showBorder: true
    },
    {
      label: "Where",
      name: "where",
      plural: "",
      filtered: haveWhereFilters,
      open: whereOpen,
      clickAction: clickWhere,
      tags: whereTags,
      tagToggle: whereToggle
    }
  ]

  const WBox = ({
    label, clickAction, tags, tagToggle, plural
  }) => (
    <LabelContainer
      {...buttonize(
        clickAction,
        {},
        "link"
      )}
    >
      <Label>
        {label}
        ?
      </Label>
      {renderTags(tags, tagToggle, plural)}
    </LabelContainer>
  )

  const { isMobile } = props
  return (
    <WrapContainer isMobile={isMobile}>
      <div style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}>
        <div
          style={{
            color: theme.topNav.whatWhenWhereText,
            fontSize: 20,
            margin: isMobile ? "0px 10px 10px 15px" : "30px 10px 10px 15px",
            fontWeight: 600
          }}
        >
          <span className="preserve-line-breaks">
            {t("book_appointment_header")}
          </span>
        </div>
        <div style={{}}>
          {www.map(row => (
            <WBox {...row} key={row.name} />
          ))}
        </div>

        {/* campaigns.length > 0 && (
          <div style={{ margin: isMobile ? "16px 10px 0px 10px" : "16px 0px 0px 0px" }}>
            <Carousel
              responsive={{
                desktop: {
                  breakpoint: { max: 3500, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 768 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 768, min: 0 },
                  items: 1,
                }
              }}
              arrows={false}
              showDots={false}
              autoPlay={campaigns.length > 1}
              autoPlaySpeed={2500}
              infinite={campaigns.length > 1}
            >
              {campaigns.map(renderCampaign)}
            </Carousel>
          </div>
        ) */}
      </div>
    </WrapContainer>
  )
}

WhatWhenWhereContainer.propTypes = {
  campaigns: PropTypes.array.isRequired,

  clickWhat: PropTypes.func.isRequired,
  clickWhen: PropTypes.func.isRequired,
  clickWhere: PropTypes.func.isRequired,

  toggleCampaign: PropTypes.func.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  toggleRegion: PropTypes.func.isRequired,
  selectCalendarDay: PropTypes.func.isRequired,
  selectCompany: PropTypes.func.isRequired,

  whatOpen: PropTypes.bool.isRequired,
  whenOpen: PropTypes.bool.isRequired,
  whereOpen: PropTypes.bool.isRequired,

  haveWhatFilters: PropTypes.bool.isRequired,
  haveWhenFilters: PropTypes.bool.isRequired,
  haveWhereFilters: PropTypes.bool.isRequired,

  isMobile: PropTypes.bool.isRequired,

  whatTags: PropTypes.array.isRequired,
  whenTags: PropTypes.array.isRequired,
  whereTags: PropTypes.array.isRequired,

  theme: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    campaigns: state.inventoryFilter.campaigns,

    whenOpen: state.instantbook.whenOpen,
    whatOpen: state.instantbook.whatOpen,
    whereOpen: state.instantbook.whereOpen,

    haveWhatFilters: state.inventoryFilter.selectedCategories.length > 0 || state.inventoryFilter.selectedCampaigns.length > 0,
    haveWhenFilters: state.inventoryFilter.selectedDays.length > 0,
    haveWhereFilters:
      state.inventoryFilter.selectedRegions.length > 0
      || state.inventoryFilter.selectedCompanies.length > 0,

    isMobile: state.browser.lessThan.mobile,

    whatTags: combinedWhatTags(state),
    whenTags: extractSelectedDayTags(state),
    whereTags: combinedWhereTags(state)
  }
}

const mapDispatchToProps = {
  clickWhat,
  clickWhen,
  clickWhere
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(WhatWhenWhereContainer))
