// from https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
export function randomString32() {
  const length = 32
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; i -= 1) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export function calculatePrice(price, isVip) {
  if (isVip && price.discount_vip) {
    return (parseFloat(price.price) - parseFloat(price.discount_vip)).toFixed(2)
  }
  return price.price
}
