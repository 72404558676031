import { push, replace } from "connected-react-router"
import moment from "moment-timezone"

export const showServiceCalendar = bool => ({
  type: "SHOW_SERVICE_CALENDAR",
  payload: bool
})

export const fetchInventory = (id, date) => ({
  type: "FETCH_INVENTORY",
  payload: { id, date }
})

export const fetchCampaign = campaignSlug => ({
  type: "FETCH_CAMPAIGN",
  payload: { campaignSlug }
})

export const setEmployee = employeeId => ({
  type: "SET_EMPLOYEE",
  payload: { employeeId }
})

export const triggerNext = () => ({
  type: "TRIGGER_NEXT"
})

export const triggerBestPrice = () => ({
  type: "TRIGGER_BEST_PRICE"
})

export const triggerScrape = () => ({
  type: "TRIGGER_SCRAPE"
})

export const forceScrape = () => ({
  type: "FORCE_SCRAPE"
})

export const setInventoryDate = date => {
  return {
    type: "SET_INVENTORY_DATE",
    payload: { date }
  }
}

export const toggleEmployeeSelect = () => ({
  type: "TOGGLE_EMPLOYEE_SELECT"
})

export const selectAvailability = id => ({
  type: "SELECT_AVAILABILITY",
  payload: { id }
})

export const clearCart = () => ({
  type: "CLEAR_CART"
})

export const trackPurchaseStart = () => ({
  type: "TRACK_PURCHASE_START"
})

export const fetchCompaniesForService = serviceId => ({
  type: "FETCH_COMPANIES_FOR_SERVICE",
  payload: { serviceId }
})

export const fetchRelatedServices = (campaignId, serviceDescriptionId) => ({
  type: "FETCH_RELATED_SERVICES",
  payload: { campaignId, serviceDescriptionId }
})

export const setMapZoom = zoom => ({ type: "SET_MAP_ZOOM", payload: { zoom } })
export const selectStep = step => ({ type: "SELECT_STEP", payload: { step } })
export const resetBestPriceError = () => ({ type: "RESET_BEST_PRICE_ERROR" })

/**
   * This function handles the optional parameters of the Inventory Item Page
   * @param currentParams { id, date, employeeSlug }: Object with all the params present in the current url.
   * Id: Service id.
   * Date: Date for the booking, which will be used in the calendar booking module.
   * EmployeeSlug: Slug of the employee.
   * @param newParams { id, date, employeeSlug }: Object with all the params that will be added/updated to the current url.
   * @param replaceInHistory: Boolean to determine if the browser history should be replaced or not.
*/
export const navToInventory = (currentParams = {}, newParams = {}, replaceInHistory) => {
  let path = "/services"

  if (newParams.id || currentParams.id) {
    const serviceId = newParams.id || currentParams.id
    path = `${path}/${serviceId}`
  }


  if ((newParams.date && newParams.date !== moment().format("YYYY-MM-DD")) || currentParams.date) {
    const dateValue = newParams.date || currentParams.date
    path = `${path}/date/${dateValue}`
  }

  if (newParams.employeeSlug || currentParams.employeeSlug) {
    const employeeSlugValue = newParams.employeeSlug || currentParams.employeeSlug
    path = `${path}/employee/${employeeSlugValue}`
  }

  if (typeof replaceInHistory === "undefined" || !replaceInHistory) {
    return push(path)
  }

  return replace(path)
}
