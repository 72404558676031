import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { ArrowLeftIcon } from "../icons"
import { Link } from "../button"

const StyledSpan = styled.span`
  font-size: ${props => (props.large ? "20px" : "16px")};
  padding: 10px 15px 10px 15px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.primary)};
  color: #fff;
  border: none;
  border-radius: 30px;
  font-weight: 650;
  cursor: pointer;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
`

const StyledMenuAnchor = styled.a`
  font-size: 15px;
  color: ${({ theme, active }) => (active ? theme.sideNav.activeLinks : theme.sideNav.links)};
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1.2px solid #aaa;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
`

const StyledMenuSpan = styled.span`
  font-size: 15px;
  color: ${({ theme, active }) => (active ? theme.sideNav.activeLinks : theme.sideNav.links)};
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1.2px solid #aaa;
  text-align: right;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
`
const StyledSpanPlain = styled(StyledSpan)`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
`

const StyledSpanFacebook = styled(StyledSpan)`
  background-color: #4d69ba;
  max-width: 220px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`
const StyledSpanHighlight = styled(StyledSpan)`
  background-color: ${({ theme }) => theme.colors.primaryAccent};
  color: ${({ theme }) => theme.colors.primary};
`

export const SpanLinkMenu = props => <StyledMenuSpan {...props} />

export const AnchorLinkMenu = props => <StyledMenuAnchor {...props} />

export const SpanLinkPlain = props => <StyledSpanPlain {...props} />

export const SpanLinkFacebook = props => <StyledSpanFacebook {...props} />

export const SpanLinkHighlight = props => <StyledSpanHighlight {...props} />

/* Top left back link */
export const BackLink = props => {
  const { href, onClick, style } = props
  return (
    <Link
      href={href}
      onClick={onClick}
      style={{ position: "absolute", zIndex: 1, ...style }}
    >
      <ArrowLeftIcon style={{ display: "block", width: 30, height: "auto" }} />
    </Link>
  )
}

BackLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
}

export const LegalLink = styled.a`
  color: #333;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
