import { createSelector } from "reselect"

export const getArticles = state => (Object.prototype.hasOwnProperty.call(state, "articles")
  ? state.articles
  : state)

export const getViewingArticleType = createSelector(
  getArticles,
  articles => articles.articleType
)

// get article types
export const getArticleList = createSelector(
  getArticles,
  articles => articles.articleList
)

// article has more items
export const getArticleListHasMore = createSelector(
  getArticles,
  articles => articles.articleMorePages
)

/* Get the first article in the list to display as a feature item */
export const getArticleListFeature = createSelector(
  getArticles,
  articles => articles.articleList[0]
)

export const getArticleListRemaining = createSelector(
  getArticles,
  articles => articles.articleList.slice(1)
)

export const getSingleArticle = createSelector(
  getArticles,
  articles => articles.singleArticle
)

export const getArticleListLoading = createSelector(
  getArticles,
  articleList => articleList.isFetchingArticles
)

export const getArticleLoading = createSelector(
  getArticles,
  articleList => articleList.isFetchingArticle
)

export const getArticleIsEmpty = state => {
  const singleArticle = getSingleArticle(state)
  if (!singleArticle || Object.keys(singleArticle).length === 0) return true
  return false
}

export const getSingleArticleLoading = state => {
  return getArticleLoading(state)
}

// dynamic selectors

export const getSingleArticleName = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.title) result = singleArticle.title
  return result
}

export const getSingleArticleCreatedAt = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.created_at) result = singleArticle.created_at
  return result
}

export const getSingleArticleSummary = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.summary) result = singleArticle.summary
  return result
}

export const getSingleArticleHashTag = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.hashtag) result = singleArticle.hashtag
  return result
}

export const getSingleArticleSection = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.sections) result = singleArticle.sections
  return result
}

export const getSingleArticleImage = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (
    singleArticle
    && singleArticle.images
    && singleArticle.images[0]
    && singleArticle.images[0].url
  ) result = singleArticle.title
  return result
}

export const getSingleArticleRelatedArticles = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.articles) result = singleArticle.articles
  return result
}

export const getSingleArticleMerchandise = state => {
  let result = null
  const singleArticle = getSingleArticle(state)
  if (!singleArticle) return result
  if (singleArticle && singleArticle.merchandise) result = singleArticle.merchandise
  return result
}
