import {
  TYPE_ID_INSPIRATION,
} from "./constants"

export const initialState = {
  articleList: [],
  isFetchingArticles: false,
  isFetchingArticle: false,
  articleMorePages: true,

  articleType: TYPE_ID_INSPIRATION,
  singleArticle: {}
}

export default function myArticlesReducer(state = initialState, action) {
  switch (action.type) {
    // inspirations
    case "FETCH_ARTICLES":
      return {
        ...state,
        isFetchingArticles: true
      }
    case "FETCH_ARTICLES_SUCCESS":
      return {
        ...state,
        // append the old state to the new results, excluding any that are already in there
        articleList: [
          ...state.articleList,
          ...action.payload.response.filter(
            article => !state.articleList.find(check => check.id === article.id)
          )
        ],
        isFetchingArticles: false,
        articleMorePages: action.payload.response.length > 0 || false
      }
    case "FETCH_ARTICLE_SUCCESS":
      return {
        ...state,
        isFetchingArticle: false,
        singleArticle: action.payload.response,
        articleType: action.payload.response.type_id
      }
    case "FETCH_ARTICLE":
      return {
        ...state,
        singleArticle: {},
        isFetchingArticle: true
      }
    // general
    case "VIEW_ARTICLE_TYPE":
      return {
        ...state,
        articleType: action.payload,
        articleList: [],
        articleMorePages: true
      }
    default:
      return { ...state }
  }
}
