import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroller"
import {
  fetchArticles,
  fetchArticle,
  navToArticle,
  navToArticles,
  setViewArticleType
} from "./actions"
import {
  getArticleListRemaining,
  getViewingArticleType,
  getArticleListLoading,
  getArticleListHasMore,
  getArticleListFeature
} from "./selector"
import MetaSetter from "../../components/MetaSetter"
import WrapContainer from "../WrapContainer"
import Animation from "../../components/Animation"
import { thumborizeFitIn } from "../../components/images/thumborize"
import ParagraphText from "../../components/ParagraphText"
import { ArticleHeader, ArticleListParent, ParentDiv } from "./components"
import ArticleTabs from "./ArticleTabs"
import ArticleList from "./ArticleList"

import {
  TYPE_ID_INSPIRATION,
  TYPE_SLUG_INSPIRATION,
  TYPE_ID_GUIDE,
  TYPE_SLUG_GUIDE
} from "./constants"
import { GoToButton } from "../../components/button"

class ListContainer extends Component {
  componentDidMount() {
    const { setViewArticleType, match } = this.props

    /* Set the article type currently being viewed based off of matched URL parameter */
    if (
      match.params.type
      && match.params.type === TYPE_SLUG_INSPIRATION
    ) setViewArticleType(TYPE_ID_INSPIRATION)
    if (
      match.params.type
      && match.params.type === TYPE_SLUG_GUIDE
    ) setViewArticleType(TYPE_ID_GUIDE)
  }

  clickArticle = slug => {
    const { articleType, navToArticle } = this.props

    const type = articleType === TYPE_ID_GUIDE ? TYPE_SLUG_GUIDE : TYPE_SLUG_INSPIRATION

    navToArticle(type, slug)
  }

  loadScroller = page => {
    const { fetchArticles, match } = this.props

    let cta = null

    /* Kinda tricky offset/limit calculations due to feature article */
    const limit = page > 0 ? 12 : 13
    const offset = page > 0 ? page * limit + 1 : 0

    /* Default to inspiration articles, but if the URL matches another type swap it in */
    let typeId = TYPE_ID_INSPIRATION

    if (
      match.params.type
      && match.params.type === TYPE_SLUG_GUIDE
    ) typeId = TYPE_ID_GUIDE

    cta = fetchArticles(typeId, offset, limit)

    return cta
  }

  renderInfiniteScroll = () => {
    const {
      articleList,
      articleHasMore,
      articleListLoading,
      isMobile
    } = this.props

    /* Info on the hasMore value below can be found here...
        https://github.com/CassetteRocks/react-infinite-scroller/issues/143 */

    return (
      <InfiniteScroll
        pageStart={-1}
        loadMore={this.loadScroller}
        hasMore={articleHasMore && !articleListLoading}
        loader={<Animation key={0} />}
        threshold={500}
      >
        {/* <ScrollManager scrollKey="artilce-list" /> */}
        <ArticleListParent isMobile={isMobile}>
          <ArticleList
            articles={articleList}
            onClick={this.clickArticle}
            isMobile={isMobile}
          />
        </ArticleListParent>
      </InfiniteScroll>
    )
  }

  renderFeatureArticle() {
    const { isMobile, articleFeature } = this.props

    /* "latest" tab at the top center of feature section */
    const LatestTab = styled.span`
      display: block;
      position: absolute;
      width: 80px;
      height: 26px;
      top: 0;
      left: calc(50% - 40px);
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
      text-align: center;
      border-radius: 0 0 4px 4px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 26px;
    `

    const getMainImage = images => {
      const image = images.find(i => i.placement === "main" && i.position === "0")
      return image && thumborizeFitIn(encodeURIComponent(image.url), 800, 800)
    }

    const image = getMainImage(articleFeature.images)

    /* Large image positined to the left */
    const FeatureImage = styled.div`
      flex: 1;
      height: ${props => (props.isMobile ? "300px" : "100%")};
      width: ${props => (props.isMobile ? "100%" : "auto")};
      background-size: cover;
      background-image: url("${props => props.image}");
      background-repeat: no-repeat;
      background-position: center;
    `

    /* Parent div for the entire feature section */
    const FeatureParent = styled.div`
      height: ${props => (props.isMobile ? "auto" : "420px")};
      display: ${props => (props.isMobile ? "block" : "flex")};
      flex-direction: row;
      position: relative;
      padding-bottom: 40px;
      border-bottom: 1px dashed #ef609f;
      margin-bottom: 40px;
    `

    /* Feature title/summary/read more */
    const FeatureContent = styled.div`
      flex: 1;
      background-color: #ececec;
      text-align: ${props => (props.isMobile ? "left" : "center")};
    `

    const typeSlug = articleFeature.type_id === TYPE_ID_GUIDE
      ? TYPE_SLUG_GUIDE
      : TYPE_SLUG_INSPIRATION

    const href = `/${typeSlug}/${articleFeature.slug}`

    return (
      <FeatureParent isMobile={isMobile}>
        <LatestTab>Latest</LatestTab>
        {image && <FeatureImage image={image} isMobile={isMobile} />}
        <FeatureContent isMobile={isMobile}>
          <div style={{ padding: isMobile ? 20 : 70 }}>
            <h1
              style={{
                margin: 0,
                marginBottom: 20,
                fontSize: isMobile ? 20 : 24
              }}
            >
              {articleFeature.title}
            </h1>
            <ParagraphText text={articleFeature.summary} />
            <GoToButton
              style={{ display: "inline-block", marginTop: 24 }}
              aLink={href}
            >
              Read More
            </GoToButton>
          </div>
        </FeatureContent>
      </FeatureParent>
    )
  }

  render() {
    const {
      articleType,
      articleFeature,
      metaTitle,
      metaDescription
    } = this.props

    return (
      <WrapContainer loginRequired={false} showNavBack>
        <ParentDiv>
          <MetaSetter
            metaTitle={metaTitle}
            metaDescription={metaDescription}
          />
          <ArticleHeader />
          <ArticleTabs />

          {articleFeature && this.renderFeatureArticle()}

          {/* Scroller maintains page state locally so we need one per section
              or the page count doesn't reset when switching between them */}
          {articleType === TYPE_ID_INSPIRATION && this.renderInfiniteScroll()}
          {articleType === TYPE_ID_GUIDE && this.renderInfiniteScroll()}
        </ParentDiv>
      </WrapContainer>
    )
  }
}

ListContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  articleType: PropTypes.string.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  navToArticle: PropTypes.func.isRequired,
  articleListLoading: PropTypes.bool.isRequired,
  setViewArticleType: PropTypes.func,
  articleList: PropTypes.array,
  articleHasMore: PropTypes.bool.isRequired,
  articleFeature: PropTypes.object,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  match: PropTypes.object.isRequired
}

ListContainer.defaultProps = {
  setViewArticleType: null,
  articleList: []
}

function mapStateToProps(state) {
  return {
    articleList: getArticleListRemaining(state),
    articleType: getViewingArticleType(state),
    articleListLoading: getArticleListLoading(state),
    articleHasMore: getArticleListHasMore(state),
    articleFeature: getArticleListFeature(state),
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  setViewArticleType: type => dispatch(setViewArticleType(type)),
  fetchArticle: slug => dispatch(fetchArticle(slug)),
  fetchArticles: (typeId, offset, limit) => dispatch(fetchArticles(typeId, offset, limit)),
  navToArticle: (type, slug) => dispatch(navToArticle(type, slug)),
  navToArticles: type => dispatch(navToArticles(type))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)
