export const initialState = {
  campaignSlug: null,
  campaign: null,
  isFetchingCampaign: true,
  selectedServiceDescriptionId: null,
  selectedServiceId: null,
  selectedCompanyId: null,
  companies: [],
  selectedStep: 1,
  mapZoom: 14,
  relatedServices: null
}

export default function campaignView(state = initialState, action) {
  switch (action.type) {
    case "SET_MAP_ZOOM":
      return {
        ...state,
        mapZoom: action.payload.zoom
      }
    case "FETCH_CAMPAIGN":
      return {
        ...state,
        isFetchingCampaign: true,
        campaign: null,
        campaignError: false,
        selectedServiceDescriptionId: null,
        selectedCompanyId: null,
        selectedServiceId: null
      }
    case "FETCH_CAMPAIGN_SUCCESS":
      return {
        ...state,
        campaign: action.payload.response,
        isFetchingCampaign: false,
        campaignError: false,
        relatedServices: null
      }
    case "FETCH_CAMPAIGN_ERROR":
      return {
        ...state,
        campaign: null,
        isFetchingCampaign: false,
        campaignError: true
      }
    case "SELECT_SERVICE_DESCRIPTION_ID":
      return {
        ...state,
        selectedServiceDescriptionId: action.payload.serviceDescriptionId
      }
    case "FETCH_SIMILAR_SERVICES_SUCCESS":
      return {
        ...state,
        companies: action.payload.response.companies
      }
    case "FETCH_RELATED_SERVICES_SUCCESS":
      return {
        ...state,
        relatedServices: action.payload.response
      }
    case "SELECT_CATEGORY":
      return {
        ...state,
        selectedCategory: action.payload.category
      }
    case "SELECT_SERVICE_ID":
      return {
        ...state,
        selectedServiceId: action.payload.id
      }
    case "SELECT_COMPANY_ID":
      return {
        ...state,
        selectedCompanyId: action.payload.id
      }
    case "SELECT_STEP":
      return {
        ...state,
        selectedStep: action.payload.step
      }
    case "UNSELECT_WIZARD_LOCATION":
      return {
        ...state,
        selectedCompanyId: null,
        selectedServiceId: null
      }
    case "UNSELECT_WIZARD_SERVICE_DESCRIPTION":
      return {
        ...state,
        selectedServiceId: null,
        selectedCompanyId: null,
        selectedServiceDescriptionId: null,
        companies: []
      }
    default:
      return state
  }
}
