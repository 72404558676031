export const initialState = {
  id: null,
  cart: {},
  addingItemToCart: false,
  cartError: false,
  cartErrorMessage: null
}

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CART_SUCCESS":
    case "ADD_ITEM_TO_CART_SUCCESS":
    case "REMOVE_ITEM_FROM_CART_SUCCESS":
    case "REMOVE_NOTES_FROM_CART_SUCCESS":
    case "ADD_NOTES_TO_CART_SUCCESS":
    case "ENABLE_BALANCE_IN_CART_SUCCESS":
    case "DISABLE_BALANCE_IN_CART_SUCCESS":
    case "SELECT_PAYMENT_METHOD_SUCCESS":
      return {
        ...state,
        addingItemToCart: false,
        id: action.payload.response.id,
        cart: action.payload.response.cart
      }

    case "ADD_ITEM_TO_CART_FAILURE":
      const error = action.payload.response.message
        ? action.payload.response.message
        : "Sorry, there was an error adding that item to your cart"
      return {
        ...state,
        addingItemToCart: false,
        cartError: true,
        cartErrorMessage: error
      }
    case "CLEAR_CART_ERROR":
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null
      }
    /*
    case "CLEAR_CART":
      return {
        ...initialState,
        phoneNumber: state.phoneNumber,
        purchaseErrorMessage: null,
        purchaseError: false
      }
    */
    case "LOG_OUT":
    case "PAYMENT_AUTHORIZE_SUCCESS":
      return initialState
    case "ADD_ITEM_TO_CART_AND_REDIRECT":
      return {
        ...state,
        addingItemToCart: true,
      }
    default:
      return state
  }
}
