import { Observable } from "rxjs/Observable"
import {
  mapTo,
  mergeMap,
  takeUntil,
  tap
} from "rxjs/operators"
import { ofType } from "redux-observable"

import {
  apiPaymentAuthorize,
  apiPaymentConfirm,
  apiSavePromotionCode,
  apiValidatePromotionCode,
  handleXhrError
} from "../../api/api"

import { fetchCart, removeFromCart } from "../Cart/actions"

import "rxjs/add/operator/mergeMap"
import "rxjs/add/operator/filter"
import "rxjs/add/operator/debounceTime"
import "rxjs/add/operator/map"
import "rxjs/add/operator/catch"
import {
  PAYMENT_AUTHORIZE,
  PAYMENT_AUTHORIZE_FAILURE,
  PAYMENT_AUTHORIZE_REDIRECT,
  PAYMENT_AUTHORIZE_STRIPE,
  PAYMENT_AUTHORIZE_SUCCESS,
  PAYMENT_CONFIRM,
  PAYMENT_CONFIRM_CHECK,
  PAYMENT_CONFIRM_COMPLETE,
  PAYMENT_CONFIRM_FAILURE,
  PAYMENT_CONFIRM_SUCCESS,
  PAYMENT_CONFIRM_UNAVAILABLE
} from "./constants"

export const savePromotionCodeAndReloadCartEpic = action$ => action$.ofType("SAVE_PROMOTION_CODE_SUCCESS").mapTo(fetchCart())

const savePromotionCodeFulfilled = (response, action) => ({
  type: "SAVE_PROMOTION_CODE_SUCCESS",
  payload: { response, action }
})

export const savePromotionCodeEpic = (action$, { getState }) => action$.ofType("SAVE_PROMOTION_CODE").mergeMap(action => apiSavePromotionCode(
  getState().credentials.credentials,
  action.payload.code
)
  .map(response => savePromotionCodeFulfilled(response, action))
  .catch(error => handleXhrError(error, "SAVE_PROMOTION_CODE_FAILURE")))

const validatePromotionCodeFulfilled = (response, action) => ({
  type: "VALIDATE_PROMOTION_CODE_SUCCESS",
  payload: { response, action }
})

export const validatePromotionCodeEpic = (action$, { getState }) => action$.ofType("VALIDATE_PROMOTION_CODE").mergeMap(action => apiValidatePromotionCode(
  getState().credentials.credentials,
  action.payload.code
)
  .map(response => validatePromotionCodeFulfilled(response, action))
  .catch(error => handleXhrError(error, "VALIDATE_PROMOTION_CODE_FAILURE")))

const doPromotionCodeCheck = code => ({
  type: "VALIDATE_PROMOTION_CODE",
  payload: { code }
})

export const autoCheckPromotionCodeEpic = action$ => action$
  .ofType("ENTER_PROMOTION_CODE")
  .debounceTime(200)
  .map(action => doPromotionCodeCheck(action.payload.code))

const redirectPaymentGateway = (action) => {
  const { response } = action.payload

  /* If payment gateway requires loading preformed HTML then stuff that into
     the browser DOM, otherwise redirect to preprepared URL */
  if (response.redirectHtml) {
    document.open()
    document.write(response.redirectHtml)
    document.close()
  } else if (response.redirectUrl) {
    window.location = response.redirectUrl
  }

  return Observable.create(observer => {
    observer.complete()
  })
}

export const paymentAuthorizeRedirectEpic = (action$, { getState }) => action$.ofType(PAYMENT_AUTHORIZE_REDIRECT).mergeMap(action => redirectPaymentGateway(action, getState)
  .catch(error => handleXhrError(error, PAYMENT_AUTHORIZE_FAILURE)))



/* Check if payment confirmation is complete */
const paymentConfirmCheck = (action, getState) => {

  /* Check all items have been confirmed before moving onto payment authorization */
  let complete = true

  const items = getState().cart.cart.items.filter(
    item => item.type === "appointment"
  )
  const confirmation = getState().purchase.paymentConfirmation

  for (let i = 0; i < items.length; i += 1) {
    complete = complete && confirmation[items[i].id] && confirmation[items[i].id].complete
  }

  if (complete) {
    return true
  }

  return false
}

/* Build action and payload for payment authorize step */
const paymentAuthorize = (action, getState) => {
  const { purchase } = getState()

  const payload = {
    reference: purchase.paymentReference
  }


  /* If it's stripe the payment needs to be authorized there before calling authorize at the flossie API */
  if (purchase.selectedPaymentMethod.slug === "stripe") {
    return { type: PAYMENT_AUTHORIZE_STRIPE, payload }
  }

  if (purchase.paymentToken) {
    payload.token = purchase.paymentToken
  }

  if (purchase.selectedPaymentMethodOption === "cc_existing") {
    payload.existing_card = 1
  } else if (purchase.selectedPaymentMethodOption === "cc_new") {
    const expiry = purchase.cardExpiry.split(" / ")

    payload.card_name = purchase.cardName
    payload.card_cvc = purchase.cardCvc
    payload.card_number = purchase.cardNumber

    const [cardExpiryMonth, cardExpiryYear] = expiry
    payload.card_expiry_month = cardExpiryMonth
    payload.card_expiry_year = cardExpiryYear
  }

  if (purchase.useFlossieDollars) {
    payload.use_balance = 1
  }

  return { type: PAYMENT_AUTHORIZE, payload }
}

async function authorizeStripePayment(purchase, observer) {
  const paymentFailure = { type: PAYMENT_AUTHORIZE_FAILURE, reference: purchase.paymentReference }

  if (!window.stripePayment) {
    paymentFailure.payload = { response: { message: "No valid Stripe client" } }

    observer.next(paymentFailure)
    observer.complete()

    return
  }

  if (!purchase.paymentSecret) {
    paymentFailure.payload = { response: { message: "No valid Stripe client secret found" } }

    observer.next(paymentFailure)
    observer.complete()

    return
  }

  const { stripe, cardElement } = window.stripePayment

  if (!cardElement) {
    paymentFailure.payload = { response: { message: "Count not find Stripe card element" } }

    observer.next(paymentFailure)
    observer.complete()

    return
  }

  const result = await stripe.confirmCardPayment(purchase.paymentSecret, {
    payment_method: {
      card: cardElement
    }
  })


  if (result.error) {
    // Show error to your customer (e.g., insufficient funds)
    console.log(result.error.message)

    paymentFailure.payload = { response: { message: result.error.message } }

    observer.next(paymentFailure)
    observer.complete()

    return
  }

  if (result.paymentIntent.status !== "succeeded") {
    paymentFailure.payload = { response: { message: "Unsuccessful payment intent status" } }

    observer.next(paymentFailure)
    observer.complete()

    return
  }

  const payload = { reference: purchase.paymentReference }

  observer.next({ type: PAYMENT_AUTHORIZE, payload })
  observer.complete()
}


export const paymentAuthorizeStripeEpic = (action$, { getState }) => action$.ofType(PAYMENT_AUTHORIZE_STRIPE).mergeMap(action => {
  const { purchase } = getState()

  return Observable.create(observer => {
    authorizeStripePayment(purchase, observer)
  })
})

const paymentConfirmAvailable = (action, getState) => {
  let available = true

  const items = getState().cart.cart.items.filter(
    item => item.type === "appointment"
  )
  const confirmation = getState().purchase.paymentConfirmation

  for (let i = 0; i < items.length; i += 1) {
    available = available && confirmation[items[i].id].available
  }

  return available
}

const paymentConfirmUnavailable = (action, getState) => {
  const { purchase, cart } = getState()

  const items = []

  /* Get cart items designated as unavailable */
  Object.keys(purchase.paymentConfirmation)
    .filter(id => purchase.paymentConfirmation[id].available === false)
    .forEach(id => {
      const item = cart.cart.items.find(i => i.id === id)

      items.push(item)
    })

  return { type: PAYMENT_CONFIRM_UNAVAILABLE, payload: { items } }
}

export const paymentRemoveUnavailableEpic = (action$) => action$.ofType(PAYMENT_CONFIRM_UNAVAILABLE).mergeMap(action => {
  return action.payload.items.map(item => removeFromCart(item.identifier))
})

export const paymentConfirmAvailableEpic = (action$, { getState }) => action$
  .ofType(PAYMENT_CONFIRM_COMPLETE)
  .filter(action => paymentConfirmAvailable(action, getState))
  .mergeMap(action => Observable.of(paymentAuthorize(action, getState)))

export const paymentConfirmUnavailableEpic = (action$, { getState }) => action$
  .ofType(PAYMENT_CONFIRM_COMPLETE)
  .filter(action => !paymentConfirmAvailable(action, getState))
  .mergeMap(action => Observable.of(paymentConfirmUnavailable(action, getState)))


export const paymentConfirmCheckEpic = (action$, { getState }) => action$
  .ofType(PAYMENT_CONFIRM_CHECK)
  .filter(action => paymentConfirmCheck(action, getState))
  .mapTo({ type: PAYMENT_CONFIRM_COMPLETE })

/* Emit a single PAYMENT_CONFIRM_CHECK action once per second to monitor availability checks until all are complete */
export const paymentConfirmMonitorEpic = (action$) => {
  return action$.pipe(
    ofType(PAYMENT_CONFIRM),
    mergeMap(() => Observable.interval(1000).pipe(
      mapTo({ type: PAYMENT_CONFIRM_CHECK }),
      takeUntil(action$.pipe(
        ofType(PAYMENT_CONFIRM_COMPLETE, PAYMENT_CONFIRM_FAILURE)
      )),
    )),
  )
}


const paymentConfirmFulfilled = (response, action, getState) => {
  const items = getState().cart.cart.items.filter(
    item => item.type === "appointment"
  )

  if (response.success && response.reference && items.length > 0) {
    return {
      type: PAYMENT_CONFIRM_SUCCESS,
      payload: { response, action, items }
    }
  }

  return { type: PAYMENT_CONFIRM_FAILURE, payload: { response, action } }
}


export const paymentConfirmEpic = (action$, { getState }) => action$.ofType(PAYMENT_CONFIRM).mergeMap(action => {
  const { cart, credentials } = getState()

  return apiPaymentConfirm(credentials.credentials, cart.id, action.payload)
    .map(response => paymentConfirmFulfilled(response, action, getState))
    .catch(error => handleXhrError(error, PAYMENT_CONFIRM_FAILURE))
})

const paymentAuthorizeFulfilled = (response, action) => {
  if (response.success && response.redirect) {
    return { type: PAYMENT_AUTHORIZE_REDIRECT, payload: { response, action } }
  }

  if (response.success) {
    return { type: PAYMENT_AUTHORIZE_SUCCESS, payload: { response, action } }
  }

  return { type: PAYMENT_AUTHORIZE_FAILURE, payload: { response, action } }
}

export const paymentAuthorizeEpic = (action$, { getState }) => action$.ofType(PAYMENT_AUTHORIZE).mergeMap(action => apiPaymentAuthorize(
  getState().credentials.credentials,
  action.payload
)
  .map(response => paymentAuthorizeFulfilled(response, action, getState))
  .catch(error => handleXhrError(error, PAYMENT_AUTHORIZE_FAILURE)))
