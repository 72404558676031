import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import moment from "moment-timezone"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroller"

import Animation from "../../components/Animation"
import ScrollManager from "../../components/ScrollManager"
import PromoBanner from "../../components/banners/PromoBanner"
import CampaignBanner from "../../components/banners/CampaignBanner"

import { navToInventory } from "../InventoryView/actions"
import {
  clickWhen,
  clickWhat,
  clickWhere,
  fetchInstantBookServices,
  readyToFetchServices
} from "./actions"

import WrapContainer from "../WrapContainer"
import WhatWhenWhereContainer from "./WhatWhenWhereContainer"
import CalendarContainer from "../InventoryFilter/CalendarContainer"
import CategoryContainer from "../InventoryFilter/CategoryContainer"
import CompanyFilterContainer from "../InventoryFilter/CompanyFilterContainer"
import SortContainer from "../InventoryFilter/SortContainer"
import ShadowBox from "../../components/ShadowBox"
import MetaSetter from "../../components/MetaSetter"

import { urlToFilter, filterToUrl } from "./urlFilter"

import { InstantBookService } from "./InstantBookService"

const DayHeading = styled.h2`
  color: ${props => props.theme.colors.secondary};
  padding: 20px 0px 10px 0px;
  margin: 0px;
  border-bottom: 1px solid #ccc;
  text-align: center;
`

const ListContainer = styled.div`
  flex: 2;
  max-width: ${({ theme }) => theme.instantBookMaxWidth};
  margin-left: auto;
  margin-right: auto;
`

const NoResults = () => (
  <div>
    <DayHeading>No Results!</DayHeading>
    <p>Try different search filters</p>
    <div style={{ marginTop: 2000 }}>&nbsp;</div>
    <p>Try different search filters</p>
  </div>
)

const noScroll = () => {
  window.scrollTo(0, 0)
}

class InstantBookContainer extends Component {
  componentDidMount() {
    /* Prevent a full API get and refresh if the component is mounted after
       scrolling when the user clicks back after viewing a service */
    const { isScrolling } = this.props
    if (!isScrolling) {
      this.extractSearch(true)
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props
    if (pathname !== prevProps.pathname) {
      this.extractSearch(false)
    }

    /* Lock window scrollbar in place when modals are open */
    const { whenOpen, whatOpen, whereOpen } = this.props
    if (whenOpen || whatOpen || whereOpen) {
      window.addEventListener("scroll", noScroll)
    } else {
      window.removeEventListener("scroll", noScroll)
    }
  }
  // Filters

  setSortOption = opt => {
    const filter = this.urlToFilterWithDefault()
    filter.sortBy = opt
    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }
  // Filters End

  // flatten services, as they are in groups by page
  getServicesFromProps = () => {
    if (!this.hasAnyServices()) return []
    const services = []
    const { instantbookservices } = this.props

    Object.values(instantbookservices).forEach(e => {
      e.forEach(f => services.push(f))
    })

    return services
  }

  fetchServices = () => {
    const { readyToFetch, fetchInstantBookServices } = this.props
    return readyToFetch && fetchInstantBookServices()
  }

  hasAnyServices = () => {
    const { instantbookservices } = this.props
    return Object.keys(instantbookservices).length > 0
  }

  clickCategory = category_slug => {
    const filter = this.urlToFilterWithDefault()
    if (filter.selectedCategories.includes(category_slug)) {
      filter.selectedCategories = filter.selectedCategories.filter(
        r => r !== category_slug
      )
    } else {
      filter.selectedCategories.push(category_slug)
    }

    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }

  clickCampaign = campaign_slug => {
    const filter = this.urlToFilterWithDefault()
    if (filter.selectedCampaigns.includes(campaign_slug)) {
      filter.selectedCampaigns = filter.selectedCampaigns.filter(
        r => r !== campaign_slug
      )
    } else {
      filter.selectedCampaigns.push(campaign_slug)
    }

    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }

  clickRegion = region_slug => {
    const filter = this.urlToFilterWithDefault()

    if (filter.selectedRegions.includes(region_slug)) {
      filter.selectedRegions = filter.selectedRegions.filter(
        r => r !== region_slug
      )
    } else {
      filter.selectedRegions.push(region_slug)
    }
    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }

  // company
  clickCompany = company_slug => {
    const filter = this.urlToFilterWithDefault()

    if (filter.selectedCompanies.includes(company_slug)) {
      filter.selectedCompanies = filter.selectedCompanies.filter(
        c => c !== company_slug
      )
    } else {
      filter.selectedCompanies.push(company_slug)
    }

    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }

  extractSearch = (init) => {
    const { setFilter, initFilter } = this.props

    if (init) {
      initFilter(this.urlToFilterWithDefault())
    } else {
      setFilter(this.urlToFilterWithDefault())
    }
  }

  // Calendar
  clickDay = d => {
    const date = moment(d).format("YYYY-MM-DD")
    const filter = this.urlToFilterWithDefault()
    // check if day is included

    if (filter.selectedDays.includes(date)) {
      filter.selectedDays = filter.selectedDays.filter(day => day !== date)
    } else {
      filter.selectedDays.push(date)
    }
    const { navToFilter } = this.props
    navToFilter(filterToUrl(filter))
  }

  urlToFilterWithDefault = () => {
    const { pathname, loggedIn } = this.props
    return urlToFilter(pathname, loggedIn)
  }

  servicesWithDayHeadings = () => {
    const today = moment().format("YYYY-MM-DD")
    let current = null
    const serviceContent = []
    const { lastFilterMessageShow } = this.props
    if (lastFilterMessageShow > 0) {
      serviceContent.push(
        <p
          style={{ textAlign: "center", padding: 20 }}
          key="no-matching-results"
        >
          Sorry no matching search results. We've broadened your search to help you find what you need.
        </p>
      )
    }

    const { isMobile, isVip } = this.props
    this.getServicesFromProps().forEach(service => {
      if (service.date !== current) {
        current = service.date
        serviceContent.push(
          <DayHeading isMobile={isMobile} key={`heading-${current}`}>
            {moment(service.date).format("dddd ")}
            {" "}
            Appointments
          </DayHeading>
        )
      }

      const linkUrl = service.date === today
        ? `/services/${service.slug}`
        : `/services/${service.slug}/date/${service.date}`

      serviceContent.push(
        <InstantBookService
          isMobile={isMobile}
          key={`service-${service.id}-${service.date}`}
          {...service}
          userIsVip={isVip}
          aLink={linkUrl}
          clickAction={() => this.clickService(service.slug, service.date)}
        />
      )
    })
    return serviceContent
  }

  renderMeta = () => {
    const {
      pathname, loggedIn, metaTitle, metaDescription
    } = this.props

    if (pathname === "/") {
      return (
        <MetaSetter
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        />
      )
    }

    const currentFilter = urlToFilter(pathname, loggedIn)

    // if we have multiple selections then don't attempt to set title

    // TODO: these should be selectors
    if (
      currentFilter.selectedCategories.length
        + currentFilter.selectedCompanies.length
        + currentFilter.selectedRegions.length
        + currentFilter.selectedCampaigns.length
      !== 1
    ) return <MetaSetter />

    if (currentFilter.selectedCategories.length === 1) {
      const { categories } = this.props
      const categorySlug = currentFilter.selectedCategories[0]
      const category = categories.find(c => c.slug === categorySlug)
      if (category) {
        return (
          <MetaSetter
            metaTitle={category.name}
            metaDescription={category.description}
          />
        )
      }
    }

    if (currentFilter.selectedCampaigns.length === 1) {
      const { campaigns } = this.props
      const campaignSlug = currentFilter.selectedCampaigns[0]
      const campaign = campaigns.find(c => c.slug === campaignSlug)
      if (campaign) {
        return (
          <MetaSetter
            metaTitle={campaign.name}
          />
        )
      }
    }

    if (currentFilter.selectedCompanies.length === 1) {
      const companySlug = currentFilter.selectedCompanies[0]
      const { companies } = this.props
      const company = companies.find(c => c.slug === companySlug)
      if (company) {
        return (
          <MetaSetter
            metaTitle={company.name}
            metaDescription={company.description}
          />
        )
      }
    }

    if (currentFilter.selectedRegions.length === 1) {
      const regionSlug = currentFilter.selectedRegions[0]
      const { regions } = this.props
      const region = regions.find(c => c.slug === regionSlug)
      if (region) {
        return <MetaSetter metaTitle={region.name} />
      }
    }
    return <MetaSetter />
  }

  clickService(slug, date) {
    const { navToInventory } = this.props
    navToInventory(slug, date)
  }

  renderList = () => {
    const { readyToFetch, anyMorePages, noResults } = this.props

    if (!readyToFetch) return null

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.fetchServices}
        hasMore={anyMorePages}
        loader={(
          <Animation
            style={{ position: "absolute", width: "100%", left: 0 }}
            key={0}
          />
        )}
      >
        {this.renderMeta()}
        <ScrollManager scrollKey="instant-book-services" />
        {this.servicesWithDayHeadings()}
        {noResults && <NoResults />}
      </InfiniteScroll>
    )
  }


  render() {
    const {
      whenOpen,
      clickWhen,
      isMobile,
      clickWhat,
      whereOpen,
      clickWhere,
      whatOpen
    } = this.props
    return (
      <WrapContainer
        loginRequired={false}
        showNavBack={false}
        navTitle="Instant Book"
      >
        <PromoBanner />
        <WhatWhenWhereContainer
          selectCalendarDay={this.clickDay}
          selectCompany={this.clickCompany}
          toggleRegion={this.clickRegion}
          toggleCategory={this.clickCategory}
          toggleCampaign={this.clickCampaign}
        />
        <CampaignBanner />
        <div style={{ paddingTop: 0 }}>
          {whenOpen && (
            <ShadowBox
              header="When?"
              closeBox={clickWhen}
              isFullscreen={isMobile}
            >
              <CalendarContainer
                clickDay={this.clickDay}
                closeAction={clickWhen}
              />
            </ShadowBox>
          )}
          {whatOpen && (
            <ShadowBox
              header="What?"
              closeBox={clickWhat}
              isFullscreen={isMobile}
            >
              <CategoryContainer
                toggleCategory={this.clickCategory}
                toggleCampaign={this.clickCampaign}
                closeAction={clickWhat}
              />
            </ShadowBox>
          )}
          {whereOpen && (
            <ShadowBox
              header="Where?"
              closeBox={clickWhere}
              isFullscreen={isMobile}
            >
              <CompanyFilterContainer
                selectCompany={this.clickCompany}
                selectRegion={this.clickRegion}
                closeAction={clickWhere}
              />
            </ShadowBox>
          )}

          <SortContainer setSortOption={this.setSortOption} />

          <ListContainer>
            {this.renderList()}
          </ListContainer>
        </div>
      </WrapContainer>
    )
  }
}

InstantBookContainer.propTypes = {
  whenOpen: PropTypes.bool.isRequired,
  whatOpen: PropTypes.bool.isRequired,
  whereOpen: PropTypes.bool.isRequired,

  isMobile: PropTypes.bool.isRequired,

  clickWhat: PropTypes.func.isRequired,
  clickWhere: PropTypes.func.isRequired,
  clickWhen: PropTypes.func.isRequired,

  setFilter: PropTypes.func.isRequired,
  initFilter: PropTypes.func.isRequired,
  navToFilter: PropTypes.func.isRequired,

  fetchInstantBookServices: PropTypes.func.isRequired,
  instantbookservices: PropTypes.object.isRequired,
  navToInventory: PropTypes.func.isRequired,
  readyToFetchServices: PropTypes.func.isRequired,
  readyToFetch: PropTypes.bool.isRequired,
  lastFilterMessageShow: PropTypes.number.isRequired,
  anyMorePages: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
  isScrolling: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,

  pathname: PropTypes.string.isRequired,

  categories: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,


  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string
}

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,

    instantbookservices: state.instantbook.instantbookservices,
    whenOpen: state.instantbook.whenOpen,
    whatOpen: state.instantbook.whatOpen,
    whereOpen: state.instantbook.whereOpen,
    readyToFetch: state.instantbook.readyToFetch,
    anyMorePages: state.instantbook.anyMorePages,
    isScrolling: state.instantbook.isScrolling,

    hash: state.router.location.hash,
    isMobile: state.browser.lessThan.mobile,

    sortBy: state.inventoryFilter.sortBy,

    loggedIn: !!state.profile.profile.id,

    noResults: state.instantbook.noResults,
    isVip: !!state.profile.profile.vip,
    lastFilterMessageShow: state.inventoryFilter.lastFilterMessageShow,
    lastFilterAddedName: state.inventoryFilter.lastFilterAddedName,

    categories: state.inventoryFilter.catCategories,
    companies: state.inventoryFilter.companies,
    regions: state.inventoryFilter.regions,
    campaigns: state.inventoryFilter.campaigns
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  navHome: () => dispatch(push("/")),
  navToFilter: filterUrl => dispatch(push(filterUrl)),
  navToInventory: (slug, date) => dispatch(navToInventory(currentParams, { id: slug, date })),

  clickWhat: () => dispatch(clickWhat()),
  clickWhen: () => dispatch(clickWhen()),
  clickWhere: () => dispatch(clickWhere()),

  fetchInstantBookServices: payload => dispatch(fetchInstantBookServices(payload)),
  readyToFetchServices: () => dispatch(readyToFetchServices()),

  setFilter: filterState => dispatch({ type: "SET_INVENTORY_FILTER", payload: { filterState } }),
  initFilter: filterState => dispatch({ type: "INIT_INVENTORY_FILTER", payload: { filterState } })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstantBookContainer)
