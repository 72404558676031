import ReactGA from "react-ga"
import ReactGA4 from "react-ga4"
import TagManager from "react-gtm-module"
import ReactPixel from "react-facebook-pixel"

const { GOOGLE_ANALYTICS_ID, GOOGLE_ANALYTICS4_ID, GOOGLE_TAG_MANAGER_ID, FACEBOOK_PIXEL_ID } = SITE_CONFIG

const allowTracking = () => {
  return ((GOOGLE_ANALYTICS_ID.length || GOOGLE_ANALYTICS4_ID.length) && document.cookie.includes("cookiesok") && document.cookie.split(";").some((item) => item.includes("cookiesok=true")))
}


export const initGaTrackerEpic = (action$, { dispatch, getState }) => action$.ofType("COOKIE_OK").mergeMap(action => {
  if (GOOGLE_ANALYTICS_ID.length) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  }

  if (GOOGLE_ANALYTICS4_ID.length) {
    ReactGA4.initialize(GOOGLE_ANALYTICS4_ID)
  }

  if (GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_ID
    })
  }
  return []
})

export const initFbTrackerEpic = (action$, { dispatch, getState }) => action$.ofType("COOKIE_OK").mergeMap(action => {
  if (FACEBOOK_PIXEL_ID) {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    }

    for (let i = 0; i < FACEBOOK_PIXEL_ID.length; i++) {
      ReactPixel.init(FACEBOOK_PIXEL_ID[i], advancedMatching, options)
    }

    // ReactPixel.grantConsent()
    ReactPixel.pageView()
  }
  return []
})


// GA
const GaActions = {
  "@@router/LOCATION_CHANGE": action => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      Array.isArray(GOOGLE_ANALYTICS_ID) && GOOGLE_ANALYTICS_ID
        .filter(tracker => tracker.gaOptions && tracker.gaOptions.name)
        .map(tracker => tracker.gaOptions.name)
    )

    ReactGA4.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    })
  },
  /* REGISTRATION_SUCCESS: action => {
    gtag("event", "sign_up")
    gtag("config", GOOGLE_ANALYTICS_ID, {
      page_title: "Registration Success",
      page_path: "/register/success"
    })
  },
  TRACK_PURCHASE_START: (action, getState) =>
    gtag("event", "begin_checkout", {
      items: [
        {
          id: getState().inventoryView.selectedAvailability,
          name: getState().inventoryView.inventoryItem.description.name,
          // price: calculateVoucheredPrice(getState),
          brand: getState().inventoryView.inventoryItem.company.name,
          category: `${getState().inventoryView.inventoryItem.description.category.id}-${
            getState().inventoryView.inventoryItem.description.category.name
          }`
        }
      ]
    }),
  FETCH_INVENTORY_SUCCESS: (action, getState) =>
    gtag("event", "view_item", {
      items: [
        {
          id: action.payload.response.id,
          name: action.payload.response.description.name,
          // price: calculateVoucheredPrice(getState, true),
          brand: action.payload.response.company.name,
          category: `${action.payload.response.description.category.id}-${action.payload.response.description.category.name}`
        }
      ]
    }),
  MAKE_PURCHASE_SUCCESS: (action, getState) => {
    gtag("event", "purchase", {
      id: action.payload.response.id,
      transaction_id: action.payload.response.id,
      // value: calculateVoucheredPrice(getState),
      currency: getState().inventoryView.inventoryItem.currency.code,
      tax: 0,
      shipping: 0,
      items: [
        {
          id: getState().inventoryView.selectedAvailability,
          name: `${getState().inventoryView.inventoryItem.description.name} -- ${
            getState().inventoryView.inventoryItem.company.name
          }`,
          // price: calculateVoucheredPrice(getState),
          quantity: 1,
          brand: getState().inventoryView.inventoryItem.company.name,
          category: `${getState().inventoryView.inventoryItem.description.category.id}-${
            getState().inventoryView.inventoryItem.description.category.name
          }`
        }
      ]
    })
  },
  FETCH_INSTANT_BOOK_SERVICES_SUCCESS: (action, getState) => {
    gtag("event", "view_item_list", {
      items: action.payload.response.inventory.map((i, idx) => ({
        list_position: idx + 1 + action.payload.action.payload.filter.offset,
        id: i.id,
        name: i.description.name,
        brand: i.company.name,
        category: `${i.description.category.id}-${i.description.category.name}`,
        price: i.price.lowest.price,
        list_name: "Instant Book"
      }))
    })
  } */
}

export const gaTrackerActionsEpic = (action$, { dispatch, getState }) =>
  action$.ofType(...Object.keys(GaActions)).mergeMap(action => {
    if (allowTracking()) {
      const result = GaActions[action.type](action, getState)
    }
    return []
  })




const trackItem = (getState, viewOnly = false) => {
  const { inventoryItem } = getState().inventoryView
  if (!inventoryItem) return {}
  return {
    content_type: "product",
    content_ids: [inventoryItem.id],
    contents: [
      {
        id: inventoryItem.id,
        quantity: 1
        // item_price: calculateVoucheredPrice(getState, viewOnly)
      }
    ],
    currency: inventoryItem.currency.code,
    num_items: 1
    // value: calculateVoucheredPrice(getState, viewOnly)
  }
}

const FbActions = {
  "@@router/LOCATION_CHANGE": action => fbq("track", "PageView"),
  REGISTRATION_SUCCESS: action => fbq("track", "CompleteRegistration"),
  TRACK_PURCHASE_START: (action, getState) =>
    fbq("track", "AddToCart", trackItem(getState)),
  FETCH_INVENTORY_SUCCESS: (action, getState) =>
    fbq("track", "ViewContent", trackItem(getState, true)),
  MAKE_PURCHASE: (action, getState) =>
    fbq("track", "InitiateCheckout", trackItem(getState)),
  MAKE_PURCHASE_SUCCESS: (action, getState) =>
    fbq("track", "Purchase", trackItem(getState)),
  MAKE_PURCHASE_FAILURE: (action, getState) =>
    fbq("trackCustom", "PurchaseFailure", trackItem(getState))
}

export const fbTrackerActionsEpic = (action$, { dispatch, getState }) =>
  action$.ofType(...Object.keys(FbActions)).mergeMap(action => {
    if (allowTracking() && typeof fbq !== "undefined") {
      const result = FbActions[action.type](action, getState)
    }
    return []
  })
