import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ProductImage from "./ProductImage"
import FreeIcon from "../icons/free.svg"

const ThemedFreeIcon = styled(FreeIcon)`
  fill: ${({ theme }) => theme.colors.primary};

  position: absolute;
  width: 35px;
  height: 35px;
  top: 0px;
  left: -10px;
`

const FreeProduct = ({ product }) => (
  <div style={{ position: "relative" }}>
    <ProductImage product={product} />
    <ThemedFreeIcon />
  </div>
)

FreeProduct.propTypes = {
  product: PropTypes.object,
}

export default FreeProduct
