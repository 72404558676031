import React from "react"
import PropTypes from "prop-types"

import { Trans, useTranslation } from "react-i18next"
import { SubmitInput } from "../../components/forms/Inputs"
import ShadowBox from "../../components/ShadowBox"

const PatchTestingAlert = ({
  onConfirm, onClose, isMobile, requiredPatchTestItems,
}) => {
  const { t } = useTranslation()

  if (requiredPatchTestItems && requiredPatchTestItems.length > 0) {
    return (
      <ShadowBox
        containerStyle={{
          minWidth: 0,
          minHeight: 0,
          maxWidth: 300,
          marginLeft: 10,
          marginRight: 10,
          textAlign: "center"
        }}
        header={t("patch_testing_title")}
        isFullscreen={isMobile}
        closeBox={onClose}
      >
        <div>
          <React.Fragment>
            <Trans
              i18nKey="patch_testing_content_header"
              components={{
                p: <p />,
                small: <small />,
              }}
            />
            {
              requiredPatchTestItems.map(service => <p key={service.id}>{service.description.name}</p>)
            }
            {t("patch_testing_content_footer", { defaultValue: "" })}
          </React.Fragment>
          <SubmitInput
            onClick={onConfirm}
            value="Confirm"
          />
        </div>
      </ShadowBox>
    )
  }
  return null
}

PatchTestingAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  requiredPatchTestItems: PropTypes.array,
}

export default PatchTestingAlert
