import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStylesWrapper = createGlobalStyle`
  /** GLOBAL STYLES. **/
  html {
    overflow-y: scroll;
  }
  body {
    font-family: proxima-nova, sans-serif;
    margin: 0;
    padding: 0;
  }
  p {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    letter-spacing: normal;
  }
  h1 {
    font-size: 32px;
    font-family: proxima-nova, sans-serif;
  }
  h2 {
    font-size: 24px;
    font-family: proxima-nova, sans-serif;
  }
  h3 {
    letter-spacing: normal;
    font-size: 18px;
    font-family: proxima-nova, sans-serif;
  }
  h4 {
    letter-spacing: normal;
    font-size: 16px;
    font-family: proxima-nova, sans-serif;
  }
  * {
    font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  .preserve-line-breaks {
    white-space: pre;
  }

  // Theme Global Styles Override.
  ${({ theme, franchiseTheme }) => (franchiseTheme.globalStyles ? franchiseTheme.globalStyles(theme) : "")}
  /** end: GLOBAL STYLES. **/

  /** THEMED MAP. **/
  .themed-cluster {
    img {
      // this filter converts the black cluster circle to the primary color of the current theme.
      // for new themes use this tool to get the filter https://codepen.io/nicofetter/full/dyRPGjP
      filter: ${({ theme }) => theme.filterFromBlackToPrimary};
    }
  }

  .themed-label {
    width: 60px;
    height: 0px;
    overflow: visible;
    text-align: center;
    font-size: 10px !important;
    white-space: normal !important;
    color: ${({ theme }) => theme.colors.primary};
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;
    background-color: #f00;
  }

  .gm-style {
    // this is the only way I found to style the marker icons in the react-google-maps
    img[src="/location-pin.svg"] {
      max-height: 38px;
      bottom: 0;
      top: auto !important;
      filter: ${({ theme }) => theme.filterFromBlackToPrimary};
    }
  }
  /** end: THEMED MAP. **/
`

export const GlobalStyles = ({ franchiseTheme }) => <GlobalStylesWrapper franchiseTheme={franchiseTheme} />


const renderLogo = (logoImage, logoSize, onClick) => (
  <div>
    <a href="/" onClick={onClick}>
      <img
        style={{ display: "block", padding: "15px 0" }}
        alt="logo"
        src={logoImage}
        height={logoSize}
      />
    </a>
  </div>
)

export const theme = (franchiseTheme) => ({
  // flossie default theme

  // Max width for page as a whole, primarily affecting header/navigation
  pageMaxWidth: "none",

  // Max width for instant book listings on the home page
  instantBookMaxWidth: "800px",

  logoImage: "/header-left-dolly.png",
  logoSize: 70,
  logo: renderLogo,

  wwwBgImage: "/www-background.jpg",
  wwwBgColor: "#676767",
  wwwBgImagePosition: "center center",
  wwwBgImagePositionMobile: "center center",

  cookieBgColor: "#373737ee",
  sectionDividerColor: "rgba(250,200,205,0.6)",

  filterFromBlackToPrimary: "invert(63%) sepia(74%) saturate(3223%) hue-rotate(299deg) brightness(96%) contrast(94%)",
  ...franchiseTheme, // franchise override

  colors: {
    primary: "#EF609F", // For brand module button fills, accents: eg star ratings - use when appearing on white background (ie #FFFFFF)
    primaryAccent: "#FFF",

    secondary: "#EF609F", // For utility nav, button fill and outlines, icons, text
    secondaryAccent: "#FFF",

    tertiary: "#ECECEC", // For rollover product panels, and module backgrounds as needed

    accent: "#EF609F", // For accents like discounts, etc.
    error: "#BC3C3C", // For error messages

    disabled: "#e2e2e2",
    disabledAccent: "#FFF",

    inactive: "#CCC",
    headers: "#444444",
    lightHover: "#EF609F11",
    darkHover: "#EDEAE3",

    overlayColor: "rgba(250,200,205,0.6)",

    price: "#EF609F",

    ...(franchiseTheme.colors ? franchiseTheme.colors : {}),
  },
  topNav: {
    background: "#444444",
    links: "#FFF",
    icons: "#000",
    whatWhenWhereText: "#FFF",

    ...(franchiseTheme.topNav ? franchiseTheme.topNav : {}),
  },
  sideNav: {
    alignment: "right",
    top: "70px",
    background: "#575757",
    links: "#FFF",
    activeLinks: "#EF609F",
    legalLinks: "#CCC",
    ...(franchiseTheme.sideNav ? franchiseTheme.sideNav : {}),
  },
  dateSelector: {
    defaultText: "#000",
    disabledText: "#CCC",
    saleText: "#EF609F",
    selectedText: "#fff",
    selectedBackground: "#444",
    ...(franchiseTheme.dateSelector ? franchiseTheme.dateSelector : {}),
  },
  timeSelector: {
    defaultText: "#000",
    selectedText: "#FFF",
    selectedBackground: "#444",
    hoverText: "#000",
    hoverBackground: "#FFF",
    ...(franchiseTheme.timeSelector ? franchiseTheme.timeSelector : {}),
  },
  button: {
    borderRadius: "30px",
    borderWidth: "2px",
    textTransform: "none",
    letterSpacing: "0px",
    fontWeight: 700,
    fontSizeSmall: "13px",
    fontSizeLarge: "16px",
    ...(franchiseTheme.button ? franchiseTheme.button : {}),
  },
  paymentMethod: {
    backgroundColor: "#ef609f",
    headerText: "#fff",
    ...(franchiseTheme.paymentMethod ? franchiseTheme.paymentMethod : {}),
  },
  poweredByFlossie: {
    backgroundColor: "#ef609f",
    text: "#fff",
    ...(franchiseTheme.poweredByFlossie ? franchiseTheme.poweredByFlossie : {}),
  },
  responsive: {
    mobile: 768,

    // Defined in redux/rootReducer.js by redux-responsive
    extraSmall: "(max-width: 480px)",
    small: "(max-width: 768px)",
    medium: "(max-width: 992px)",
    large: "(max-width: 1200px)",

  },
})
