import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"
import { goBack } from "connected-react-router"
import styled from "styled-components"

import MetaSetter from "../../components/MetaSetter"
import { CompanyLogo } from "../../components/images/CompanyLogo"
import { BackLink } from "../../components/nav/Links"
import ParagraphText from "../../components/ParagraphText"
import Pricing from "../../components/Pricing"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import ShadowBox from "../../components/ShadowBox"
import ErrorBar from "../../components/ErrorBar"
import ProductDescription from "../../components/ProductDescription"
import ExtraTag from "../../components/tags/ExtraTag"
import CampaignTag from "../../components/tags/CampaignTag"
import { SubmitInput } from "../../components/forms/Inputs"
import { ServiceGallery, getServiceImage } from "../../components/images/ServiceGallery"

import FavouritesBtn from "../../components/favourites/FavouritesBtn"

import CalendarContainer from "./CalendarContainer"

import CalendarBookingModule from "./CalendarBookingModule"

import { CompanyLink } from "./components"

import { clearCartError } from "../Cart/actions"
import { formatDate } from "../../../utils/momentHelpers"

import {
  fetchInventory,
  setInventoryDate,
  selectAvailability,
} from "./actions"

import { navToFourOhFour } from "../FourOhFour/actions"

import {
  userFavouriteService,
  fetchFavourites,
  setUserFavouriteServiceId
} from "../Favourites/actions"

import { redirectToLogin, storeLastLocation } from "../actions"
import { navToCompany } from "../Companies/actions"

import { navToInventory } from "./actions"

import WrapContainer from "../WrapContainer"

import { Loading } from "../../components/nav/Loading"
import Seperator from "../../components/Seperator"
import { AccordianHeader, AccordianContent } from "../../components/accordians"
import { RenderStarRatings, Review } from "./components"

import { getUserHasFavouritedService } from "../Favourites/selectors"
import { ArrowRightIcon } from "../../components/icons"

class InventoryViewContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedTab: "about" }
    this.reviewRef = React.createRef()
  }

  componentDidMount() {
    const {
      setInventoryDate,
      fetchInventory,
      fetchFavourites,
      match: { params },
    } = this.props

    const { date: dateFromUrl } = params

    // check if we have a date in the URL
    if (dateFromUrl) {
      // we save the url date in the redux store
      setInventoryDate(dateFromUrl)
    } else {
      // we save todays date in the redux store
      setInventoryDate(this.inventoryDate())
    }

    fetchInventory(this.inventoryId(), this.inventoryDate())
    fetchFavourites()

    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    // redirect from slug to ID
    const {
      setUserFavouriteServiceId,
      inventoryItem,
      inventoryDate,
      navToInventory,
      fourOhFour,
      navToFourOhFour
    } = this.props

    /* If inventoryDate in redux store has changed, update the date in the URL as well */
    if (
      inventoryItem
      && inventoryDate
      && prevProps.inventoryDate !== inventoryDate
    ) {
      navToInventory(inventoryItem.slug, inventoryDate, true)
    }

    if (!prevProps.inventoryItem && inventoryItem) {
      setUserFavouriteServiceId(inventoryItem.id)
      // The inventory item was not loaded and now is
    }

    if (!prevProps.fourOhFour && fourOhFour) {
      navToFourOhFour('INVENTORY_VIEW_STATE_404')
    }
  }

  clickTab = tab => {
    const { selectedTab } = this.state
    if (selectedTab !== tab) {
      this.setState({ selectedTab: tab })
    } else {
      this.setState({ selectedTab: false })
    }
  }

  selectAvailability = id => {
    const { selectAvailability } = this.props
    selectAvailability(id)
  }

  renderStarRatings = item => {
    if (item.rating_count === "0" && item.company.rating_count !== "0") {
      return (
        <RenderStarRatings
          clickStars={this.clickStars}
          rating={parseFloat(item.company.rating_customer)}
          ratingCount={parseInt(item.company.rating_count, 10)}
        />
      )
    }

    return (
      <RenderStarRatings
        clickStars={this.clickStars}
        rating={parseFloat(item.rating_customer)}
        ratingCount={parseInt(item.rating_count, 10)}
      />
    )
  }

  renderReviewContent = item => {
    const { serviceReviews, companyReviews } = this.props

    const { selectedTab: tab } = this.state

    if (serviceReviews.length === 0 && companyReviews.length === 0) return null

    const reviews = serviceReviews.length > 0 ? serviceReviews : companyReviews

    return (
      <React.Fragment>
        <Seperator />
        <span ref={this.reviewRef} />
        <AccordianHeader
          title={item.reviews.length === 0 ? "Salon Reviews" : "Reviews"}
          open={tab === "reviews"}
          onClick={() => this.clickTab("reviews")}
        />
        <AccordianContent open={tab === "reviews"}>
          <div style={{ maxHeight: 400, overflowY: "scroll" }}>
            {reviews.map(this.renderReview)}
          </div>
        </AccordianContent>
      </React.Fragment>
    )
  }

  renderServiceExtra = serviceExtra => {
    return (<ProductDescription key={`product-${serviceExtra.id}`} product={serviceExtra} />)
  }

  renderDescriptiveContent = item => {
    const { serviceReviews, companyReviews, isLarge } = this.props

    const { selectedTab: tab } = this.state

    return (
      <React.Fragment>

        {/* TODO: Make sure this works properly with new API before reinstating
        {(item.extras || item.campaigns) && (
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 8 }}>
            {item.extras && (<ExtraTag />)}
            {item.campaigns && item.campaigns.map(c => (<CampaignTag campaign={c} />))}
          </div>
        )} */}

        {item.extras && item.extras.map(this.renderServiceExtra)}
        {item.campaigns && item.campaigns.map(this.renderCampaign)}

        {!isLarge && this.renderCompany()}

        <Seperator />
        <AccordianHeader
          title="About This Service"
          open={tab === "about"}
          onClick={() => this.clickTab("about")}
        />
        <AccordianContent open={tab === "about"}>
          <ParagraphText
            text={item.description.about}
            style={{ margin: "0 0 8px 0", fontSize: 16, color: "#000" }}
          />

          <div style={{ marginBottom: 20 }}>
            <span style={{ fontWeight: 500 }}>Duration:</span>
            {" "}
            {item.duration}
            {" "}
            Mins
          </div>
        </AccordianContent>

        {item.description.product_info && (
          <React.Fragment>
            <Seperator />
            <AccordianHeader
              title="Products We Use"
              open={tab === "product"}
              onClick={() => this.clickTab("product")}
            />
            <AccordianContent open={tab === "product"}>
              <ParagraphText
                text={item.description.product_info}
                style={{ marginBottom: 20 }}
              />
            </AccordianContent>
          </React.Fragment>
        )}

        {item.description.preparation && (
          <React.Fragment>
            <Seperator />
            <AccordianHeader
              title="How to Prepare"
              open={tab === "prepare"}
              onClick={() => this.clickTab("prepare")}
            />
            <AccordianContent open={tab === "prepare"}>
              <ParagraphText
                text={item.description.preparation}
                style={{ marginBottom: 20 }}
              />
            </AccordianContent>
          </React.Fragment>
        )}

        {item.description.terms_and_conditions && (
          <React.Fragment>
            <Seperator />
            <AccordianHeader
              title="Booking Terms"
              open={tab === "terms"}
              onClick={() => this.clickTab("terms")}
            />
            <AccordianContent open={tab === "terms"}>
              <ParagraphText
                text={item.description.terms_and_conditions}
                style={{ marginBottom: 20 }}
              />
            </AccordianContent>
          </React.Fragment>
        )}

        {this.renderReviewContent(item)}

        {(item.description.preparation
          || item.description.terms_and_conditions
          || item.description.product_info
          || serviceReviews.length > 0
          || companyReviews.length > 0) && <Seperator />}
      </React.Fragment>
    )
  }

  clickStars = () => {
    this.scrollToReviews()
    this.clickTab("reviews")
  }

  renderReview = review => (
    <Review style={{ marginBottom: 20 }} review={review} key={review.id} />
  )

  renderCampaign = campaign => (
    <div key={`campaign-${campaign.id}`}>
      <h4 style={{ marginBottom: 8 }}>{campaign.headline}</h4>
      <div style={{ display: "flex", alignItems: "top", marginBottom: 20 }}>
        <p style={{ margin: 0 }}>{campaign.description}</p>
      </div>
    </div>
  )

  scrollToReviews = () => this.reviewRef.current
    && window.scrollTo({
      top: this.reviewRef.current.offsetTop - 100,
      behavior: "smooth"
    })

  inventoryDate = () => {
    const { match } = this.props
    const { date } = match.params
    if (typeof date === "undefined" || date === "today") {
      return moment().format("YYYY-MM-DD")
    }
    return date
  }

  inventoryId = () => {
    const { match } = this.props
    return match.params.id
  }

  navToListings = e => {
    e.preventDefault()
    const { navToListings } = this.props
    navToListings()
  }

  renderCompany() {
    const {
      inventoryItem,
      navToCompany,
      isLarge
    } = this.props

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: isLarge ? 0 : 12,
          marginBottom: 20,
          padding: isLarge ? "0 14px" : "0"
        }}
      >
        <div style={{ flex: 1 }}>
          <h3
            style={{
              fontWeight: 500,
              fontSize: 20,
              margin: 0,
              padding: 0,
              marginBottom: -2
            }}
          >
            {inventoryItem.company.name}
          </h3>
          <div>
            <CompanyAddressBuilder
              company={inventoryItem.company}
              addressOne
              district
              inline
            />
            <CompanyAddressBuilder
              company={inventoryItem.company}
              postCode
              region
              inline
            />
          </div>
          <CompanyLink
            style={{
              fontSize: 14, marginTop: 8, display: "flex", alignItems: "center",
            }}
            href={`/company/${inventoryItem.company.slug}`}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              navToCompany(inventoryItem.company.slug)
            }}
          >
            View salon information
            <ArrowRightIcon style={{
              marginLeft: "4px", height: 16, width: "auto", verticalAlign: "top"
            }}
            />
          </CompanyLink>
        </div>
        <CompanyLogo
          company={inventoryItem.company}
          size={80}
          style={{ display: "block" }}
        />
      </div>
    )
  }

  renderInventory() {
    const {
      navToListings,
      isLarge,
      isMedium,
      isVip,
      inventoryItem,
      userFavouriteService,
      isFavourited,
      haveProfile
    } = this.props

    if (!inventoryItem) return null

    // const item = inventoryItem
    const serviceId = inventoryItem.id

    const { pricing, currency } = inventoryItem

    const paymentMethods = inventoryItem.company.payment_methods
    const oxiPayAvailable = paymentMethods
      .find(method => method.slug === "oxipay")

    /* Quick hack until there is time to refactor breakpoints properly */
    const isMobile = !isMedium

    /* Main container for the two content columns */
    const ServiceContainer = styled.div`
      display: grid;
      grid-column-gap: 40px;
      grid-template-columns: ${props => (props.isMobile ? "100%" : "calc(50% - 20px) calc(50% - 20px)")};
      max-width: 980px;
      margin: 0px auto;
      padding-top: ${props => (props.isMobile ? 0 : "20px")};
    `

    /* Remove company object from service object passed to gallery component to prevent
       company logo from being displayed */
    const { company, ...serviceGallery } = inventoryItem

    return (
      <ServiceContainer isMobile={!isLarge}>
        <div style={{ position: "relative" }}>
          <BackLink
            href="/"
            onClick={e => {
              e.preventDefault()
              navToListings()
            }}
            style={{ top: !isLarge ? 20 : 0, left: !isLarge ? 20 : -60 }}
          />

          <ServiceGallery style={{ height: isMobile ? 260 : 320 }} service={serviceGallery} />

          <div
            style={{
              margin: isMobile ? "20px 0 0 0" : 0,
              padding: isMobile ? "0 10px" : 0
            }}
          >

            <div
              style={{ display: "flex", marginTop: 20, position: "relative" }}
            >
              {haveProfile && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 4
                  }}
                >
                  <FavouritesBtn
                    isMobile={isMobile}
                    isFavourited={isFavourited}
                    onClick={() => (serviceId
                      ? userFavouriteService(serviceId, isFavourited)
                      : null)
                    }
                  />
                </div>
              )}
              <div style={{ flex: 1, marginTop: -8 }}>
                <Pricing
                  style={{ textAlign: "left" }}
                  hideTag
                  prices={pricing}
                  currency={currency}
                  isMobile={isMobile}
                  userIsVip={isVip}
                />
                {oxiPayAvailable && (
                  <div>
                    <div style={{ display: "inline-block" }}>
                    </div>
                  </div>
                )}
                {inventoryItem.rating_count
                  + inventoryItem.company.rating_count
                  > 0 && (
                  <div style={{ textAlign: "left", marginTop: 6 }}>
                    <div style={{ display: "inline-block" }}>
                      {this.renderStarRatings(inventoryItem)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 10, padding: isMobile ? "0 10px" : 0 }}>

            <div style={{ marginBottom: 20 }}>{this.renderDescriptiveContent(inventoryItem)}</div>

          </div>
        </div>

        <div style={{ position: "relative", padding: isMobile ? "0 10px" : 0 }}>
          <div style={{ marginTop: isMobile ? 20 : 0 }}>

            {!isMobile && this.renderCompany()}
            {this.renderBookingForm()}
          </div>
        </div>
      </ServiceContainer>
    )
  }

  renderExtras() {
    const { inventoryItem } = this.props
    if (inventoryItem.extras) {
      return inventoryItem.extras.map(ex => (
        <div
          key={ex.name}
          style={{
            display: "flex",
            margin: "15px 10px 0px 10px",
            paddingLeft: 10,
            borderTop: "1px solid #e2e2e2",
            borderBottom: "1px solid #e2e2e2",
            padding: "5px 0px 5px 0px",
            color: "rgba(0,0,0,0.6)"
          }}
        >
          <div style={{ flex: 1 }}>
            <img
              src={ex.images.find(i => i.placement === "logo").url}
              style={{ width: 50, margin: 0 }}
              alt="logo"
            />
            <img
              src={ex.images.find(i => i.placement === "main").url}
              style={{ width: 50, margin: 0 }}
              alt="product"
            />
          </div>
          <div style={{ flex: 4, fontWeight: 300, fontSize: 16 }}>
            {ex.name}
            <br />
            <span style={{ fontSize: 14 }}>
              {ex.description}
              {" "}
              {ex.size}
            </span>
          </div>
        </div>
      ))
    }
    return null
  }

  renderFullCalendar() {
    const { showServiceCalendar, inventoryItem } = this.props
    return (
      <CalendarContainer
        clickDay={day => {
          // format the date and timezone
          const dateStr = formatDate(day, "YYYY-MM-DD", inventoryItem.company.timezone)

          // nav to the correct service by id and date
          navToInventory(inventoryItem.slug, dateStr, true)
        }}
        showModal={inventoryItem && showServiceCalendar}
      />
    )
  }

  renderBookingForm() {
    const { match, navToInventory, inventoryItem } = this.props

    return (
      <CalendarBookingModule
        match={match}
        serviceId={inventoryItem.id}
        clickDayCallback={dateStr => navToInventory(
          inventoryItem.slug,
          dateStr,
          true
        )
        }
      />
    )
  }

  renderCartError() {
    const {
      cartError, cartErrorMessage, clearCartError, isMobile
    } = this.props

    if (cartError) {
      return (
        <ShadowBox
          header="Whoops!"
          isFullscreen={isMobile}
          closeBox={clearCartError}
        >
          <div style={{ textAlign: "center" }}>
            <ErrorBar message={cartErrorMessage} />
            <p>
              <SubmitInput
                onClick={clearCartError}
                value="Please click here to try again"
              />
            </p>
          </div>
        </ShadowBox>
      )
    }
    return null
  }

  render() {
    const { inventoryItem, isFetchingInventory } = this.props

    const imageUrl = inventoryItem
      ? getServiceImage(inventoryItem.images, inventoryItem.description)
      : null

    return (
      <WrapContainer loginRequired={false} showNavBack>
        {this.renderCartError()}

        {!isFetchingInventory && inventoryItem && (
          <React.Fragment>
            <MetaSetter
              canonicalPath={inventoryItem && `/services/${inventoryItem.slug}`}
              metaTitle={inventoryItem && `${inventoryItem.company.name} - ${inventoryItem.description.name}`}
              metaDescription={inventoryItem && inventoryItem.description.about}
              ogTitle={inventoryItem && `${inventoryItem.company.name} - ${inventoryItem.description.name}`}
              ogDescription={inventoryItem && inventoryItem.description.about}
              ogUrl={inventoryItem && window.location.href}
              ogImage={inventoryItem && imageUrl}
              productBrand={inventoryItem && inventoryItem.company.name}
              productAvailability={inventoryItem && "in stock"}
              productCondition={inventoryItem && "new"}
              productPriceAmount={
                inventoryItem && inventoryItem.pricing.lowest.price && `${inventoryItem.pricing.lowest.price}`
              }
              productPriceCurrency={
                inventoryItem && inventoryItem.currency.code
              }
              productRetailerItemId={inventoryItem && inventoryItem.id}
            />
            {this.renderFullCalendar()}
            {this.renderInventory()}
          </React.Fragment>
        )}
        {isFetchingInventory && <Loading />}
      </WrapContainer>
    )
  }
}

InventoryViewContainer.propTypes = {
  fetchInventory: PropTypes.func.isRequired,
  isFetchingInventory: PropTypes.bool.isRequired,
  serviceReviews: PropTypes.array.isRequired,
  companyReviews: PropTypes.array.isRequired,
  inventoryItem: PropTypes.object,
  match: PropTypes.object.isRequired,
  setInventoryDate: PropTypes.func.isRequired,
  navToCompany: PropTypes.func.isRequired,
  navToListings: PropTypes.func.isRequired,
  navToInventory: PropTypes.func.isRequired,
  selectAvailability: PropTypes.func.isRequired,
  navToFourOhFour: PropTypes.func.isRequired,
  clearCartError: PropTypes.func.isRequired,
  isVip: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool.isRequired,
  fourOhFour: PropTypes.bool.isRequired,
  fetchFavourites: PropTypes.func.isRequired,
  setUserFavouriteServiceId: PropTypes.func.isRequired,
  inventoryDate: PropTypes.string,
  userFavouriteService: PropTypes.func.isRequired,
  isFavourited: PropTypes.bool,
  haveProfile: PropTypes.bool,
  cartError: PropTypes.bool,
  cartErrorMessage: PropTypes.string,
  showServiceCalendar: PropTypes.bool
}

InventoryViewContainer.defaultProps = {
  inventoryItem: null
}

function mapStateToProps(state) {
  return {
    isLarge: state.browser.greaterThan.large,
    isMedium: state.browser.greaterThan.medium,
    isMobile: state.browser.lessThan.mobile,
    isFetchingInventory: state.inventoryView.isFetchingInventory,
    credentials: state.credentials.credentials,
    inventoryDate: state.inventoryView.inventoryDate,
    inventoryItem: state.inventoryView.inventoryItem,
    serviceReviews: state.inventoryView.serviceReviews,
    companyReviews: state.inventoryView.companyReviews,
    isVip: !!state.profile.profile.vip,
    loggingIn: state.credentials.loggingIn,
    pathname: state.router.location.pathname,
    haveProfile: !!state.profile.profile.id,
    campaigns: state.inventoryView.campaigns,
    fourOhFour: state.inventoryView.fourOhFour,
    isFavourited: getUserHasFavouritedService(state),
    showServiceCalendar: state.inventoryView.showServiceCalendar,
    cartError: state.cart.cartError,
    cartErrorMessage: state.cart.cartErrorMessage
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  fetchInventory: (id, date) => dispatch(fetchInventory(id, date)),
  setInventoryDate: date => dispatch(setInventoryDate(date)),
  navToInventory: (id, date, replaceInHistory) => dispatch(navToInventory(currentParams, { id, date }, replaceInHistory)),
  selectAvailability: id => dispatch(selectAvailability(id)),
  navToCompany: slug => dispatch(navToCompany(slug)),
  redirectToLogin: () => dispatch(redirectToLogin()),
  storeLastLocation: location => dispatch(storeLastLocation(location)),
  navToListings: () => dispatch(goBack()),
  fetchFavourites: () => dispatch(fetchFavourites()),
  clearCartError: () => dispatch(clearCartError()),
  userFavouriteService: (serviceId, isFavourited) => dispatch(userFavouriteService(serviceId, isFavourited)),
  setUserFavouriteServiceId: serviceId => dispatch(setUserFavouriteServiceId(serviceId)),
  navToFourOhFour: reason => dispatch(navToFourOhFour(reason)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryViewContainer)
