import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"

import { SubmitInput } from "../../components/forms/Inputs"
import AvailableEmployeeList from "./AvailableEmployeeList"
import { navToInventory } from "./actions"
import { EmployeeSelectButton } from "./EmployeeSelectButton"
import { EMPLOYEE_ID_ANY, EMPLOYEE_ID_LOADING } from "./reducer"

import DateAndTimeSelector from "./DateAndTimeSelector"

import { addAvailabilityToCartAndRedirect } from "../Cart/actions"
import * as selectors from "./selectors"
import { dayIsUnavailable } from "../../../utils/momentHelpers"

import {
  setEmployee,
  triggerNext,
  triggerScrape,
  triggerBestPrice,
  forceScrape,
  setInventoryDate,
  selectAvailability,
  clearCart,
  trackPurchaseStart,
  toggleEmployeeSelect,
  showServiceCalendar,
  resetBestPriceError,
} from "./actions"

import { getIsAddingItemToCart } from "../Cart/selectors"
import styled from "styled-components"
import { QuestionMarkInCircleIcon, UserIcon } from "../../components/icons"
import ArrowDownIcon from "../../components/icons/chevron-down.svg"

class CalendarBookingModule extends Component {
  constructor() {
    super()
    this.state = {
      searchStrategy: "next-available",
      bookingTooltipTriggerMethod: null
    }
  }

  componentDidMount() {
    const {
      inventoryItem, inventoryDate, setInventoryDate, employeeId, setEmployee, triggerScrape
    } = this.props

    if (inventoryItem) {
      /* Catch any attempts to load an unavailable date and find next valid one instead */
      const availableDate = moment.tz(inventoryDate, inventoryItem.company.timezone)

      while (dayIsUnavailable(inventoryItem.company, availableDate)) {
        availableDate.add(1, "day")
      }

      if (availableDate.format("YYYY-MM-DD") !== inventoryDate) {
        setInventoryDate(availableDate.format("YYYY-MM-DD"))
      }

      this.setSearchStrategy(employeeId)

      const { match: { params } } = this.props

      /* Employee ID only ever fails this check on first load when its set to
         null, everything in here should only occur once on init. */
      if (employeeId === EMPLOYEE_ID_LOADING) {
        let employeeSlugFound = false

        /* There is an employee slug in the url so we need to select it. This is applicable
           whether hide_any_employee is set or not, so occurs first */
        if (params && params.employeeSlug) {
          const employeeToSelect = inventoryItem.employees.find(employee => employee.slug === params.employeeSlug)
          if (employeeToSelect) {
            setEmployee(employeeToSelect.id)

            employeeSlugFound = true
          }
        }

        if (!employeeSlugFound) {
          if (inventoryItem.hide_any_employee && inventoryItem.employees && inventoryItem.employees.length > 0) {
            /* No employee selected so init with first employee value in list and scrape based on value for hide any employee */
            setEmployee(inventoryItem.employees[0].id)
          } else {
            /* Select any employee */
            setEmployee(EMPLOYEE_ID_ANY)
          }
        }

        triggerScrape()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { employeeId } = this.props

    const { bookingTooltipTriggerMethod } = this.state

    /* Employee selection has just come into effect so update UI */
    if (prevProps.employeeId !== employeeId) {
      this.setSearchStrategy(employeeId)
    }

    if (bookingTooltipTriggerMethod === "click") {
      setTimeout(() => this.setShowBookingToolTipTriggerMethod(null), 5000)
    }
  }

  setShowBookingToolTipTriggerMethod = (displayTrigger) => {
    this.setState({
      bookingTooltipTriggerMethod: displayTrigger
    })
  }

  setSearchStrategy = employeeId => {
    if (employeeId && employeeId !== EMPLOYEE_ID_ANY) {
      this.setState({ searchStrategy: "by-stylist" })
    }

    if (!employeeId || employeeId === EMPLOYEE_ID_ANY) {
      this.setState({ searchStrategy: "next-available" })
    }
  }

  onSelectEmployee = id => {
    const {
      toggleEmployeeSelect,
      setEmployee,
      triggerScrape,
      employeeId,
      navToInventory,
      inventoryItem,
      match: {
        params: currentParams
      }
    } = this.props

    /* Only fire off a new scrape request when the employee has been changed */
    const scrapeRequired = (id !== employeeId)

    toggleEmployeeSelect()
    setEmployee(id)

    /* If employee is not found it is set to EMPLOYEE_ID_ANY and should be stripped from the URL path */
    const employee = inventoryItem.employees.find(employee => employee.id === id)

    if (employee) {
      navToInventory(currentParams, { employeeSlug: employee.slug })
    } else {
      delete currentParams.employeeSlug
      navToInventory(currentParams)
    }


    /* Only confirm search strategy select after an employee has been selected */
    this.setState({ searchStrategy: "by-stylist" })

    if (scrapeRequired) {
      triggerScrape()
    }
  }

  renderAvailableEmployeeList = () => {
    const {
      inventoryItem, isMobile, isVip, toggleEmployeeSelect
    } = this.props
    return (
      <div
        style={{
          flex: 1,
          padding: "10px 0px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <AvailableEmployeeList
          employees={inventoryItem.employees}
          pricing={inventoryItem.pricing.employees}
          onSelectEmployee={this.onSelectEmployee}
          currency={inventoryItem.currency}
          role={inventoryItem.description.category.default_role}
          isMobile={isMobile}
          isVip={isVip}
          onClose={toggleEmployeeSelect}
          company={inventoryItem.company}
          hideAnyEmployee={inventoryItem.hide_any_employee}
        />
      </div>
    )
  }

  getAvailability = () => {
    const { availabilities, selectedAvailability } = this.props
    return (
      availabilities.find(
        a => a.id === selectedAvailability
      )
    )
  }

  clickBook = () => {
    const {
      selectedAvailability,
      campaignId,
      trackPurchaseStart,
      addAvailabilityToCartAndRedirect
    } = this.props

    trackPurchaseStart()

    addAvailabilityToCartAndRedirect(selectedAvailability, campaignId)
  }

  inventoryDate = () => {
    /* Prioritise date in URL as used on main service page */
    const { match } = this.props
    const urlDate = match.params.date

    if (typeof urlDate !== "undefined") {
      if (urlDate === "today") {
        return moment().format("YYYY-MM-DD")
      }

      return urlDate
    }

    /* Otherwise fall back to date in redux state as used on wizard and promo pages */
    const { inventoryDate } = this.props

    if (typeof inventoryDate === "undefined" || inventoryDate === "today") {
      return moment().format("YYYY-MM-DD")
    }

    return inventoryDate
  }

  clickDay = day => {
    const {
      setInventoryDate, triggerScrape, forceScrape, clickDayCallback
    } = this.props

    const dateStr = day.format("YYYY-MM-DD")

    if (dateStr === this.inventoryDate()) {
      /* If the user clicks the current date again, force a rescrape of inventory */
      forceScrape()
    } else {
      /* Default behaviour is to change to the new date then get existing inventory from API and local Flossie DB if possible */
      setInventoryDate(dateStr)
      triggerScrape()
    }

    if (typeof clickDayCallback !== "undefined") {
      clickDayCallback(dateStr)
    }
  }

  triggerFindNextAvailable = () => {
    const { toggleEmployeeSelect } = this.props
    toggleEmployeeSelect()
    this.onSelectEmployee(EMPLOYEE_ID_ANY)
    // this.setState({ searchStrategy: "next-available" })
  }

  renderBookButton() {
    const availability = this.getAvailability()
    const {
      startedScrape, employeeId, isAddingItemToCart, findBestPriceError
    } = this.props

    let disabledStyle = {}
    let label = "Select Date"
    if (startedScrape) {
      // only show this if we started the scrape and there is an employee selected
      label = "Finding Availability..."
    }

    if (startedScrape || !availability || !employeeId || findBestPriceError) {
      // disabled when no availability or no employee selected
      disabledStyle = { backgroundColor: "#e2e2e2", cursor: "not-allowed" }
    } else {
      label = "Select time"
    }
    return (
      <SubmitInput
        id="book-now-button"
        disabled={!availability || isAddingItemToCart}
        onClick={this.clickBook}
        value={label}
        loading={isAddingItemToCart}
        style={{
          flex: 1,
          margin: "10px 0px",
        }}
      />
    )
  }

  renderDateAndTimeSelector() {
    const {
      inventoryItem,
      availabilities,
      selectAvailability,
      selectedAvailability,
      isVip,
      employeeId,
      toggleEmployeeSelect,
      startedScrape,
      finishedScrape,
      inventoryDate,
      showServiceCalendar,
      nextAvailabilityError,
      findBestPriceError,
      resetBestPriceError,
      scrapeStatus,
      isFetchingBestPrice,
    } = this.props

    let clickPrevWeek = null

    /* Do not display previous week link if viewing the current week */
    const minimumDate = moment().add(1, "week").startOf("week")

    if (minimumDate.isBefore(this.inventoryDate()) || minimumDate.isSame(this.inventoryDate())) {
      /* Find available dates in the last week and allow prev week button if present */
      const availableDate = moment(this.inventoryDate()).subtract(1, "week")

      while (availableDate.isBefore(this.inventoryDate())) {
        /* Date comparison is done in salon timezone in dayIsUnavailable() but UI is done in local browser time
           so create a converted version of availableDate to pass for check */
        const timezoneDate = moment.tz(availableDate.format("YYYY-MM-DD"), inventoryItem.company.timezone).startOf("day")

        if (!dayIsUnavailable(inventoryItem.company, timezoneDate)) {
          clickPrevWeek = () => this.clickDay(availableDate)

          break
        }

        availableDate.add(1, "day")
      }
    }


    /* There is always a next week */
    const nextDate = moment(this.inventoryDate()).add(1, "week")

    while (dayIsUnavailable(inventoryItem.company, nextDate)) {
      nextDate.add(1, "day")
    }

    const clickNextWeek = () => this.clickDay(nextDate)

    const clickFindNextDate = () => {
      const { triggerNext } = this.props
      triggerNext()
    }


    const clickFindBestPrice = () => {
      const { triggerBestPrice } = this.props
      triggerBestPrice()
    }

    const showFindNextDate = inventoryItem.company.allow_next_appointment
    const companyAllowsLowestPrice = inventoryItem.company.allow_lowest_price

    const { searchStrategy } = this.state
    return (
      <DateAndTimeSelector
        selectedEmployeeId={employeeId}
        nextAvailabilityError={nextAvailabilityError}
        startedScrape={startedScrape}
        finishedScrape={finishedScrape}
        scrapeStatus={scrapeStatus}
        inventoryItem={inventoryItem}
        availabilities={availabilities}
        selectedAvailability={selectedAvailability}
        showFindNextDate={showFindNextDate}
        clickFindNextDate={clickFindNextDate}
        isFetchingBestPrice={isFetchingBestPrice}
        showFindBestPrice={// we only show best price link if:
          !findBestPriceError // no error
          && finishedScrape // finished scraping for best price
          && searchStrategy === "next-available" // strategy is next available (meaning any stylyst)
          && availabilities.length > 0 // must have times to find best price
          && inventoryItem.employees // must have employees to find best price
          && inventoryItem.employees.length > 0
          && companyAllowsLowestPrice // company allow searching best price
        }
        clickFindBestPrice={clickFindBestPrice}
        clickPrevWeek={clickPrevWeek}
        clickNextWeek={clickNextWeek}
        clickDay={this.clickDay}
        selectAvailability={selectAvailability}
        selectedDate={inventoryDate}
        showServiceCalendar={showServiceCalendar}
        toggleEmployeeSelect={toggleEmployeeSelect}
        isVip={isVip}
        findBestPriceError={findBestPriceError}
        resetBestPriceError={resetBestPriceError}
      />
    )
  }

  renderEmployeeSelector() {
    const {
      inventoryItem,
      employeeId,
      toggleEmployeeSelect,
      isMobile,
      setEmployee,
      triggerScrape
    } = this.props

    const {
      bookingTooltipTriggerMethod
    } = this.state

    const { employees, description } = inventoryItem

    const defaultRole = description.category.default_role

    // hide Employee Select Button if there's nothing to choose from
    if (!employees || employees.length === 0) {
      return null
    }

    const EmployeeSelectContainer = styled.div`
      cursor: pointer;
      display: flex;
      flex-direction: column;
      background-color: ${props => (props.white ? "" : props.theme.colors.tertiary)};
        
      > .employee-select-heading {
          text-align: center;
          padding: 1.7vh;
          display: flex;
          position: relative;
          flex-wrap: nowrap;
      }
        
      > .employee-select-heading svg {
          height: 1.5vh;
      }
        
      > .employee-select-heading .down-arrow {
          margin-left: auto;
          order: 2
      }

      > .employee-select-heading .heading-text {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 50%;
          transform: translateX(-50%);
          font-weight: bold;
      }
    `

    const ThemedArrowDownIcon = styled(ArrowDownIcon)`
        width: 20px;
        margin-left: 5px;
        fill: ${({ theme }) => theme.colors.primary};
    `

    const BookAppointmentHeading  = styled.div`
        display: flex;
        padding-bottom: 0.8vh;
        align-items: center;
        font-weight: bolder;
        > div {
            cursor: pointer;
        }
        > * {
            padding: 0.2vh;
        }
    `

    const ToolTipPopup = styled.div`
        display: ${(props) => (props.show ? "block" : "none")};
        position: absolute;
        top: 10px;
        left: 10px;
        width: 20vh;
        background: #D1D2BF;
        z-index: 10;
        border: 1px solid #848575;
        font-size: .8em;
        padding: 1vh;
        border-radius: 1vh;
    `

    const clickShowToolTip = () => {
      const { bookingTooltipTriggerMethod } = this.state

      this.setShowBookingToolTipTriggerMethod(bookingTooltipTriggerMethod === "click" ? null : "click")
    }

    return (
      <React.Fragment>
        <BookAppointmentHeading onMouseEnter={() => this.setShowBookingToolTipTriggerMethod("hover")} onMouseLeave={() => this.setShowBookingToolTipTriggerMethod(null)}>
          <div>Book an appointment</div>
          <div role="button" tabIndex={0} style={{ position: "relative", display: "grid", alignItems: "center" }} onKeyDown={clickShowToolTip} onClick={clickShowToolTip}>
            <QuestionMarkInCircleIcon />
            <ToolTipPopup show={bookingTooltipTriggerMethod !== null}>
              Select the arrow below to view stylists and rates. Payment will be processed at the salon, based on the stylist level. If you prefer a particular stylist, pick one to view their schedule.
            </ToolTipPopup>
          </div>
        </BookAppointmentHeading>
        <EmployeeSelectContainer onClick={toggleEmployeeSelect}>
          <div className="employee-select-heading">
            <div className="down-arrow">
              <ThemedArrowDownIcon />
            </div>
            <div className="heading-text">
              <UserIcon />
              &nbsp;Select&nbsp;
              {defaultRole}
            </div>
          </div>
          <EmployeeSelectButton
            isMobile={isMobile}
            currency={inventoryItem.currency}
            price={inventoryItem.pricing}
            employees={employees}
            employeeId={employeeId}
            role={defaultRole}
            company={inventoryItem.company}
            hideAnyEmployee={inventoryItem.hide_any_employee}
          />
        </EmployeeSelectContainer>
      </React.Fragment>
    )
  }

  render () {
    const {
      inventoryItem, employeeSelectOpen
    } = this.props

    if (!inventoryItem) return null
    if (employeeSelectOpen) return this.renderAvailableEmployeeList()

    // const { searchStrategy } = this.state

    return (
      <div style={{ display: "flex", flexDirection: "column", padding: "0px 14px" }}>

        {this.renderEmployeeSelector()}
        {this.renderDateAndTimeSelector()}
        {this.renderBookButton()}

      </div>
    )
  }
}

CalendarBookingModule.propTypes = {
  match: PropTypes.object.isRequired,
  trackPurchaseStart: PropTypes.func.isRequired,
  toggleEmployeeSelect: PropTypes.func.isRequired,
  setEmployee: PropTypes.func.isRequired,
  triggerScrape: PropTypes.func.isRequired,
  setInventoryDate: PropTypes.func.isRequired,
  forceScrape: PropTypes.func.isRequired,
  clickDayCallback: PropTypes.func,
  startedScrape: PropTypes.bool.isRequired,
  finishedScrape: PropTypes.bool.isRequired,
  scrapeStatus: PropTypes.string,
  employeeSelectOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
  availabilities: PropTypes.array.isRequired,
  inventoryItem: PropTypes.object,
  selectedAvailability: PropTypes.string,
  selectAvailability: PropTypes.func,
  inventoryDate: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
  campaignId: PropTypes.string,
  addAvailabilityToCartAndRedirect: PropTypes.func.isRequired,
  triggerNext: PropTypes.func.isRequired,
  triggerBestPrice: PropTypes.func.isRequired,
  showServiceCalendar: PropTypes.func.isRequired,
  isAddingItemToCart: PropTypes.bool.isRequired,
  nextAvailabilityError: PropTypes.bool.isRequired,
  findBestPriceError: PropTypes.bool,
  isFetchingBestPrice: PropTypes.bool,
  resetBestPriceError: PropTypes.func.isRequired,
  navToInventory: PropTypes.func.isRequired,
}

CalendarBookingModule.defaultProps = {
  selectedAvailability: null,
  employeeId: null,
  campaignId: null
}

function mapStateToProps(state) {
  return {
    nextAvailabilityError: state.inventoryView.nextAvailabilityError,
    startedScrape: state.inventoryView.startedScrape,
    finishedScrape: state.inventoryView.finishedScrape,
    inventoryDate: state.inventoryView.inventoryDate,
    inventoryItem: state.inventoryView.inventoryItem,
    availabilities: state.inventoryView.availabilities,
    selectedAvailability: state.inventoryView.selectedAvailability,
    isVip: state.profile.profile.vip || false,
    employeeId: state.inventoryView.employeeId,
    employeeSelectOpen: state.inventoryView.employeeSelectOpen,
    scrapeStatus: state.inventoryView.scrapeStatus,
    isMobile: state.browser.lessThan.mobile,
    isAddingItemToCart: getIsAddingItemToCart(state),
    findBestPriceError: selectors.getFindBestPriceError(state),
    isFetchingBestPrice: state.inventoryView.isFetchingBestPrice,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setInventoryDate: date => dispatch(setInventoryDate(date)),
  triggerScrape: () => dispatch(triggerScrape()),
  triggerNext: () => dispatch(triggerNext()),
  triggerBestPrice: () => dispatch(triggerBestPrice()),
  forceScrape: () => dispatch(forceScrape()),
  selectAvailability: id => dispatch(selectAvailability(id)),

  clearCart: () => dispatch(clearCart()),
  trackPurchaseStart: () => dispatch(trackPurchaseStart()),

  toggleEmployeeSelect: () => dispatch(toggleEmployeeSelect()),
  setEmployee: employeeId => dispatch(setEmployee(employeeId)),
  showServiceCalendar: bool => dispatch(showServiceCalendar(bool)),

  addAvailabilityToCartAndRedirect: (id, campaignId) => dispatch(addAvailabilityToCartAndRedirect(id, campaignId)),

  resetBestPriceError: () => dispatch(resetBestPriceError()),
  navToInventory: (currentParams, newParams) => dispatch(navToInventory(currentParams, newParams))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarBookingModule)
