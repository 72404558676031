import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import moment from "moment-timezone"
import styled from "styled-components"
import {
  getSingleArticleName,
  getSingleArticleSummary,
  getSingleArticleHashTag,
  getSingleArticleSection,
  getArticleIsEmpty,
  getSingleArticleLoading,
  getViewingArticleType,
  getSingleArticleImage,
  getSingleArticleRelatedArticles,
  getSingleArticleCreatedAt,
  getSingleArticleMerchandise
} from "./selector"
import { fetchArticle, navToArticle } from "./actions"
import WrapContainer from "../../containers/WrapContainer"
import MetaSetter from "../../components/MetaSetter"
import ParagraphText from "../../components/ParagraphText"
/* import { SpanLink } from "../../components/nav/Links" */
import {
  ArticleHeader,
  ArticleListParent,
  ArticleDate,
  ParentDiv
} from "./components"
import ArticleTabs from "./ArticleTabs"
import ArticleList from "./ArticleList"
import Article from "./Article"
import { filterToUrl } from "../InstantBook/urlFilter"

import {
  SORT_POPULARITY,
} from "../InventoryFilter/constants"

import {
  TYPE_SLUG_INSPIRATION,
  TYPE_ID_GUIDE,
  TYPE_SLUG_GUIDE
} from "./constants"

class ArticleContainer extends Component {
  componentDidMount() {
    const { match, fetchArticle } = this.props

    // fetch the article from the match slug
    fetchArticle(match.params.slug)
  }

  componentWillUpdate(nextProps) {
    const { title } = this.props
    if (nextProps.title !== title) {
      window.scrollTo(0, 0)
    }
  }

  clickArticle = slug => {
    const {
      navToArticle,
      fetchArticle,
      relatedArticles
    } = this.props

    const article = relatedArticles.find(a => {
      return a.slug === slug
    })

    const type = article.type_id === TYPE_ID_GUIDE ? TYPE_SLUG_GUIDE : TYPE_SLUG_INSPIRATION

    navToArticle(type, slug)
    fetchArticle(slug)
  }

  render() {
    const {
      title,
      createdAt,
      summary,
      hashtag,
      sections,
      isLoadingArticle,
      relatedArticles,
      isMobile,
      merchandise,
      navToListings
    } = this.props

    const ArticleCta = styled.a`
      display: block;
      text-decoration: none;
      margin: ${props => (props.isMobile ? "0 10px" : 0)};
      padding: 10px 0;
      width: ${props => (props.isMobile ? "calc(100% - 20px)" : "100%")};
      height: auto;
      overflow: hidden;
      border-radius: 10px;
      background-image: url(/article-cta-bg.png);
      background-size: cover;
      background-position: center;
    `

    const merchandiseFilter = {
      selectedDays: [],
      selectedCategories: [],
      selectedCompanies: [],
      selectedDistricts: [],
      selectedRegions: [],
      sortBy: SORT_POPULARITY
    }

    if (merchandise && merchandise.company) merchandiseFilter.selectedCompanies.push(merchandise.company.slug)
    if (merchandise && merchandise.region) merchandiseFilter.selectedRegions.push(merchandise.region.slug)
    if (merchandise && merchandise.category) merchandiseFilter.selectedCategories.push(merchandise.category.slug)

    const merchandiseUrl = filterToUrl(merchandiseFilter)

    return (
      <WrapContainer loginRequired={false} showNavBack>
        <ParentDiv>
          <MetaSetter
            metaTitle={title}
            metaDescription={summary}
          />
          <ArticleHeader />

          <ArticleTabs />

          <div style={{ display: "flex", borderTop: "1px dashed #ef609f" }}>
            <div style={{ flex: 1 }}>
              <div style={{ padding: isMobile ? "0px 10px" : 0 }}>
                <h1 style={{ margin: "20px 0px", fontSize: 32 }}>{title}</h1>
                {createdAt && (
                  <ArticleDate style={{ marginBottom: 10 }}>
                    {moment(createdAt).format("DD/MM/YY")}
                  </ArticleDate>
                )}
                <ParagraphText text={summary} style={{ margin: 0, fontStyle: "italic", fontSize: 20 }} />

                {hashtag && (
                  <p
                    style={{
                      margin: "20px 0px",
                      textAlign: "center",
                      color: "#ef609f"
                    }}
                  >
                    {hashtag}
                  </p>
                )}
              </div>

              {/* ensure that the async call has fininshed fetching the article before trying to render. */}
              {!isLoadingArticle && sections && (
                <div>
                  <Article isMobile={isMobile} sections={sections} />
                  <div style={{ clear: "both", marginBottom: 20 }} />

                  {/* Hide CTA until pandemic is over */}
                  {/*
                  <ArticleCta
                    isMobile={isMobile}
                    href={merchandiseUrl}
                    onClick={e => {
                      e.preventDefault()
                      window.scrollTo(0, 0)
                      navToListings(merchandiseUrl)
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      {!isMobile && (
                        <img
                          alt="Flossie Logo"
                          src="/flossielogo.png"
                          width="60"
                          height="60"
                          style={{ display: "block", margin: "0 20px" }}
                        />
                      )}
                      <div
                        style={{
                          flex: 1,
                          color: "#fff",
                          textAlign: isMobile ? "center" : "left"
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            marginBottom: 4,
                            padding: isMobile ? "0 20px" : 0
                          }}
                        >
                          Experience the faster and friendly way to find the
                          perfect appointment
                        </div>
                        {!isMobile && (
                          <div style={{ fontSize: 12, fontWeight: 300 }}>
                            Only Tried &amp; Tested businesses
                            <br />
                            Instant book in under 30 seconds
                          </div>
                        )}
                      </div>
                      <div style={{ margin: isMobile ? 20 : 0 }}>
                        <SpanLink
                          style={{ margin: isMobile ? 0 : "0 20px 0 0" }}
                        >
                          View Appointments
                        </SpanLink>
                      </div>
                    </div>
                  </ArticleCta> */}
                </div>
              )}

              {!isLoadingArticle && relatedArticles && (
                <div>
                  <h2>You might also like</h2>
                  <ArticleListParent isMobile={isMobile}>
                    <ArticleList
                      articles={relatedArticles}
                      onClick={this.clickArticle}
                      isMobile={isMobile}
                    />
                  </ArticleListParent>
                </div>
              )}
            </div>
          </div>
        </ParentDiv>
      </WrapContainer>
    )
  }
}

ArticleContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  summary: PropTypes.string,
  hashtag: PropTypes.string,
  sections: PropTypes.array,
  fetchArticle: PropTypes.func,
  isLoadingArticle: PropTypes.bool,
  navToArticle: PropTypes.func,
  navToListings: PropTypes.func.isRequired,
  match: PropTypes.object,
  merchandise: PropTypes.object,
  relatedArticles: PropTypes.array
}

ArticleContainer.defaultProps = {
  fetchArticle: null,
  isLoadingArticle: false,
  navToArticle: null,
  match: {},
  relatedArticles: [],
  merchandise: {}
}

function mapStateToProps(state) {
  return {
    isEmpty: getArticleIsEmpty(state),
    title: getSingleArticleName(state),
    createdAt: getSingleArticleCreatedAt(state),
    summary: getSingleArticleSummary(state),
    hashtag: getSingleArticleHashTag(state),
    sections: getSingleArticleSection(state),
    isLoadingArticle: getSingleArticleLoading(state),
    articleType: getViewingArticleType(state),
    image: getSingleArticleImage(state),
    relatedArticles: getSingleArticleRelatedArticles(state),
    merchandise: getSingleArticleMerchandise(state),
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  fetchArticle: slug => dispatch(fetchArticle(slug)),
  navToArticle: (type, slug) => dispatch(navToArticle(type, slug)),
  navToListings: url => dispatch(push(url))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleContainer)
