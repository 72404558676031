import { registration, registrationCodeRequest, handleXhrError } from "../../api/api"

import { storeCredentials } from "../Login/epics"

import { fetchCart } from "../Cart/actions"

export const registrationAndReloadCartEpic = action$ => action$.ofType("REGISTRATION_SUCCESS").mapTo(fetchCart())

const registrationFulfilled = (credentials, noRedirect) => ({
  type: "REGISTRATION_SUCCESS",
  payload: { credentials, noRedirect }
})

export const registrationEpic = action$ => action$
  .ofType("SUBMIT_REGISTRATION")
  .filter(action => Object.values(action.payload).join("") !== "") // filter out when facebook submits the form
  .mergeMap(action => registration(
    action.payload.code,
    action.payload.firstName,
    action.payload.lastName,
    action.payload.phone,
    action.payload.email,
    action.payload.password,
    action.payload.newsletter,
  )
    .map(response => registrationFulfilled(
      storeCredentials(response.token),
      action.payload.nowrap
    ))
    .catch(error => handleXhrError(error, "REGISTRATION_FAILURE")))

const registrationCodeRequestFulfilled = (response, action) => ({
  type: "REGISTRATION_CODE_REQUEST_SUCCESS",
  payload: { response, action }
})

export const registrationCodeRequestEpic = action$ => action$
  .ofType("SUBMIT_REGISTRATION_CODE_REQUEST")
  .filter(action => Object.values(action.payload).join("") !== "") // filter out when facebook submits the form
  .mergeMap(action => registrationCodeRequest(
    action.payload.firstName,
    action.payload.lastName,
    action.payload.phone,
    action.payload.email,
    action.payload.password,
    action.payload.googleRecaptchaToken,
  )
    .map(response => registrationCodeRequestFulfilled(
      response, action
    ))
    .catch(error => handleXhrError(error, "REGISTRATION_FAILURE")))
