import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import styled, { withTheme } from "styled-components"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

import {
  updateFirstName,
  updateLastName,
  updateEmail,
  updatePhone,
  updatePassword,
  submitRegistration,
  submitRegistrationCodeRequest,
  updateError,
  updateCode
} from "./actions"
import { submitFacebookLogin, clickedFacebookLogin } from "../Login/actions"
import Animation from "../../components/Animation"

import MetaSetter from "../../components/MetaSetter"
import {
  InputLabel,
  TextInput,
  PhoneNumberInput,
  PasswordInput,
  SubmitInput, PhoneNumberInputLabel
} from "../../components/forms/Inputs"
import {
  SpanLinkFacebook,
  LegalLink
} from "../../components/nav/Links"

import WrapContainer from "../WrapContainer"
import NavBarContainer from "../NavBar/NavBarContainer"
import ErrorBar from "../../components/ErrorBar"
import { GoToButton, Link } from "../../components/button"
import { FacebookIcon, ShieldIcon } from "../../components/icons"

import NewsletterSignup from "./components/NewsletterSignup"
import AdditionalSignup from "./components/AdditionalSignup"

const { FACEBOOK_ENABLED, GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

const FormContainer = styled.div`
  margin-left: auto;
  flex: 1;
  margin-right: auto;
  max-width: 600px;
`


class RegistrationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { fbLoggingIn: false, googleRecaptchaToken: false }
  }

  updateFirstName = e => {
    e.preventDefault()
    const { updateFirstName } = this.props
    updateFirstName(e.target.value)
  }

  updateLastName = e => {
    e.preventDefault()
    const { updateLastName } = this.props
    updateLastName(e.target.value)
  }

  updateEmail = e => {
    e.preventDefault()
    const { updateEmail } = this.props
    updateEmail(e.target.value)
  }

  updatePhone = value => {
    const { updatePhone } = this.props
    updatePhone(value)
  }

  updatePassword = e => {
    e.preventDefault()
    const { updatePassword } = this.props
    updatePassword(e.target.value)
  }

  updateCode = e => {
    e.preventDefault()
    const { updateCode } = this.props
    updateCode(e.target.value)
  }

  handleReCaptchaVerify = (token) => {
    this.setState({ googleRecaptchaToken: token })
  }

  /* Some franchises have additional newsletter field for marketing signup */
  getNewsletter = () => (document.getElementById("newsletter") && document.getElementById("newsletter").checked)

  validateForm = () => {
    const {
      registration: {
        firstName,
        lastName,
        phone,
        email,
        password
      },
      updateError
    } = this.props

    if (!firstName.length) {
      updateError("Please enter your first name")

      return false
    }

    if (!lastName.length) {
      updateError("Please enter your last name")

      return false
    }

    if (!email.length) {
      updateError("Please enter your email address")

      return false
    }

    if (!phone.length) {
      updateError("Please enter your phone number")

      return false
    }

    if (!password.length) {
      updateError("Please enter your password")

      return false
    }

    if (password.length < 8) {
      updateError("Your password must be at least 8 characters long")

      return false
    }

    let complexity = 0

    if (password.match(/[A-Z]/)) {
      complexity += 1
    }

    if (password.match(/[a-z]/)) {
      complexity += 1
    }

    if (password.match(/[0-9]/)) {
      complexity += 1
    }

    if (password.match(/[^\w\s]/)) {
      complexity += 1
    }

    if (complexity < 2) {
      updateError("Please enter a password using a combination of lowercase and uppercase letters, numbers or symbols")

      return false
    }

    return true
  }

  submitRegistration = e => {
    e.preventDefault()
    const {
      registration: {
        firstName,
        lastName,
        phone,
        email,
        password,
        code,
        isProcessing
      },
      submitRegistration,
      noWrap
    } = this.props

    const newsletter = this.getNewsletter()

    if (this.validateForm() && !isProcessing) {
      submitRegistration(
        code,
        firstName,
        lastName,
        phone,
        email,
        password,
        noWrap,
        newsletter
      )
    }
  }

  submitRegistrationCodeRequest = e => {
    e.preventDefault()


    const {
      registration: {
        firstName,
        lastName,
        phone,
        email,
        password
      },
      submitRegistrationCodeRequest
    } = this.props
    const { googleRecaptchaToken } = this.state
    if (this.validateForm()) {
      submitRegistrationCodeRequest(firstName, lastName, phone, email, password, googleRecaptchaToken)
    }
  }

  fbComponentClicked = () => {
    this.setState({ fbLoggingIn: true })
    const newsletter = this.getNewsletter()

    FB.login(response => {
      if (response.authResponse) {
        const { submitFacebookLogin, noWrap } = this.props
        const { googleRecaptchaToken } = this.state
        submitFacebookLogin(
          response.authResponse.accessToken,
          googleRecaptchaToken,
          noWrap,
          newsletter
        )
      } else {
        this.setState({ fbLoggingIn: false })
        console.log("User cancelled login or did not fully authorize.")
      }
    })
  }

  navToLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    const { toggleRegistration, navToLogin } = this.props
    if (toggleRegistration) {
      toggleRegistration()
    } else {
      navToLogin()
    }
  }

  navToTerms = e => {
    const { navToTerms } = this.props
    e.preventDefault()
    navToTerms()
  }

  navToPrivacyPolicy = e => {
    const { navToPrivacyPolicy } = this.props
    e.preventDefault()
    navToPrivacyPolicy()
  }


  verificationForm() {
    const { registration, hideVerificationCodeForm } = this.props
    const { hasError } = registration

    return (
      <form onSubmit={this.submitRegistration}>

        <FormContainer>
          {this.renderFormHeader(false)}
          {hasError && <ErrorBar message={registration.lastError} />}


          <div>
            <div style={{ marginBottom: 20 }}>
              We&apos;ve emailed a verification code to
              {" "}
              <span style={{ fontWeight: "bold" }}>{registration.email}</span>
              {" "}
              which you can enter below to complete your sign up.
            </div>


            <TextInput
              onChange={this.updateCode}
              value={registration.code}
              style={{ display: "block", margin: "0 auto" }}
            />

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              If you don&apos;t see the email in your inbox please check your junk folder or
              {" "}
              <Link href="mailto:help@flossie.com">contact us</Link>
              {" "}
              for assistance.
            </div>


          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: "row"
            }}
          >

            <SubmitInput
              style={{ maxWidth: 90, color: "#000", backgroundColor: "#fff" }}
              onClick={e => {
                e.preventDefault()
                hideVerificationCodeForm()
              }}
              value="Back"
            />
            <div style={{ flex: 1 }} />
            <SubmitInput
              variant="secondary"
              style={{ maxWidth: 110 }}
              onClick={this.submitRegistration}
              value="Complete"
            />
          </div>

        </FormContainer>
      </form>
    )
  }


  registrationForm() {
    const { registration, isMobile, theme } = this.props
    const { hasError } = registration
    const { fbLoggingIn } = this.state
    if (fbLoggingIn) return <Animation />

    const formContainerStyle = isMobile
      ? { display: "block" }
      : {
        display: "grid",
        gridTemplateColumns: "290px 290px",
        gridTemplateRows: "auto",
        gridColumnGap: "20px",
        gridRowGap: "14px"
      }
    const formInputStyle = isMobile
      ? { display: "block", width: "calc(100% - 32px)", marginBottom: 14 }
      : { display: "inline-block" }

    return (
      <form onSubmit={this.submitRegistrationCodeRequest}>
        {GOOGLE_RECAPTCHA_SITE_ID && <GoogleReCaptcha onVerify={this.handleReCaptchaVerify} />}
        <FormContainer>
          {this.renderFormHeader(true)}

          {hasError && <ErrorBar message={registration.lastError} />}

          <div style={formContainerStyle}>
            <TextInput
              onChange={this.updateFirstName}
              value={registration.firstName}
              style={formInputStyle}
              label="First Name*"
            />
            <TextInput
              onChange={this.updateLastName}
              value={registration.lastName}
              style={formInputStyle}
              label="Last Name*"
            />
            <TextInput
              onChange={this.updateEmail}
              value={registration.email}
              type="email"
              style={formInputStyle}
              label="Email Address*"
            />
            <InputLabel htmlFor="phone-input">
              <span>Phone Number*</span>
              <PhoneNumberInput
                id="phone-input"
                onChange={this.updatePhone}
                value={registration.phone}
                style={{ marginBottom: isMobile ? 14 : 0 }}
              />
            </InputLabel>
            <div>
              <PasswordInput
                onChange={this.updatePassword}
                value={registration.password}
                style={{ ...formInputStyle, width: "calc(100% - 30px)" }}
                label="Password*"
              />
            </div>

            <div>
              <div style={{ display: "flex", fontSize: 12, fontWeight: 200 }}>
                <div><ShieldIcon /></div>
                <div style={{ marginLeft: 8 }}>

                  {this.renderSignupNotice(formInputStyle)}

                </div>
              </div>
            </div>

          </div>

          <NewsletterSignup isMobile={isMobile} />

          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column-reverse" : "row"
            }}
          >
            {FACEBOOK_ENABLED && (
              <SpanLinkFacebook
                style={{ marginTop: isMobile ? 40 : 0, marginBottom: isMobile ? 40 : 0 }}
                onClick={this.fbComponentClicked}
              >
                <FacebookIcon variant="secondary" fill="#FFF" />
                Sign Up with Facebook
              </SpanLinkFacebook>
            )}
            <div style={{ flex: 1 }} />
            <SubmitInput
              variant="primary"
              style={{ maxWidth: 100 }}
              onClick={this.submitRegistrationCodeRequest}
              value="Sign Up"
            />
          </div>
        </FormContainer>
      </form>
    )
  }


  renderSignupNotice(formInputStyle) {
    const { theme } = this.props

    if (theme.signupNotice) {
      return theme.signupNotice(formInputStyle, this.navToTerms, this.navToPrivacyPolicy)
    }

    return (
      <React.Fragment>
        <AdditionalSignup />

        <p
          style={{
            ...formInputStyle,
            fontSize: 12,
            fontWeight: 200,
            margin: 0,
            lineHeight: "16px"
          }}
        >
          {"By continuing, you are agreeing to Flossie's"}
          {" "}
          <LegalLink onClick={this.navToTerms} href="/terms/customer">
            Terms of Service
          </LegalLink>
          {" "}
          and
          {" "}
          <LegalLink onClick={this.navToPrivacyPolicy} href="/privacy">
            Privacy Policy
          </LegalLink>
        </p>
      </React.Fragment>
    )
  }

  renderFormHeader(showLogin) {
    const {
      isMobile,
      registration: {
        enterCode
      }
    } = this.props

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column-reverse" : "row",
          textAlign: isMobile ? "center" : "left",
          marginBottom: "20px"
        }}
      >
        <span
          style={{
            flex: 1,
            fontWeight: 700,
            fontSize: "22px",
            margin: 0,
            marginTop: isMobile ? "20px" : "0px"
          }}
        >
          { enterCode ? "Verify your email address" : "Create an Account" }
        </span>

        {showLogin && (
          <React.Fragment>
            <GoToButton
              variant="secondary"
              style={{ marginBottom: isMobile ? "20px" : "0" }}
              aLink="/login"
              onClick={this.navToLogin}
            >
              Have an account? Log In here
            </GoToButton>
          </React.Fragment>
        )}
      </div>
    )
  }

  renderForms() {
    const {
      registration: {
        enterCode
      }

    } = this.props

    return (
      <React.Fragment>
        <div style={{ display: (enterCode) ? "none" : "block" }}>{this.registrationForm()}</div>
        {enterCode && (<div>{this.verificationForm()}</div>)}
      </React.Fragment>
    )
  }

  render() {
    const {
      noWrap
    } = this.props


    if (noWrap) return this.renderForms()
    return (
      <WrapContainer>
        <MetaSetter metaTitle="Sign Up" />

        <div style={{ marginTop: 50 }}>
          {this.renderForms()}
        </div>
      </WrapContainer>
    )
  }
}

RegistrationContainer.propTypes = {
  submitRegistration: PropTypes.func.isRequired,
  submitRegistrationCodeRequest: PropTypes.func.isRequired,
  updateFirstName: PropTypes.func.isRequired,
  updateLastName: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  navToLogin: PropTypes.func.isRequired,
  navToTerms: PropTypes.func.isRequired,
  navToPrivacyPolicy: PropTypes.func.isRequired,
  hideVerificationCodeForm: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submitFacebookLogin: PropTypes.func.isRequired,
  noWrap: PropTypes.bool,
  toggleRegistration: PropTypes.func,
  theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    registration: state.registration,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  updateFirstName: firstName => dispatch(updateFirstName(firstName)),
  updateLastName: lastName => dispatch(updateLastName(lastName)),
  updatePhone: phone => dispatch(updatePhone(phone)),
  updateEmail: email => dispatch(updateEmail(email)),
  updateCode: code => dispatch(updateCode(code)),
  submitFacebookLogin: (token, googleRecaptchaToken, noWrap, newsletter) => dispatch(submitFacebookLogin(token, googleRecaptchaToken, noWrap, newsletter)),
  clickedFacebookLogin: () => dispatch(clickedFacebookLogin()),
  updatePassword: password => dispatch(updatePassword(password)),
  updateError: error => dispatch(updateError(error)),
  submitRegistration: (code, firstName, lastName, phone, email, password, nowrap, newsletter) => dispatch(
    submitRegistration(code, firstName, lastName, phone, email, password, nowrap, newsletter)
  ),
  submitRegistrationCodeRequest: (firstName, lastName, phone, email, password, googleRecaptchaToken) => dispatch(
    submitRegistrationCodeRequest(firstName, lastName, phone, email, password, googleRecaptchaToken)
  ),
  navToLogin: () => dispatch(push("/login")),
  navToTerms: () => dispatch(push("/terms/customer")),
  navToPrivacyPolicy: () => dispatch(push("/privacy")),
  hideVerificationCodeForm: () => dispatch({ type: "REGISTRATION_CODE_HIDE" })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(RegistrationContainer))
