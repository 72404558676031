import React from "react"
import PropTypes from "prop-types"

import { ThemedButtonLink, VARIANT } from "./ThemedButton"

const GoToButton = ({
  aLink, style, className, children, variant, filled, ...rest
}) => {
  /**
  * A button className is generated using variant and filled props.
  * Below are all possible names:
  * go-to-button-primary
  * go-to-button-primary-filled
  * go-to-button-secondary
  * go-to-button-secondary-filled
  * In the franchise theme file we can redefine the style for each class.
  */
  const buttonClassName = `go-to-button-${variant}${filled ? "-filled" : ""}`

  return (
    <div className={`${className} ${buttonClassName}`} style={style}>
      <ThemedButtonLink
        className={variant}
        variant={variant}
        href={aLink}
        filled={filled}
        {...rest}
      >
        {children}
      </ThemedButtonLink>
    </div>
  )
}

GoToButton.defaultProps = {
  children: "Select",
  variant: VARIANT.PRIMARY,
  className: "",
  filled: false,
}

GoToButton.propTypes = {
  aLink: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.string,
  filled: PropTypes.bool,
  variant: PropTypes.oneOf([VARIANT.PRIMARY, VARIANT.SECONDARY, VARIANT.TERTIARY]),
}

export default GoToButton
