import { initialState as bookings } from "../containers/MyBookings/reducer"
import { initialState as instantbook } from "../containers/InstantBook/reducer"
import { initialState as inventoryView } from "../containers/InventoryView/reducer"
import { initialState as campaignView } from "../containers/InventoryView/campaignReducer"
import { initialState as cart } from "../containers/Cart/reducer"
import { initialState as purchase } from "../containers/Purchase/reducer"

export const migrations = {
  0: state => {
    const migration = {
      ...state,
      bookings,
      cart,
      purchase,
      inventoryView,
      campaignView
    }

    delete migration.promoCodes
    delete migration.reviewBooking

    return migration
  },
  1: state => {
    const migration = {
      ...state,
      bookings,
      cart,
      purchase,
      inventoryView,
      campaignView
    }

    delete migration.offerView

    return migration
  },
  2: state => {
    const migration = { ...state, instantbook }

    return migration
  }
}
