export const PURCHASE_PHONE_UPDATE = "PURCHASE_PHONE_UPDATE"
export const PAYMENT_AUTHORIZE = "PAYMENT_AUTHORIZE"
export const PAYMENT_AUTHORIZE_STRIPE = "PAYMENT_AUTHORIZE_STRIPE"
export const PAYMENT_AUTHORIZE_FAILURE = "PAYMENT_AUTHORIZE_FAILURE"
export const PAYMENT_AUTHORIZE_REDIRECT = "PAYMENT_AUTHORIZE_REDIRECT"
export const PAYMENT_AUTHORIZE_SUCCESS = "PAYMENT_AUTHORIZE_SUCCESS"
export const PAYMENT_CANCEL = "PAYMENT_CANCEL"
export const PAYMENT_TOKEN = "PAYMENT_TOKEN"
export const PAYMENT_CONFIRM = "PAYMENT_CONFIRM"
export const PAYMENT_CONFIRM_CHECK = "PAYMENT_CONFIRM_CHECK"
export const PAYMENT_CONFIRM_COMPLETE = "PAYMENT_CONFIRM_COMPLETE"
export const PAYMENT_CONFIRM_SUCCESS = "PAYMENT_CONFIRM_SUCCESS"
export const PAYMENT_CONFIRM_FAILURE = "PAYMENT_CONFIRM_FAILURE"
export const PAYMENT_CONFIRM_UNAVAILABLE = "PAYMENT_CONFIRM_UNAVAILABLE"
