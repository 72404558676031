import React from "react"

const newsletterSignupContentStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  margin: "20px 0px 10px 0px"
}

import PropTypes from "prop-types"

import { Trans } from "react-i18next"

import AvedaNewsletter from "../../../franchises/aveda/components/Newsletter"

const Newsletter = ({ isMobile }) => {
  return (
    <div style={{ padding: isMobile ? "0px 16px" : 0 }}>
      <Trans
        i18nKey="newsletter_signup_content"
        components={{
          top_margin_container: <div style={{ marginTop: 20 }} />,
          signup_content_style: <div style={newsletterSignupContentStyle} />,
          label: <label htmlFor="newsletter" style={{ cursor: "pointer" }} />,
          checkbox: <input style={{ cursor: "pointer" }} id="newsletter" name="newsletter" type="checkbox" defaultChecked />,
          label_container: <div style={{ fontSize: 16, marginLeft: 8 }} />,
          aveda_us_newsletter: <AvedaNewsletter url="https://www.aveda.com" langCode="us" />,
          aveda_uk_newsletter: <AvedaNewsletter url="https://www.aveda.co.uk" langCode="uk" />
        }}
      />
    </div>
  )
}
Newsletter.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Newsletter
