import React from "react"
import styled from "styled-components"

import { BagIcon } from "../icons"

const Tag = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 4px;
  border: 3px solid ${({ theme }) => theme.colors.darkHover};
  background-color: ${({ theme }) => theme.colors.darkHover};
  svg {
    height: 20px;
  }
`

const ExtraTag = () => {
  return (
    <Tag>
      <BagIcon style={{ marginLeft: 4, marginRight: 4 }} />
      <span
        style={{
          fontWeight: 700,
          padding: 5,
          whiteSpace: "nowrap"
        }}
      >
        Free Gift
      </span>
    </Tag>
  )
}


export default ExtraTag
