import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { TopicLink } from "./FaqLink"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const SideBar = styled.div`
  display: ${({ isMobile }) => (isMobile ? "none" : "block")};
  padding: 0 30px;
  border-left: ${({ theme }) => `solid 2px ${theme.colors.inactive}`};
  margin-left: 15px;
  min-width: 95px;
  h4 {
    font-weight: 400;
  }
`

const ContainerWithSideBar = ({
  children, topics, navigateTo, isMobile,
}) => (
  <Container>
    {children}
    <SideBar isMobile={isMobile}>
      <h3>FAQ Topics</h3>
      {topics.map(topic => <TopicLink key={topic.slug} topic={topic} size="h4" navigateTo={navigateTo} />)}
    </SideBar>
  </Container>
)

ContainerWithSideBar.propTypes = {
  children: PropTypes.element,
  topics: PropTypes.array,
  navigateTo: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default ContainerWithSideBar
