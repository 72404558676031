import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { push } from "connected-react-router"
import styled from "styled-components"
import { goBack } from "connected-react-router"

import * as faqsSelectors from "./selectors"
import { loadAllFaqs, searchFaqs } from "./actions"
import { navToFourOhFour } from "../FourOhFour/actions"
import WrapContainer from "../../containers/WrapContainer"
import MetaSetter from "../../components/MetaSetter"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: ${props => (props.isLarge ? "auto" : "20px")};
  margin-right: ${props => (props.isLarge ? "auto" : "20px")};
  margin-bottom: 80px;
  position: relative;
  padding: 0;
  div > h1 + div {
    text-align: center;
  }
  a {
    cursor: pointer;
  }
  img, iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  iframe {

  }

  h1, h2, h3, h4 {
    margin: 20px auto;
  }
`

const FaqsContentHoc = (InnerComponent) => {
  const FaqsContentWrapper = ({
    faqTopics, children, navigateTo, loadAllFaqs, isLarge, ...rest
  }) => {
    React.useEffect(() => {
      loadAllFaqs()
      window.scrollTo(0, 0)
    }, [])

    const onNavigateTo = (slug) => {
      navigateTo(slug)
    }

    if (!faqTopics) return null

    return (
      <WrapContainer loginRequired={false}>
        <MetaSetter
          metaTitle="Flossie FAQ's" // TODO configure this in prismic
          metaDescription="Flossie FAQs"
        />
        <ParentDiv isLarge={isLarge}>
          <InnerComponent
            {...rest}
            faqTopics={faqTopics}
            navigateTo={onNavigateTo}
          >
            {children}
          </InnerComponent>
        </ParentDiv>
      </WrapContainer>
    )
  }
  FaqsContentWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    navigateTo: PropTypes.func.isRequired,
    faqTopics: PropTypes.array,
    loadAllFaqs: PropTypes.func.isRequired,
    isLarge: PropTypes.bool.isRequired,
  }

  const mapStateToProps = state => ({
    faqTopics: faqsSelectors.getFaqsTopic(state),
    searchResults: faqsSelectors.getSearchResults(state),
    isLarge: state.browser.greaterThan.large,
  })

  const mapDispatchToProps = dispatch => ({
    navToFourOhFour: reason => dispatch(navToFourOhFour(reason)),
    navigateTo: slug => dispatch(push(slug)),
    navToPrevious: () => dispatch(goBack()),
    loadAllFaqs: () => dispatch(loadAllFaqs()),
    searchFaqs: query => dispatch(searchFaqs(query)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(FaqsContentWrapper)
}

export default FaqsContentHoc
