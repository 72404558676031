import { push } from "connected-react-router"

// inspirations

export const fetchArticles = (typeId, offset, limit = 10) => ({
  type: "FETCH_ARTICLES",
  payload: {
    offset,
    limit,
    type_id: typeId
  }
})

export const fetchArticle = slug => ({
  type: "FETCH_ARTICLE",
  payload: slug
})

export const setViewArticleType = typeId => ({
  type: "VIEW_ARTICLE_TYPE",
  payload: typeId
})

export const navToArticles = type => push(`/${type}`)
export const navToArticle = (type, slug) => push(`/${type}/${slug}`)

export const navToHome = () => push("/")
