import moment from "moment-timezone"

export default function getMonths(count = 12) {
  const m = moment()
  const months = [m]
  for (let i = 1; i < count; i += 1) {
    months.push(moment(m).add(i, "months"))
  }
  return months
}
