import { Observable } from "rxjs"
import {
  apiGetFavourites,
  apiUserFavouriteService,
  handleXhrError
} from "../../api/api"

import { fetchFavourites } from "./actions"

const fetchFavouritesFullfilled = response => ({
  type: "FETCH_USER_FAVORITES_SUCCESS",
  payload: { response }
})

const apiUserFavouriteServiceFullfilled = response => ({
  type: "USER_FAVORITE_SERVICE_SUCCESS",
  payload: { response }
})

export const fetchFavouritesEpic = (action$, { getState }) => action$.ofType("FETCH_USER_FAVORITES").mergeMap(action => {
  const { credentials } = getState().credentials

  if (!credentials.token) {
    return Observable.of(fetchFavouritesFullfilled([], action))
  }

  return apiGetFavourites(credentials, action.payload)
    .map(response => fetchFavouritesFullfilled(response, action))
    .catch(error => handleXhrError(error, "FETCH_USER_FAVORITES_FAILURE"))
})

export const userToggleFavouriteEpic = (action$, { getState }) => action$.ofType("USER_FAVORITE_SERVICE").switchMap(action => apiUserFavouriteService(getState().credentials.credentials, action.payload)
  .flatMap(response => Observable.of(
    apiUserFavouriteServiceFullfilled(response, action$),
    // re-fetch the favourites to update the list
    fetchFavourites()
  ))
  .catch(error => handleXhrError(error, "USER_FAVORITE_SERVICE_FAILURE")))
