import React from "react"
import PropTypes from "prop-types"

import { ThemedSelectButton, VARIANT } from "./ThemedButton"

/**
 * This is a select box but looks like a button.
 */
const SelectButton = ({ variant, ...rest }) => <ThemedSelectButton variant={variant} {...rest} />

SelectButton.defaultProps = {
  variant: VARIANT.PRIMARY,
}

SelectButton.propTypes = {
  variant: PropTypes.oneOf([VARIANT.PRIMARY, VARIANT.SECONDARY, VARIANT.TERTIARY]),
}

export default SelectButton
