export const initialState = {
  employee: null
}

export default function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case "CLEAR_EMPLOYEEE":
      return {
        ...state,
        employee: null
      }
    case "FETCH_EMPLOYEE_SUCCESS":
      return {
        ...state,
        employee: action.payload.response
      }
    default:
      return state
  }
}
