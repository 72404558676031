import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import moment from "moment-timezone"
import styled, { withTheme } from "styled-components"
import { Trans } from "react-i18next"

import {
  homeClicked,
  navToSignIn,
  navToFavourites,
  navToBookings,
  navToHome,
  navToProfile,
  navToRegister,
  toggleMenu,
} from "./actions"
import { logOut, preLogOut } from "../Profile/actions"
import { navToArticles } from "../Articles/actions"

import { AnchorLinkMenu, SpanLinkMenu } from "../../components/nav/Links"

import PoweredByFlossie from "../../components/PoweredByFlossie"

import * as faqSelectors from "../Faqs/selectors"

const {
  RETURN_LINK_TITLE,
  RETURN_LINK_URL,
  SHOW_PBF_SIDE_MENU
} = SITE_CONFIG


const SideMenuDiv = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.sideNav.top};
  z-index: 008;
  height: 100%;
  min-height: 680px;
  background-color: ${({ theme }) => theme.sideNav.background};
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition: right 0.4s;
  transition: left 0.4s;
`

const StyledBottomContainer = styled.div`
  position: absolute;
  bottom: 225px;
  width: calc(100% - 60px);
`


const Copyright = styled.p`
  text-align: right;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.sideNav.legalLinks};
`

const StyledShowMenuParent = styled.div`
  z-index: 6;
  position: "fixed";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.overlayColor};
`

const LegalLink = styled.a`
  color: ${({ theme }) => theme.sideNav.legalLinks};
  font-size: 10px;
  display: block;
`


const StyledPrivacyContainer = styled.div`
  margin-top: 20;
  font-size: 10;
  color: ${({ theme }) => theme.sideNav.legalLinks};
  text-align: right;
`

const MenuLink = styled(SpanLinkMenu)`
  margin-bottom: 10px;
  width: 200px;
`

const MenuPoweredByFlossie = styled(PoweredByFlossie)`
  position: static;
  display: inline-block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  a {
    text-transform: none !important;
  }
`

class SideNavContainer extends React.Component {
  clickLogOut = e => {
    e.preventDefault()
    const { preLogOut, logOut } = this.props
    preLogOut()
    logOut()
  }

  render() {
    const {
      className,
      menuOpen,
      loggedIn,
      isMobile,
      showFooter,
      hasFaqs,
      navToHome,
      navToBookings,
      navToFavourites,
      navToProfile,
      navToArticles,
      navToAbout,
      navToSignIn,
      navToRegister,
      navToPrivacyPolicy,
      navToCustomerTerms,
      navToHealthAndSafety,
      navToHelp,
      theme,
      id,
    } = this.props


    const isCurrentPath = (nodepath, partialMatch = false) => {
      const { urlpath } = this.props
      let isActive = false
      if (!urlpath || !nodepath) return isActive
      if (urlpath === nodepath) isActive = true

      if (partialMatch) {
        if (urlpath.search(nodepath) === 0) {
          isActive = true
        }
      }
      return isActive
    }

    const sideMenuStyle = {}

    if (theme.sideNav.alignment === "left") {
      sideMenuStyle.left = menuOpen ? "0px" : "-260px"
    } else {
      sideMenuStyle.right = menuOpen ? "0px" : "-260px"
    }

    return (
      <div className={className} style={{ margin: "0 auto", maxWidth: theme.pageMaxWidth, position: "relative" }}>
        {menuOpen && <StyledShowMenuParent onClick={this.toggleBurger} />}
        <SideMenuDiv id={id} style={sideMenuStyle} menuOpen={menuOpen}>
          <MenuLink
            active={isCurrentPath("/")}
            name="top"
            onClick={navToHome}
          >
            <Trans i18nKey="sidenav_home" />
          </MenuLink>

          {loggedIn && (
            <MenuLink
              active={isCurrentPath("/bookings")}
              onClick={navToBookings}
            >
              <Trans i18nKey="sidenav_bookings" />
            </MenuLink>
          )}
          {loggedIn && (
            <MenuLink
              active={isCurrentPath("/favourites")}
              onClick={navToFavourites}
            >
              <Trans i18nKey="sidenav_favourites" />
            </MenuLink>
          )}
          {loggedIn && (
            <MenuLink
              active={isCurrentPath("/account")}
              onClick={navToProfile}
            >
              <Trans i18nKey="sidenav_profile" />
            </MenuLink>
          )}
          {SITE_CONFIG.SHOW_INSPIRATION && (
            <MenuLink
              active={isCurrentPath("/inspiration")}
              onClick={navToArticles}
              i18n="sidenav_inspiration"
            >
              <Trans i18nKey="sidenav_inspiration" />
            </MenuLink>
          )}
          {SITE_CONFIG.SHOW_ABOUT && (
            <MenuLink onClick={navToAbout}>
              <Trans i18nKey="sidenav_about" />
            </MenuLink>
          )}
          {SITE_CONFIG.SHOW_YOUR_HEALTH && (
            <MenuLink onClick={navToHealthAndSafety}>
              <Trans i18nKey="sidenav_your_health" />
            </MenuLink>
          )}
          {hasFaqs && (
            <MenuLink
              active={isCurrentPath("/help", true)}
              onClick={navToHelp}
            >
              <Trans i18nKey="sidenav_help" />
            </MenuLink>
          )}
          {!loggedIn && (
            <MenuLink
              active={isCurrentPath("/login") || (!isMobile && isCurrentPath("/register"))}
              onClick={navToSignIn}
            >
              <Trans i18nKey="sidenav_login" />
            </MenuLink>
          )}
          {!loggedIn && isMobile && (
            <MenuLink
              active={isCurrentPath("/register")}
              onClick={navToRegister}
            >
              <Trans i18nKey="sidenav_register" />
            </MenuLink>
          )}

          {loggedIn && (
            <MenuLink
              active
              style={{ borderBottom: "none", marginTop: 20 }}
              onClick={this.clickLogOut}
            >
              <Trans i18nKey="sidenav_logout" />
            </MenuLink>
          )}

          {showFooter && (
            <StyledBottomContainer isMobile={isMobile}>
              <StyledPrivacyContainer>
                <LegalLink onClick={navToPrivacyPolicy} href="/privacy">
                  Cookie &amp; Privacy Policy
                </LegalLink>
                <LegalLink
                  onClick={navToCustomerTerms}
                  href="/terms/customer"
                >
                  Booking Terms &amp; Conditions
                </LegalLink>
                {SHOW_PBF_SIDE_MENU && <MenuPoweredByFlossie />}
                <Copyright>
                  © Flossie.com Limited
                  {" "}
                  {moment().format("YYYY")}
                  <br />
                  All Rights Reserved
                </Copyright>
              </StyledPrivacyContainer>
              {RETURN_LINK_URL && RETURN_LINK_TITLE && (
                <AnchorLinkMenu
                  style={{
                    width: 200, marginBottom: 10, fontSize: "13px", marginTop: 20, paddingBottom: 0, display: "block", textAlign: "right", borderBottom: "none"
                  }}
                  href={RETURN_LINK_URL}
                >
                  {RETURN_LINK_TITLE}
                </AnchorLinkMenu>
              )}
            </StyledBottomContainer>
          )}
        </SideMenuDiv>
      </div>
    )
  }
}


SideNavContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  navToSignIn: PropTypes.func.isRequired,
  navToAbout: PropTypes.func.isRequired,
  navToRegister: PropTypes.func.isRequired,
  navToBookings: PropTypes.func.isRequired,
  navToFavourites: PropTypes.func.isRequired,
  navToProfile: PropTypes.func.isRequired,
  navToHome: PropTypes.func.isRequired,
  navToArticles: PropTypes.func.isRequired,
  navToCustomerTerms: PropTypes.func.isRequired,
  navToPrivacyPolicy: PropTypes.func.isRequired,
  navToHealthAndSafety: PropTypes.func.isRequired,
  navToHelp: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  preLogOut: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  urlpath: PropTypes.string,
  hasFaqs: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  showFooter: PropTypes.bool,
}

SideNavContainer.defaultProps = {
  hasFaqs: false,
  className: "",
  id: "menu",
  showFooter: true,
}

const mapStateToProps = state => ({
  loggedIn: !!state.profile.profile.id,
  menuOpen: state.navBar.menuOpen,
  userId: state.profile.profile.id,
  userEmail: state.profile.profile.username,
  isMobile: state.browser.lessThan.mobile,
  urlpath: state.router.location.pathname,
  hasFaqs: faqSelectors.getShowFaqs(state),
})

const mapDispatchToProps = dispatch => ({
  navToSignIn: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToSignIn())
  },
  navToRegister: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToRegister())
  },
  navToBookings: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToBookings())
  },
  navToFavourites: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToFavourites())
  },
  navToHome: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(homeClicked())
    dispatch(navToHome())
  },
  navToProfile: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToProfile())
  },
  navToArticles: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToArticles("inspiration"))
  },
  navToAbout: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/about/customers"))
  },
  navToCustomerTerms: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/terms/customer"))
  },
  navToPrivacyPolicy: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/privacy"))
  },
  navToHealthAndSafety: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/your-health"))
  },
  navToHelp: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/help"))
  },
  logOut: () => dispatch(logOut()),
  preLogOut: () => dispatch(preLogOut())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SideNavContainer))
