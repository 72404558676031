import { combineEpics } from "redux-observable"

import {
  submitLoginEpic,
  submitLoginFacebook,
  fetchProfileEpic,
  fetchProfileAfterPurchaseEpic,
  handleAuthFailureEpic,
  logOutEpic,
  automaticLoginEpic,
  // pusherSubscribeEpic,
  postDeviceEpic,
  rayGunIdentifyEpic,
  submitLoginAndReloadCartEpic
} from "../containers/Login/epics"
import { intercomEpic } from "./intercomEpic"
import {
  resetPasswordEpic,
  updateUserPasswordEpic,
  updatePasswordFullfilledEpic,
  navigateToPreviousLocationEpic,
} from "../containers/ResetPassword/epics"
import {
  registrationEpic,
  registrationCodeRequestEpic,
  registrationAndReloadCartEpic
} from "../containers/Registration/epics"
import { fetchBookingsEpic, cancelUserBookingEpic } from "../containers/MyBookings/epics"
import { fetchOrderEpic } from "../containers/Order/epics"
import {
  autoCheckPromotionCodeEpic,
  validatePromotionCodeEpic,
  savePromotionCodeEpic,
  savePromotionCodeAndReloadCartEpic,
  paymentConfirmEpic,
  paymentConfirmCheckEpic,
  paymentConfirmMonitorEpic,
  paymentConfirmAvailableEpic,
  paymentConfirmUnavailableEpic,
  paymentRemoveUnavailableEpic,
  paymentAuthorizeEpic,
  paymentAuthorizeRedirectEpic,
  paymentAuthorizeStripeEpic
} from "../containers/Purchase/epics"
import {
  fetchCartEpic,
  addItemToCartEpic,
  addItemToCartAndRedirectEpic,
  removeFromCartEpic,
  removeNotesFromCartEpic,
  addNotesToCartEpic,
  enableBalanceInCartEpic,
  disableBalanceInCartEpic,
  selectCartPaymentMethodEpic,
  clearCartEpic
} from "../containers/Cart/epics"
import {
  fetchInventoryEpic,
  getCompaniesForServiceEpic,
  getCompanyReviewsEpic,
  getServiceReviewsEpic,
  getCampaignsForServiceEpic,
  fetchCampaignEpic,
  fetchCampaignsEpic,
  fetchServicesByServiceDescriptionIdEpic,
  fetchRelatedServicesEpic,
  fetchInventoryErrorEpic,
} from "../containers/InventoryView/epics"
import {
  scrapeAvailabilityEpic,
  scrapeAvailabilityTriggerEpic,
  nextAvailabilityEpic,
  nextAvailabilityTriggerEpic,
  bestPriceEpic,
  bestPriceTriggerEpic,
} from "../containers/InventoryView/scrapeEpic"
import {
  fetchCompanyEpic,
  fetchCompanyServicesEpic,
  getCompanyProfileReviewsEpic
} from "../containers/Companies/epics"
import { fetchEmployeeEpic } from "../containers/Employees/epics"
import { profileSaveEpic, authenticationSaveEpic } from "../containers/Profile/epics"
import {
  initGaTrackerEpic,
  initFbTrackerEpic,
  gaTrackerActionsEpic,
  fbTrackerActionsEpic
} from "../containers/Analytics/epics"

import { fetchArticlesEpic, fetchArticleEpic } from "../containers/Articles/epics"

import {
  fetchInstantBookServicesEpic,
  filterChangeEpic,
  paginatedFetchEpic,
  reSearchOnEmptyEpic,
  redirectToLastFilterOnOverFilter
} from "../containers/InstantBook/epics"

import {
  fetchCategoriesEpic,
  fetchRegionsEpic,
  companySearchEpic
} from "../containers/InventoryFilter/epics"

import {
  fetchFavouritesEpic,
  userToggleFavouriteEpic
} from "../containers/Favourites/epics"

export default combineEpics(
  logOutEpic,
  handleAuthFailureEpic,
  submitLoginEpic,
  submitLoginFacebook,
  submitLoginAndReloadCartEpic,
  automaticLoginEpic,
  fetchProfileEpic,
  fetchProfileAfterPurchaseEpic,
  rayGunIdentifyEpic,
  resetPasswordEpic,
  updateUserPasswordEpic,
  registrationEpic,
  registrationCodeRequestEpic,
  registrationAndReloadCartEpic,
  fetchBookingsEpic,
  cancelUserBookingEpic,
  fetchOrderEpic,
  fetchInstantBookServicesEpic,
  filterChangeEpic,
  fetchCategoriesEpic,
  fetchRegionsEpic,
  redirectToLastFilterOnOverFilter,
  companySearchEpic,
  paginatedFetchEpic,
  fetchInventoryEpic,
  getCompaniesForServiceEpic,
  getCampaignsForServiceEpic,
  fetchCampaignEpic,
  fetchCampaignsEpic,
  fetchInventoryErrorEpic,
  fetchServicesByServiceDescriptionIdEpic,
  fetchRelatedServicesEpic,
  profileSaveEpic,
  authenticationSaveEpic,
  reSearchOnEmptyEpic,
  scrapeAvailabilityTriggerEpic,
  scrapeAvailabilityEpic,
  nextAvailabilityTriggerEpic,
  nextAvailabilityEpic,
  bestPriceTriggerEpic,
  bestPriceEpic,
  postDeviceEpic,
  paymentConfirmEpic,
  paymentConfirmCheckEpic,
  paymentConfirmMonitorEpic,
  paymentConfirmAvailableEpic,
  paymentConfirmUnavailableEpic,
  paymentRemoveUnavailableEpic,
  paymentAuthorizeEpic,
  paymentAuthorizeRedirectEpic,
  paymentAuthorizeStripeEpic,
  getCompanyReviewsEpic,
  getServiceReviewsEpic,
  fetchCompanyEpic,
  fetchCompanyServicesEpic,
  getCompanyProfileReviewsEpic,
  fetchEmployeeEpic,
  intercomEpic,
  initGaTrackerEpic,
  initFbTrackerEpic,
  gaTrackerActionsEpic,
  fbTrackerActionsEpic,
  fetchArticlesEpic,
  fetchArticleEpic,
  fetchFavouritesEpic,
  userToggleFavouriteEpic,
  fetchCartEpic,
  clearCartEpic,
  addItemToCartEpic,
  addItemToCartAndRedirectEpic,
  removeFromCartEpic,
  removeNotesFromCartEpic,
  addNotesToCartEpic,
  enableBalanceInCartEpic,
  disableBalanceInCartEpic,
  selectCartPaymentMethodEpic,
  autoCheckPromotionCodeEpic,
  validatePromotionCodeEpic,
  savePromotionCodeEpic,
  savePromotionCodeAndReloadCartEpic,
  navigateToPreviousLocationEpic,
  updatePasswordFullfilledEpic,
)
