import React from "react"
import styled from "styled-components"
import moment from "moment-timezone"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { RoundImage } from "../../components/images/RoundImage"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { ListTopHeading } from "../../components/datarenderers/ListTopHeading"
import { thumborize } from "../../components/images/thumborize"
import buttonize from "../../components/buttonize"
import Pricing from "../../components/Pricing"
import ExtraTag from "../../components/tags/ExtraTag"
import CampaignTag from "../../components/tags/CampaignTag"
import { RenderStarRatings } from "../InventoryView/components"
import GoToButton from "../../components/button/GoToButton"

const StyledTimeSpan = styled.span`
  font-weight: 600;
  margin: 0px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
`

const WrapperDiv = styled.div`
  display: flex;
  margin: 0px 8px;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  max-width: 100%;
  position: relative;
`

const BuyButton = styled(GoToButton)`
  a {
    display: block;
  }
`

const ServiceImage = ({
  company, images, width, category
}) => {
  if (
    SITE_CONFIG.INSTANT_BOOK_IMAGE_SELECTOR === "logo"
    && company.images
    && company.images.find(i => i.placement === "logo")
  ) {
    return (
      <RoundImage
        src={thumborize(
          company.images.find(i => i.placement === "logo").url,
          width * 4,
          width * 4
        )}
        size={width}
      />
    )
  }
  // TODO: add this to a global selector.
  const listImages = images.find(
    i => i.placement === "list" && i.position === "0"
  )
  const catImages = category.images.find(
    i => i.placement === "list" && i.position === "0"
  )
  let showListType = listImages
  if (!listImages) showListType = catImages

  if (
    SITE_CONFIG.INSTANT_BOOK_IMAGE_SELECTOR === "list"
    && images
    && showListType
  ) {
    return (
      <RoundImage
        src={thumborize(showListType.url, width * 4, width * 4)}
        size={width}
      />
    )
  }
  return null
}



ServiceImage.propTypes = {
  company: PropTypes.object,
  images: PropTypes.array,
  width: PropTypes.number,
  category: PropTypes.object
}


export const InstantBookService = ({
  aLink,
  date,
  isMobile,
  images,
  clickAction,
  description,
  currency,
  company,
  pricing,
  availabilities,
  has_extras,
  campaigns,
  userIsVip,
  showRating,
  rating,
  ratingCount,
  onClickRating
}) => {
  const { t } = useTranslation()

  return (
    <div {...buttonize(clickAction, {}, "link")}>
      <WrapperDiv
        style={{
          margin: "auto",
          padding: "10px",
          paddingTop: isMobile ? 10 : 15,
          paddingBottom: isMobile ? 10 : 15
        }}
      >
        <ServiceImage
          company={company}
          category={description.category}
          images={images}
          width={isMobile ? 64 : 110}
        />

        <div
          style={{
            flex: 5,
            marginLeft: isMobile ? 15 : 20,
            fontSize: 16,
            position: "relative"
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3 }}>
              <ListTopHeading style={{ fontSize: isMobile ? 15 : 17 }}>
                {description.name}
              </ListTopHeading>
              <div style={{ margin: "4px 0px" }}>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: isMobile ? 14 : 16,
                    padding: 0,
                  }}
                >
                  <div>{company.name}</div>
                  <CompanyAddressBuilder
                    company={company}
                    district
                    region
                    inline
                  />
                </div>
              </div>

              {(has_extras || campaigns) && (
                <div style={{ display: "flex", flexWrap: "wrap", marginBottom: -4 }}>
                  {has_extras && (<ExtraTag />)}
                  {campaigns && campaigns.map(c => (<CampaignTag key={`campaign-${c.id}`} campaign={c} />))}
                </div>
              )}

            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between"
              }}
            >
              {pricing && pricing.default && (
                <Pricing
                  style={{ marginLeft: 4 }}
                  prices={pricing}
                  currency={currency}
                  isMobile={isMobile}
                  userIsVip={userIsVip}
                />
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 12, alignItems: "flex-end" }}>
            <div style={{ flex: 1 }}>
              <div style={{ fontWeight: 600, padding: 0 }}>
                {!showRating && moment(date).format("ddd MMMM Do YYYY")}
              </div>
              <div style={{ padding: 0 }}>
                {!showRating && !availabilities && (
                  <StyledTimeSpan isMobile={isMobile}>
                    Refresh Times
                  </StyledTimeSpan>
                )}
                {!showRating
                  && availabilities
                  && availabilities.slice(0, 3).map(a => (
                    <StyledTimeSpan
                      key={`availability-${a.id}`}
                      isMobile={isMobile}
                    >
                      {moment(a.time.start_time)
                        .tz(company.timezone)
                        .format("h:mma")}
                      {a !== availabilities.slice(0, 3).slice(-1)[0] && " | "}
                    </StyledTimeSpan>
                  ))}
                <div style={{ marginTop: "5px" }}>
                  {showRating && onClickRating && ratingCount && rating && (
                    <RenderStarRatings
                      clickStars={() => onClickRating()}
                      rating={rating}
                      ratingCount={ratingCount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <BuyButton variant="primary" aLink={aLink}>{t("instant_book_select")}</BuyButton>
            </div>
          </div>
        </div>
      </WrapperDiv>
    </div>
  )
}

InstantBookService.propTypes = {
  date: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  clickAction: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  availabilities: PropTypes.array,
  campaigns: PropTypes.array,
  has_extras: PropTypes.bool.isRequired,
  aLink: PropTypes.string,
  category: PropTypes.object,
  userIsVip: PropTypes.bool,
  showRating: PropTypes.bool,
  rating: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  ratingCount: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  onClickRating: PropTypes.func
}

InstantBookService.defaultProps = {
  availabilities: null,
  campaigns: null
}
