import React from "react"
import PropTypes from "prop-types"

import FreeProduct from "../images/FreeProduct"
import ParagraphText from "../ParagraphText"
import {
  ListTopHeading,
} from "../datarenderers"

const ProductCard = ({
  product,
  isMobile
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: isMobile ? "center" : "flex-start",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <div style={{ marginRight: isMobile ? 0 : 20, marginBottom: isMobile ? 20 : 0 }}>
        <FreeProduct product={product} />
      </div>


      <div style={{ marginBottom: 10 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <ListTopHeading>
            <div style={{ marginTop: 10, fontWeight: "bold", fontSize: isMobile ? 16 : 18 }}>
              +&nbsp;
              {product.name}
            </div>
            <ParagraphText
              style={{ marginTop: 8, marginBottom: 0, fontWeight: 300, fontSize: 16 }}
              text={product.description}
            />
          </ListTopHeading>
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
}

export default ProductCard
