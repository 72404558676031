import { push } from "connected-react-router"

// TODO: these are not being used?
export const navToSignIn = () => push("/login")
export const navToRegister = () => push("/register")
export const navToBookings = () => push("/bookings")
export const navToFavourites = () => push("/favourites")
export const navToCompanies = () => push("/companies")
export const navToProfile = () => push("/account")
export const navToHome = () => push("/")
export const toggleMenu = () => ({ type: "TOGGLE_MENU" })
export const cookieOk = () => ({ type: "COOKIE_OK" })
export const cookieDecline = () => ({ type: "COOKIE_DECLINE" })
export const homeClicked = () => ({ type: "HOME_CLICKED" })
