import { createSelector } from "reselect"

export const getCampaignView = state => (Object.prototype.hasOwnProperty.call(state, "campaignView")
  ? state.campaignView
  : state)

export const getRelatedServices = createSelector(
  getCampaignView,
  campaign => campaign.relatedServices
)
