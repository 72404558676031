import React from "react"
import PropTypes from "prop-types"
import { PrismicRichText } from '@prismicio/react'

import ContainerWithSideBar from "./ContainerWithSideBar"
import SearchInput from "./SearchInput"
import Header from "./Header"
import FaqsContentHoc from "./FaqsContentHoc"
import { TopicLink } from "./FaqLink"

const Answer = ({
  match, navigateTo, faqTopics, navToFourOhFour, navToPrevious, isMobile,
}) => {
  if (!match || !match.params || !match.params.faq) {
    navigateTo("/help")
  }

  const { faq } = match.params
  let faqData
  const topic = faqTopics.find(t => t.faqs.find((faqOfTopic) => {
    if (faqOfTopic.slug === faq) {
      faqData = faqOfTopic
      return true
    }
    return false
  }))

  if (!faqData) {
    navToFourOhFour(`Unable to find faq data ${faq}`)
  }

  if (!topic) {
    navToFourOhFour(`Unable to find topic for faq ${faq}`)
  }


  const showEmbedVideo = (faq) => {
    return faq.data.video ? <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: faq.data.video.html }} /> : null
  }

  return (
    <div>
      <Header navToPrevious={navToPrevious}><TopicLink size="h3" topic={topic} suffix=" FAQs" navigateTo={navigateTo} /></Header>
      <SearchInput match={match} navigateTo={navigateTo} />
      <ContainerWithSideBar isMobile={isMobile} topics={faqTopics} navigateTo={navigateTo}>
        <div style={{ flex: 1 }}>
          <h1>{faqData.data.question}</h1>
          <PrismicRichText field={faqData.data.answer} />
          {showEmbedVideo(faqData)}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

Answer.propTypes = {
  match: PropTypes.object.isRequired,
  faqTopics: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired,
  navToFourOhFour: PropTypes.func.isRequired,
  navToPrevious: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default FaqsContentHoc(Answer)
