import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { goBack } from "connected-react-router"
import { withTheme } from "styled-components"

import {
  homeClicked,
  navToSignIn,
  navToHome,
  toggleMenu,
} from "./actions"
import { logOut, preLogOut } from "../Profile/actions"

import PoweredByFlossie from "../../components/PoweredByFlossie"
import CartContainer from "../Cart/CartContainer"
import SideNavContainer from "./SideNavContainer"
import AvedaHeader from "../../franchises/aveda/components/Header"

import { MenuIcon } from "../../components/icons"
import { FixedDiv } from "./components"


class NavBarContainer extends React.Component {
  componentDidMount() {
    /* Quick hack so if menu is open and click is neither the menu toggle button
       or within the menu itself, then close the menu */
    document.addEventListener("click", e => {
      const { menuOpen } = this.props

      const isBurger = e.target.closest("#burger") !== null
      const isMenu = e.target.closest("#menu") !== null

      if (menuOpen && !isBurger && !isMenu) {
        const { closeMenu } = this.props
        closeMenu()
      }
    })
  }

  clickLogOut = e => {
    e.preventDefault()
    const { preLogOut, logOut } = this.props
    preLogOut()
    logOut()
  }

  toggleBurger = e => {
    e.preventDefault()
    const { toggleMenu } = this.props
    toggleMenu()
  }

  renderHeader() {
    const {
      navToSignIn, navToHome, menuOpen, theme, browser
    } = this.props

    // This abomination is explain within the Aveda theme configuration
    if (theme.avedaHeaderHack) {
      return (<AvedaHeader browser={browser} onHomeClick={navToHome} onLoginClick={navToSignIn} onBurgerClick={this.toggleBurger} menuOpen={menuOpen} />)
    }

    return (
      <div style={{ height: 70 }}>
        <FixedDiv>
          {theme.logo(theme.logoImage, theme.logoSize, navToHome)}

          <div style={{ flex: 1 }} />

          <CartContainer />

          <a style={{ display: "inline-block", marginRight: 24 }} id="burger" onClick={this.toggleBurger} href="/">
            <MenuIcon />
          </a>

        </FixedDiv>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {/* Render powered by flossie top tab */}
        {SITE_CONFIG.SHOW_PBF_TOP && (
          <PoweredByFlossie />
        )}

        {/* Render side nav */}
        <SideNavContainer />

        {/* Render site header */}
        {this.renderHeader()}
      </React.Fragment>
    )
  }
}


NavBarContainer.propTypes = {
  navToSignIn: PropTypes.func.isRequired,
  navToHome: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  preLogOut: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  browser: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

NavBarContainer.defaultProps = {
}

const mapStateToProps = state => ({
  displayName: `${state.profile.profile.first_name} ${state.profile.profile.last_name}`,
  loggedIn: !!state.profile.profile.id,
  menuOpen: state.navBar.menuOpen,
  userId: state.profile.profile.id,
  userEmail: state.profile.profile.username,
  isMobile: state.browser.lessThan.mobile,
  browser: state.browser,
})

const mapDispatchToProps = dispatch => ({
  navToSignIn: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navToSignIn())
  },


  navToHome: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(homeClicked())
    dispatch(navToHome())
  },


  goBack: () => dispatch(goBack()),
  toggleMenu: () => dispatch(toggleMenu()),
  closeMenu: () => dispatch({ type: "CLOSE_MENU" }),
  logOut: () => dispatch(logOut()),
  preLogOut: () => dispatch(preLogOut())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(NavBarContainer))
