import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import buttonize from "../../components/buttonize"

import ClickableLabel from "../../components/ClickableLabel"
import ShadowBox from "../../components/ShadowBox"
import EmployeeCard from "../../components/cards/EmployeeCard"

import { EMPLOYEE_ID_ANY } from "./reducer"

const EmployeeClickableLabel = styled(ClickableLabel)`
  padding: 10px;
`

const sortEmployee = (a, b) => {
  if (a.pricing && b.pricing) {
    const order = parseFloat(a.pricing.default.price) - parseFloat(b.pricing.default.price)

    if (order !== 0) {
      return parseFloat(a.pricing.default.price) - parseFloat(b.pricing.default.price)
    }
  }

  if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) return -1
  if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) return 1

  return 0
}

const RenderAvailableEmployeeList = ({
  employees,
  pricing,
  onSelectEmployee,
  onClose,
  currency,
  isMobile,
  isVip,
  role,
  hideAnyEmployee,
  company
}) => {
  const selectOptions = employees
    .sort(sortEmployee)
    .map(e => (
      <EmployeeClickableLabel
        key={e.id}
        {...buttonize(() => onSelectEmployee(e.id), { flex: 1 })}
        white
      >
        <EmployeeCard
          employee={e}
          pricing={pricing}
          isMobile={isMobile}
          currency={currency}
          userIsVip={isVip}
          company={company}
        />
      </EmployeeClickableLabel>
    ))

  return (
    <ShadowBox
      header={`Select ${role}`}
      isFullscreen={isMobile}
      closeBox={onClose}
    >
      <div
        style={{
          overflowY: "scroll",
          marginLeft: isMobile ? "-10px" : 0,
          marginRight: isMobile ? "-20px" : "-10px",
          maxHeight: isMobile ? "calc(100vh - 150px)" : "calc(100vh - 250px)"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!hideAnyEmployee && (
            <EmployeeClickableLabel
              key={EMPLOYEE_ID_ANY}
              {...buttonize(() => onSelectEmployee(EMPLOYEE_ID_ANY), { flex: 1 })}
              white
            >
              Any Stylist
            </EmployeeClickableLabel>
          )}

          {selectOptions}
        </div>
      </div>
    </ShadowBox>
  )
}

RenderAvailableEmployeeList.propTypes = {
  employees: PropTypes.array,
  pricing: PropTypes.array,
  onSelectEmployee: PropTypes.func,
  onClose: PropTypes.func,
  currency: PropTypes.object,
  isMobile: PropTypes.bool,
  isVip: PropTypes.bool,
  hideAnyEmployee: PropTypes.bool,
  role: PropTypes.string,
  company: PropTypes.object
}

export default RenderAvailableEmployeeList
