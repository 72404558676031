import React from "react"
import ArticleYouTubeEmbed from "./ArticleYouTubeEmbed"
import ParagraphText from "../../components/ParagraphText"
import { thumborize } from "../../components/images/thumborize"
import { ArrowRightIcon } from "../../components/icons"

const renderSections = (
  idx,
  id,
  image,
  content,
  title,
  youTubeId,
  ctaLabel,
  ctaUrl,
  isMobile
) => {
  let imageStyle = {
    width: "100%",
    display: "block",
    borderRadius: 2,
    margin: "20px 0"
  }

  if (isMobile) {
    imageStyle = {
      ...imageStyle,
      margin: "20px 0px",
      float: "none",
      width: "100%",
      borderRadius: 0
    }
  }

  const showCta = (ctaLabel && ctaUrl && ctaUrl.match(/^http/))

  let result = (
    <div role="main" key={id}>
      {image && <img src={image} alt={title} style={imageStyle} />}
      <h2
        style={{
          margin: "0 0 4px 0",
          fontSize: 18,
          padding: isMobile ? "0px 10px" : 0
        }}
      >
        {title}
      </h2>
      {youTubeId && (
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <ArticleYouTubeEmbed videoId={youTubeId} />
        </div>
      )}
      {content && (
        <ParagraphText
          style={{
            margin: 0,
            marginBottom: 10,
            fontSize: 16,
            lineHeight: "22px",
            padding: isMobile ? "0px 10px" : 0
          }}
          text={content}
        />
      )}
      {showCta && (
        <div style={{ textAlign: "center" }}>
          <a style={{ color: "#ef609f", fontSize: 18, fontWeight: 500, textDecoration: "none" }} href={ctaUrl}>
            {ctaLabel}&nbsp;
            <ArrowRightIcon style={{ marginLeft: 5 }} />
          </a>
        </div>
      )}
    </div>
  )
  // no data to display, render null.
  if (!content && !image && !title) result = null
  return result
}

const Article = ({ isMobile, sections }) => sections.map((section, idx) => {
  // ensure all assigned values are not undefined.
  const id = (section && section.id) || null
  // const image = (section && section.images && section.images[0] && section.images[0].url) || null

  const imageUrl = (section.images
        && section.images.find(
          i => i.placement === "main" && i.position === "0"
        ))
      || null
  const image = (imageUrl && thumborize(encodeURIComponent(imageUrl.url), 980, 0)) || null

  const content = (section && section.content) || null
  const title = (section && section.title) || null
  const youTubeId = (section && section.youtube_id) || null

  const ctaLabel = (section && section.cta_label) || null
  const ctaUrl = (section && section.cta_url) || null

  // render the data for article sections.
  return renderSections(idx, id, image, content, title, youTubeId, ctaLabel, ctaUrl, isMobile)
})

export default Article
