import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"
import { connect } from "react-redux"
import moment from "moment"

import {
  fetchBookings,
  toggleHistory,
  navToBooking,
  navToHome,
  cancelBooking,
} from "./actions"

import { navToInventory } from "../InventoryView/actions"
import WrapContainer from "../WrapContainer"
import { Booking } from "./Booking"
import { SubmitInput } from "../../components/forms/Inputs"
import HugeTitle from "../../components/HugeTitle"
import Button from "../../components/button"

class MyBookingsContainer extends Component {
  clickBooking = id => {
    const { navToBooking } = this.props
    navToBooking(id)
  }

  clickToggleHistory = e => {
    e.preventDefault()
    const { toggleHistory, fetchBookings } = this.props
    toggleHistory()
    fetchBookings()
  }

  handleCancel = (bookingId) => {
    const { cancelBooking } = this.props
    cancelBooking(bookingId)
  }

  navToRebook = booking => {
    const { navToInventory } = this.props
    navToInventory(
      booking.service.slug,
      moment().format("YYYY-MM-DD")
    )
  }

  render() {
    const {
      isMobile,
      showHistory,
      bookings,
      navToHome,
      fetchBookings,
      credentials,
      theme,
    } = this.props
    return (
      <WrapContainer
        loginRequired
        showNavBack={false}
        onAuth={fetchBookings}
      >
        <div
          style={{
            backgroundColor: "#f7f7f7",
            borderBottom: `3px solid ${theme.colors.primary}`
          }}
        >
          <HugeTitle
            style={{
              padding: "20px 0px",
              fontWeight: 600
            }}
          >
            My Appointments
          </HugeTitle>
        </div>
        <div
          style={{
            maxWidth: 760,
            margin: isMobile ? "0px 10px 20px 10px" : "0px auto",
            paddingTop: 10
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "20px auto"
            }}
          >
            <div
              style={{
                flex: 1,
                marginRight: 5
              }}
            >
              <Button
                variant="secondary"
                onClick={
                  showHistory
                    ? this.clickToggleHistory
                    : e => e.preventDefault()
                }
                filled={!showHistory}
                style={{ width: "100%" }}
              >
                Upcoming
              </Button>
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: 5
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="secondary"
                onClick={
                  showHistory
                    ? e => e.preventDefault()
                    : this.clickToggleHistory
                }
                filled={showHistory}
              >
                Past
              </Button>
            </div>
          </div>
          {bookings.bookings
            && bookings.bookings.map(booking => (
              <Booking
                {...booking}
                appointmentTime={booking.appointment_time}
                isMobile={isMobile}
                key={booking.id}
                onClick={() => this.clickBooking(booking.order.id)}
                onCancel={this.handleCancel}
                credentials={credentials}
                canRepurchase={booking.can_repurchase}
                appointmentSynced={booking.appointment_synced}
                onRebook={(e) => { e.preventDefault(); e.stopPropagation(); this.navToRebook(booking) }}
              />
            ))}
          {bookings.bookings && bookings.bookings.length === 0 && !showHistory && (
            <div>
              <p style={{ textAlign: "center" }}>
                You have no upcoming appointments.
              </p>
              <p style={{ textAlign: "center" }}>
                <SubmitInput value="Find an appointment" onClick={navToHome} />
              </p>
            </div>
          )}
          {bookings.bookings && bookings.bookings.length === 0 && showHistory && (
            <div>
              <p style={{ textAlign: "center" }}>You have no past appointments.</p>
            </div>
          )}
        </div>
      </WrapContainer>
    )
  }
}

MyBookingsContainer.propTypes = {
  fetchBookings: PropTypes.func.isRequired,
  navToBooking: PropTypes.func.isRequired,
  navToHome: PropTypes.func.isRequired,
  toggleHistory: PropTypes.func.isRequired,
  bookings: PropTypes.object.isRequired,
  showHistory: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired, // injected by "withTheme" from styled-components
  navToInventory: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    bookings: state.bookings,
    showHistory: state.bookings.showHistory,
    isMobile: state.browser.lessThan.mobile,
    credentials: state.credentials.credentials,
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  fetchBookings: () => dispatch(fetchBookings()),
  toggleHistory: () => dispatch(toggleHistory()),
  navToHome: () => dispatch(navToHome()),
  navToBooking: bookingId => dispatch(navToBooking(bookingId)),
  cancelBooking: bookingId => dispatch(cancelBooking(bookingId)),
  navToInventory: (id, date) => dispatch(navToInventory(currentParams, { id, date })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(MyBookingsContainer))
