export const initialState = {
  email: "",
  password: "",
  hasError: false,
  lastError: "",
  lastLocation: null,
  manualFacebookLogin: false
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case "CLICKED_FACEBOOK_LOGIN":
      return {
        ...state,
        manualFacebookLogin: true
      }
    case "STORE_LAST_LOCATION":
      return {
        ...state,
        lastLocation: action.payload.location
      }
    case "CLEAR_LAST_LOCATION":
      return {
        ...state,
        lastLocation: null
      }
    case "SUBMIT_LOGIN_FORM":
      return {
        ...state,
        hasError: false
      }
    case "LOGIN_FORM_EMAIL_UPDATE":
      return {
        ...state,
        hasError: false,
        email: action.payload.email
      }
    case "LOGIN_FORM_PASSWORD_UPDATE":
      return {
        ...state,
        hasError: false,
        password: action.payload.password
      }
    case "SUBMIT_LOGIN_FORM_SUCCESS":
      return {
        ...state,
        hasError: false
      }
    case "SUBMIT_LOGIN_FORM_ERROR": {
      return {
        ...state,
        hasError: true,
        lastError: action.payload.error_response.response.data.message
      }
    }
    default:
      return state
  }
}
