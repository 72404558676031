import moment from "moment-timezone"

const categoryTag = cat => ({
  id: `category--${cat.id}`,
  type: "category",
  originalId: cat.slug,
  name: cat.name
})

const campaignTag = campaign => ({
  id: `campaign--${campaign.id}`,
  type: "campaign",
  originalId: campaign.slug,
  name: campaign.headline
})

const companyTag = company => ({
  id: `company--${company && company.id}`,
  type: "company",
  originalId: company && company.slug,
  name: company && company.name
})

const regionTag = region => ({
  id: `region--${region.id}`,
  type: "region",
  originalId: region.slug,
  name: region.name
})

const dayTag = dayStr => {
  const day = moment(dayStr)
  return {
    id: `day-${day.format("YYYY-MM-DD")}`,
    type: "date",
    name: day.format("ddd D MMM"),
    originalId: day.format()
  }
}

export const extractSelectedCampaignTags = state => state.inventoryFilter.selectedCampaigns
  .map(cid => {
    const campaign = state.inventoryFilter.campaigns.find(
      campaign => campaign.slug === cid
    )
    if (!campaign) return null
    return campaignTag(campaign)
  })
  .filter(c => !!c)

export const extractSelectedCategoryTags = state => state.inventoryFilter.selectedCategories
  .map(cid => {
    const cat = state.inventoryFilter.catCategories.find(
      category => category.slug === cid
    )
    if (!cat) return null
    return categoryTag(cat)
  })
  .filter(c => !!c)

const extractSelectedCompanyTags = state => state.inventoryFilter.selectedCompanies.map(cid => companyTag(state.inventoryFilter.companies.find(c => c.slug === cid)))

const extractRegions = state => {
  const regions = []
  state.inventoryFilter.companies.forEach(c => {
    regions.push(c.district.region)
  })
  return regions
}

const extractSelectedRegionTags = state => state.inventoryFilter.selectedRegions
  .map(did => {
    const region = extractRegions(state).find(d => d.slug === did)
    // console.log('region', region)
    if (region) return regionTag(region)
    return null
  })
  .filter(x => !!x)

export const combinedWhatTags = state => [].concat(
  extractSelectedCategoryTags(state),
  extractSelectedCampaignTags(state)
)

export const combinedWhereTags = state => [].concat(
  extractSelectedCompanyTags(state),
  extractSelectedRegionTags(state)
)

export const extractSelectedDayTags = state => state.inventoryFilter.selectedDays.map(day => dayTag(day))

export const combineSelectedTags = state => [].concat(
  extractSelectedCategoryTags(state),
  extractSelectedCompanyTags(state),
  extractSelectedDayTags(state)
)

const extractCategoryTags = state => state.inventoryFilter.catCategories.map(categoryTag)
const extractCompanyTags = state => state.inventoryFilter.companies.map(companyTag)

export const extractTagSuggestions = state => [].concat(
  extractCategoryTags(state),
  extractCompanyTags(state)
)

export const matchSearch = (getState, query) => {
  return extractTagSuggestions(getState()).filter(t => t.name.toUpperCase().includes(query.toUpperCase()))
}
