import { createSelector } from "reselect"

export const getFavourites = state => (Object.prototype.hasOwnProperty.call(state, "favourites")
  ? state.favourites
  : state)

export const getFavouritesViewingId = createSelector(
  getFavourites,
  favourites => favourites.favouritesViewingServiceId
)

export const listUserFavourites = createSelector(
  getFavourites,
  favourites => favourites.favouritesList
)

export const getIsFetchingFavourites = createSelector(
  getFavourites,
  favourites => favourites.isFetchingFavourites
)

export const getListUserFavourites = (state, type = "0") => {
  const userFavourites = listUserFavourites(state)
  if (!userFavourites || !userFavourites.length) return []
  return userFavourites.filter(
    favourite => favourite.type_id === type.toString()
  )
}

export const getUserHasFavouritedService = state => {
  let isFavourited = false
  const serviceId = getFavouritesViewingId(state)
  const userFavourites = getListUserFavourites(state)
  if (!serviceId || !userFavourites.length) return isFavourited
  isFavourited = userFavourites
    .map(favouritesItem => favouritesItem.service.id)
    .includes(serviceId)
  return isFavourited
}
