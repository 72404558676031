import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import { goBack } from "connected-react-router"

import {
  fetchCompany,
  clearCompany,
  navToServices
} from "./actions"

import { navToHome } from "../NavBar/actions"

import MetaSetter from "../../components/MetaSetter"
import WrapContainer from "../WrapContainer"
import CompanyServices from "./CompanyServices"
import { Loading } from "../../components/nav/Loading"

import "react-image-gallery/styles/css/image-gallery.css" // TODO, no glbal css.
import { AccordianHeader, AccordianContent } from "../../components/accordians"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { BackLink } from "../../components/nav/Links"

import { RenderStarRatings, Review } from "../InventoryView/components"
import { CompanyMap, CompanyHours, Phone } from "../../components/datarenderers"
import Seperator from "../../components/Seperator"
import ParagraphText from "../../components/ParagraphText"
import { CompanyGallery } from "../../components/images/CompanyGallery"
import SocialMediaLinks from "./SocialMediaLinks"

class CompanyViewContainer extends Component {
  constructor(props) {
    super(props)
    if (props.isMobile) {
      this.state = { openTabs: [] }
    } else {
      this.state = { openTabs: ["findus", "hours", "loveus"] }
    }
    this.reviewRef = React.createRef()
  }

  componentDidMount() {
    const { clearCompany, fetchCompany } = this.props
    clearCompany()
    fetchCompany(this.companyId())
  }

  scrollToReviews = () => this.reviewRef.current
    && window.scrollTo({
      top: this.reviewRef.current.offsetTop - 100,
      behavior: "smooth"
    })

  clickStars = () => {
    if (!this.tabIsOpen("reviews")) this.clickTab("reviews")
    this.scrollToReviews()
  }

  tabIsOpen = tab => {
    const { openTabs } = this.state
    return openTabs.includes(tab)
  }

  clickTab = tab => {
    const { openTabs } = this.state
    if (this.tabIsOpen(tab)) {
      this.setState({ openTabs: openTabs.filter(t => t !== tab) })
    } else {
      this.setState({ openTabs: [...openTabs, tab] })
    }
  }

  companyId = () => {
    const { match } = this.props
    return match.params.id
  }

  goToServices = e => {
    e.preventDefault()
    const { navToServices } = this.props
    navToServices(this.companyId())
  }

  serviceList = () => {
    const { services } = this.props
    if (!services) return null
    const serviceList = [
      ...new Set(services.map(s => s.category.name))
    ].sort()
    return (
      <p>
        {serviceList.map(s => (
          <span key={`service-${s}`}>
            {s}
            <br />
          </span>
        ))}
      </p>
    )
  }

  renderHowToFindUs = () => {
    const { company } = this.props
    return (
      <React.Fragment>
        <AccordianHeader
          title="How To Find Us"
          open={this.tabIsOpen("findus")}
          onClick={() => this.clickTab("findus")}
        />
        <AccordianContent open={this.tabIsOpen("findus")}>
          <div style={{ height: 200, marginBottom: 20 }}>
            <CompanyMap company={company} height={200} />
          </div>
        </AccordianContent>
      </React.Fragment>
    )
  }

  renderCompany = () => {
    const {
      company, loadingCompany, navToListings, isMedium, isLarge, reviews, match,
    } = this.props

    /* Main container for the two content columns */
    const CompanyContainer = styled.div`
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: ${props => (props.isMedium ? "100%" : "calc(50% - 10px) calc(50% - 10px)")};
      max-width: 980px;
      margin: 0px auto;
      padding-top: ${props => (props.isMedium ? 0 : "20px")};
    `

    if (loadingCompany || !company) return null

    return (
      <React.Fragment>
        <CompanyContainer isMedium={isMedium}>
          <div style={{ position: "relative" }}>
            <BackLink
              href="/"
              onClick={e => {
                e.preventDefault()
                navToListings()
              }}
              style={{ top: !isLarge ? 10 : 0, left: !isLarge ? 10 : -60 }}
            />

            <CompanyGallery company={company} />

            {!isMedium && (
              <div
                style={{
                  backgroundColor: "#ececec",
                  marginTop: 20
                }}
              >
                <CompanyMap company={company} height={300} />
              </div>
            )}
          </div>

          <div style={{ padding: isMedium ? 10 : 0 }}>

            {company.rating_count > 0 && (
            <div
              style={{
                textAlign: "left",
              }}
            >
              <div style={{ display: "inline-block" }}>
                <RenderStarRatings
                  clickStars={this.clickStars}
                  rating={company.rating_customer}
                  ratingCount={company.rating_count}
                />
              </div>

            </div>
            )}


            <div style={{ margin: "15px 0" }}>
              <CompanyAddressBuilder
                company={company}
                addressOne
                addressTwo
                district
                region
                postCode
                inline
                showIcon
              />
              <div style={{ margin: "2px 0 20px 0" }}>
                <Phone number={company.phone_number} />
              </div>
              <SocialMediaLinks company={company} isMedium={isMedium} />
            </div>


            <Seperator />

            <AccordianHeader
              title="Current Opening Hours"
              open={this.tabIsOpen("hours")}
              onClick={() => this.clickTab("hours")}
            />
            <AccordianContent open={this.tabIsOpen("hours")}>
              <CompanyHours hours={company.hours} />
              <div style={{ marginBottom: 20 }} />
            </AccordianContent>

            {isMedium && (
              <React.Fragment>
                <Seperator />
                {this.renderHowToFindUs()}
              </React.Fragment>
            )}

            <Seperator />

            <AccordianHeader
              title="About This Salon"
              open={this.tabIsOpen("loveus")}
              onClick={() => this.clickTab("loveus")}
            />

            <AccordianContent open={this.tabIsOpen("loveus")}>
              <ParagraphText text={company.description} />
              <div style={{ marginBottom: 20 }} />
            </AccordianContent>

            <Seperator />

            {reviews.length > 0 && (
              <React.Fragment>
                <span ref={this.reviewRef} />
                <Seperator />
                <AccordianHeader
                  title="Reviews"
                  open={this.tabIsOpen("reviews")}
                  onClick={() => this.clickTab("reviews")}
                />
                <AccordianContent open={this.tabIsOpen("reviews")}>
                  <div style={{ maxHeight: 400, overflow: "scroll" }}>
                    {reviews.map(r => (
                      <Review
                        style={{ marginBottom: 14 }}
                        key={`review-${r.id}`}
                        review={r}
                      />
                    ))}
                  </div>
                </AccordianContent>
              </React.Fragment>
            )}

          </div>
        </CompanyContainer>

        <div
          style={{
            maxWidth: "980px",
            margin: "0px auto",
            padding: isMedium ? 10 : 0
          }}
        >
          <div style={{ marginTop: 40, paddingBottom: 40 }}>
            <h2 style={{ textAlign: "center", marginBottom: 20 }}>Our Services</h2>

            <Seperator />
            <CompanyServices companyId={company.id} match={match} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderLoading = () => {
    const { loadingCompany } = this.props
    return loadingCompany && <Loading />
  }

  render() {
    const { company, loadingCompany } = this.props
    return (
      <WrapContainer loginRequired={false} showNavBack navTitle="Company View">
        {!loadingCompany && company && (
          <MetaSetter
            canonicalPath={company && `/company/${company.slug}`}
            metaTitle={company && company.name}
            metaDescription={company && company.description}
          />
        )}
        {this.renderCompany()}
        {this.renderLoading()}
      </WrapContainer>
    )
  }
}

CompanyViewContainer.propTypes = {
  company: PropTypes.object,
  fetchCompany: PropTypes.func.isRequired,
  navToHome: PropTypes.func.isRequired,
  clearCompany: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
  navToServices: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  navToListings: PropTypes.func.isRequired,
}

CompanyViewContainer.defaultProps = {
  company: null
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    services: state.company.services,
    reviews: state.company.reviews,
    loadingCompany: state.company.loadingCompany,
    isMobile: state.browser.lessThan.mobile,
    isMedium: state.browser.lessThan.medium,
    isLarge: state.browser.greaterThan.large,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCompany: id => dispatch(fetchCompany(id)),
  clearCompany: () => dispatch(clearCompany()),
  navToHome: () => dispatch(navToHome()),
  navToServices: id => dispatch(navToServices(id)),
  navToListings: () => dispatch(goBack()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyViewContainer)
