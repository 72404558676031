import { apiGetArticles, apiGetArticle, handleXhrError } from "../../api/api"

// inspirations

const fetchArticlesFullfilled = response => ({
  type: "FETCH_ARTICLES_SUCCESS",
  payload: { response }
})

const fetchArticleFullfilled = response => ({
  type: "FETCH_ARTICLE_SUCCESS",
  payload: { response }
})

export const fetchArticlesEpic = action$ => action$.ofType("FETCH_ARTICLES").mergeMap(action => apiGetArticles(action.payload)
  .map(response => fetchArticlesFullfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_ARTICLES_FAILURE")))

export const fetchArticleEpic = action$ => action$.ofType("FETCH_ARTICLE").mergeMap(action => apiGetArticle(action.payload)
  .map(response => fetchArticleFullfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_ARTICLE_FAILURE")))
