import React from "react"

import styled from "styled-components"

export const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
`

export const ArticleDate = styled.span`
  display: block;
  color: #ccc;
  font-weight: 300;
`

export const ArticleListParent = styled.div`
  display: ${props => (props.isMobile ? "block" : "grid")};
  grid-gap: 50px;
  grid-template-columns: repeat(3, calc(33% - 33px));
  margin-bottom: 40px;
`

export const ArticleHeader = () => (
  <div style={{ marginTop: 40, marginBottom: 40 }}>
    <h1 style={{ margin: 0, textAlign: "center", fontStyle: "italic" }}>
      The Flossie Dossier
    </h1>
    <h2
      style={{
        margin: 0,
        padding: "0 20px",
        textAlign: "center",
        fontSize: 16,
        fontWeight: 400,
        color: "#333"
      }}
    >
      Your one stop inspiration destination for all things beauty, hair and wellbeing.
    </h2>
  </div>
)
