import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

import { PriceBlock } from "./PriceBlock"

const RangeBlock = styled.div`
  display: flex;
  align-items: top;
  >div {
    flex: initial;
    + div {
      padding-left: 5px;
    }
  }
`

export const PriceRange = ({
  lowestPrice, highestPrice, showCode, currency
}) => {
  if (!lowestPrice || lowestPrice === highestPrice) {
    return <RangeBlock><PriceBlock price={highestPrice} currency={currency} showCode={showCode} /></RangeBlock>
  }
  return (
    <RangeBlock>
      <PriceBlock price={lowestPrice} currency={currency} />
      <div>{" - "}</div>
      <PriceBlock price={highestPrice} currency={currency} showCode={showCode} />
    </RangeBlock>
  )
}

PriceRange.propTypes = {
  lowestPrice: PropTypes.string.isRequired,
  highestPrice: PropTypes.string.isRequired,
  showCode: PropTypes.bool,
  currency: PropTypes.object,
}
