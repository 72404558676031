import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { SubmitInput } from "../../components/forms/Inputs"
import WrapContainer from "../../containers/WrapContainer"
import rg4js from "raygun4js"

class FourOhFour extends Component {
  componentDidMount () {
    const { reason } = this.props
    //
    // if (reason) {
    //   rg4js('send', {
    //     error: new Error(`404: ${window.location.pathname + window.location.search}`),
    //     tags: [
    //       '404',
    //       'direct_404'
    //     ]
    //   })
    // }
  }

  render () {
    const { navToHome } = this.props
    return (
      <WrapContainer loginRequired={false}>
        <div
          style={{
            maxWidth: 800,
            paddingTop: 40,
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <h2 style={{ textAlign: "center" }}>Sorry, we couldn't find the page you are looking for</h2>
          <p style={{ textAlign: "center" }}>
            <SubmitInput
              onClick={navToHome}
              value="Click here to return to the homepage"
            />
          </p>
        </div>
      </WrapContainer>
    )
  }
}

FourOhFour.propTypes = {
  navToHome: PropTypes.func.isRequired,
  reason: PropTypes.string
}

const mapDispatchToProps = dispatch => ({
  navToHome: () => dispatch(push("/"))
})

export default connect(
  null,
  mapDispatchToProps
)(FourOhFour)
