import { showLoading, hideLoading } from "react-redux-loading-bar"

const showActions = [
  "SUBMIT_LOGIN_FORM",

  "SUBMIT_RESET_PASSWORD",

  "SUBMIT_UPDATE_PASSWORD",

  "FETCH_CART",

  "FETCH_ORDER",
  "FETCH_BOOKING",
  "FETCH_BOOKINGS",

  "FETCH_ARTICLES",
  "FETCH_ARTICLE",

  "SUBMIT_REGISTRATION",

  "SUBMIT_FRANCHISE_FORM",

  "FETCH_INSTANT_BOOK_SERVICES",

  "FETCH_COMPANY",

  "FETCH_INVENTORY",

  "FETCH_CATEGORIES",

  "FETCH_REGIONS",

  "FETCH_COMPANY_SERVICES",

  "FETCH_USER_FAVORITES",

  "FETCH_EMPLOYEE",

  "SELECT_PAYMENT_METHOD",

  "ADD_ITEM_TO_CART",
  "ADD_ITEM_TO_CART_AND_REDIRECT",

  "REMOVE_ITEM_FROM_CART",
  "REMOVE_NOTES_FROM_CART",

  "ENABLE_BALANCE_IN_CART",
  "DISABLE_BALANCE_IN_CART"
]

const hideActions = [
  "SUBMIT_LOGIN_FORM_SUCCESS",
  "SUBMIT_LOGIN_FORM_ERROR",

  "UPDATE_PASSWORD_SUCCESS",
  "UPDATE_PASSWORD_FAILURE",

  "RESET_PASSWORD_SUCCESS",
  "RESET_PASSWORD_FAILURE",

  "FETCH_ORDER_SUCCESS",
  "FETCH_ORDER_FAILURE",

  "FETCH_BOOKING_SUCCESS",
  "FETCH_BOOKING_FAILURE",

  "FETCH_BOOKINGS_SUCCESS",
  "FETCH_BOOKINGS_FAILURE",

  "FETCH_ARTICLE_SUCCESS",
  "FETCH_ARTICLE_FAILURE",

  "FETCH_ARTICLES_SUCCESS",
  "FETCH_ARTICLES_FAILURE",

  "REGISTRATION_SUCCESS",
  "REGISTRATION_FAILURE",

  "FETCH_INSTANT_BOOK_SERVICES_COMPLETE",
  "FETCH_INSTANT_BOOK_SERVICES_AUTO_UNFAIL",
  "FETCH_INSTANT_BOOK_SERVICES_ERROR",

  "FETCH_CART_SUCCESS",
  "FETCH_CART_FAILURE",

  "FETCH_COMPANY_SUCCESS",
  "FETCH_COMPANY_FAILURE",

  "FETCH_COMPANY_SERVICES_SUCCESS",
  "FETCH_COMPANY_SERVICES_FAILURE",

  "FETCH_INVENTORY_SUCCESS",
  "FETCH_INVENTORY_FAILURE",
  "FETCH_INVENTORY_ERROR",

  "FETCH_CATEGORIES_SUCCESS",
  "FETCH_CATEGORIES_FAILURE",

  "FETCH_REGIONS_SUCCESS",
  "FETCH_REGIONS_FAILURE",

  "FETCH_COMPANY_SERVICES_SUCCESS",
  "FETCH_COMPANY_SERVICES_FAILURE",

  "FETCH_EMPLOYEE_SUCCESS",
  "FETCH_EMPLOYEE_FAILURE",

  "SUBMIT_FRANCHISE_FORM_SUCCESS",
  "SUBMIT_FRANCHISE_FORM_FAILURE",

  "FETCH_USER_FAVORITES_SUCCESS",
  "FETCH_USER_FAVORITES_FAILURE",

  "FETCH_CART_SUCCESS",
  "FETCH_CART_FAILURE",

  "ADD_ITEM_TO_CART_SUCCESS",
  "ADD_ITEM_TO_CART_FAILURE",

  "REMOVE_ITEM_FROM_CART_SUCCESS",
  "REMOVE_ITEM_FROM_CART_FAILURE",

  "REMOVE_NOTES_FROM_CART_SUCCESS",
  "REMOVE_NOTES_FROM_CART_FAILURE",

  "DISABLE_BALANCE_IN_CART_SUCCESS",
  "DISABLE_BALANCE_IN_CART_FAILURE",

  "ENABLE_BALANCE_IN_CART_SUCCESS",
  "ENABLE_BALANCE_IN_CART_FAILURE",

  "SELECT_PAYMENT_METHOD_SUCCESS",
  "SELECT_PAYMENT_METHOD_FAILURE"
]

// TODO, use a list of action types
export default ({ dispatch }) => next => action => {
  if (action.type) {
    if (showActions.includes(action.type)) {
      dispatch(showLoading())
    } else if (hideActions.includes(action.type)) {
      dispatch(hideLoading())
    }
  }

  return next(action)
}
