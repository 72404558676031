import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { CompanyLogo } from "./CompanyLogo"
import { thumborize, thumborizeFitIn } from "./thumborize"

const ServiceGalleryImage = styled.div`
  width: 100%;
  position: relative;
  height: 300px;
  background-image: url("${props => props.galleryImageUrl}");
  background-size: cover;
  background-position: center;
  background-color: #fafafa;
`

const ServiceName = styled.div`
  position: absolute;
  background-image: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 25%, rgba(0,0,0,1) 90%);
  bottom: 0;
  color: #fff;
  width: ${props => (props.hasLogo ? "calc(100% - 100px)" : "calc(100% - 40px)")};
  height: auto;
  padding: ${props => (props.hasLogo ? "20px 80px 20px 20px" : "20px")};
  font-size: 22px;
`

export const getServiceImage = (images, description, thumborizeOptions = {}) => {
  let image = images.find(
    i => i.placement === "gallery" && i.position === "0"
  )
  if (!image) {
    image = description.images.find(
      i => i.placement === "gallery" && i.position === "0"
    )
  }

  if (!image) {
    return null
  }

  // Type is disabled/fitin/resize
  thumborizeOptions = {
    type: "disabled",
    width: 800,
    height: 800,

    ...thumborizeOptions
  }

  let url = encodeURIComponent(image.url);

  if (thumborizeOptions.type == "fitin") {
    url = thumborizeFitIn(url, thumborizeOptions.width, thumborizeOptions.height)
  } else if (thumborizeOptions.type == "resize") {
    url = thumborize(url, thumborizeOptions.width, thumborizeOptions.height)
  }

  return url
}

export const ServiceGallery = (
  { service, style = {} }
) => {
  const galleryImageUrl = getServiceImage(service.images, service.description, { type: "fitin" })

  if (!galleryImageUrl) {
    return null
  }

  const hasLogo = typeof service.company !== "undefined"

  return (
    <ServiceGalleryImage galleryImageUrl={galleryImageUrl} style={{ ...style }}>
      {service.company && (
      <CompanyLogo
        company={service.company}
        size={40}
        style={{
          display: "block",
          position: "absolute",
          bottom: 10,
          right: 20,
          zIndex: 2,
        }}
      />
      )}
      <ServiceName hasLogo={hasLogo}>{service.description.name}</ServiceName>
    </ServiceGalleryImage>
  )
}

ServiceGallery.propTypes = {
  service: PropTypes.object.isRequired,
  style: PropTypes.object
}
