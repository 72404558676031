import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Animation from "../../components/Animation"
import InstantBookContainer from "./InstantBookContainer"
import WrapContainer from "../../containers/WrapContainer"

const InstantBookLoadingContainer = ({ allLoaded, ...rest }) => {
  if (allLoaded) return <InstantBookContainer {...rest} />
  return (
    <WrapContainer
      loginRequired={false}
      showNavBack={false}
      navTitle="Instant Book"
    >
      <Animation />
    </WrapContainer>
  )
}

InstantBookLoadingContainer.propTypes = {
  allLoaded: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const {
    regions, catGroups, catCategories, companies
  } = state.inventoryFilter
  // TODO: this should be a selector
  const allLoaded = regions.length > 0
    && catGroups.length > 0
    && catCategories.length > 0
    // && companies.length > 0
  return {
    allLoaded
  }
}

export default connect(
  mapStateToProps,
  null
)(InstantBookLoadingContainer)
