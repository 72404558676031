import React from "react"
import PropTypes from "prop-types"

const { DEFAULT_TITLE, DEFAULT_TITLE_SUFFIX, DEFAULT_META_DESCRIPTION, ROOT_URL } = SITE_CONFIG

class MetaSetter extends React.Component {
  componentDidMount() {
    const {
      ogTitle,
      ogDescription,
      ogUrl,
      ogImage,

      productBrand,
      productAvailability,
      productCondition,
      productPriceAmount,
      productPriceCurrency,
      productRetailerItemId
    } = this.props

    this.setTitle(this.generateTitle())
    this.setMetaDescription(this.generateMetaDescription())
    this.addCanonicalTag()

    this.removeMetaFacebook("og:title")
    this.removeMetaFacebook("og:description")
    this.removeMetaFacebook("og:url")
    this.removeMetaFacebook("og:image")

    this.removeMetaFacebook("product:brand")
    this.removeMetaFacebook("product:availability")
    this.removeMetaFacebook("product:condition")
    this.removeMetaFacebook("product:price:amount")
    this.removeMetaFacebook("product:price:currency")
    this.removeMetaFacebook("product:retailer_item_id")

    if (ogTitle) {
      this.setMetaFacebook("og:title", ogTitle)
    }

    if (ogDescription) {
      this.setMetaFacebook("og:description", ogDescription)
    }

    if (ogUrl) {
      this.setMetaFacebook("og:url", ogUrl)
    }

    if (ogImage) {
      this.setMetaFacebook("og:image", ogImage)
    }

    if (productBrand) {
      this.setMetaFacebook("product:brand", productBrand)
    }

    if (productAvailability) {
      this.setMetaFacebook("product:availability", productAvailability)
    }

    if (productCondition) {
      this.setMetaFacebook("product:condition", productCondition)
    }

    if (productPriceAmount) {
      this.setMetaFacebook("product:price:amount", productPriceAmount)
    }

    if (productPriceCurrency) {
      this.setMetaFacebook("product:price:currency", productPriceCurrency)
    }

    if (productRetailerItemId) {
      this.setMetaFacebook("product:retailer_item_id", productRetailerItemId)
    }
  }

  componentDidUpdate() {
    this.setTitle(this.generateTitle())
    this.setMetaDescription(this.generateMetaDescription())
    this.addCanonicalTag()
  }

  componentWillUnmount() {
    this.setTitle(this.generateDefaultTitle())
    this.setMetaDescription(DEFAULT_META_DESCRIPTION)
    this.removeCanonicalTag()
  }

  addMetaFacebookTag(property, content) {
    const meta = document.createElement("meta")
    meta.setAttribute("property", property)
    meta.content = content

    document.getElementsByTagName("head")[0].appendChild(meta)
  }

  addCanonicalTag = () => {
    if (this.props.canonicalPath) {
      const canonicalTag =
        document.getElementById("canonical") || document.createElement("link")
      canonicalTag.id = "canonical"
      canonicalTag.rel = "canonical"
      canonicalTag.href = `${ROOT_URL}${this.props.canonicalPath}`
      document.head.appendChild(canonicalTag)
    } else {
      this.removeCanonicalTag()
    }
  }

  removeCanonicalTag = () => {
    const canonicalTag = document.getElementById("canonical")
    if (canonicalTag) canonicalTag.parentNode.removeChild(canonicalTag)
  }

  setTitle = (title) => {
    if (document.title !== title) {
      document.title = title
    }
  }

  setMetaDescription = description => {
    const metaDesc = document
      .getElementsByTagName("meta")
      .namedItem("description")
    if (metaDesc) metaDesc.setAttribute("content", description)
  }

  setMetaFacebook(property, content) {
    const metaFacebook = document.querySelector(`meta[property="${property}"]`)

    if (!metaFacebook) {
      this.addMetaFacebookTag(property, content)

      return
    }

    metaFacebook.setAttribute("content", content)
  }

  removeCanonicalTag = () => {
    const canonicalTag = document.getElementById("canonical")
    if (canonicalTag) canonicalTag.parentNode.removeChild(canonicalTag)
  }

  addCanonicalTag = () => {
    const { canonicalPath } = this.props
    if (canonicalPath) {
      const canonicalTag = document.getElementById("canonical") || document.createElement("link")
      canonicalTag.id = "canonical"
      canonicalTag.rel = "canonical"
      canonicalTag.href = `${ROOT_URL}${canonicalPath}`
      document.head.appendChild(canonicalTag)
    } else {
      this.removeCanonicalTag()
    }
  }

  addMetaFacebookTag = (property, content) => {
    const meta = document.createElement("meta")
    meta.setAttribute("property", property)
    meta.content = content

    document.getElementsByTagName("head")[0].appendChild(meta)
  }

  removeMetaFacebook = property => {
    const metaFacebook = document.querySelector(`meta[property="${property}"]`)
    if (metaFacebook) metaFacebook.parentNode.removeChild(metaFacebook)
  }

  generateMetaDescription = () => {
    const { metaDescription } = this.props
    if (!metaDescription) return DEFAULT_META_DESCRIPTION
    return metaDescription
  }

  generateTitle = () => {
    const { metaTitle } = this.props

    if (!metaTitle) {
      return this.generateDefaultTitle()
    }

    return this.addTitleSuffix(metaTitle)
  }

  generateDefaultTitle = () => {
    return this.addTitleSuffix(DEFAULT_TITLE)
  }

  addTitleSuffix = (title) => {
    return `${title} - ${DEFAULT_TITLE_SUFFIX}`
  }

  render() {
    return null
  }
}

/*

<meta property="og:title" content="Facebook T-Shirt">
<meta property="og:description" content="Unisex Facebook T-shirt, Small">
<meta property="og:url" content="https://example.org/facebook">
<meta property="og:image" content="https://example.org/facebook.jpg">

<meta property="product:brand" content="Facebook">
<meta property="product:availability" content="in stock">
<meta property="product:condition" content="new">
<meta property="product:price:amount" content="9.99">
<meta property="product:price:currency" content="USD">

<meta property="product:retailer_item_id" content="facebook_tshirt_001">
*/

MetaSetter.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  canonicalPath: PropTypes.string,

  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,

  productBrand: PropTypes.string,
  productAvailability: PropTypes.string,
  productCondition: PropTypes.string,
  productPriceAmount: PropTypes.string,
  productPriceCurrency: PropTypes.string,
  productRetailerItemId: PropTypes.string
}

MetaSetter.defaultProps = {
  canonicalPath: null,
  metaTitle: null,
  metaDescription: null,
  ogTitle: null,
  ogDescription: null,
  ogUrl: null,
  ogImage: null,

  productBrand: null,
  productAvailability: null,
  productCondition: null,
  productPriceAmount: null,
  productPriceCurrency: null,
  productRetailerItemId: null
}

export default MetaSetter
