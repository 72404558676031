import React from "react"
import PropTypes from "prop-types"

const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: 0,
    "padding-bottom": "56.25%"
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
}

const ArticleYouTubeEmbed = ({ videoId }) => {
  const basePath = "https://www.youtube.com/embed"
  if (!videoId || (!videoId && !videoId.length)) return null
  return (
    <div style={styles.container}>
      <iframe
        title={videoId}
        style={styles.video}
        src={`${basePath}/${videoId}?rel=0`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}

ArticleYouTubeEmbed.propTypes = {
  videoId: PropTypes.string
}

export default ArticleYouTubeEmbed
