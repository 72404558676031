import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import moment from "moment-timezone"
import {
  fetchOrder,
  navToOrder,
  navToBookings,
  addToCalendarTrack,
} from "./actions"
import { navToInventory } from "../InventoryView/actions"
import { cancelBooking } from "../MyBookings/actions"

import BookingItemCard from "../../components/cards/BookingItemCard"
import { AccordianHeader, AccordianContent } from "../../components/accordians"

import { SubmitInput } from "../../components/forms/Inputs"
import { Loading } from "../../components/nav/Loading"
import ParagraphText from "../../components/ParagraphText"
import WrapContainer from "../WrapContainer"
import ShadowBox from "../../components/ShadowBox"
import { CompanyMap } from "../../components/datarenderers/CompanyMap"
import { BackLink } from "../../components/nav/Links"
import Seperator from "../../components/Seperator"
import { AddToCalendarButton } from "../../components/datarenderers"


const InformationText = styled.p`
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
`

const Header = styled.h2`
  color: ${props => props.theme.colors.secondary};
  margin: 0px 46px 20px 46px;
  font-size: 19px;
  font-weight: bold;
  display: inline-block;
  max-width: ${props => (props.isMobile ? "260px" : "none")};
`

class OrderViewContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { accordionTab: "" }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { match, fetchProfile } = this.props

    /* If confirmation popup then reload profile to ensure user's FD balance etc updates */
    if (match.params.confirmation) {
      fetchProfile()
    }
  }

  setAccordionTab = tab => {
    const { accordionTab } = this.state
    if (tab === accordionTab) {
      this.setState({ accordionTab: "" })
    } else {
      this.setState({ accordionTab: tab })
    }
  }

  orderId = () => {
    const { match } = this.props
    return match.params.id
  }

  fetchOrder = () => {
    const { fetchOrder } = this.props
    return fetchOrder(this.orderId())
  }

  navToRebook = booking => {
    const { navToInventory } = this.props
    navToInventory(
      booking.service.slug,
      moment().format("YYYY-MM-DD"),
    )
  }

  headerImage = booking => {
    const img = booking.service.images.find(i => i.placement === "gallery")
    if (img) return img.url
    return null
  }

  closePoupUp = e => {
    e.preventDefault()
    const { navToOrder } = this.props
    navToOrder(this.orderId())
  }

  handleCancelBooking = (bookingId) => {
    const { cancelBooking, order } = this.props
    const shouldRedirect = order.bookings.length === 1
    // if we have only one booking and we are cancel it we tell the action to redirect after is done
    cancelBooking(bookingId, shouldRedirect)
  }

  renderLoading() {
    const { isFetchingOrder } = this.props
    if (isFetchingOrder) return <Loading />
    return null
  }

  renderConfirmationPopup = () => {
    const {
      order, isFetchingOrder, match, credentials,
    } = this.props

    if (!match.params.confirmation || isFetchingOrder) return null

    const multipleBookings = order.bookings.length > 1
    return (
      <ShadowBox
        containerStyle={{
          minWidth: 0,
          minHeight: 0,
          maxWidth: 400,
          marginLeft: 10,
          marginRight: 10,
          textAlign: "left"
        }}
        isFullscreen={false}
        header={multipleBookings ? "Your appointments are confirmed!" : "Your appointment is confirmed!"}
        closeBox={this.closePoupUp}
      >
        <InformationText>You will receive an email confirmation shortly</InformationText>

        <div style={{
          textAlign: "center", margin: "15px auto", marginBottom: 0, display: "block"
        }}
        >
          <AddToCalendarButton orderId={order.id} credentials={credentials} />
        </div>
      </ShadowBox>
    )
  }

  renderBooking(booking) {
    const {
      isMobile,
      isMedium,
      isFetchingOrder,
      order,
      navToReview,
      resetUserReview,
      setRating,
      credentials
    } = this.props

    if (isFetchingOrder) return null
    if (!booking) return null

    const {
      id,
      service,
      company,
      appointment_time,
      duration,
      price,
      products
    } = booking

    const {
      currency,
      description
    } = service

    const {
      terms_and_conditions,
      preparation,
      about
    } = description

    const { accordionTab } = this.state

    return (
      <div
        key={id}
        style={{
          margin: "0 auto",
          marginBottom: 60,
          maxWidth: 960,
          padding: "0 15px",
          display: isMedium ? "block" : "flex",
          textAlign: isMedium ? "center" : "left"
        }}
      >
        <div style={{ width: isMedium ? "100%" : "50%", margin: "0 auto" }}>
          <BookingItemCard
            booking={booking}
            isMobile={isMobile}
            price={price}
            currency={currency}
            startTime={appointment_time}
            duration={duration}
            extras={products}
            onCancel={this.handleCancelBooking}
            credentials={credentials}
            onRebook={(e) => { e.preventDefault(); e.stopPropagation(); this.navToRebook(booking) }}
          />

          <div style={{ flex: 1, padding: 0 }}>
            <Seperator />
            <AccordianHeader
              title="About This Service"
              open={accordionTab === "about"}
              onClick={() => this.setAccordionTab("about")}
            />
            <AccordianContent open={accordionTab === "about"}>
              <ParagraphText text={about} />
            </AccordianContent>

            <Seperator />
            {preparation && (
              <div>
                <AccordianHeader
                  title="How to Prepare"
                  open={accordionTab === "prepare"}
                  onClick={() => this.setAccordionTab("prepare")}
                />
                <AccordianContent open={accordionTab === "prepare"}>
                  <ParagraphText text={preparation} />
                </AccordianContent>

                <Seperator />
              </div>
            )}

            {terms_and_conditions && (
              <div>
                <AccordianHeader
                  title="Booking Terms"
                  open={accordionTab === "terms"}
                  onClick={() => this.setAccordionTab("terms")}
                />
                <AccordianContent open={accordionTab === "terms"}>
                  <ParagraphText text={terms_and_conditions} />
                </AccordianContent>
                <Seperator />
              </div>
            )}

            {booking.notes && (
              <div>
                <AccordianHeader
                  title="Booking Notes"
                  open={accordionTab === "bookingNotes"}
                  onClick={() => this.setAccordionTab("bookingNotes")}
                />
                <AccordianContent open={accordionTab === "bookingNotes"}>
                  <ParagraphText text={booking.notes} />
                </AccordianContent>
                <Seperator />
              </div>
            )}
          </div>

        </div>

        <div style={{ width: isMedium ? "100%" : "50%", margin: "0 auto", padding: isMedium ? "15px 0" : "15px 0 0 30px" }}>
          <CompanyMap company={company} />
        </div>

      </div>
    )
  }

  renderBookings() {
    const {
      order, isFetchingOrder, isMobile, isLarge, navToBookings
    } = this.props

    if (isFetchingOrder || !order.bookings || order.bookings.length === 0) return null
    const multipleBookings = order.bookings.length > 1
    const appointmentTime = order.bookings
      .map(booking => booking.appointment_time)
      .reduce((latestTime, booking) => {
        if (!latestTime) {
          return booking.appointment_time
        }

        if (moment(booking.appointment_time).isAfter(latestTime)) {
          return booking.appointment_time
        }

        return latestTime
      })

    return (
      <React.Fragment>
        <div
          style={{
            margin: "20px auto",
            maxWidth: 960,
            textAlign: "center",
            position: "relative"
          }}
        >
          <BackLink
            href="/"
            onClick={e => {
              e.preventDefault()
              navToBookings()
            }}
            style={{ top: 0, left: !isLarge ? 20 : -60 }}
          />
          <Header isMobile={isMobile}>
            {moment(appointmentTime).isBefore()
              ? multipleBookings ? "We hope you enjoyed these appointments!" : "We hope you enjoyed this appointment!"
              : multipleBookings ? "Your appointments are confirmed!" : "Your appointment is confirmed!"}
          </Header>
          <InformationText style={{ maxWidth: "360px" }}>
            {moment(appointmentTime).isBefore() ? "" : `We've emailed you confirming your appointment${multipleBookings ? "s" : ""}`}
          </InformationText>
        </div>
        <div>{order.bookings.map(booking => this.renderBooking(booking))}</div>
      </React.Fragment>
    )
  }

  render() {
    const { order, isFetchingOrder, navToBookings } = this.props

    return (
      <WrapContainer loginRequired onAuth={this.fetchOrder}>
        {this.renderLoading()}
        {order && this.renderBookings()}
        {order && this.renderConfirmationPopup()}
        {!isFetchingOrder && !order && (
          <div style={{ display: "block", margin: "0 auto", textAlign: "center" }}>
            <div style={{ margin: "30px 0 15px 0" }}>Order not found</div>
            <SubmitInput
              onClick={() => { navToBookings() }}
              value="Go to Bookings"
            />
          </div>
        )}
      </WrapContainer>
    )
  }
}

OrderViewContainer.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  isFetchingOrder: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  order: PropTypes.object,
  navToOrder: PropTypes.func.isRequired,
  navToInventory: PropTypes.func.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  navToReview: PropTypes.func,
  resetUserReview: PropTypes.func,
  setRating: PropTypes.func,
  navToBookings: PropTypes.func,
  cancelBooking: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    order: state.bookings.order,
    isFetchingOrder: state.bookings.isFetchingOrder,
    credentials: state.credentials.credentials,
    isMobile: state.browser.lessThan.mobile,
    isMedium: state.browser.lessThan.medium,
    isLarge: state.browser.greaterThan.large,
    lastError: state.bookings.lastError,
    hasError: state.bookings.hasError,
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  fetchOrder: id => dispatch(fetchOrder(id)),
  navToOrder: id => dispatch(navToOrder(id)),
  navToBookings: () => dispatch(navToBookings()),
  addToCalendarTrack: () => dispatch(addToCalendarTrack()),
  navToInventory: (id, date) => dispatch(navToInventory(currentParams, { id, date })),
  fetchProfile: () => dispatch({ type: "FETCH_PROFILE" }),
  cancelBooking: (bookingId, shouldRedirect) => dispatch(cancelBooking(bookingId, shouldRedirect)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderViewContainer)
