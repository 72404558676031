import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

// TODO: make this a stateless components.
class Animation extends React.Component {
  constructor(props) {
    super(props)

    this.animationRef = React.createRef()
  }

  componentDidMount() {
    const { theme } = this.props
    this.animationInterval = insertAnimation(this.animationRef.current.id, theme.colors.primary)
  }

  componentWillUnmount() {
    clearInterval(this.animationInterval)
  }

  render() {
    const { style, height } = this.props

    const animationId = `animation-${Math.floor(Math.random() * 10000000)}`

    return (
      <div
        id={animationId}
        style={{ width: "100%", height: height || "500px", ...style }}
        ref={this.animationRef}
      />
    )
  }
}

Animation.propTypes = {
  style: PropTypes.object,
  height: PropTypes.number,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Animation)
