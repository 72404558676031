import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const PrivacyLink = styled.a`
  font-weight: 700;
  color: #333;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const AvedaNewsletter = ({ url, langCode }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        margin: "20px 0px 10px 0px"
      }}
      >
        <div><input style={{ cursor: "pointer" }} id="newsletter" name="newsletter" type="checkbox" /></div>
        <div style={{ fontSize: 16, marginLeft: 8 }}><label htmlFor="newsletter" style={{ cursor: "pointer" }}>Sign me up to hear from Aveda!</label></div>
      </div>
      <div style={{ fontSize: 12, fontWeight: 200, textAlign: "center" }}>
        To learn more, view our
        {" "}
        <PrivacyLink rel="noopener noreferrer" target="_blank" href={`${url}/customer-service-privacy`}>Privacy Policy</PrivacyLink>
        .
        {langCode === "us" && (
          <React.Fragment>
            <br />
            If I am a California resident, I agree to the
            {" "}
            <PrivacyLink rel="noopener noreferrer" target="_blank" href={`${url}/customer-service-privacy#financialincentive`}>Notice of FINANCIAL INCENTIVE</PrivacyLink>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
AvedaNewsletter.defaultProps = {

}

AvedaNewsletter.propTypes = {
  langCode: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default AvedaNewsletter
