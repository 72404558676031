import moment from "moment-timezone"

import { PAGE_SIZE } from "../InstantBook/constants"

const { DEFAULT_TIMEZONE } = SITE_CONFIG

export const getCompanyFilterFromState = (inventoryFilterState) => {
  const filter = {}

  if (inventoryFilterState.companySearch) {
    filter.post_code = inventoryFilterState.companySearch
    filter.company_name = inventoryFilterState.companySearch
    filter.district_name = inventoryFilterState.companySearch
    filter.region_name = inventoryFilterState.companySearch
    filter.country_name = inventoryFilterState.companySearch
  }

  return filter
}

export const getInventoryFilterFromState = (inventoryFilterState, clear = false) => {
  // eslint-disable-line

  const page = clear ? 0 : inventoryFilterState.currentPage

  const limit = PAGE_SIZE
  const offset = limit * page

  const filter = {}
  // filter.region_id = inventoryFilterState.regionId
  filter.offset = offset
  filter.limit = limit

  // sort order
  filter.order = inventoryFilterState.sortBy
  if (inventoryFilterState.latitude) {
    filter.latitude = inventoryFilterState.latitude
    filter.longitude = inventoryFilterState.longitude
  }

  // filter by days
  if (inventoryFilterState.selectedDays.length > 0) {
    filter.times = inventoryFilterState.selectedDays.map(d => ({ day: moment.tz(d, "UTC").format("YYYY-MM-DD") }))
  }

  // bbox
  if (inventoryFilterState.bbox) filter.bbox = `${inventoryFilterState.bbox.north},${inventoryFilterState.bbox.east},${inventoryFilterState.bbox.south},${inventoryFilterState.bbox.west}`

  // vip only
  if (inventoryFilterState.vipOnly) filter.vip = "1"

  // sale only
  if (inventoryFilterState.saleOnly) filter.sale = "1"

  // bookmarks only
  if (inventoryFilterState.bookmarksOnly) filter.bookmarks = "1"

  // categories
  if (inventoryFilterState.selectedCategories.length > 0) filter.category_slug = inventoryFilterState.selectedCategories.join()

  // campaigns
  if (inventoryFilterState.selectedCampaigns.length > 0) filter.campaign_slug = inventoryFilterState.selectedCampaigns.join()

  // companies
  if (inventoryFilterState.selectedCompanies.length > 0) filter.company_slug = inventoryFilterState.selectedCompanies.join()

  // regions
  if (inventoryFilterState.selectedRegions.length > 0) filter.region_slug = inventoryFilterState.selectedRegions.join()

  return filter
}
