import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import PropTypes from "prop-types"

import { TextInput, SubmitInput } from "../../../components/forms/Inputs"

import { addPromotionCodeToCart } from "../../Cart/actions"

import { enterPromotionCode, savePromotionCode } from "../actions"

const InfoMessage = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.accent};
`

class PromotionCodeSelector extends Component {
  enterPromotionCode = e => {
    const { enterPromotionCode } = this.props

    e.preventDefault()
    enterPromotionCode(e.target.value)
  }

  savePromotionCode = e => {
    const { promotionCodeEntered, savePromotionCode } = this.props

    e.preventDefault()

    savePromotionCode(promotionCodeEntered)
  }

  addPromotionCodeToCart = id => {
    const { addPromotionCodeToCart, closePromotionCodeSelector } = this.props

    addPromotionCodeToCart(id)
    closePromotionCodeSelector()
  }

  savePromotionCodeButton = () => (
    <SubmitInput onClick={this.savePromotionCode} value="Apply Code" />
  )

  renderPromotionCodeStatus = () => {
    const {
      promotionCodeEntered,
      promotionCodeErrorMessage,
      promotionCodeValidating,
      promotionCodeValid
    } = this.props

    if (promotionCodeEntered === "") return null

    return (
      <div>
        {promotionCodeValidating && (
          <div>
            <InfoMessage>... Checking</InfoMessage>
          </div>
        )}

        {!promotionCodeValidating && (
          <div>
            {promotionCodeValid && this.savePromotionCodeButton()}
            {!promotionCodeValid && <ErrorMessage>Invalid code</ErrorMessage>}
            {promotionCodeErrorMessage && (
              <ErrorMessage>{promotionCodeErrorMessage}</ErrorMessage>
            )}
          </div>
        )}
      </div>
    )
  }

  renderPromotionCode = (code, currency) => (
    <div
      key={code.id}
      style={{
        display: "flex",
        flex: 1,
        padding: 15,
        alignItems: "center",
        borderBottom: "1px solid #ccc"
      }}
    >
      <div
        style={{
          flex: 2, textAlign: "left", fontSize: 20, fontWeight: 500
        }}
      >
        {code.voucher_code}
      </div>
      <div
        style={{
          flex: 1, textAlign: "right", fontSize: 20, fontWeight: 500
        }}
      >
        {code.applicable && (
          <SubmitInput
            onClick={() => this.addPromotionCodeToCart(code.id)}
            value={`TAKE ${currency.symbol}${code.discount_dollars} OFF`}
          />
        )}

        {!code.applicable && <div>Not applicable</div>}
      </div>
    </div>
  )

  render() {
    const {
      promotionCodes,
      promotionCodeEntered,
      currency
    } = this.props

    return (
      <div>
        {promotionCodes.length > 0 && (
          <h2 style={{ textAlign: "center" }}>
            Select Promo Code
          </h2>
        )}
        <div>
          {promotionCodes.map(code => {
            return this.renderPromotionCode(code, currency)
          })}
        </div>
        <p style={{ textAlign: "center", marginTop: 30 }}>
          Enter New Promo Code
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <TextInput
              id="new_promotion_code"
              onChange={this.enterPromotionCode}
              value={promotionCodeEntered}
            />
          </div>
        </div>
        <div style={{ minHeight: 40, padding: 10, textAlign: "center" }}>
          {this.renderPromotionCodeStatus()}
        </div>
      </div>
    )
  }
}

PromotionCodeSelector.propTypes = {
  addPromotionCodeToCart: PropTypes.func.isRequired,
  enterPromotionCode: PropTypes.func,
  promotionCodeEntered: PropTypes.string,
  savePromotionCode: PropTypes.func,
  closePromotionCodeSelector: PropTypes.func,
  promotionCodeErrorMessage: PropTypes.string,
  promotionCodeValidating: PropTypes.bool,
  promotionCodeValid: PropTypes.bool,
  promotionCodes: PropTypes.array,
  currency: PropTypes.object
}

function mapStateToProps(state) {
  return {
    promotionCodeEntered: state.purchase.promotionCodeEntered,
    promotionCodeValidating: state.purchase.promotionCodeValidating,
    promotionCodeValid: state.purchase.promotionCodeValid,
    promotionCodeErrorMessage: state.purchase.promotionCodeErrorMessage,

    promotionCodes: state.cart.cart.codes,
    activePromotionCodes: state.cart.cart.items.filter(i => i.type === "code"),
    currency: state.cart.cart.currency
  }
}

const mapDispatchToProps = dispatch => ({
  savePromotionCode: code => dispatch(savePromotionCode(code)),
  enterPromotionCode: code => dispatch(enterPromotionCode(code)),
  addPromotionCodeToCart: code => dispatch(addPromotionCodeToCart(code))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCodeSelector)
