const infoPayload = item => (item && {
  inventoryId: item.id,
  companyId: item.company.id,
  categoryId: item.category.id
})
  || {}

const INTERCOM_ACTIONS = {
  "@@router/LOCATION_CHANGE": () => {
    window.Intercom("update")
  },
  FETCH_INVENTORY_SUCCESS: action => {
    const item = action.payload.response
    window.Intercom("trackEvent", "web-view-item", infoPayload(item))
  },
  TRACK_PURCHASE_START: (action, getState) => {
    const item = getState().inventoryView.inventoryItem
    window.Intercom("trackEvent", "web-purchase-start", infoPayload(item))
  },
  MAKE_PURCHASE: (action, getState) => {
    const item = getState().inventoryView.inventoryItem
    window.Intercom("trackEvent", "web-purchase-press-buy", infoPayload(item))
  },
  MAKE_PURCHASE_FAILURE: (action, getState) => {
    const item = getState().inventoryView.inventoryItem
    window.Intercom("trackEvent", "web-purchase-failure", infoPayload(item))
  },
  MAKE_PURCHASE_SUCCESS: (action, getState) => {
    const item = getState().inventoryView.inventoryItem
    window.Intercom("trackEvent", "web-purchase-success", infoPayload(item))
  }
}

export const intercomEpic = (action$, { getState }) => action$.ofType(...Object.keys(INTERCOM_ACTIONS)).mergeMap(action => {
  if (typeof window.Intercom !== "undefined") {
    // console.log(action.type, INTERCOM_ACTIONS[action.type])
    INTERCOM_ACTIONS[action.type](action, getState)
  }
  return []
})
