import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import CompanySelector from "../../components/CompanySelector"

class CompanyFilterContainer extends Component {
  clickCompany = slug => {
    const { closeAction, selectCompany } = this.props
    closeAction()
    selectCompany(slug)
  }

  clickRegion = slug => {
    const { closeAction, selectRegion } = this.props
    closeAction()
    selectRegion(slug)
  }

  render() {
    const { companies, topCompanies } = this.props

    return (
      <CompanySelector
        companies={companies}
        topCompanies={topCompanies}
        showRegionSelector
        onSelectRegion={slug => {
          this.clickRegion(slug)
        }}
        onSelectCompany={slug => {
          this.clickCompany(slug)
        }}
        scrollContainerStyle={{
        }}
      />
    )
  }
}

CompanyFilterContainer.defaultProps = {
  topCompanies: []
}

CompanyFilterContainer.propTypes = {
  selectCompany: PropTypes.func.isRequired,
  selectRegion: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  closeAction: PropTypes.func.isRequired,
  topCompanies: PropTypes.array
}

function mapStateToProps(state) {
  return {
    companies: state.inventoryFilter.companies,
    selectedCompanies: state.inventoryFilter.selectedCompanies,
    selectedRegions: state.inventoryFilter.selectedRegions,
    isMobile: state.browser.lessThan.mobile,
    topCompanies:
      state.profile.profile
      && state.profile.profile.top
      && state.profile.profile.top.companies
  }
}

export default connect(
  mapStateToProps
)(CompanyFilterContainer)
