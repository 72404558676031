import PrismicContent from "../../../dist/all_prismic_data.json"

import { LOAD_LEGALS_CONTENT } from "./actions"

import { PRISMIC_TYPES } from "../../constants"

// REDUCER
export const initialState = {
  partnerTerms: {},
  privacyTerms: {},
  customerTerms: {},
}

const getLegalsContent = (type) => {
  if (PrismicContent && PrismicContent[type]) {
    const { data } = PrismicContent[type]
    return data
  }
  return null
}

export default function legalsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LEGALS_CONTENT:
      return {
        ...state,
        partnerTerms: getLegalsContent(PRISMIC_TYPES.PARTNER_TERMS),
        privacyTerms: getLegalsContent(PRISMIC_TYPES.PRIVACY_POLICY),
        customerTerms: getLegalsContent(PRISMIC_TYPES.CUSTOMER_TERMS),
      }
    default:
      return state
  }
}
