import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { FlossieRadioButton } from "../containers/Purchase/helpers"
import { setMapZoom } from "../containers/InventoryView/actions"
import { fetchCompanies, companySearchUpdate } from "../containers/InventoryFilter/actions"
import buttonize from "./buttonize"
import CompanyAddressBuilder from "./address/CompanyAddressBuilder"
import { TextInput } from "./forms/Inputs"
import { RoundImage } from "./images/RoundImage"
import { thumborize } from "./images/thumborize"
import Map from "./map"
import { PriceRange } from "./datarenderers"
import Button from "./button"
import PlusIcon from "./icons/plus.svg"
import ArrowDownIcon from "./icons/chevron-down.svg"
import { CheckBoxIcon, RadioButtonIcon, SpinnerIcon } from "./icons"

const iconStyle = css`
  display: block;
  height: 20px;
  width: 20px;
  margin-top: 11px;
  fill: ${({ theme }) => theme.colors.primary};
`

const ThemedPlusIcon = styled(PlusIcon)`
  ${iconStyle}
`
const ThemedArrowDownIcon = styled(ArrowDownIcon)`
  ${iconStyle}
`

const CompanyDiv = styled.div`
  min-height: 42px;
  margin: 0px;

  font-size: 14px;

  font-weight: 300;
  color: #000;
  display: flex;
  padding: 12px 14px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightHover};
  }
`

const CompanyName = styled.p`
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 0;
`

const CountryDiv = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: 1px solid #ccc;
  height: 42px;
  padding: 0px 14px;

  &:last-child {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }
`

const RegionDiv = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: 1px dashed #ccc;
  height: 42px;
  padding: 0px 14px;
`

const CompanyRow = styled.div`
  display: ${props => (props.isMobile ? "block" : "flex")};
  flex: 1;
  margin-left: 10px;
  justify-content: space-between;
`

const toggleArray = (selected, id) => {
  if (selected.includes(id)) {
    return selected.filter(c => c !== id)
  }
  return [...selected, id]
}

const CompaniesMap = props => {
  const { companies, selectedServiceId, onClick } = props
  const points = companies.map(company => ({
    latitude: parseFloat(company.latitude),
    longitude: parseFloat(company.longitude)
  }))

  const markers = companies.map(c => ({
    id: c.id,
    onClick: () => onClick(c.slug),
    latitude: c.latitude,
    longitude: c.longitude,
    highlighted: !selectedServiceId || selectedServiceId === c.service.id,
    name: c.name
  }))

  return (
    <Map
      {...props}
      centerOnUserPosition
      points={points}
      markers={markers}
    />
  )
}

CompaniesMap.propTypes = {
  companies: PropTypes.array,
  selectedServiceId: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func
}

let companySearchTimer

class CompanySelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: "list",
      mapNeedsPan: true,
      companySearch: props.companySearch,
      selectedRegionNames: [],
      selectedCountryNames: []
    }
  }

  componentDidMount() {
    const uniqueCountries = this.getUniqueCountries()

    // Expand by default country dropdown when only 1 country is available
    const { selectedCountryNames } = this.state
    if (uniqueCountries.length === 1 && selectedCountryNames.length === 0) {
      this.toggleCountry(uniqueCountries[0].name)
    }

    const uniqueRegions = this.getUniqueRegions()

    // Expand by default region dropdown when only 1 region is available
    const { selectedRegionNames } = this.state
    if (uniqueRegions.length === 1 && selectedRegionNames.length === 0) {
      this.toggleRegion(uniqueRegions[0].name)
    }
  }

  companyIsSelected = id => {
    const { selectedCompanies } = this.props
    return selectedCompanies.includes(id)
  }

  clickCompany = slug => {
    const { closeAction, onSelectCompany } = this.props
    if (closeAction) {
      closeAction()
    }

    onSelectCompany(slug)
  }

  clickRegion = slug => {
    const { closeAction, onSelectRegion } = this.props
    if (closeAction) {
      closeAction()
    }

    onSelectRegion(slug)
  }

  renderCompany = c => {
    const { allowMultipleSelection, currency, isMobile } = this.props

    return (
      <CompanyDiv
        {...buttonize(() => this.clickCompany(c.slug))}
        selected={this.companyIsSelected(c.slug)}
        key={`company-${c.slug}`}
      >

        {c.images && c.images.find(i => i.placement === "logo") && (
          <RoundImage
            src={thumborize(
              c.images.find(i => i.placement === "logo").url,
              40 * 4,
              40 * 4
            )}
            size={40}
          />
        )}

        <CompanyRow isMobile={isMobile}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <CompanyName>{c.name}</CompanyName>
            <div>
              <CompanyAddressBuilder
                company={c}
                addressOne
                addressTwo
                district
                region
                postCode
                inline
              />
            </div>
          </div>

          {/* Optional price range used on campaign page company selector */}
          {/* TODO: Fix this - broken since API rebuild
          {c.service && c.service.pricing && (
          <div style={{ display: "flex", margin: isMobile ? "5px 0 0 0" : "0 5px" }}>
            <PriceRange
              lowestPrice={c.service.pricing.lowest.price}
              highestPrice={c.service.pricing.highest.price}
              currency={currency}
              showCode={false}
            />
          </div>
        )} */}

        </CompanyRow>

        {!allowMultipleSelection && <FlossieRadioButton selected={this.companyIsSelected(c.slug)} onClick={() => this.clickCompany(c.slug)} />}
        {allowMultipleSelection && (
          <RadioButtonIcon selected={this.companyIsSelected(c.slug)} />
        )}
      </CompanyDiv>
    )
  }

  regionIsSelected = regionSlug => {
    const { selectedRegions } = this.props
    return selectedRegions.includes(regionSlug)
  }

  renderAllRegion = region => (
    <CompanyDiv
      {...buttonize(() => this.clickRegion(region.slug))}
      selected={this.regionIsSelected(region.slug)}
      key={`region-${region.id}`}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: 10 }}>
        <CompanyName>
          All of
          {" "}
          {region.name}
        </CompanyName>
      </div>
      <CheckBoxIcon checked={this.regionIsSelected(region.slug)} />
    </CompanyDiv>
  )

  toggleCountry = country => {
    const { selectedCountryNames } = this.state
    const selectedCountries = selectedCountryNames

    this.setState({ selectedCountryNames: toggleArray(selectedCountries, country) })
  }

  toggleRegion = region => {
    const { selectedRegionNames } = this.state
    const selectedRegions = selectedRegionNames

    this.setState({ selectedRegionNames: toggleArray(selectedRegions, region) })
  }

  sortByName = (a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  }

  getUniqueRegions = () => {
    const { companies } = this.props
    const regions = companies.map(c => c.district.region)
    const uniqueRegions = []

    const orderedRegionNames = [...new Set(regions.map(r => r.name))].sort()
    orderedRegionNames.forEach(regionName => {
      const region = regions.find(reg => reg.name === regionName)
      if (region) uniqueRegions.push(region)
    })

    return uniqueRegions
  }

  getUniqueCountries = () => {
    const { regions } = this.props

    const countries = regions.map(r => r.country).sort(this.sortByName)

    const uniqueCountries = []

    countries.forEach(c => {
      if (!uniqueCountries.find(u => u.id === c.id)) {
        uniqueCountries.push(c)
      }
    })

    return uniqueCountries
  }

  renderCountries = () => {
    const { regions, companies } = this.props

    const uniqueCountries = this.getUniqueCountries()

    const { selectedCountryNames } = this.state

    /* Only show regions that have at least one company in them */
    const availableRegions = regions.filter(r => companies.find(c => c.district.region.id === r.id))

    /* Only show countries that have at least one region in them */
    const availableCountries = uniqueCountries.filter(c => availableRegions.find(r => r.country.id === c.id))

    return availableCountries.map(c => (
      <React.Fragment key={c.name}>
        <CountryDiv {...buttonize(() => this.toggleCountry(c.name))}>
          <div style={{ flex: 1, height: "100%", lineHeight: "42px" }}>
            {c.name}
          </div>
          {selectedCountryNames.includes(c.name) ? (
            <ThemedArrowDownIcon />
          ) : (
            <ThemedPlusIcon />
          )}
        </CountryDiv>
        {selectedCountryNames.includes(c.name)
          && this.renderRegions(availableRegions.filter(r => r.country.name === c.name))}
      </React.Fragment>
    ))
  }

  renderRegions = regions => {
    const { selectedRegionNames } = this.state
    const { showRegionSelector, companies } = this.props
    return regions.map(r => (
      <React.Fragment key={r.name}>
        <RegionDiv {...buttonize(() => this.toggleRegion(r.name))}>
          <div style={{
            flex: 1, height: "100%", lineHeight: "42px", fontStyle: "italic", textTransform: "none"
          }}
          >
            {r.name}
          </div>
          {selectedRegionNames.includes(r.name) ? (
            <ThemedArrowDownIcon />
          ) : (
            <ThemedPlusIcon />
          )}
        </RegionDiv>
        {selectedRegionNames.includes(r.name)
          && showRegionSelector
          && this.renderAllRegion(r)}
        {selectedRegionNames.includes(r.name)
          && companies
            .filter(c => c.district.region.name === r.name)
            .sort(this.sortByName)
            .map(this.renderCompany)}
      </React.Fragment>
    ))
  }

  renderTopCompanies = () => {
    const nameStyle = { flex: 1, height: "100%", lineHeight: "42px" }
    const {
      topCompanies,
      showTopCompanies,
      toggleTopCompanies
    } = this.props

    return (
      <React.Fragment key="your-companies">
        <CountryDiv {...buttonize(toggleTopCompanies)}>
          <div style={nameStyle}>Your Top Salons</div>
          {showTopCompanies ? (
            <ThemedArrowDownIcon />
          ) : (
            <ThemedPlusIcon />
          )}
        </CountryDiv>
        {showTopCompanies
          && topCompanies
            .sort(this.sortByName)
            .map(this.renderCompany)}
      </React.Fragment>
    )
  }

  updateSearch = e => {
    const { fetchCompanies, companySearchUpdate } = this.props

    this.setState({ companySearch: e.target.value })

    if (companySearchTimer) {
      clearTimeout(companySearchTimer)
    }

    companySearchTimer = setTimeout(() => {
      const { companySearch } = this.state
      this.setState({ mapNeedsPan: true })
      companySearchUpdate(companySearch)
      fetchCompanies()
    }, 250)

    e.preventDefault()
  }

/*
  matchCompany = company => {
    const { q } = this.state
    return (
      company.district.region.name
        .toLowerCase()
        .includes(q.toLowerCase())
      || company.district.name
        .toLowerCase()
        .includes(q.toLowerCase())
      || company.name.toLowerCase().includes(q.toLowerCase())
      || company.address_one.toLowerCase().includes(q.toLowerCase())
      || company.post_code
        .toLowerCase()
        .replace(/\s/g, "")
        .includes(q.toLowerCase().replace(/\s/g, ""))
    )
  }
*/
  getResults = () => {
    const { companies } = this.props

    return companies
      .sort(this.sortByName)
  }

  renderResults = () => {
    const results = this.getResults()

    return (
      <div style={{ paddingBottom: 20 }}>
        {results.length > 0 && results.map(this.renderCompany)}
        {results.length === 0 && (
          <div style={{ textAlign: "center" }}>
            Sorry, your search returned no results
          </div>
        )}
      </div>
    )
  }

  renderList = () => {
    const { topCompanies, companySearch, isSearchingCompanies } = this.props

    if (isSearchingCompanies) {
      return (
        <div style={{ textAlign: "center" }}>Searching</div>
      )
    }

    return (
      <div>
        {topCompanies.length > 0
          && companySearch === ""
          && this.renderTopCompanies()}
        {companySearch === "" && this.renderCountries()}
        {companySearch !== "" && this.renderResults()}
      </div>
    )
  }

  getPoints = () => {
    const { mapNeedsPan } = this.state
    const { companies } = this.props
    if (mapNeedsPan && companies.length > 0) {
      this.setState({ mapNeedsPan: false })
      return companies.map(c => ({
        latitude: c.latitude,
        longitude: c.longitude
      }))
    }
    return null
  }

  mapHeight = () => {
    const { isMobile } = this.props
    return isMobile ? "calc(100vh - 280px)" : "400px"
  }

  setMapNeedsMap = value => this.setState({ mapNeedsPan: value })

  renderMap = () => {
    const { mapZoom, setMapZoom } = this.props
    const { mapNeedsPan } = this.state
    return this.getResults().length <= 0
      ? (
        <div style={{ textAlign: "center" }}>
          Sorry, your search returned no results
        </div>
      )
      : (
        <CompaniesMap
          companies={this.getResults()}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
          getPoints={() => this.getResults()}
          onClick={this.clickCompany}
          mapNeedsPan={mapNeedsPan}
          setMapNeedsMap={this.setMapNeedsMap}
          height={this.mapHeight()}
        />
      )
  }

  /*
  // TODO: Can we remove this? If So, we can remove also the component TabButton as its only being used here.
  renderSearch = () => {
    return ReactDOM.createPortal(
      <div style={{ paddingBottom: 24}}>
        <div style={{display: 'flex', marginBottom: 10 }}><TextInput style={{flex: 1}} placeholder='Enter salon name, location or postcode...' value={this.state.q} onChange={this.updateSearch}/></div>
        <div style={{display: 'grid', gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)', gridTemplateRows: '100%', gridColumnGap: 20 }}>
          <TabButton href='#' active={this.state.tab === 'list'} onClick={(e) => { e.preventDefault(); this.setState({tab: 'list'}) }}>List</TabButton>
          <TabButton href='#' active={this.state.tab === 'map'} onClick={(e) => { e.preventDefault(); this.setState({tab: 'map', mapNeedsPan: true}) }}>Map</TabButton>
        </div>
      </div>, this.searchPortalContainer)
  } */

  clickClosestCompany = position => {
    /* Looted from https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
       This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km) */
    function calcDistance(lat1, lon1, lat2, lon2) {
      const R = 6371 // km earth
      const dLat = toRad(lat2 - lat1)
      const dLon = toRad(lon2 - lon1)
      const lat1Rad = toRad(lat1)
      const lat2Rad = toRad(lat2)

      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.sin(dLon / 2)
          * Math.sin(dLon / 2)
          * Math.cos(lat1Rad)
          * Math.cos(lat2Rad)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c
      return d
    }

    /* Converts numeric degrees to radians */
    function toRad(Value) {
      return (Value * Math.PI) / 180
    }

    const companies = this.getResults()

    companies.sort((a, b) => {
      const distanceA = calcDistance(
        position.coords.latitude,
        position.coords.longitude,
        a.latitude,
        a.longitude
      )
      const distanceB = calcDistance(
        position.coords.latitude,
        position.coords.longitude,
        b.latitude,
        b.longitude
      )

      return distanceA - distanceB
    })

    this.clickCompany(companies[0].slug)
  }

  render() {
    const {
      isMobile, scrollContainerStyle, hideTextSearch, isSearchingCompanies
    } = this.props

    const { companySearch } = this.state

    const headerPortalHeight = "123px"
    const { tab } = this.state

    return (
      <div>
        <div style={{ paddingBottom: 30 }}>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "calc(50% - 10px) calc(50% - 10px)",
              gridTemplateRows: "100%",
              gridColumnGap: 20
            }}
          >
            <Button
              variant="secondary"
              filled={tab === "list"}
              onClick={e => {
                e.preventDefault()
                this.setState({ tab: "list" })
              }}
            >
              List
            </Button>
            <Button
              variant="secondary"
              filled={tab === "map"}
              onClick={e => {
                e.preventDefault()
                this.setState({ tab: "map", mapNeedsPan: true })
              }}
            >
              Map
            </Button>
          </div>
          <div
            style={{
              display: "flex", alignItems: "center", marginTop: 16, position: "relative"
            }}
          >
            {!hideTextSearch && (
              <div style={{ position: "relative", display: "flex", width: "100%" }}>
                <TextInput
                  style={{ flex: 1 }}
                  placeholder="Enter salon name, location or postcode..."
                  value={companySearch}
                  onChange={this.updateSearch}
                />
                {isSearchingCompanies && (
                  <SpinnerIcon style={{ position: "absolute", right: 10, top: 12 }} />
                )}
              </div>
            )}
            {/* Commented out until can work with category selection, or made to return a list of results not just auto-select one

            <div style={{ marginLeft: 8 }}>
              <Geolocation
                lazy
                onSuccess={this.clickClosestCompany}
                render={({getCurrentPosition, fetchingPosition, position, error}) => {
                  return (
                    <React.Fragment>
                      <span onClick={getCurrentPosition} style={{ cursor: 'pointer', marginLeft: 10 }}>Closest to me <img src='/view-arrow.png' alt='Closest to me' style={{ height: 20, width: 'auto', verticalAlign: 'top' }} /></span>
                    </React.Fragment>
                  )
                }
              } />
            </div> */}
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            marginLeft: 0,
            marginRight: 0,
            maxHeight: isMobile
              ? `calc(100vh - 150px - ${headerPortalHeight}`
              : `calc(100vh - 270px - ${headerPortalHeight}`,
            ...scrollContainerStyle
          }}
        >
          <div style={{ display: "flex", minHeight: 400 }}>
            <div style={{ flex: 1 }}>
              {tab === "list" && this.renderList()}
              {tab === "map" && this.renderMap()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CompanySelector.defaultProps = {
  showRegionSelector: true,
  scrollContainerStyle: {},
  topCompanies: [],
  allowMultipleSelection: true
}

CompanySelector.propTypes = {
  onSelectCompany: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  companySearchUpdate: PropTypes.func.isRequired,
  isSearchingCompanies: PropTypes.bool.isRequired,
  selectedCompanies: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  closeAction: PropTypes.func,
  mapZoom: PropTypes.number.isRequired,
  setMapZoom: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func,
  showRegionSelector: PropTypes.bool,
  showTopCompanies: PropTypes.bool.isRequired,
  companySearch: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  scrollContainerStyle: PropTypes.object,
  topCompanies: PropTypes.array,
  hideTextSearch: PropTypes.bool,
  allowMultipleSelection: PropTypes.bool,
  selectedRegions: PropTypes.array,
  toggleTopCompanies: PropTypes.func,
  currency: PropTypes.object, // used for showing service price range
}

function mapStateToProps(state) {
  return {
    selectedCompanies: state.inventoryFilter.selectedCompanies,
    selectedRegions: state.inventoryFilter.selectedRegions,
    selectedCountries: state.inventoryFilter.selectedCountries,
    regions: state.inventoryFilter.regions,
    mapZoom: state.inventoryFilter.mapZoom,
    isMobile: state.browser.lessThan.mobile,
    showTopCompanies: state.inventoryFilter.showTopCompanies,
    companySearch: state.inventoryFilter.companySearch,
    isSearchingCompanies: state.inventoryFilter.isSearchingCompanies
  }
}

const mapDispatchToProps = dispatch => ({
  setMapZoom: zoom => dispatch(setMapZoom(zoom)),
  fetchCompanies: () => dispatch(fetchCompanies()),
  companySearchUpdate: search => dispatch(companySearchUpdate(search)),
  toggleTopCompanies: () => dispatch({ type: "TOGGLE_TOP_COMPANIES" })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySelector)
