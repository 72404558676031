import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { BackLink } from "../../components/nav/Links"

const Container = styled.div`
  position: relative;
`

const Header = ({ children, navToPrevious }) => (
  <Container>
    <BackLink
      style={{ position: "absolute", top: "calc(50% - 15px)", left: 0 }}
      href="/"
      onClick={e => {
        e.preventDefault()
        navToPrevious()
      }}
    />
    <div style={{ padding: "0px 40px", display: "flex", alignItems: "center" }}>
      {children}
    </div>
  </Container>
)

Header.propTypes = {
  children: PropTypes.element.isRequired,
  navToPrevious: PropTypes.func.isRequired,
}

export default Header
