import { createSelector } from "reselect"

export const getInventoryFilter = state => (Object.prototype.hasOwnProperty.call(state, "inventoryFilter")
  ? state.inventoryFilter
  : state)

export const getCatGroups = createSelector(
  getInventoryFilter,
  inventoryFilter => inventoryFilter.catGroups
)

export const getFlattenedCategoryGroups = createSelector(
  getCatGroups,
  catGroups => {
    const flattenedGroups = []

    for (let i = 0; i < catGroups.length; i += 1) {
      if (catGroups[i].groups && catGroups[i].groups.length > 0) {
        flattenedGroups.push(...catGroups[i].groups)
      } else {
        flattenedGroups.push(catGroups[i])
      }
    }

    return flattenedGroups
  }
)
