import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import buttonize from "../../components/buttonize"
import { TextInput } from "../../components/forms/Inputs"
import { RoundImage } from "../../components/images/RoundImage"
import { thumborize } from "../../components/images/thumborize"

import { toggleCategoryGroup } from "./actions"
import {
  CheckBoxIcon, ChevronDownIcon, PlusIcon, TagIcon
} from "../../components/icons"
import Button from "../../components/button"

/* Container for individual category row. eg Manicure, Pedicure */
const CategoryLine = styled.div`
  min-height: 42px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightHover};
  }
`

const CategoryGroup = styled.div`
  color: ${props => (props.selected ? "#f60" : "#000")};
`

/* Container for individual group row. eg Nails, Hair */
const CategoryHeading = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: ${props => (props.isSubCategory ? "1px dashed #ccc" : "1px solid #ccc")};
  height: 42px;
  padding: 0px 14px;
`

const TagWrapper = styled.div`
  flex: 1;
  height: 100%;
  line-height: 42px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 15px;
  }
`

class CategoryContainer extends Component {
  searchBoxContainer = null

  constructor(props) {
    super(props)
    this.state = { q: "" }
  }

  componentDidMount() {
    const { catGroups, toggleCategoryGroup, selectedCategoryGroups } = this.props
    if (catGroups.length === 1) {
      // If there is only 1 category we open the dropdown by default
      const catId = catGroups[0].id

      if (!selectedCategoryGroups.includes(catId)) {
        // only open it on first render
        // this is for allowing user to close it
        toggleCategoryGroup(catId)
      }
    }
  }

  renderCampaignLine = campaign => {
    return (
      <CategoryLine
        key={campaign.id}
        selected={this.isSelectedCampaign(campaign)}
        {...buttonize(() => this.clickCampaign(campaign.slug))}
      >
        <div style={{ flex: 1, textAlign: "left" }}>
          <div style={{ fontSize: "14px", color: "#000", fontWeight: 500 }}>
            {campaign.headline}
          </div>
          <div style={{
            fontSize: 12, color: "#000", fontWeight: 400, paddingRight: 12, maxWidth: 518, marginTop: 4
          }}
          >
            {campaign.description}
          </div>
        </div>
        <CheckBoxIcon checked={this.isSelectedCampaign(campaign)} />
      </CategoryLine>
    )
  }

  renderCategoryLine = cat => {
    const groupNames = this.findGroupNames(cat.id)
    const { q } = this.state

    const images = cat.descriptions && cat.descriptions[0] && cat.descriptions[0].images

    return (
      <CategoryLine
        key={cat.id}
        selected={this.isSelectedCategory(cat)}
        {...buttonize(() => this.clickCategory(cat.slug))}
      >
        {images && images.find(i => i.placement === "gallery") && (
          <RoundImage
            src={thumborize(
              images.find(i => i.placement === "gallery").url,
              40 * 4,
              40 * 4
            )}
            size={40}
            style={{ marginRight: 10 }}
          />
        )}

        <div style={{ flex: 1, textAlign: "left" }}>
          <div style={{ fontSize: "14px", color: "#000", fontWeight: 500 }}>
            {cat.name}
          </div>
          {q !== "" && <div>{groupNames}</div>}
        </div>

        <CheckBoxIcon checked={this.isSelectedCategory(cat)} />
      </CategoryLine>
    )
  }

  renderSubCategoryGroup = categoryGroup => (
    <div key={`subcategory${categoryGroup.id}`}>
      {categoryGroup.groups
        && categoryGroup.groups.sort(this.sortCatGroups).map(g => this.renderCategoryGroup(g, true))}
      {this.getCategories(categoryGroup).map(
        this.renderCategoryLine
      )}
    </div>
  )

  renderCategoryGroup = (categoryGroup, isSubCategory) => {
    const accordianStyle = { display: "block", height: 20, marginTop: 11 }
    const nameStyle = {
      flex: 1,
      height: "100%",
      lineHeight: "42px",
      textTransform: isSubCategory ? "none" : "uppercase",
      fontStyle: isSubCategory ? "italic" : "normal"
    }
    const { toggleCategoryGroup, selectedCategoryGroups } = this.props
    return (
      <CategoryGroup
        key={`catgroup-${categoryGroup.id}`}
        selected={this.isSelectedCategoryGroup(categoryGroup)}
      >
        <CategoryHeading
          isSubCategory={isSubCategory}
          {...buttonize(() => toggleCategoryGroup(categoryGroup.id))}
        >
          <div style={nameStyle}>{categoryGroup.name}</div>
          {selectedCategoryGroups.includes(categoryGroup.id) ? (
            <ChevronDownIcon style={accordianStyle} />
          ) : (
            <PlusIcon style={accordianStyle} />
          )}
        </CategoryHeading>
        {selectedCategoryGroups.includes(categoryGroup.id)
          && this.renderSubCategoryGroup(categoryGroup)}
      </CategoryGroup>
    )
  }

  getCategories = categoryGroup => {
    const cats = categoryGroup.categories
    const { catCategories } = this.props
    if (!cats) return []
    return catCategories.filter(c => cats.indexOf(c.id) !== -1)
  }

  isSelectedCampaign = campaign => {
    const { selectedCampaigns } = this.props
    return selectedCampaigns.includes(campaign.slug)
  }

  isSelectedCategory = category => {
    const { selectedCategories } = this.props
    return selectedCategories.includes(category.slug)
  }

  isSelectedCategoryGroup = group => {
    const { selectedCategoryGroups } = this.props
    return selectedCategoryGroups.includes(group.id)
  }

  clickCategory = cid => {
    const { toggleCategory } = this.props
    toggleCategory(cid)
  }

  clickCampaign = cid => {
    const { closeAction, toggleCampaign } = this.props
    closeAction()
    toggleCampaign(cid)
  }

  renderCampaigns = () => {
    const { campaigns } = this.props
    if (!campaigns || campaigns.length === 0) return null

    const accordianStyle = { display: "block", height: 20, marginTop: 11 }

    const { toggleCampaigns, showCampaigns } = this.props
    return (
      <CategoryGroup key="campaigns-top" selected>
        <CategoryHeading {...buttonize(toggleCampaigns)}>
          <TagWrapper>
            Special Offers
            <TagIcon />
          </TagWrapper>
          {showCampaigns ? (
            <ChevronDownIcon style={accordianStyle} />
          ) : (
            <PlusIcon style={accordianStyle} />
          )}
        </CategoryHeading>
        {showCampaigns && campaigns.map(this.renderCampaignLine)}
      </CategoryGroup>
    )
  }

  renderTopCategories = () => {
    const { topCategories, catCategories } = this.props
    if (!topCategories) return null
    const topCats = topCategories
      .map(c => catCategories.find(cat => c.id === cat.id))
      .filter(c => typeof c !== "undefined")

    const accordianStyle = { display: "block", height: 20, marginTop: 11 }
    const nameStyle = { flex: 1, height: "100%", lineHeight: "42px" }

    const { toggleTopCategories, showTopCategories } = this.props
    return (
      <CategoryGroup key="catgroup-top" selected>
        <CategoryHeading {...buttonize(toggleTopCategories)}>
          <div style={nameStyle}>Your Top Categories</div>
          {showTopCategories ? (
            <ChevronDownIcon style={accordianStyle} />
          ) : (
            <PlusIcon style={accordianStyle} />
          )}
        </CategoryHeading>
        {showTopCategories && topCats.map(this.renderCategoryLine)}
      </CategoryGroup>
    )
  }

  clickClose = e => {
    e.preventDefault()
    const { closeAction } = this.props
    closeAction()
  }

  sortCatGroups = (a, b) => {
    /* Sort by position first */
    if (parseInt(a.position, 10) > parseInt(b.position, 10)) {
      return -1
    }
    if (parseInt(a.position, 10) < parseInt(b.position, 10)) {
      return 1
    }

    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  }

  renderList = () => {
    const { catGroups, campaigns } = this.props

    return (
      <React.Fragment>
        {campaigns.length > 0 && this.renderCampaigns()}
        {this.renderTopCategories()}
        {catGroups
          .sort(this.sortCatGroups)
          .map(g => this.renderCategoryGroup(g, false))}
      </React.Fragment>
    )
  }

  findGroupNames = catID => {
    let groupNames = null
    const { catGroups } = this.props
    catGroups.forEach(g => {
      if (g.groups) {
        g.groups.forEach(g2 => {
          if (g2.categories.includes(catID)) {
            groupNames = `${g.name} - ${g2.name}`
          }
        })
      } else if (g.categories.includes(catID)) groupNames = g.name
    })
    return groupNames
  }

  renderSearchCat = cat => {
    return this.renderCategoryLine(cat)
  }

  renderResults = () => {
    const { catCategories } = this.props
    const { q } = this.state
    const cats = catCategories.filter(cat => q
      .toLowerCase()
      .split(" ")
      .every(
        w => cat.name.toLowerCase().includes(w)
            || cat.keywords.toLowerCase().includes(w)
      ))

    return (
      <div>
        <div style={{ margin: 5, textAlign: "center", paddingTop: 10 }}>
          Search Results
        </div>
        {cats.map(this.renderSearchCat)}
      </div>
    )
  }

  updateSearch = e => {
    e.preventDefault()
    this.setState({ q: e.target.value })
  }

  render() {
    const { isMobile } = this.props
    const { q } = this.state

    const headerPortalHeight = "70px"
    const bottomConfirm = "20px"

    return (
      <div>
        <div style={{ height: headerPortalHeight }}>
          <div style={{ display: "flex" }}>
            <TextInput
              style={{ flex: 1 }}
              placeholder="Enter service name or category..."
              value={q}
              onChange={this.updateSearch}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            marginLeft: isMobile ? "-10px" : 0,
            marginRight: isMobile ? "-20px" : "-10px",
            maxHeight: isMobile
              ? `calc(100vh - 150px - ${headerPortalHeight} - ${bottomConfirm}`
              : `calc(100vh - 250px - ${headerPortalHeight} - ${bottomConfirm}`
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <div>
                {q === "" && this.renderList()}
                {q !== "" && this.renderResults()}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
          <Button onClick={this.clickClose}>Close</Button>
        </div>
      </div>
    )
  }
}

CategoryContainer.propTypes = {
  catGroups: PropTypes.array.isRequired,
  catCategories: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  toggleCategoryGroup: PropTypes.func.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  toggleCampaign: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  selectedCampaigns: PropTypes.array.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  selectedCategoryGroups: PropTypes.array.isRequired,
  topCategories: PropTypes.array,
  showTopCategories: PropTypes.bool.isRequired,
  showCampaigns: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleTopCategories: PropTypes.func.isRequired,
  toggleCampaigns: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    selectedCategoryGroups: state.inventoryFilter.selectedCategoryGroups,
    selectedCategories: state.inventoryFilter.selectedCategories,
    selectedCampaigns: state.inventoryFilter.selectedCampaigns,
    catGroups: state.inventoryFilter.catGroups,
    showTopCategories: state.inventoryFilter.showTopCategories,
    showCampaigns: state.inventoryFilter.showCampaigns,
    catCategories: state.inventoryFilter.catCategories,
    campaigns: state.inventoryFilter.campaigns,
    topCategories:
      state.profile.profile
      && state.profile.profile.top
      && state.profile.profile.top.categories,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  toggleCategoryGroup: c => dispatch(toggleCategoryGroup(c)),
  toggleTopCategories: () => dispatch({ type: "TOGGLE_TOP_CATEGORIES" }),
  toggleCampaigns: () => dispatch({ type: "TOGGLE_CAMPAIGNS" })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryContainer)
