import { push } from "connected-react-router"
import BrowserDetector from 'browser-dtector'

import {
  login,
  apiGetUserProfile,
  facebookLogin,
  handleXhrError,
  apiPostDevice
} from "../../api/api"

import { fetchCart } from "../Cart/actions"
import { logOut, preLogOut } from "../Profile/actions"

import { randomString32 } from "../InventoryView/lib"

export const submitLoginAndReloadCartEpic = action$ => action$.ofType("SUBMIT_LOGIN_FORM_SUCCESS").mapTo(fetchCart())

export const submitLoginFulfulled = (credentials, noRedirect) => ({
  type: "SUBMIT_LOGIN_FORM_SUCCESS",
  payload: { credentials, noRedirect }
})
const autoLoginFulfilled = credentials => ({
  type: "AUTO_LOGIN_SUCCESS",
  payload: { credentials }
})
const fetchProfileFulfilled = (response, completeLogin) => ({
  type: "FETCH_PROFILE_SUCCESS",
  payload: { response, completeLogin }
})

const loadDeviceToken = () => {
  const { localStorage } = window
  let token = localStorage.getItem("FLOSSIE_USER_DEVICE_TOKEN")
  if (!token) {
    token = randomString32()
    localStorage.setItem("FLOSSIE_USER_DEVICE_TOKEN", token)
  }
  return token
}

export const storeCredentials = (token) => {
  const { localStorage } = window
  localStorage.setItem("FLOSSIE_USER_API_TOKEN", token)
  return { token }
}

const loadCredentials = () => {
  const { localStorage } = window
  const token = localStorage.getItem("FLOSSIE_USER_API_TOKEN")
  if (!token) return {}
  return { token }
}

const clearCredentials = () => {
  const { localStorage } = window
  localStorage.removeItem("FLOSSIE_USER_API_TOKEN")
}

const storeCurrentLocation = (path) => {
  const { localStorage } = window
  localStorage.setItem("REDIRECT_TO", path)
}

export const submitLoginEpic = (action$, { dispatch, getState }) => action$.ofType("SUBMIT_LOGIN_FORM").mergeMap(action => login(action.payload.email, action.payload.password, action.payload.googleRecaptchaToken)
  .map(response => {
    if (response.success) {
      return submitLoginFulfulled(
        storeCredentials(response.token),
        action.payload.noRedirect
      )
    }
    if (response.password_reset_required) {
      // save the current location to redirect after pass reset
      const { location } = getState().router
      const redirectTo = location && location.pathname !== "/login" ? location.pathname : "/"
      storeCurrentLocation(redirectTo)
      // redirect to reset password page
      return dispatch(push(`/reset-password/required/${response.unique_key}`))
    }
    return handleXhrError("Unknown error on login", "SUBMIT_LOGIN_FORM_ERROR")
  })
  .catch(error => handleXhrError(error, "SUBMIT_LOGIN_FORM_ERROR")))

export const submitLoginFacebook = action$ => action$.ofType("SUBMIT_FACEBOOK_TOKEN").mergeMap(action => facebookLogin(action.payload.token, action.payload.googleRecaptchaToken, action.payload.newsletter)
  .map(response => submitLoginFulfulled(
    storeCredentials(response.token),
    action.payload.noRedirect
  ))
  .catch(error => handleXhrError(error, "SUBMIT_FACEBOOK_TOKEN_ERROR")))

export const automaticLoginEpic = action$ => action$.ofType("TRY_STARTUP_AUTH").mergeMap(() => {
  const credentials = loadCredentials()
  if (!credentials.token) {
    return [{ type: "NO_STORED_CREDENTIALS" }]
  }
  return apiGetUserProfile(credentials)
    .map(() => autoLoginFulfilled(credentials))
    .catch(error => handleXhrError(error, "FETCH_PROFILE_FAILURE"))
})

export const fetchProfileEpic = (action$) => action$
  .ofType(
    "AUTO_LOGIN_SUCCESS",
    "SUBMIT_LOGIN_FORM_SUCCESS",
    "REGISTRATION_SUCCESS"
  )
  .mergeMap(action => {
    const { credentials } = action.payload
    return apiGetUserProfile(credentials)
      .map(response => fetchProfileFulfilled(response, true))
      .catch(error => handleXhrError(error, "FETCH_PROFILE_FAILURE"))
  })

export const fetchProfileAfterPurchaseEpic = (action$, { getState }) => action$.ofType("FETCH_PROFILE").mergeMap(() => {
  return apiGetUserProfile(getState().credentials.credentials)
    .map(response => fetchProfileFulfilled(response, false))
    .catch(error => handleXhrError(error, "FETCH_PROFILE_FAILURE"))
})

export const logOutEpic = (action$) => action$.ofType("PRE_LOG_OUT").mergeMap(() => {
  clearCredentials()
  return [push("/")]
})

// All FETCH_PROFILE_FAILURE actions ultimately end up being routed through here leading to automatic logout
export const handleAuthFailureEpic = (action$) => action$.mergeMap(action => {
  // TODO: initiate some kind of action to inform the user that they have been logged out
  if (action.payload && action.payload.error_response && action.payload.error_response.response && action.payload.error_response.response.status === 401) {
    return [preLogOut(), logOut()]
  }

  return []
})

export const postDeviceEpic = (action$, { getState }) => action$.ofType("FETCH_PROFILE_SUCCESS")
  .filter(action => action.payload.completeLogin)
  .mergeMap(() => {
    const { credentials } = getState().credentials
    const browser = new BrowserDetector(window.navigator.userAgent)
    const b = browser.parseUserAgent()

    return apiPostDevice(credentials, {
      device_manufacturer: b.name ? b.name : "Unknown",
      device_model: b.version ? b.version : "Unknown",
      device_os: b.platform ? b.platform : "Unknown",
      device_os_version: b.isMobile ? "mobile" : "desktop",
      device_token: loadDeviceToken(),
      device_app_version: "Web",
      device_app_type: 0
    })
      .map(() => ({ type: "POST_DEVICE_SUCCESS" }))
      .catch(error => handleXhrError(error, "POST_DEVICE_ERROR"))
  })

export const rayGunIdentifyEpic = action$ => action$.ofType("FETCH_PROFILE_SUCCESS")
  .filter(action => action.payload.completeLogin)
  .mergeMap(action => {
    if (typeof rg4js !== "undefined") {
      rg4js("setUser", { identifier: action.payload.response.id })
    }
    return []
  })
