import {
  PAYMENT_AUTHORIZE_FAILURE,
  PAYMENT_AUTHORIZE_REDIRECT, PAYMENT_AUTHORIZE_SUCCESS,
  PAYMENT_CANCEL,
  PAYMENT_CONFIRM, PAYMENT_CONFIRM_FAILURE,
  PAYMENT_CONFIRM_SUCCESS,
  PAYMENT_CONFIRM_UNAVAILABLE, PAYMENT_TOKEN,
  PURCHASE_PHONE_UPDATE
} from "./constants"

// Define error types to allow more fine grained control over user flow
export const PURCHASE_ERROR_DEFAULT = 0
export const PURCHASE_ERROR_UNAVAILABLE = 1

const initialPurchaseErrorState = {
  purchaseError: false,
  purchaseErrorType: PURCHASE_ERROR_DEFAULT,
  purchaseErrorMessage: null,
  purchaseErrorData: {}
}

export const initialState = {
  inProgress: false,
  donePurchase: false,

  ...initialPurchaseErrorState,

  confirmedIsPurchased: null,
  orderId: null,

  phoneNumber: "",
  salonNote: "",
  useFlossieDollars: true,
  unavailable: false,
  loggedOutShowSignup: true,
  selectedPurchaseBtn: "Buy Now",

  paymentReference: null,
  paymentConfirmation: {},
  paymentConfirmationSteps: {},
  paymentAuthorizeSteps: {},
  paymentToken: null,
  paymentSecret: null,

  selectedPaymentMethod: null,
  selectedPaymentMethodOption: null,

  cardNumber: "",
  cardExpiry: "",
  cardCvc: "",
  cardName: "",

  promotionCodeEntered: "",
  promotionCodeValidating: false,
  promotionCodeValid: false,
  promotionCodeErrorMessage: null
}

function handleWsMessage(state, action) {
  /* Filter out invalid events */
  const events = [
    "client-begin-confirm",
    "client-progress-confirm",
    "client-end-confirm",
    "client-begin-purchase",
    "client-progress-purchase",
    "client-end-purchase"
  ]

  if (
    events.includes(action.payload.name)
    && action.payload.data.reference === state.paymentReference
  ) {
    switch (action.payload.name) {
      case "client-begin-confirm":
        return {
          ...state,
          paymentConfirmation: {
            ...state.paymentConfirmation,
            [action.payload.data.appointment_availability_id]: {
              complete: false,
              available: false
            }
          },
          paymentConfirmationSteps: {
            ...state.paymentConfirmationSteps,
            [action.payload.data.appointment_availability_id]: {}
          }
        }
      case "client-progress-confirm":
        return {
          ...state,
          paymentConfirmationSteps: {
            ...state.paymentConfirmationSteps,
            [action.payload.data.appointment_availability_id]: {
              steps: action.payload.data.steps,
              current: action.payload.data.current
            }
          }
        }
      case "client-end-confirm":
        return {
          ...state,
          paymentConfirmation: {
            ...state.paymentConfirmation,
            [action.payload.data.appointment_availability_id]: {
              complete: true,
              available: action.payload.data.available
            }
          }
        }
      case "client-begin-purchase":
        return {
          ...state,
          paymentAuthorizeSteps: {}
        }
      case "client-progress-purchase":
        return {
          ...state,
          paymentAuthorizeSteps: action.payload.data
        }
      case "client-end-purchase":
        return {
          ...state,
          confirmedIsPurchased: true
        }
      default:
        return state
    }
  }

  return state
}

export default function purchaseReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CART_SUCCESS":
    case "ADD_ITEM_TO_CART_SUCCESS":
    case "REMOVE_ITEM_FROM_CART_SUCCESS":
      const paymentMethod = action.payload.response.cart.payment_methods.find(
        p => p.slug === action.payload.response.cart.payment_method
      )

      let purchaseButton = "Buy Now"

      if (paymentMethod && paymentMethod.type === "partial") {
        purchaseButton = "Part Pay"
      }

      if (paymentMethod && paymentMethod.type === "deferred") {
        purchaseButton = "Pay In Salon"
      }

      return {
        ...state,
        selectedPurchaseBtn: purchaseButton,
        selectedPaymentMethod: paymentMethod,
        selectedPaymentMethodOption: null,
        paymentToken: null
      }

    case "VALIDATE_PROMOTION_CODE_SUCCESS":
      return {
        ...state,
        promotionCodeValidating: false,
        promotionCodeErrorMessage: !action.payload.response.valid
          ? action.payload.response.error
          : null,
        promotionCodeValid: action.payload.response.valid
      }

    case "SAVE_PROMOTION_CODE_SUCCESS":
    case "CLEAR_PROMOTION_CODE":
      return {
        ...state,
        promotionCodeErrorMessage: null,
        promotionCodeValidating: false,
        promotionCodeValid: false,
        promotionCodeEntered: ""
      }

    case "ENTER_PROMOTION_CODE":
      return {
        ...state,
        promotionCodeErrorMessage: null,
        promotionCodeValidating: true,
        promotionCodeValid: false,
        promotionCodeEntered: action.payload.code
      }

    case "CLEAR_PURCHASE_ERROR":
      return {
        ...state,
        ...initialPurchaseErrorState,
      }

    case "TOGGLE_FLOSSIE_DOLLARS":
      return {
        ...state,
        useFlossieDollars: !state.useFlossieDollars
      }
    case "TOGGLE_SIGNUP_VIEW":
      return {
        ...state,
        loggedOutShowSignup: !state.loggedOutShowSignup
      }

    case PAYMENT_CONFIRM:
      return {
        ...state,
        inProgress: true,
        donePurchase: false,

        ...initialPurchaseErrorState,

        paymentReference: null,
        paymentConfirmation: {},
        paymentConfirmationSteps: {},
        paymentAuthorizeSteps: {},

        confirmedIsPurchased: null,
        orderId: null
      }

    case PAYMENT_CONFIRM_SUCCESS:
      const { reference, secret } = action.payload.response

      return {
        ...state,
        paymentReference: reference,
        paymentSecret: secret,
        paymentConfirmation: {},
        paymentConfirmationSteps: {}
      }

    case PAYMENT_CONFIRM_UNAVAILABLE:
      let errorMessage = "Sorry, the appointment time you selected for the below service is no longer available and has been removed from your cart\n\n"

      action.payload.items.forEach(item => {
        errorMessage = `${errorMessage + item.name}\n`
      })

      return {
        ...state,
        inProgress: false,

        paymentReference: null,
        paymentConfirmation: {},
        paymentConfirmationSteps: {},
        paymentAuthorizeSteps: {},
        paymentToken: null,

        purchaseError: true,
        purchaseErrorMessage: errorMessage,
        purchaseErrorType: PURCHASE_ERROR_UNAVAILABLE,
        purchaseErrorData: { items: action.payload.items }
      }

    case "SELECT_PAYMENT_METHOD_FAILURE":
    case "ADD_NOTES_TO_CART_FAILURE":
    case "REMOVE_NOTES_FROM_CART_FAILURE":
    case PAYMENT_CONFIRM_FAILURE:

      return {
        ...state,
        inProgress: false,

        paymentReference: null,
        paymentConfirmation: {},
        paymentConfirmationSteps: {},
        paymentAuthorizeSteps: {},
        paymentToken: null,
        paymentSecret: null,

        purchaseError: true,
        purchaseErrorType: PURCHASE_ERROR_DEFAULT,

        // Errors returned in action.payload.response.message are 412 and defined
        // as return to user
        purchaseErrorMessage:
          (action.payload
            && action.payload.response
            && action.payload.response.message)
          || (action.payload
            && action.payload.error_response
            && action.payload.error_response.response
            && action.payload.error_response.response.data
            && action.payload.error_response.response.data.message
            && action.payload.error_response.response.data.message.includes("phoneNumber")
            && "Sorry, your phone number is not valid"
          )
          || "Unknown error"
      }

    case PAYMENT_CANCEL:
      return {
        ...state,

        inProgress: false,

        ...initialPurchaseErrorState,

        paymentReference: null,
        paymentConfirmation: {},
        paymentConfirmationSteps: {},
        paymentAuthorizeSteps: {},
        paymentToken: null,
        paymentSecret: null
      }

    case PAYMENT_AUTHORIZE_REDIRECT:
      return {
        ...state,

        inProgress: false,

        ...initialPurchaseErrorState,

        paymentConfirmation: {},
        paymentConfirmationSteps: {},
        paymentAuthorizeSteps: {}
      }

    case PAYMENT_AUTHORIZE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        donePurchase: true,

        ...initialPurchaseErrorState,

        paymentToken: null,
        paymentSecret: null,
        orderId: action.payload.response.id
      }

    case PAYMENT_AUTHORIZE_FAILURE:
      return {
        ...state,
        inProgress: false,
        purchaseError: true,
        purchaseErrorType: PURCHASE_ERROR_DEFAULT,
        purchaseErrorMessage:
          (action.payload
            && action.payload.response
            && action.payload.response.message)
          || "Sorry, there was an unknown error processing your payment",
        paymentToken: null,
        paymentSecret: null
      }

    case "FETCH_PROFILE_SUCCESS":
      return {
        ...state,
        phoneNumber: action.payload.response.phone_number
      }
    case PURCHASE_PHONE_UPDATE:
      return {
        ...state,
        phoneNumber: action.payload.number
      }
    case "CARD_CVC_CHANGE":
      return {
        ...state,
        cardCvc: action.cvc
      }
    case "CARD_NUMBER_CHANGE":
      return {
        ...state,
        cardNumber: action.num
      }
    case "CARD_EXPIRY_CHANGE":
      return {
        ...state,
        cardExpiry: action.exp
      }
    case "CARD_NAME_CHANGE":
      return {
        ...state,
        cardName: action.name
      }
    case "SELECT_PAYMENT_METHOD":
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        paymentToken: null
      }
    case "SELECT_PAYMENT_METHOD_OPTION":
      return {
        ...state,
        selectedPaymentMethodOption: action.payload,
        paymentToken: null
      }
    case "SELECT_PURCHASE_BTN":
      return {
        ...state,
        selectedPurchaseBtn: action.payload
      }

    case PAYMENT_TOKEN:
      return {
        ...state,
        paymentToken: action.payload
      }
    case "WS_MESSAGE":
      return handleWsMessage(state, action)
    case "RESET_PURCHASE":
    case "LOG_OUT":
      return initialState
    default:
      return state
  }
}
