import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import CreditCardInput from "react-credit-card-input"
import { CardElement } from "@stripe/react-stripe-js"
import {
  clearPromotionCode,
  handleCardNameChange,
  handleCardCvcChange,
  handleCardNumberChange,
  handleCardExpiryChange,
  selectPaymentMethod,
  selectPaymentMethodOption,
  selectPaymentBtn
} from "./actions"

import { enableBalanceInCart, disableBalanceInCart } from "../Cart/actions"

import { TextInput } from "../../components/forms/Inputs"
import FlossieCheckBox from "../../components/FlossieCheckBox"

import PaymentMethodButton from "./components/PaymentMethodButton"

import { FlossieRadioButton } from "./helpers"

export const StyledCCDiv = styled.div`

  border-top-color: ${({ border, theme }) => (border ? theme.sectionDividerColor : "#000")};
  border-top-width: ${props => (props.border ? "2px" : "0px")};
  border-top-style: ${props => (props.border ? "solid" : "none")};

  border-bottom-color: ${({ border, theme }) => (border ? theme.sectionDividerColor : "#000")};
  border-bottom-width: ${props => (props.border ? "2px" : "0px")};
  border-bottom-style: ${props => (props.border ? "solid" : "none")};
  padding-bottom: 25px;
  padding-top: 25px;
`

const StyledOxiPayParentDiv = styled.div`
  padding-top: 15;
  font-weight: 400;
  >p:first-child {
    border-bottom: ${({ theme }) => `solid 2px ${theme.sectionDividerColor}`};
    padding-bottom: 15px;
  }
`

const PaymentLogosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  >div {
    padding: 15px;
  }
  img {
    max-width: 130px;
    height: 36px;
    width: auto;
  }
`

class PaymentContainer extends Component {
  componentDidUpdate() {
    const {
      paymentMethods,
      selectedPaymentMethod,
      selectedPaymentMethodOption,
      selectPaymentMethod,
      selectPaymentMethodOption,
      selectPaymentBtn,
      haveExistingCard
    } = this.props

    let paymentMethodRequired = false
    if (!selectedPaymentMethod) {
      /* If no payment method selected at all then set something */
      paymentMethodRequired = true
    } else if (
      !paymentMethods.filter(p => p.slug === selectedPaymentMethod.slug).length
    ) {
      /* If selected payment method is not available then reset */
      paymentMethodRequired = true
    }

    if (paymentMethodRequired) {
      const paymentButtons = []
      // Our first priority are primary payment methods
      let paymentMethod = paymentMethods.find(p => p.type === "primary")
      if (!paymentMethod) {
        // If primary not found try to find partial payment methods
        paymentMethod = paymentMethods.find(p => p.type === "partial")
      }
      if (!paymentMethod) {
        // If partial not found try to find deferred payment methods
        paymentMethod = paymentMethods.find(p => p.type === "deferred")
      }
      selectPaymentMethod(paymentMethod)
      let paymentButton
      switch (paymentMethod.type) {
        case "primary":
          paymentButton = "Buy Now"
          break
        case "partial":
          paymentButton = "Part Pay"
          break
        default:
        case "deferred":
          paymentButton = "Pay In Salon"
      }
      selectPaymentBtn(paymentButton)
    }

    let paymentMethodOptionRequired = false

    /* Selected a credit card payment type but not whether we want new or existing */
    if (
      selectedPaymentMethod
      && selectedPaymentMethod.type === "primary"
      && !selectedPaymentMethodOption
    ) paymentMethodOptionRequired = true

    if (paymentMethodOptionRequired) {
      const paymentMethodOption = haveExistingCard ? "cc_existing" : "cc_new"
      selectPaymentMethodOption(paymentMethodOption)
    }
  }


  toggleFlossieDollars = () => {
    const {
      useFlossieDollars,
      disableBalanceInCart,
      enableBalanceInCart
    } = this.props

    if (useFlossieDollars) {
      disableBalanceInCart()
    } else {
      enableBalanceInCart()
    }
  }

  renderFlossieDollars = () => {
    const {
      flossieDollarsBalance,
      useFlossieDollars,
      selectedPurchaseMethod
    } = this.props
    if (selectedPurchaseMethod === "Pay In Salon") return null
    return (
      <FlossieCheckBox
        labelContent={`( $${flossieDollarsBalance} )`}
        selected={useFlossieDollars}
        onClick={this.toggleFlossieDollars}
        extraStyle={{
          flexDirection: "row-reverse",
          marginTop: "30px",
          marginBottom: "10px",
          paddingLeft: 6
        }}
      >
        <div
          style={{
            margin: 0,
            flex: 1,
            fontSize: "16px",
            fontWeight: 350
          }}
        >
          Use Flossie Dollars
        </div>
      </FlossieCheckBox>
    )
  }

  renderNewCreditCard = () => {
    const {
      cardNumber,
      cardExpiry,
      cardCvc,
      cardName,
      handleCardNameChange,
      handleCardNumberChange,
      handleCardExpiryChange,
      handleCardCvcChange
    } = this.props
    return (
      <div>
        <div style={{ display: "flex" }}>
          <TextInput
            style={{
              flex: 1, boxSizing: "content-box", border: "2px solid #ccc", padding: "15px 20px"
            }}
            placeholder="Cardholder name"
            value={cardName}
            onChange={e => {
              handleCardNameChange(e.target.value)
            }}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CreditCardInput
            containerStyle={{ display: "block" }}
            fieldStyle={{
              padding: "15px 10px",
              height: "20px",
              border: "2px solid #ccc",
              boxSizing: "content-box",
              borderRadius: "60px"
            }}
            inputStyle={{ fontSize: "16px" }}
            cardImageStyle={{ display: "none" }}
            cardNumberInputProps={{
              value: cardNumber,
              onChange: e => {
                e.preventDefault()
                handleCardNumberChange(e.target.value)
              }
            }}
            cardExpiryInputProps={{
              value: cardExpiry,
              onChange: e => {
                e.preventDefault()
                handleCardExpiryChange(e.target.value)
              }
            }}
            cardCVCInputProps={{
              value: cardCvc,
              onChange: e => {
                e.preventDefault()
                handleCardCvcChange(e.target.value)
              }
            }}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "15px"
          }}
        >
           <------TEMP - hide checkbox as non functional at this stage---->
          <StyledCheckbox type="checkbox" name="save_cc" checked={true} />
          <div>
            <label style={{ fontSize: "15px" }}>
              For express checkout, save details for your next booking
            </label>
          </div>
        </div> */}
      </div>
    )
  }

  renderExistingCreditCard = () => {
    const { existingCard } = this.props
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 10
          }}
        >
          <span style={{ flex: 1, fontWeight: 600, fontSize: "16px" }}>
            {existingCard.name}
          </span>
          <span
            style={{
              flex: 1,
              fontWeight: 300,
              textAlign: "right",
              fontSize: "16px"
            }}
          >
            {existingCard.number.replace(/\./g, "x")}
          </span>
          <span style={{ flex: 1, textAlign: "right" }}>
            {existingCard.expiry}
          </span>
        </div>
      </div>
    )
  }

  renderCreditCard = () => {
    const { haveExistingCard, selectedPaymentMethod, selectedPaymentMethodOption } = this.props

    /* Both /profile/get and /cart/get must be loaded before this goes any further */
    if (typeof haveExistingCard === "undefined" || typeof selectedPaymentMethod === "undefined") {
      return null
    }

    if (selectedPaymentMethod.slug === "stripe") {
      return (
        <StyledCCDiv border>
          {this.renderStripe()}
        </StyledCCDiv>
      )
    }

    return (
      <StyledCCDiv border>
        {this.renderCreditCardSelector()}
        {haveExistingCard && selectedPaymentMethodOption === "cc_existing" && this.renderExistingCreditCard()}
        {selectedPaymentMethodOption === "cc_new" && this.renderNewCreditCard() }

      </StyledCCDiv>
    )
  }

  renderStripe = () => {
    const cardOptions = {
      style: {
        base: {
          color: "#32325d",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    }

    return (
      <CardElement options={cardOptions} />
    )
  }

  renderPartPay = () => {
    const { bookingPrice } = this.props
    if (!bookingPrice || !bookingPrice.enableOxiPay) return null

    return (
      <StyledOxiPayParentDiv>
        <p style={{ margin: 0 }}>
          {bookingPrice.oxipay.totalPayments}
          {" "}
          payments of
          {" "}
          {bookingPrice.currency.symbol + bookingPrice.oxipay.paymentValue}
          {" "}
          interest free with Oxipay
        </p>
        <pre style={{ margin: 0, paddingTop: 15, fontWeight: 200 }}>
          Don't have an account?
        </pre>
        <pre style={{ margin: 0, fontWeight: 200 }}>
          Simply click 'Complete Purchase' to create your account.
        </pre>
      </StyledOxiPayParentDiv>
    )
  }

  renderCreditCardSelector = () => {
    const {
      haveExistingCard,
      paymentMethods,
      selectedPaymentMethodOption,
      selectPaymentMethod,
      selectPaymentMethodOption
    } = this.props

    const paymentMethod = paymentMethods.find(p => p.type === "primary")

    return (
      <div>
        <div style={{
          width: "100%", textAlign: "center", display: "inline-block", marginBottom: 10
        }}
        >
          {haveExistingCard && (
            <div style={{ display: "inline-block", marginRight: 10, padding: "0px 10px" }}>
              <FlossieRadioButton
                label="Use Saved Card"
                onClick={() => {
                  selectPaymentMethod(paymentMethod)
                  selectPaymentMethodOption("cc_existing")
                }}
                selected={selectedPaymentMethodOption === "cc_existing"}
              />
            </div>
          )}

          <div style={{ display: "inline-block", padding: "0px 10px" }}>
            <FlossieRadioButton
              label="New Credit Card"
              onClick={() => {
                selectPaymentMethod(paymentMethod)
                selectPaymentMethodOption("cc_new")
              }}
              selected={selectedPaymentMethodOption === "cc_new"}
            />
          </div>
        </div>
      </div>
    )
  }

  renderPaymentMethodSelector = (type) => {
    const {
      selectedPaymentMethod,
      selectPaymentMethod,
      paymentMethods
    } = this.props
    if (type === "deferred") return null
    return (
      <div>
        <div
          style={{
            color: "black",
            marginTop: 10,
            fontWeight: 200
          }}
        >
          Choose your preferred payment option:
        </div>
        <div style={{ textAlign: "center" }}>
          <PaymentLogosWrapper>
            {paymentMethods
              .filter(p => p.type === type)
              .map(p => {
                let logo = false

                if (p.images) {
                  logo = p.images.find(i => i.placement === "logo")
                }

                return (
                  <div
                    key={p.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => selectPaymentMethod(p)}
                  >
                    <div style={{ marginRight: 4 }}>
                      <FlossieRadioButton
                        onClick={() => selectPaymentMethod(p)}
                        selected={selectedPaymentMethod.slug === p.slug}
                      />
                    </div>
                    <div>
                      {logo && (
                        <img alt={`${p.name} logo`} src={logo.url} />
                      )}
                      {!logo && <span>{p.name}</span>}
                    </div>
                  </div>
                )
              })}
          </PaymentLogosWrapper>
        </div>
      </div>
    )
  }

  render() {
    const {
      paymentMethods,

      flossieDollarsBalance,

      bookingPrice,
      paymentRequired,

      selectPaymentBtn,
      selectedPurchaseMethod,
      isMobile,
      clearPromotionCode,
      hasExistingCard,
    } = this.props

    const showFlossieDollars = paymentMethods.filter(p => p.slug === "dollars").length > 0
      && parseInt(flossieDollarsBalance, 10) > 0
    const showPromoCodes = paymentMethods.filter(p => p.slug === "codes").length > 0
    // const showCreditCard = (paymentMethods.filter(p => p.type === 'primary').length > 0 && paymentRequired)

    const hasBuyNow = paymentMethods.filter(p => p.type === "primary").length > 0
    const hasPartPay = paymentMethods.filter(p => p.type === "partial").length > 0
    const hasPayLater = paymentMethods.filter(p => p.type === "deferred").length > 0

    const canPartPay = bookingPrice
      && bookingPrice.enableOxiPay
      && bookingPrice.oxipay.visible
      && hasPartPay

    return (
      <div style={{ padding: "0px 10px" }}>
        <PaymentMethodButton
          onChange={selectPaymentBtn}
          hasBuyNow={hasBuyNow}
          hasPartPay={hasPartPay}
          hasPayLater={hasPayLater}
          canPartPay={canPartPay}
          showFlossieDollars={showFlossieDollars}
          showPromoCodes={showPromoCodes}
          renderFlossieDollars={this.renderFlossieDollars}
          renderPaymentMethodSelector={this.renderPaymentMethodSelector}
          renderCreditCard={this.renderCreditCard}
          renderPartPay={this.renderPartPay}
          paymentRequired={paymentRequired}
          selectedPurchaseMethod={selectedPurchaseMethod}
          hasExistingCard={hasExistingCard}
          clearPromotionCode={clearPromotionCode}
          isMobile={isMobile}
        />
      </div>
    )
  }
}

PaymentContainer.propTypes = {
  useFlossieDollars: PropTypes.bool.isRequired,
  enableBalanceInCart: PropTypes.func.isRequired,
  disableBalanceInCart: PropTypes.func.isRequired,

  flossieDollarsBalance: PropTypes.number.isRequired,
  cardNumber: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  cardCvc: PropTypes.string.isRequired,
  cardExpiry: PropTypes.string.isRequired,

  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethod: PropTypes.object,

  haveExistingCard: PropTypes.bool.isRequired,
  paymentRequired: PropTypes.bool.isRequired,
  existingCard: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedPaymentMethodOption: PropTypes.string,
  selectPaymentMethod: PropTypes.func,
  selectPaymentMethodOption: PropTypes.func,
  selectPaymentBtn: PropTypes.func,
  clearPromotionCode: PropTypes.func,
  selectedPurchaseMethod: PropTypes.string,
  handleCardNameChange: PropTypes.func.isRequired,
  handleCardExpiryChange: PropTypes.func,
  bookingPrice: PropTypes.object,
  handleCardNumberChange: PropTypes.func,
  handleCardCvcChange: PropTypes.func
}

function mapStateToProps(state) {
  return {
    haveExistingCard: state.profile.profile.existing_card,
    existingCard: {
      name: state.profile.profile.card_name,
      number: state.profile.profile.card_number,
      type: state.profile.profile.card_type,
      expiry: `${state.profile.profile.card_expiry_month} / ${state.profile.profile.card_expiry_year}`
    },

    paymentMethods: state.cart.cart.payment_methods,
    selectedPaymentMethod: state.purchase.selectedPaymentMethod,
    selectedPaymentMethodOption: state.purchase.selectedPaymentMethodOption,
    selectedPurchaseMethod: state.purchase.selectedPurchaseBtn,

    cardNumber: state.purchase.cardNumber,
    cardExpiry: state.purchase.cardExpiry,
    cardCvc: state.purchase.cardCvc,
    cardName: state.purchase.cardName,

    flossieDollarsBalance: parseInt(state.profile.profile.total_balance, 10),
    useFlossieDollars: state.cart.cart.use_balance,

    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  handleCardNameChange: name => dispatch(handleCardNameChange(name)),
  handleCardNumberChange: num => dispatch(handleCardNumberChange(num)),
  handleCardExpiryChange: exp => dispatch(handleCardExpiryChange(exp)),
  handleCardCvcChange: cvc => dispatch(handleCardCvcChange(cvc)),
  selectPaymentMethod: method => dispatch(selectPaymentMethod(method)),
  selectPaymentMethodOption: option => dispatch(selectPaymentMethodOption(option)),
  selectPaymentBtn: method => dispatch(selectPaymentBtn(method)),
  enableBalanceInCart: () => dispatch(enableBalanceInCart()),
  disableBalanceInCart: () => dispatch(disableBalanceInCart()),
  clearPromotionCode: () => dispatch(clearPromotionCode())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentContainer)
