import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledP = styled.div`
  font-weight: 400;
  margin: 0px;
  margin-top: 4px;
  font-size: 15px;
  display: flex;
`

const CompanyHour = ({
  is_open, day, open, close
}) => {
  if (!is_open) {
    return (
      <StyledP>
        <div style={{ flex: 1 }}>
          <b>{day}</b>
        </div>
        <div style={{ flex: 2 }}>Closed</div>
      </StyledP>
    )
  }
  return (
    <StyledP>
      <div style={{ flex: 1 }}>
        <b>{day}</b>
      </div>
      <div style={{ flex: 2 }}>
        {open}
        {" "}
        -
        {" "}
        {close}
      </div>
    </StyledP>
  )
}

CompanyHour.propTypes = {
  is_open: PropTypes.bool,
  day: PropTypes.string,
  open: PropTypes.string,
  close: PropTypes.string
}

export const CompanyHours = ({ hours }) => (
  <div>{hours && hours.map(h => <CompanyHour {...h} key={h.dow} />)}</div>
)

CompanyHours.propTypes = {
  hours: PropTypes.array
}
