import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Row = styled.div`
  display: flex;
  svg {
    margin-right: 10px;
    fill: ${props => props.theme.colors.primary};
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  b {
    font-weight: 500;
  }
`

const IconDataRow = ({
  icon, children, style, className
}) => (
  <Row style={style} className={className}>
    <div style={{ width: 20, textAlign: "center", marginRight: 10 }}>{icon}</div>
    <div style={{ flex: 1 }}>{children}</div>
  </Row>
)

IconDataRow.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string,
}
export default IconDataRow
