import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import PaymentDetails from "./PaymentDetails"
import { AccordianHeader, AccordianContent } from "../../../components/accordians"
import ShadowBox from "../../../components/ShadowBox"
import PromotionCodeButton from "./PromotionCodeButton"
import PromotionCodeSelector from "./PromotionCodeSelector"

import { selectPaymentMethod, selectPaymentMethodOption } from "../actions"

const StyledPromoCodeDiv = styled.div`
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
  padding-top: 20px;
`


/* Ensure accordian top margin isn't there on first payment method so is flush with booking details container */
const PaymentMethodContainer = styled.div`

  & > div > div {

  }

  & > div > div:first-child {
    margin-bottom: 0px;
    padding: 10px 15px;
    background-color: ${props => props.theme.paymentMethod.backgroundColor};
    color: ${props => props.theme.paymentMethod.headerText};
  }

  & > div > div:first-child + div {
    border: 2px solid ${props => props.theme.paymentMethod.backgroundColor};
    padding: 0px 15px 20px 15px !important;
  }

  & > div > div > div {
    color: ${props => props.theme.paymentMethod.headerText};
  }

  & > div > div > div p {
    color: initial;
  }

  div:first-child > div {
    margin-top: 0px;
  }
`

class PaymentMethodButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      promotionCodeSelectorOpen: false,
      opened: {
        primary: false,
        partial: false,
        deferred: false
      }
    }
  }

  componentDidMount() {
    const {
      selectedPaymentMethod,
      paymentMethods
    } = this.props

    /* If the cart has returned a selected payment method, make sure that
       payment method box is open on load */
    if (selectedPaymentMethod) {
      this.setState({
        opened: {
          [selectedPaymentMethod.type]: true
        }
      })
      /* otherwise select a default opened payment method in reverse order of display to not override the rule */
    } else {
      if (paymentMethods.find(m => m.type === 'deferred')) this.setState({ opened: { deferred: true } })
      if (paymentMethods.find(m => m.type === 'partial')) this.setState({ opened: { partial: true } })
      if (paymentMethods.find(m => m.type === 'primary')) this.setState({ opened: { primary: true } })
    }
  }

  closePromotionCodeSelector = () => {
    const { clearPromotionCode } = this.props
    clearPromotionCode()
    this.setState({ promotionCodeSelectorOpen: false })
  }

  openPromotionCodeSelector = () => {
    const { clearPromotionCode } = this.props

    clearPromotionCode()
    this.setState({ promotionCodeSelectorOpen: true })
  }

  renderPromotionCodes = () => {
    const { selectedPurchaseMethod, isMobile } = this.props
    if (selectedPurchaseMethod === "Pay In Salon") return null

    const { promotionCodeSelectorOpen } = this.state
    return (
      <StyledPromoCodeDiv>
        <PromotionCodeButton
          openPromotionCodeSelector={this.openPromotionCodeSelector}
        />

        {promotionCodeSelectorOpen && (
          <ShadowBox
            isFullscreen={isMobile}
            closeBox={() => this.setState({ promotionCodeSelectorOpen: false })}
          >
            <PromotionCodeSelector
              closePromotionCodeSelector={this.closePromotionCodeSelector}
            />
          </ShadowBox>
        )}
      </StyledPromoCodeDiv>
    )
  }

  renderButton(item) {
    const {
      onChange,
      selectPaymentMethod,
      selectPaymentMethodOption,
      selectedPaymentMethod,
      showFlossieDollars,
      showPromoCodes,
      paymentRequired,
      selectedPurchaseMethod,
      renderFlossieDollars,
      renderPaymentMethodSelector,
      renderCreditCard,
      renderPartPay,
    } = this.props

    const { opened } = this.state

    const onClick = () => {
      if (item.type === selectedPaymentMethod.type && opened[item.type]) {
        this.setState({
          opened: {
            [item.type]: false
          }
        })
      } else {
        this.setState({
          opened: {
            [item.type]: true
          }
        })
      }

      if (item && item.id && item.enabled) {
        onChange(item.btnText)
      }
      if (item && item.paymentMethod && item.enabled) {
        selectPaymentMethod(item.paymentMethod)
        selectPaymentMethodOption(item.paymentMethodOption)
      }
    }

    if (!item || !item.visible || !selectedPaymentMethod) {
      return null
    }

    const isSelected = item.type === selectedPaymentMethod.type

    return (
      <div style={{ marginBottom: 20 }}>
        <AccordianHeader
          title={item.btnText}
          open={opened[item.type]}
          onClick={onClick}
          isSelected={isSelected}
        />
        <AccordianContent open={isSelected && opened[item.type]}>
          <div>
            <PaymentDetails />
            {showFlossieDollars && renderFlossieDollars()}
            {showPromoCodes && this.renderPromotionCodes()}
            {paymentRequired && renderPaymentMethodSelector(item.type)}
            {paymentRequired && selectedPurchaseMethod === "Buy Now" && renderCreditCard()}
            {paymentRequired && selectedPurchaseMethod === "Part Pay" && renderPartPay()}
          </div>
        </AccordianContent>
      </div>
    )
  }

  render() {
    const {
      cart,
      paymentMethods,
      hasBuyNow,
      hasPartPay,
      hasPayLater,
      canPartPay
    } = this.props

    if (!cart || !cart.currency) {
      return null
    }

    const buyNowPaymentMethod = paymentMethods.find(p => p.type === "primary")

    const buyNowButton = {
      id: 1,
      btnText: "Buy Now",
      paymentMethod: buyNowPaymentMethod,
      paymentMethodOption: "cc_existing",
      enabled: true,
      visible: hasBuyNow,
      type: "primary"
    }

    const buyNowPriceEnabled = cart.items.filter(
      i => i.buy_now_discount_percent
          && parseFloat(i.buy_now_discount_percent) > 0
    ).length > 0

    if (buyNowPriceEnabled) buyNowButton.btnLabel = "get rewarded"

    const partPayPaymentMethod = paymentMethods.find(p => p.type === "partial")

    const partPayButton = {
      id: 2,
      btnText: "Part Pay",
      paymentMethod: partPayPaymentMethod,
      paymentMethodOption: null,
      enabled: true,
      visible: hasPartPay,
      type: "partial"
    }

    if (!canPartPay) {
      partPayButton.enabled = false
      partPayButton.btnLabel = `minimum ${cart.currency.symbol}20.00`
    }

    const payInSalonPaymentMethod = paymentMethods.find(
      p => p.type === "deferred"
    )

    const payInSalonButton = {
      id: 3,
      btnText: "Pay In Salon",
      paymentMethod: payInSalonPaymentMethod,
      paymentMethodOption: null,
      enabled: true,
      visible: hasPayLater,
      type: "deferred"
    }

    return (
      <PaymentMethodContainer>
        {this.renderButton(buyNowButton)}
        {this.renderButton(partPayButton)}
        {this.renderButton(payInSalonButton)}
      </PaymentMethodContainer>
    )
  }
}

PaymentMethodButton.propTypes = {
  onChange: PropTypes.func,
  selectPaymentMethod: PropTypes.func,
  selectPaymentMethodOption: PropTypes.func,
  selectedPaymentMethod: PropTypes.object,
  cart: PropTypes.object,
  paymentMethods: PropTypes.array,
  hasBuyNow: PropTypes.bool,
  hasPartPay: PropTypes.bool,
  hasPayLater: PropTypes.bool,
  canPartPay: PropTypes.bool,
  showFlossieDollars: PropTypes.bool,
  showPromoCodes: PropTypes.bool,
  paymentRequired: PropTypes.bool,
  selectedPurchaseMethod: PropTypes.string,
  renderFlossieDollars: PropTypes.func.isRequired,
  renderPaymentMethodSelector: PropTypes.func.isRequired,
  renderCreditCard: PropTypes.func.isRequired,
  renderPartPay: PropTypes.func.isRequired,
  clearPromotionCode: PropTypes.func.isRequired,
  hasExistingCard: PropTypes.bool,
  isMobile: PropTypes.bool,

  /* Required for now to make DPS credit card text boxes update on input */
  cardName: PropTypes.string,
  cardCvc: PropTypes.string,
  cardNumber: PropTypes.string,
  cardExpiry: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    paymentMethods: state.cart.cart.payment_methods,
    selectedPaymentMethod: state.purchase.selectedPaymentMethod,
    selectedPurchaseMethod: state.purchase.selectedPurchaseBtn,
    cardNumber: state.purchase.cardNumber,
    cardExpiry: state.purchase.cardExpiry,
    cardCvc: state.purchase.cardCvc,
    cardName: state.purchase.cardName,
  }
}

const mapDispatchToProps = dispatch => ({
  selectPaymentMethod: method => dispatch(selectPaymentMethod(method)),
  selectPaymentMethodOption: option => dispatch(selectPaymentMethodOption(option))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodButton)
