import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { goBack, push } from "connected-react-router"
import styled from "styled-components"

import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js"

import {
  paymentCancel,
  paymentConfirm,
  paymentToken,
  navToPurchase,
  navToOrderConfirmation,
  toggleFlossieDollars,
  toggleRegistration,
  clearPurchaseError,
  handleSalonNoteChange
} from "./actions"
import { PURCHASE_ERROR_UNAVAILABLE } from "./reducer"
import { navToInventory } from "../InventoryView/actions"

import { getRequiredPatchTestItems } from "../Cart/selectors"

import { removeFromCart } from "../Cart/actions"

import { SubmitInput } from "../../components/forms/Inputs"
import { BackLink } from "../../components/nav/Links"
import Animation from "../../components/Animation"
import ErrorBar from "../../components/ErrorBar"
import ShadowBox from "../../components/ShadowBox"
import PaymentContainer from "./PaymentContainer"
import BookingDetailsContainer from "./BookingDetailsContainer"
import WrapContainer from "../WrapContainer"
import LoginContainer from "../Login/LoginContainer"
import RegistrationContainer from "../Registration/RegistrationContainer"
import FlossieCheckBox from "../../components/FlossieCheckBox"
import PatchTestingAlert from "./PatchTestingAlert"

import {
  PurchaseButton
} from "./helpers"

const ThemedLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`

const LoggedOutDiv = styled.div`
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`

const StyleErrorDiv = styled.div`
  flex: 1;
  padding: 0;
  position: relative;
`
const LoadingLine = styled.div`
  height: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
`
const LoadingText = styled.h2`
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`

class PurchaseContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPatchRequiredItems: false,
      patchTestRequiredConfirmedByUser: false,
      readBookingTerms: true,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    /* Check URL for tag indicating the user bailed out of the payment process and cancel the transaction if so */
    const urlParts = document.URL.split("#")

    if (urlParts.length === 2 && urlParts[1] === "cancel") {
      const { paymentCancel, navToPurchase } = this.props
      paymentCancel()
      navToPurchase()
    }

    /* Purchase has changed from incomplete to complete */
    const { purchase, navToOrderConfirmation } = this.props
    if (purchase.donePurchase && !prevProps.purchase.donePurchase) {
      navToOrderConfirmation(purchase.orderId)
    }
  }

  renderPaymentBtnText = (cart, method) => {
    let btnText = "Buy Now"
    if (!method || !cart) return btnText
    const paymentRequired = `${cart.currency.symbol}${cart.total} ${cart.currency.code}`
    btnText = `Buy Now ${paymentRequired}`
    if (method === "Part Pay") btnText = `Complete Purchase ${paymentRequired}`
    if (method === "Pay In Salon") btnText = "Complete Booking!"
    return btnText
  }

  // paymentRequired = () => calculateCheckoutPrice(this.props).paymentRequired

  newCreditCardValid = () => {
    const {
      selectedPaymentMethodOption,
      cardName,
      cardCvc,
      cardNumber,
      cardExpiry
    } = this.props
    if (selectedPaymentMethodOption !== "cc_new") return true
    if (cardName === "") {
      alert("Please enter your Cardholder Name")
      return false
    }
    if (cardCvc === "") {
      alert("Please enter your Card Security (CVC) code")
      return false
    }
    if (cardNumber === "") {
      alert("Please enter your Card Number")
      return false
    }
    if (cardExpiry === "") {
      alert("Please enter your Card Number")
      return false
    }
    return true
  }

  phoneNumberValid = (phoneNumber) => {
    if (phoneNumber !== "") return true
    alert("Please enter your phone number for the Salon")
    return false
  }


  makePurchase = () => {
    const {
      paymentConfirm,
      paymentMethods,
      paymentToken,
      selectedPaymentMethod,
      profile,
      purchase,
      requiredPatchTestItems,
      stripe,
      elements
    } = this.props

    const { patchTestRequiredConfirmedByUser } = this.state

    const phoneNumber = profile.phone_number ? profile.phone_number : purchase.phoneNumber

    if (!this.phoneNumberValid(phoneNumber)) {
      // Error message handled by function
      return
    } else if (
      !paymentMethods.filter(p => p.slug === selectedPaymentMethod.slug).length
      // make sure at least one VALID payment method is selected
    ) {
      alert("Please select a payment method")

      return
    } else if (
      !patchTestRequiredConfirmedByUser
      && requiredPatchTestItems
      && requiredPatchTestItems.length > 0
    ) {
      this.setState({ showPatchRequiredItems: true })

      return
    }

    if (selectedPaymentMethod.slug === "stripe") {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        alert("Stripe library has not finished loading")

        return
      }

      window.stripePayment = {
        stripe,
        elements,
        cardElement: elements.getElement(CardElement)
      }
    }
    paymentConfirm(phoneNumber)
  }

  isUnavailable = () => false

  renderError = () => {
    const {
      purchase, isMobile, clearPurchaseError, navToInventory
    } = this.props

    if (purchase.purchaseError) {
      let buttonText = "Please click here to try again"

      if (purchase.purchaseErrorType === PURCHASE_ERROR_UNAVAILABLE) {
        buttonText = "Click here to find a new appointment"
      }

      const handleClick = () => {
        clearPurchaseError()

        // If unavailable error send them back where they came from to try find another time
        if (purchase.purchaseErrorType === PURCHASE_ERROR_UNAVAILABLE) {
          navToInventory(purchase.purchaseErrorData.items[0].service.slug)
        }
      }

      return (
        <ShadowBox
          isFullscreen={isMobile}
          closeBox={handleClick}
        >
          <div style={{ textAlign: "center" }}>
            <ErrorBar message={purchase.purchaseErrorMessage} />
            <p>
              <SubmitInput
                onClick={handleClick}
                value={buttonText}
              />
            </p>
          </div>
        </ShadowBox>
      )
    }

    return null
  }

  renderNoPaymentMethodError = () => {
    const { navToPrevious } = this.props
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <p>No Payment method available.</p>
          <p>
            <SubmitInput
              onClick={navToPrevious}
              value="Continue Shopping"
            />
          </p>
        </div>
      </div>
    )
  }

  renderProgress = () => {
    const { purchase, cart } = this.props
    const {
      inProgress,
      donePurchase,
      paymentConfirmationSteps,
      paymentAuthorizeSteps
    } = purchase
    if (!inProgress || donePurchase) return null

    const confirmPercent = []

    const items = cart.items.filter(item => item.type === "appointment")

    if (!items.length) return null

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]

      if (!paymentConfirmationSteps[item.id]) {
        confirmPercent[i] = 0

        continue
      }

      const progress = paymentConfirmationSteps[item.id]

      if (!progress.current || !progress.current) {
        confirmPercent[i] = 0

        continue
      }

      confirmPercent[i] = (progress.current / progress.steps) * 100
    }

    let confirmWidth = 0
    let purchaseWidth = 0

    /* Get average percentage value then halve it as confirmation represents 50%
       of total progress bar width */
    for (let i = 0; i < confirmPercent.length; i += 1) {
      confirmWidth += confirmPercent[i]
    }

    confirmWidth = confirmWidth / confirmPercent.length / 2

    if (paymentAuthorizeSteps.current) purchaseWidth = (50 / paymentAuthorizeSteps.steps) * paymentAuthorizeSteps.current
    if (purchase.confirmedIsPurchased) purchaseWidth = 50

    const fullWidth = Math.round(confirmWidth) + Math.round(purchaseWidth)

    return (
      <div style={{ margin: 0, position: "relative", width: "100%" }}>
        <Animation height={500} />
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 0,
            right: 0,
            padding: 10
          }}
        >
          <LoadingText>
            {purchaseWidth > 1 ? "Processing" : "Confirming Booking"}
          </LoadingText>
          <LoadingLine style={{ width: `${fullWidth}%` }} />
          <p style={{ textAlign: "center", color: "#ccc", fontSize: 11 }}>
            {fullWidth}
            %
          </p>
        </div>
      </div>
    )
  }

  changePhoneNumber = e => {
    e.preventDefault()
    const { phoneNumberUpdate } = this.props
    phoneNumberUpdate(e.target.value)
  }

  processingPurchase = () => {
    const { purchase } = this.props
    return purchase.inProgress
  }

  showLoading = () => this.processingPurchase()

  renderLoggedOutPanel = () => {
    const { loggedOutShowSignup, toggleRegistration, location } = this.props
    if (loggedOutShowSignup) {
      return (
        <div style={{ padding: 15 }}>
          <div style={{ padding: "0px 5px", marginTop: 20 }}>
            <RegistrationContainer
              noWrap
              toggleRegistration={toggleRegistration}
            />
          </div>
        </div>
      )
    }

    return (
      <div style={{ padding: 15 }}>
        <div style={{ padding: "0px 5px", marginTop: 20 }}>
          <LoginContainer
            location={location}
            noWrap
            toggleRegistration={toggleRegistration}
          />
        </div>
      </div>
    )
  }

  closePatchTestingRequired = () => {
    this.setState({ showPatchRequiredItems: false })
  }

  confirmPatchTestingByUser = () => {
    this.setState({ patchTestRequiredConfirmedByUser: true }, () => {
      this.makePurchase()
    })
  }

  renderEmptyCart() {
    const { navToPrevious, navToHome, isMobile } = this.props
    return (
      <WrapContainer loginRequired={false}>
        <StyleErrorDiv>{this.renderError()}</StyleErrorDiv>
        <div style={{ position: "relative" }}>
          <BackLink
            href="/"
            onClick={e => {
              e.preventDefault()
              navToPrevious()
            }}
            style={{ top: isMobile ? 20 : 0, left: isMobile ? 20 : -60 }}
          />
          <div style={{ padding: 40, textAlign: "center" }}>
            You have no items in your cart
          </div>
          <div style={{ textAlign: "center" }}>
            <SubmitInput
              onClick={() => {
                navToHome()
              }}
              value="Begin shopping"
            />
          </div>
        </div>
      </WrapContainer>
    )
  }

  toggleReadBookingTerms = () => {
    const { readBookingTerms } = this.state
    this.setState({ readBookingTerms: !readBookingTerms })
  }

  renderBookingTerms() {
    const {
      navToCustomerTerms
    } = this.props

    return (
      <div style={{ paddingLeft: 4, paddingTop: 4 }}>
        I have read the
        {" "}
        <ThemedLink onClick={navToCustomerTerms} href="/terms/customer">booking terms</ThemedLink>
        {" "}
        and understand the cancellation policy.

        <div style={{ marginTop: 10 }}>For any upcoming appointments I give consent for the salon to contact me to about patch testing and an appointment deposit if required.</div>

      </div>
    )
  }

  render() {
    const {
      cart,
      isLarge,
      loggedIn,
      selectedPurchaseMethod,
      navToPrevious,
      paymentMethods,
      requiredPatchTestItems,
    } = this.props

    /* No items in cart so no need to proceed any further */
    if (
      typeof cart === "undefined"
      || typeof cart.items === "undefined"
      || !cart.items.length
    ) {
      return this.renderEmptyCart()
    }

    /* See: calculateCheckoutPrice */
    const bookingPrice = {
      defaultPrice: cart.total,
      basePrice: cart.total,
      partPayPrice: cart.total,
      buyNowPrice: cart.total,
      paymentRequired: true,
      currency: cart.currency
    }
    const {
      showPatchRequiredItems,
      patchTestRequiredConfirmedByUser,
      readBookingTerms
    } = this.state

    const isMobile = !isLarge

    return (
      <WrapContainer loginRequired={false}>

        <div
          style={{
            position: "relative",
            maxWidth: 980,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            height: 0
          }}
        >
          {!this.showLoading() && (
          <BackLink
            href="/"
            onClick={e => {
              e.preventDefault()
              navToPrevious()
            }}
            style={{ top: 20, left: isMobile ? 20 : -60 }}
          />
          )}
        </div>

        {!patchTestRequiredConfirmedByUser
          && showPatchRequiredItems
          && <PatchTestingAlert onConfirm={this.confirmPatchTestingByUser} onClose={this.closePatchTestingRequired} isMobile={isMobile} requiredPatchTestItems={requiredPatchTestItems} />}

        <LoggedOutDiv>
          {!this.processingPurchase() && (
            <div style={{ flex: 1, padding: 0 }}>
              {!loggedIn && this.renderLoggedOutPanel()}
            </div>
          )}
        </LoggedOutDiv>


        <StyleErrorDiv>
          {this.renderError()}
        </StyleErrorDiv>

        {(!paymentMethods || paymentMethods.length === 0)
          ? this.renderNoPaymentMethodError()
          : (
            <div
              style={{
                position: "relative",
                maxWidth: 980,
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: isMobile ? 0 : 20,
                display: (this.showLoading()) ? "none" : "block"
              }}
            >
              {!loggedIn && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    top: 0,
                    right: 0,
                    backgroundColor: "rgb(255, 255, 255, 0.7)"
                  }}
                />
              )}

              <div
                style={{
                  justifyContent: "center",
                  display: isMobile ? "block" : "grid",
                  gridGap: "20px",
                  gridTemplateRows: isMobile ? "repeat(2, auto)" : "none",
                  gridTemplateColumns: isMobile
                    ? "none"
                    : "repeat(2, calc(50% - 10px))"
                }}
              >
                <div>
                  <BookingDetailsContainer />
                </div>
                <div>
                  <PaymentContainer
                    bookingPrice={bookingPrice}
                    paymentRequired={bookingPrice.paymentRequired}
                    purchaseMethod={selectedPurchaseMethod}
                  />

                  <div style={{ marginTop: 10, padding: "0px 6px 0px 10px" }}>
                    <FlossieCheckBox
                      selected={readBookingTerms}
                      onClick={this.toggleReadBookingTerms}
                      labelContent={this.renderBookingTerms()}
                      extraStyle={{ fontSize: 14 }}
                      buttonStyle={{ alignItems: "top" }}
                    />
                  </div>

                  <PurchaseButton
                    disabled={!readBookingTerms}
                    makePurchase={this.makePurchase}
                    btnText={this.renderPaymentBtnText(
                      cart,
                      selectedPurchaseMethod
                    )}
                  />
                </div>
              </div>
            </div>
          )
        }
        {this.renderProgress()}
      </WrapContainer>
    )
  }
}

PurchaseContainer.propTypes = {
  paymentConfirm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  navToPurchase: PropTypes.func.isRequired,
  navToOrderConfirmation: PropTypes.func.isRequired,
  paymentCancel: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  purchase: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  selectedPaymentMethodOption: PropTypes.string,
  cardName: PropTypes.string,
  cardCvc: PropTypes.string,
  cardNumber: PropTypes.string,
  cardExpiry: PropTypes.string,
  paymentMethods: PropTypes.array,
  selectedPaymentMethod: PropTypes.object,
  requiredPatchTestItems: PropTypes.array,
  clearPurchaseError: PropTypes.func,
  navToCustomerTerms: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  selectedPurchaseMethod: PropTypes.string,
  navToPrevious: PropTypes.func,
  navToHome: PropTypes.func,
  navToInventory: PropTypes.func,
  phoneNumberUpdate: PropTypes.func,
  loggedOutShowSignup: PropTypes.bool,
  toggleRegistration: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  location: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    isLarge: state.browser.greaterThan.large,
    loggedIn: !!state.profile.profile.id,
    isVip: !!state.profile.profile.vip,
    profile: state.profile.profile,


    noAvailabilityResults: state.inventoryView.noAvailabilityResults,

    paymentMethods: state.cart.cart.payment_methods,

    cart: state.cart.cart,
    requiredPatchTestItems: getRequiredPatchTestItems(state),

    purchase: state.purchase,
    salonNote: state.purchase.salonNote,
    flossieDollarsBalance: parseInt(state.profile.profile.total_balance, 10),
    useFlossieDollars: state.purchase.useFlossieDollars,
    loggedOutShowSignup: state.purchase.loggedOutShowSignup,
    cardNumber: state.purchase.cardNumber,
    cardExpiry: state.purchase.cardExpiry,
    cardCvc: state.purchase.cardCvc,
    cardName: state.purchase.cardName,

    selectedPaymentMethod: state.purchase.selectedPaymentMethod,
    selectedPurchaseMethod: state.purchase.selectedPurchaseBtn
  }
}

const mapDispatchToProps = dispatch => ({
  paymentConfirm: (...args) => dispatch(paymentConfirm(...args)),
  navToPurchase: () => dispatch(navToPurchase(true)),
  navToOrderConfirmation: id => dispatch(navToOrderConfirmation(id)),
  navToPrevious: () => dispatch(goBack()),
  navToHome: () => dispatch(push("/")),
  removeFromCart: identifier => dispatch(removeFromCart(identifier)),
  paymentCancel: () => dispatch(paymentCancel()),
  paymentToken: token => dispatch(paymentToken(token)),
  toggleFlossieDollars: () => dispatch(toggleFlossieDollars()),

  toggleRegistration: () => dispatch(toggleRegistration()),
  clearPurchaseError: () => dispatch(clearPurchaseError()),
  navToInventory: (slug) => dispatch(navToInventory({}, { id: slug }, true)),

  // selectAvailability: id => dispatch(selectAvailability(id)),
  handleSalonNoteChange: note => dispatch(handleSalonNoteChange(note)),
  navToCustomerTerms: e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(push("/terms/customer"))
  }
})

const {
  STRIPE_ENABLED
} = SITE_CONFIG

const InjectedPurchaseContainer = (props) => {

  if (STRIPE_ENABLED) {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <PurchaseContainer {...props} elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    )
  }

  return (<PurchaseContainer {...props}  />)
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectedPurchaseContainer)
