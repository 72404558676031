import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

import Animation from "../../components/Animation"
import MetaSetter from "../../components/MetaSetter"

import {
  submitLogin,
  submitFacebookLogin,
  updateEmail,
  updatePassword,
  clickedFacebookLogin
} from "./actions"
import { storeLastLocation } from "../../containers/actions"
import NavBarContainer from "../NavBar/NavBarContainer"
import WrapContainer from "../WrapContainer"

import {
  TextInput,
  PasswordInput,
  SubmitInput
} from "../../components/forms/Inputs"
import {
  SpanLinkPlain,
  SpanLinkFacebook
} from "../../components/nav/Links"

import ErrorBar from "../../components/ErrorBar"
import GoToButton from "../../components/button/GoToButton"
import { FacebookIcon } from "../../components/icons"

const { FACEBOOK_ENABLED, GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

class LoginContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { fbLoggingIn: false, googleRecaptchaToken: false }
  }

  updateEmail = e => {
    e.preventDefault()
    const { updateEmail } = this.props
    updateEmail(e.target.value)
  }

  updatePassword = e => {
    e.preventDefault()
    const { updatePassword } = this.props
    updatePassword(e.target.value)
  }

  submitLogin = e => {
    e.preventDefault()
    const { submitLogin, login, noWrap } = this.props
    const { googleRecaptchaToken } = this.state

    submitLogin(
      login.email,
      login.password,
      googleRecaptchaToken,
      noWrap
    )
  }

  navToForgotPassword = e => {
    e.preventDefault()
    const { navToForgotPassword } = this.props
    navToForgotPassword()
  }

  navToRegister = e => {
    e.preventDefault()
    const { toggleRegistration, navToRegister } = this.props
    if (toggleRegistration) {
      toggleRegistration()
    } else navToRegister()
  }

  fbComponentClicked = () => {
    this.setState({ fbLoggingIn: true })
    FB.login(response => {
      // console.log("fb clicked", response) // TODO, remove console
      const { submitFacebookLogin, noWrap } = this.props
      const { googleRecaptchaToken } = this.state
      if (response.authResponse) {
        submitFacebookLogin(
          response.authResponse.accessToken,
          googleRecaptchaToken,
          noWrap,
          false
        )
      } else {
        this.setState({ fbLoggingIn: false })
        // console.log("User cancelled login or did not fully authorize.")
      }
    })
  }

  handleReCaptchaVerify = (token) => {
    this.setState({ googleRecaptchaToken: token })
  }

  loginForm() {
    const { login, isMobile } = this.props
    const { hasError } = login

    // Create an event handler so you can call the verification on button click event or form submit

    return (
      <form onSubmit={this.submitLogin}>
        {GOOGLE_RECAPTCHA_SITE_ID && <GoogleReCaptcha onVerify={this.handleReCaptchaVerify} />}
        <div
          style={{
            marginLeft: "auto",
            flex: 1,
            marginRight: "auto",
            maxWidth: "600px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column-reverse" : "row",
              textAlign: isMobile ? "center" : "left",
              marginBottom: "20px"
            }}
          >
            <span
              style={{
                flex: 1,
                fontWeight: 700,
                fontSize: "22px",
                margin: 0,
                marginTop: isMobile ? "20px" : "0px"
              }}
            >
              Log In
            </span>
            <GoToButton
              variant="secondary"
              style={{ marginBottom: isMobile ? "20px" : "0" }}
              aLink="/register"
              onClick={this.navToRegister}
            >
              Need an account? Sign up here
            </GoToButton>
          </div>
          {hasError && <ErrorBar message={login.lastError} />}
          <div style={{ marginBottom: 14, display: "flex" }}>
            <TextInput
              onChange={this.updateEmail}
              value={login.email}
              label="Email Address*"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div style={{ marginBottom: 14, display: "flex" }}>
            <PasswordInput
              onKeyPress={this.passwordKeyPress}
              onChange={this.updatePassword}
              value={login.password}
              label="Password*"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column-reverse" : "row"
            }}
          >
            {FACEBOOK_ENABLED && (
              <React.Fragment>
                <SpanLinkFacebook onClick={this.fbComponentClicked}>
                  <FacebookIcon variant="secondary" fill="#FFF" />
                  Log In with Facebook
                </SpanLinkFacebook>

                {/* If facebook login is enabled, push both buttons to the right */}
                <div style={{ flex: 1 }} />
              </React.Fragment>
            )}

            <SpanLinkPlain
              style={{
                margin: isMobile ? "10px 0px 20px 0px" : "0px 10px 0px 0px"
              }}
              onClick={this.navToForgotPassword}
            >
              Forgot Password?
            </SpanLinkPlain>

            {/* If facebook login is disabled, push both buttons apart */}
            {!FACEBOOK_ENABLED && !isMobile && (<div style={{ flex: 1 }} />)}

            <SubmitInput variant="primary" value="Log In" />
          </div>
        </div>
      </form>
    )
  }

  render() {
    const { noWrap } = this.props
    const { fbLoggingIn } = this.state
    if (fbLoggingIn) return <Animation />
    if (noWrap) return this.loginForm()

    return (
      <WrapContainer loginRequired={false}>
        <MetaSetter metaTitle="Login" />

        <div style={{ marginTop: 50 }}>{this.loginForm()}</div>
      </WrapContainer>
    )
  }
}

LoginContainer.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  submitFacebookLogin: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  navToForgotPassword: PropTypes.func.isRequired,
  navToRegister: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
  toggleRegistration: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  noWrap: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    login: state.login,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = (dispatch, { location }) => ({
  updateEmail: email => dispatch(updateEmail(email)),
  updatePassword: password => dispatch(updatePassword(password)),
  submitLogin: (email, password, googleRecaptchaToken, noRedirect) => dispatch(submitLogin(email, password, googleRecaptchaToken, noRedirect)),
  submitFacebookLogin: (token, googleRecaptchaToken, noWrap, newsletter) => dispatch(submitFacebookLogin(token, googleRecaptchaToken, noWrap, newsletter)),
  clickedFacebookLogin: () => dispatch(clickedFacebookLogin()),
  navToForgotPassword: () => dispatch(push("/forgot-password")),
  navToRegister: () => dispatch(push("/register")),
  storeLastLocation: () => dispatch(storeLastLocation(location)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer)
