import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledP = styled.p`
  font-weight: 100;
  font-size: 16px;
  margin: 0;
`

export const ListDuration = ({ duration, style }) => (
  <StyledP style={style}>
    {duration}
    {" "}
    Minutes
  </StyledP>
)

ListDuration.propTypes = {
  duration: PropTypes.string.isRequired,
  style: PropTypes.object
}
