import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ThemedLink = styled.a`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    svg {
        fill: theme.colors.primary;
    }
`

const Link = ({ children, ...rest }) => (
  <ThemedLink {...rest}>
    {children}
  </ThemedLink>
)

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
}
export default Link
