import React from "react"
import PropTypes from "prop-types"

import { thumborize } from "../images/thumborize"
import { PromoTag } from "../SaleTags"
import Pricing from "../Pricing"

const EmployeeCard = ({
  employee, pricing, isMobile, currency, userIsVip, hidePrice, hideTag, company
}) => {
  const getProfileImage = () => {
    const image = employee.images.find(i => i.placement === "profile" && i.position === "0")
    return image && thumborize(encodeURIComponent(image.url), 240, 240)
  }

  const getCompanyLogo = () => {
    const image = company && company.images && company.images.find(i => i.placement === "logo")
    return image && thumborize(encodeURIComponent(image.url), 240, 240)
  }

  const prices = (pricing && !hidePrice) ? { default: pricing.find(p => !p.employee_id), lowest: pricing.find(p => employee.id === p.employee_id) } : false

  return (
    <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
      <div style={{ paddingRight: 12 }}>
        <img
          src={getProfileImage() || getCompanyLogo()}
          alt=""
          style={{
            width: 45,
            height: 45,
            borderRadius: 30,
            display: "block"
          }}
        />
      </div>
      <div style={{ flex: 2, textAlign: "left" }}>
        <span style={{ display: "block", fontSize: isMobile ? 15 : 17, fontWeight: 500 }}>
          {employee.first_name}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            fontWeight: 400
          }}
        >
          {employee.role}
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "flex-end", paddingRight: "1em" }}>
        {!hideTag && prices && prices.lowest && prices.lowest.sale
          && prices.lowest.price_original && (
            <div
              style={{ textAlign: "right", marginBottom: 12, marginLeft: 4 }}
            >
              <PromoTag />
            </div>
        )}
        {!hidePrice && prices && (
          <Pricing
            style={{ marginLeft: 10 }}
            prices={prices}
            currency={currency}
            isMobile={isMobile}
            userIsVip={userIsVip}
            hideTag
          />
        )}
      </div>
    </div>
  )
}

EmployeeCard.propTypes = {
  employee: PropTypes.object.isRequired,
  pricing: PropTypes.array,
  isMobile: PropTypes.bool,
  currency: PropTypes.object.isRequired,
  userIsVip: PropTypes.bool,
  hidePrice: PropTypes.bool,
  company: PropTypes.object,
  hideTag: PropTypes.bool
}

export default EmployeeCard
