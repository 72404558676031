import React from "react"
import PropTypes from "prop-types"

import { iCalBookingLink } from "../../api/api"
import { SubmitInput } from "../forms/Inputs"
import Button from "../button"

const createBookingLink = (bookingId, orderId, credentials) => {
  return iCalBookingLink(credentials, orderId ? { order_id: orderId } : { id: bookingId })
}

const openIcal = (bookingId, orderId, credentials) => {
  window.location = createBookingLink(bookingId, orderId, credentials)
}

export const AddToCalendarButton = ({ bookingId, orderId, credentials }) => (
  <SubmitInput
    variant="primary"
    onClick={() => openIcal(bookingId, orderId, credentials)}
    value="Add To Calendar"
  />
)

export const AddToCalendarLink = ({ bookingId, orderId, credentials }) => {
  const handleCalendarClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    openIcal(bookingId, orderId, credentials)
  }

  return <Button variant="primary" onClick={handleCalendarClick}>Add to calendar</Button>
}


const propTypes = {
  orderId: PropTypes.string,
  bookingId: PropTypes.string,
  credentials: PropTypes.object.isRequired,
}

AddToCalendarButton.propTypes = propTypes
AddToCalendarLink.propTypes = propTypes
