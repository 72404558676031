export const initialState = {
  email: "",
  hasError: false,
  lastError: "",
  lastErrorCode: null,
  passwordIsReset: false,
  passwordUpdated: false,
  resetPasswordToken: "",
  password: "",
  passwordAgain: ""
}

export default function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBMIT_RESET_PASSWORD":
      return {
        ...state,
        hasError: false
      }
    case "RESET_PASSWORD_EMAIL_UPDATE":
      return {
        ...state,
        hasError: false,
        email: action.payload.email
      }
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        hasError: false,
        passwordIsReset: true
      }
    case "UPDATE_PASSWORD_SUCCESS":
      return {
        ...state,
        hasError: false,
        passwordUpdated: true,
        password: "",
        passwordAgain: "",
        resetPasswordToken: ""
      }
    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        hasError: true,
        passwordIsReset: false,
        lastError: action.payload.error_response.response.data.message
      }
    case "UPDATE_PASSWORD_FAILURE":
      return {
        ...state,
        hasError: true,
        passwordUpdated: false,
        lastError: action.payload.error_response.response.data.message,
        lastErrorCode: action.payload.error_response.response.status
      }
    case "RESET_PASSWORD_TOKEN":
      return {
        ...state,
        resetPasswordToken: action.payload
      }
    case "RESET_PASSWORD_VALUE":
      return {
        ...state,
        password: action.payload
      }
    case "RESET_PASSWORD_AGAIN_VALUE":
      return {
        ...state,
        passwordAgain: action.payload
      }
    case "RESET_FORM_PROPS":
      return {
        password: "",
        passwordAgain: "",
        hasError: false,
        passwordUpdated: false,
        lastError: "",
        lastErrorCode: null
      }
    case "RESET_PASSWORD_STATE":
      return {
        ...initialState
      }
    default:
      return state
  }
}
