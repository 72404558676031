import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  TextInput,
  PasswordInput,
  PhoneNumberInput,
  SubmitInput,
  InputLabelWrapper
} from "../../components/forms/Inputs"

import {
  updateUsername,
  updatePassword,
  updateCurrentPassword,
  updateFirstName,
  updateLastName,
  updatePhone,
  initProfileForm,
  initAuthenticationForm,
  saveProfile,
  saveAuthentication
} from "./actions"

import formValidator from "../../../utils/formValidator"

const StyledErrorDiv = styled.div`
  background-color: #e2e2e2;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px 0px;
`

class ProfileFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { initProfileForm, initAuthenticationForm } = this.props
    initProfileForm()
    initAuthenticationForm()
  }

  updateFieldValues = (value, type, objectLabel, formFeedbackLabel) => {
    if (!type || !value) return null
    const error = formValidator(value, type, formFeedbackLabel)

    // if there is an error, add it to the state.
    if (error) {
      const { errors } = this.state
      this.setState({
        ...errors,
        [`${objectLabel || type}`]: formValidator(
          value,
          type,
          formFeedbackLabel
        )
      })
    } else {
      // otherwise there is no error, delete the object from the state.
      const { state } = this
      delete state[`${objectLabel || type}`]
    }
  }

  updateUsername = e => {
    e.preventDefault()
    this.updateFieldValues(e.target.value, "email")
    const { updateUsername } = this.props
    updateUsername(e.target.value)
  }

  updateFirstName = e => {
    e.preventDefault()
    this.updateFieldValues(e.target.value, "name", "firstName", "First Name")
    const { updateFirstName } = this.props
    updateFirstName(e.target.value)
  }

  updateLastName = e => {
    e.preventDefault()
    this.updateFieldValues(e.target.value, "name", "lastName", "Last Name")
    const { updateLastName } = this.props
    updateLastName(e.target.value)
  }

  updatePhone = value => {
    const { updatePhone } = this.props
    updatePhone(value)
  }

  updatePassword = e => {
    e.preventDefault()
    const { updatePassword } = this.props
    updatePassword(e.target.value)
  }

  updateCurrentPassword = e => {
    e.preventDefault()
    const { updateCurrentPassword } = this.props
    updateCurrentPassword(e.target.value)
  }

  hasError = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) return false
    }
    return true
  }

  renderSaved = () => {
    return (
      <StyledErrorDiv>
        <p style={{ margin: 0 }}>Profile Updated!</p>
      </StyledErrorDiv>
    )
  }

  renderSaveFailed = () => {
    const {
      error
    } = this.props

    return (
      <StyledErrorDiv>
        <p style={{ margin: 0 }}>
          {error.length ? error : "Something went wrong! Please try again later."}
        </p>
      </StyledErrorDiv>
    )
  }

  renderErrorMessages() {
    const { state } = this
    return (
      <StyledErrorDiv>
        {Object.keys(this.state).map((key, i) => (
          <p key={i} style={{ margin: 0 }} value={key}>
            {state[key]}
          </p>
        ))}
      </StyledErrorDiv>
    )
  }

  renderSaving = () => {
    return (
      <div>
        <p>Saving ... </p>
      </div>
    )
  }

  renderLoading = () => {
    return (
      <div>
        <p>Loading</p>
      </div>
    )
  }

  render() {
    const { profile, authentication, isMobile } = this.props
    if (!profile.id) return this.renderLoading()

    const inputStyle = isMobile ? { width: "calc(100% - 32px)" } : { width: 217 }
    const {
      saved, saving, failed, saveProfile, saveAuthentication
    } = this.props
    return (
      <div>
        {Object.keys(this.state).length > 0 && this.renderErrorMessages()}
        {saved && this.renderSaved()}
        {saving && this.renderSaving()}
        {failed && this.renderSaveFailed()}

        <div style={{ marginBottom: 50 }}>
          <InputLabelWrapper id="username" label="Email*" isMobile={isMobile}>
            <TextInput
              id="username"
              onChange={this.updateUsername}
              value={authentication.username}
              type="username"
              style={inputStyle}
            />
          </InputLabelWrapper>

          <InputLabelWrapper
            id="password"
            label="New Password"
            isMobile={isMobile}
          >
            <PasswordInput
              id="password"
              onChange={this.updatePassword}
              value={authentication.password}
              style={inputStyle}
            />
          </InputLabelWrapper>

          <InputLabelWrapper
            id="current_password"
            label="Current Password*"
            isMobile={isMobile}
          >
            <PasswordInput
              id="current_password"
              onChange={this.updateCurrentPassword}
              value={authentication.current_password}
              style={inputStyle}
            />
          </InputLabelWrapper>


          <InputLabelWrapper id="save_authentication" label="">
            <SubmitInput
              id="save_authentication"
              value="Update"
              onClick={saveAuthentication}
              disabled={
                !this.hasError(this.state)
              }
            />
          </InputLabelWrapper>
        </div>

        <div>
          <InputLabelWrapper
            id="first_name"
            label="First Name*"
            isMobile={isMobile}
          >
            <TextInput
              id="first_name"
              onChange={this.updateFirstName}
              value={profile.first_name}
              style={inputStyle}
              required
            />
          </InputLabelWrapper>
          <InputLabelWrapper id="last_name" label="Last Name*" isMobile={isMobile}>
            <TextInput
              id="last_name"
              onChange={this.updateLastName}
              value={profile.last_name}
              style={inputStyle}
            />
          </InputLabelWrapper>

          <InputLabelWrapper id="phone" label="Phone*" isMobile={isMobile}>
            <PhoneNumberInput
              label="Phone Number*"
              id="phone-input"
              onChange={this.updatePhone}
              value={profile.phone_number}
            />
          </InputLabelWrapper>

          <InputLabelWrapper id="save_profile" label="">
            <SubmitInput
              id="save_profile"
              value="Save Profile"
              onClick={saveProfile}
              disabled={
                !this.hasError(this.state)
              }
            />
          </InputLabelWrapper>
        </div>
      </div>
    )
  }
}

ProfileFormContainer.propTypes = {
  profile: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
  updateUsername: PropTypes.func.isRequired,
  updateFirstName: PropTypes.func.isRequired,
  updateLastName: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateCurrentPassword: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
  saveAuthentication: PropTypes.func.isRequired,
  initProfileForm: PropTypes.func.isRequired,
  initAuthenticationForm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.string
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profileForm,
    authentication: state.profile.authenticationForm,
    saving: state.profile.saving,
    saved: state.profile.saved,
    failed: state.profile.failed,
    error: state.profile.error,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  updateUsername: val => dispatch(updateUsername(val)),
  updateFirstName: val => dispatch(updateFirstName(val)),
  updateLastName: val => dispatch(updateLastName(val)),
  updatePassword: val => dispatch(updatePassword(val)),
  updateCurrentPassword: val => dispatch(updateCurrentPassword(val)),
  updatePhone: val => dispatch(updatePhone(val)),
  initProfileForm: () => dispatch(initProfileForm()),
  initAuthenticationForm: () => dispatch(initAuthenticationForm()),
  saveProfile: () => dispatch(saveProfile()),
  saveAuthentication: () => dispatch(saveAuthentication())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileFormContainer)
