import "rxjs/add/operator/mergeMap"
import "rxjs/add/operator/map"
import "rxjs/add/operator/catch"
import { Observable } from "rxjs/Observable"

import { bookingCancelled, navToBookings } from "./actions"
import { apiGetUserBookings, apiCancelUserBooking, handleXhrError } from "../../api/api"

const fetchBookingsFulfilled = (response) => ({
  type: "FETCH_BOOKINGS_SUCCESS",
  payload: { response }
})

export const fetchBookingsEpic = (action$, { getState }) => action$.ofType("FETCH_BOOKINGS").mergeMap(action => apiGetUserBookings(
  getState().credentials.credentials,
  getState().bookings.showHistory
)
  .map(response => fetchBookingsFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_BOOKINGS_ERROR")))

export const cancelUserBookingEpic = (action$, { getState }) => action$.ofType("CANCEL_USER_BOOKING").mergeMap(action => apiCancelUserBooking(getState().credentials.credentials, action.payload.bookingId)
  .flatMap(response => {
    const { bookingId, shouldRedirect } = action.payload
    if (shouldRedirect) {
      return Observable.of(bookingCancelled(response, bookingId), navToBookings())
    }
    return Observable.of(bookingCancelled(response, bookingId))
  })
  .catch(error => handleXhrError(error, "CANCEL_USER_BOOKING_ERROR")))
