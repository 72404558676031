import React from "react"
import moment from "moment-timezone"
import ParagraphText from "../../components/ParagraphText"
import { thumborize } from "../../components/images/thumborize"
import { ArticleDate } from "./components"

import {
  TYPE_SLUG_INSPIRATION,
  TYPE_ID_GUIDE,
  TYPE_SLUG_GUIDE
} from "./constants"
import { GoToButton } from "../../components/button"

const renderArticle = (article, index, onClick, isMobile) => {
  const { id, slug, title } = article

  const getImage = images => {
    let image = images.find(i => i.placement === "crop" && i.position === "0")
    if (image) {
      return thumborize(encodeURIComponent(image.url), 0, 512)
    }

    image = images.find(i => i.placement === "main" && i.position === "0")
    return image && thumborize(encodeURIComponent(image.url), 0, 512)
  }

  const image = getImage(article.images)

  const summary = (article && article.summary) || null

  const typeId = (article && article.type_id) || null

  const createdAt = (article && article.created_at) || null

  const typeSlug = typeId === TYPE_ID_GUIDE ? TYPE_SLUG_GUIDE : TYPE_SLUG_INSPIRATION

  const href = `/${typeSlug}/${article.slug}`

  let result = (
    <div
      onClick={() => {
        onClick(slug)
      }}
      role="main"
      key={id}
      style={{
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        paddingBottom: isMobile ? 94 : 54,
        marginBottom: isMobile ? 40 : 0,
        borderBottom: isMobile ? "1px dashed #ef609f" : "none"
      }}
    >
      {image && (
        <div
          style={{
            height: 200,
            width: "100%",
            backgroundSize: "cover",
            backgroundImage: `url("${image}")`,
            borderRadius: isMobile ? 0 : 2
          }}
        />
      )}

      <div style={{ marginTop: 20, padding: isMobile ? "0px 20px" : 0 }}>
        <h1 style={{ margin: "0", fontWeight: 500, fontSize: 20 }}>{title}</h1>
        <ArticleDate style={{ marginTop: 4, marginBottom: 16 }}>
          {moment(createdAt).format("DD/MM/YY")}
        </ArticleDate>
        <ParagraphText
          style={{ color: "#333", marginBottom: 0 }}
          text={summary}
        />
      </div>

      <GoToButton
        style={{
          display: "block",
          position: "absolute",
          bottom: isMobile ? 20 : 0,
          left: isMobile ? 20 : 0
        }}
        aLink={href}
      >
        Read More
      </GoToButton>
    </div>
  )

  // if there is no id, no article to click on, so return null;

  if (!id || !slug) result = null

  return result
}

const Article = ({ articles, onClick, isMobile }) => articles
  && articles.map((article, index) => renderArticle(article, index, onClick, isMobile))

export default Article
