import React from "react"
import PropTypes from "prop-types"
import styled, { css, withTheme } from "styled-components"
import Select from "react-select"

export const VARIANT = { PRIMARY: "primary", SECONDARY: "secondary", TERTIARY: "tertiary" }

/**
 * There 3 types of buttons. primary, secondary and secondary filled.
 *
 * Use prop variant to choose your style
 * variant= 'primary' or 'secondary'.
 * and for secondary buttons use prop filled=true|false.
 *
 * Each of them have their own default and hover over style.
 */
const defaultStyle = css`
  background: ${({ variant, theme, filled }) => {
    let bgColor = theme.colors[variant]
    if (variant === VARIANT.SECONDARY && !filled) {
      bgColor = "transparent"
    }
    return bgColor
  }};
  color: ${({ variant, filled, theme }) => {
    let color = theme.colors[`${variant}Accent`]
    if (variant === VARIANT.SECONDARY && !filled) {
      color = theme.colors[variant]
    }
    return color
  }};
  border: ${({ theme, variant }) => `solid ${theme.button.borderWidth} ${theme.colors[variant]}`};
  border-radius: ${({ theme }) => `${theme.button.borderRadius}`};

  &:disabled {
    background: ${({ theme }) => theme.colors.disabled} !important;
    border: ${({ theme }) => `solid ${theme.button.borderWidth} ${theme.colors.disabled}`};
    cursor: not-allowed;
  }

  svg {
    fill: currentColor;
    width: 14px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: currentColor;
    }
  }

  position: relative;
  outline: none;
  padding: ${({ size }) => (size === "small" ? "10px 10px" : "10px 15px")};
  cursor: pointer;
  font-size: ${({ theme, size }) => (size === "small" ? `${theme.button.fontSizeSmall}` : `${theme.button.fontSizeLarge}`)};
  line-height: ${({ theme, size }) => (size === "small" ? `${theme.button.fontSizeSmall}` : `${theme.button.fontSizeLarge}`)};
  letter-spacing: ${({ theme }) => `${theme.button.letterSpacing}`};
  text-transform: ${({ theme }) => `${theme.button.textTransform}`};
  font-weight: ${({ theme }) => `${theme.button.fontWeight}`};
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: ${({ size }) => (size === "small" ? "0px" : "116px")};
  max-width: 100%;
`

const selectBoxStyle = css`
  ${defaultStyle}
  padding: 0;
  [class*="MenuList"] {
    color: ${({ variant, filled, theme }) => {
    let color = theme.colors[`${variant}Accent`]
    if (variant === VARIANT.SECONDARY && !filled) {
      color = theme.colors[variant]
    }
    return color
  }};
  }
`

const hoverStyle = css`
  background: ${({ variant, theme, filled }) => {
    let bgColor = theme.colors[`${variant}Accent`]
    if (variant === VARIANT.SECONDARY && !filled) {
      bgColor = theme.colors[variant]
    }
    if (variant === VARIANT.PRIMARY && !filled) {
      bgColor = theme.colors[variant]
    }
    return bgColor
  }};
  color: ${({ variant, filled, theme }) => {
    let color = theme.colors[variant]
    if (variant === VARIANT.SECONDARY && !filled) {
      color = theme.colors[`${variant}Accent`]
    }
    if (variant === VARIANT.PRIMARY && !filled) {
      color = theme.colors[`${variant}Accent`]
    }
    return color
  }};
`

export const ThemedButton = styled.button`
  ${defaultStyle}
  &:enabled:hover {
    ${hoverStyle}
  }

  /* Buttons with icons embed the content inside another container */
  &:enabled:hover div {
    ${hoverStyle}
  }

  &:enabled:hover svg {
    fill: ${({ variant, theme }) => {
    return theme.colors[`${variant}Accent`]
  }};

    path {
      fill: ${({ variant, theme }) => {
    return theme.colors[`${variant}Accent`]
  }};
    }
  }
`

export const ThemedButtonLink = styled.a`
  ${defaultStyle}
  &:hover {
    ${hoverStyle}
  }
`

const ThemedSelect = styled(Select)`
  ${selectBoxStyle}
  &:hover {
    ${hoverStyle}
  }
  [class*="-option"] {
    color: ${({ variant, theme }) => {
    return theme.colors[variant]
  }};
    background-color: inherit;
    :hover {
      background: ${({ variant, theme }) => {
    return theme.colors[variant]
  }};
      color: ${({ variant, theme }) => {
    return theme.colors[`${variant}Accent`]
  }};
    }
  }
`

export const ThemedSelectButton = withTheme(({
  minWidth, menuWidth, theme, variant, filled, ...rest
}) => {
  const getSelectedOptionStyle = () => {
    const background = theme.colors[variant]
    const color = theme.colors[`${variant}Accent`]
    return { background: `${background} !important`, color: `${color} !important` }
  }

  return (
    <ThemedSelect
      {...rest}
      variant={variant}
      filled={filled}
      styles={{
        control: () => ({
          minWidth,
          outline: "none",
          boxShadow: "none !important",
          ":hover": {
            cursor: "pointer",
          }
        }),
        singleValue: styles => ({
          ...styles,
          whiteSpace: "normal",
          position: "relative",
          top: 0
        }),
        placeholder: () => ({
          padding: "10px 15px",
          color: "inherit",
        }),
        valueContainer: styles => ({
          ...styles,
          color: "inherit",
          justifyContent: "center",
          padding: 0,
          overflow: "visible",
        }),
        indicatorsContainer: styles => ({
          ...styles,
          color: "inherit",
          display: "none"
        }),
        menu: (styles) => ({
          ...styles,
          width: menuWidth,
          marginLeft: "-40px"
        }),
        option: (styles, { isSelected }) => ({
          ...styles,
          ...(isSelected ? getSelectedOptionStyle() : {}),
        })
      }}
    />
  )
})

ThemedSelectButton.defaultProps = {
  minWidth: "150px",
  menuWidth: "380px",
  size: "default",
}

ThemedSelectButton.propTypes = {
  minWidth: PropTypes.string,
  menuWidth: PropTypes.string,
  size: PropTypes.oneOf(["small", "default"]),
}
