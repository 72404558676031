import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { RoundImage } from "../../components/images/RoundImage"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { ListTopHeading, AddToCalendarLink, RebookLink } from "../../components/datarenderers"
import buttonize from "../../components/buttonize"
import { thumborize } from "../../components/images/thumborize"
import { Phone } from "../../components/datarenderers/Phone"
import CancelBookingLink from "./CancelBookingLink"
import IconDataRow from "../../components/datarenderers/IconDataRow"

import { CalendarIcon, LocationIcon } from "../../components/icons"

export const Booking = ({
  onClick,
  id,
  service,
  company,
  appointmentTime,
  duration,
  isMobile,
  onCancel,
  credentials,
  canRepurchase,
  appointmentSynced,
  onRebook,
}) => (
  <div
    {...buttonize(onClick, {
      display: "flex",
      alignItems: isMobile ? "flex-start" : "center",
      borderBottom: "1px dashed #e2e2e2",
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 16,
      color: "#575757"
    })}
    key={`booking${id}`}
  >
    <div style={{ paddingRight: isMobile ? 10 : 20 }}>
      {company.images && company.images.find(i => i.placement === "logo") && (
        <RoundImage
          src={thumborize(
            company.images.find(i => i.placement === "logo").url,
            isMobile ? 64 * 4 : 90 * 4,
            isMobile ? 64 * 4 : 90 * 4
          )}
          size={isMobile ? 64 : 90}
        />
      )}
    </div>
    <div style={{ flex: 1 }}>
      <ListTopHeading isMobile={isMobile}>{service.description.name}</ListTopHeading>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          marginTop: 10
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <IconDataRow icon={<LocationIcon />}>
            <div>
              <span style={{ display: "block", fontWeight: 700, paddingLeft: 4 }}>
                {company.name}
              </span>
              <span style={{ display: "block", fontWeight: 300, paddingLeft: 4 }}>
                <CompanyAddressBuilder
                  company={company}
                  addressOne
                  addressTwo
                  district
                  inline
                />
                <CompanyAddressBuilder
                  company={company}
                  region
                  postCode
                  inline
                />
              </span>
            </div>
          </IconDataRow>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop: isMobile ? 20 : 0
          }}
        >
          <IconDataRow icon={<CalendarIcon />}>
            <div>
              <span style={{ fontWeight: 700, padding: 4 }}>
                {moment(appointmentTime).tz(company.timezone).format("ddd D MMM h:mma")}
              </span>
              <br />
              <span style={{ fontWeight: 300, padding: 4 }}>
                {duration}
                {" "}
                Minutes
              </span>
            </div>
          </IconDataRow>
        </div>

        <div style={{
          display: "flex", flexDirection: "column", paddingTop: isMobile ? 20 : 0, paddingLeft: isMobile ? 0 : 20,
        }}
        >
          <Phone number={company.phone_number} />

          <CancelBookingLink
            style={{ marginTop: isMobile ? 6 : 0 }}
            onCancel={onCancel}
            bookingId={id}
            appointmentTime={appointmentTime}
            appointmentSynced={appointmentSynced}
            company={company}
          />

          <div style={{ marginTop: "20px" }}>
            {moment(appointmentTime).isAfter() && (<AddToCalendarLink credentials={credentials} bookingId={id} />)}
            {moment(appointmentTime).isBefore() && (<RebookLink onRebook={onRebook} />)}
          </div>
        </div>
      </div>
    </div>
  </div>
)

Booking.propTypes = {
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  appointmentTime: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  credentials: PropTypes.object.isRequired,
  canRepurchase: PropTypes.string,
  appointmentSynced: PropTypes.bool.isRequired,
  onRebook: PropTypes.func.isRequired,
}
