import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const commonProps = {
  style: PropTypes.object,
  align: PropTypes.string
}

const TagLabel = styled.span`
  display: inline-block;
  vertical-align: top;
  background-color: ${props => (props.inactive ? props.theme.colors.disabled : props.theme.colors.primary)};
  color: #fff;
  height: 12px;
  line-height: 12px;
  padding: ${props => (props.align === "right" ? "1px 0px 1px 4px" : "1px 4px 1px 0px")};
  font-size: 10px;
  font-weight: 500;
  min-width: 44px;
  text-align: center;
`

const TagArrowLeft = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 8px solid ${props => (props.inactive ? props.theme.colors.disabled : props.theme.colors.primary)};
  border-bottom: 7px solid transparent;
`

const TagArrowRight = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 8px solid ${props => (props.inactive ? props.theme.colors.disabled : props.theme.colors.primary)};
  border-bottom: 7px solid transparent;
`

const TriedAndTestedLabel = styled(TagLabel)`
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  padding: 0 6px;
`

const TriedAndTestedArrowLeft = styled(TagArrowLeft)`
  border-top: 10px solid ${props => (props.inactive ? " #868686" : "#ef609f")};
  border-right: none;
  border-left: 9px solid transparent;
  border-bottom: 10px solid
    ${props => (props.inactive ? " #868686" : "#ef609f")};
`

const TriedAndTestedArrowRight = styled(TagArrowRight)`
  border-top: 10px solid ${props => (props.inactive ? " #868686" : "#ef609f")};
  border-right: 9px solid transparent;
  border-left: none;
  border-bottom: 10px solid
    ${props => (props.inactive ? " #868686" : "#ef609f")};
`

// TODO: can we remove this? its not being used anywhere
export const TriedAndTestedTag = ({ style, align = "right" }) => (
  <div style={style}>
    {align === "left" && <TriedAndTestedArrowLeft />}
    <TriedAndTestedLabel align={align}>TRIED &amp; TESTED</TriedAndTestedLabel>
    {align === "right" && <TriedAndTestedArrowRight />}
  </div>
)

TriedAndTestedTag.propTypes = {
  ...commonProps
}

export const VipTag = ({ style, align = "right" }) => (
  <div style={style}>
    {align === "left" && <TagArrowLeft />}
    <TagLabel align={align}>VIP</TagLabel>
    {align === "right" && <TagArrowRight />}
  </div>
)

VipTag.propTypes = {
  ...commonProps
}

export const PromoTag = ({ style, align = "right" }) => (
  <div style={style}>
    {align === "left" && <TagArrowLeft />}
    <TagLabel align={align}>OFFER</TagLabel>
    {align === "right" && <TagArrowRight />}
  </div>
)

PromoTag.propTypes = {
  ...commonProps
}

export const InactiveVipTag = ({ style, price, align = "right" }) => (
  <div style={style}>
    {align === "left" && <TagArrowLeft inactive />}
    <TagLabel align={align} inactive>
      VIP
      {" "}
      {price}
    </TagLabel>
    {align === "right" && <TagArrowRight inactive />}
  </div>
)

InactiveVipTag.propTypes = {
  ...commonProps,
  price: PropTypes.string
}
