import { apiSaveProfile, handleXhrError } from "../../api/api"

const profileSaveFulfilled = (response, action) => ({
  type: "SAVE_PROFILE_SUCCESS",
  payload: { response, action }
})

export const profileSaveEpic = (action$, { getState }) => action$.ofType("SAVE_PROFILE").mergeMap(action => apiSaveProfile(
  getState().credentials.credentials,
  getState().profile.profileForm
)
  .map(response => profileSaveFulfilled(response, action))
  .catch(error => handleXhrError(error, "SAVE_PROFILE_FAILURE")))

const authenticationSaveFulfilled = (response, action) => ({
  type: "SAVE_AUTHENTICATION_SUCCESS",
  payload: { response, action }
})

export const authenticationSaveEpic = (action$, { getState }) => action$.ofType("SAVE_AUTHENTICATION").mergeMap(action => apiSaveProfile(
  getState().credentials.credentials,
  getState().profile.authenticationForm
)
  .map(response => authenticationSaveFulfilled(response, action))
  .catch(error => handleXhrError(error, "SAVE_AUTHENTICATION_FAILURE")))
