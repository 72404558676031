import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "./Header"
import FaqsContentHoc from "./FaqsContentHoc"
import SearchInput from "./SearchInput"
import { QuestionLink } from "./FaqLink"
import ContainerWithSideBar from "./ContainerWithSideBar"

const Title = styled.h1`
  text-align: center;
  flex: 1;
`

const FaqSearchResults = ({
  searchResults, faqTopics, navToPrevious, match, navigateTo, searchFaqs, isMobile,
}) => {
  React.useEffect(() => {
    if (faqTopics) {
      const { query } = match.params
      if (query && query.trim() !== "") {
        searchFaqs(query)
      } else {
        navigateTo("/help")
      }
    }
  }, [faqTopics])
  return (
    <div>
      <Header navToPrevious={navToPrevious}>
        <Title>Search Results</Title>
      </Header>
      <SearchInput navigateTo={navigateTo} match={match} />
      <ContainerWithSideBar isMobile={isMobile} topics={faqTopics} navigateTo={navigateTo}>
        <div>
          {searchResults && <span>{`${searchResults.length} Questions`}</span>}
          {searchResults && searchResults.map(faq => <QuestionLink key={faq.slug} navigateTo={navigateTo} topic={faqTopics.find(t => t.faqs.find(f => f.id === faq.id))} faq={faq} size="h4" />)}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

FaqSearchResults.propTypes = {
  faqTopics: PropTypes.array,
  navToPrevious: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  navigateTo: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  searchFaqs: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default FaqsContentHoc(FaqSearchResults)
