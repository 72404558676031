import { push } from "connected-react-router"

const loggedInActions = [
  "SUBMIT_LOGIN_FORM_SUCCESS",
  "REGISTRATION_SUCCESS"
  // 'AUTO_LOGIN_SUCCESS',
]

// insert the new dispatched actions after, by calling next() first
export default ({ dispatch, getState }) => next => action => {
  const returnValue = next(action)
  if (action.type) {
    if (loggedInActions.includes(action.type)) {
      // dispatch(fetchBookings()) // fetch profile?
      const { lastLocation } = getState().login
      if (!action.payload.noRedirect) {
        if (lastLocation) {
          dispatch({ type: "CLEAR_LAST_LOCATION" })
          dispatch(push(lastLocation))
        } else {
          dispatch(push("/"))
        }
      }
    }
    if (action.type === "LOG_OUT") {
      dispatch(push("/"))
    }
    if (action.type === "LOGIN_REQUIRED") {
      dispatch(push("/login"))
    }
  }
  return returnValue
}
