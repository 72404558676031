import {
  apiGetCategories,
  apiGetRegions,
  apiDistrictSearch,
  apiCompanySearch,
  handleXhrError
} from "../../api/api"

import { getCompanyFilterFromState } from "./lib"

const fetchCatgoriesFulfilled = (response, action) => ({
  type: "FETCH_CATEGORIES_SUCCESS",
  payload: { response, action }
})

export const fetchCategoriesEpic = action$ => action$.ofType("FETCH_CATEGORIES").mergeMap(action => apiGetCategories()
  .map(response => fetchCatgoriesFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_CATEGORIES_FAILURE")))

const fetchRegionsFulfilled = (response, action) => ({
  type: "FETCH_REGIONS_SUCCESS",
  payload: { response, action }
})

export const fetchRegionsEpic = action$ => action$.ofType("FETCH_REGIONS").mergeMap(action => apiGetRegions()
  .map(response => fetchRegionsFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_REGIONS_FAILURE")))

const companySearchFulfilled = (response, action) => ({
  type: "COMPANY_SEARCH_SUCCESS",
  payload: { response, action }
})


export const companySearchEpic = (action$, { getState }) => action$.ofType("FETCH_COMPANIES").mergeMap(action => apiCompanySearch(getCompanyFilterFromState(getState().inventoryFilter))
  .map(response => companySearchFulfilled(response, action))
  .catch(error => handleXhrError(error, "COMPANY_SEARCH_FAILURE")))
