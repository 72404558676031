// Function to add props to divs with onClick
// so that accessibility still works ( you can highlight / focus )
// then press enter.

export default (handlerFn, extraStyle = {}, role = "button") => ({
  role,
  onClick: e => {
    e.preventDefault()
    e.stopPropagation()
    e.cancelBubble = true
    handlerFn()
  },
  style: {
    cursor: "pointer",
    ...extraStyle
  },
  onKeyDown: event => {
    if (event.keycode === 13) handlerFn(event)
  }
})
