const { DEFAULT_SORT_ORDER } = SITE_CONFIG

export const urlToFilter = (pathname) => {
  const parts = pathname.split("/")

  const filterState = {
    selectedDays: [],
    selectedCategories: [],
    selectedCompanies: [],
    selectedRegions: [],
    selectedCampaigns: [],
    sortBy: DEFAULT_SORT_ORDER
  }

  /* Using this code to deconstruct other types of path is not permitted */
  if (parts[1] !== "browse") {
    return filterState
  }

  for (let i = 2; i < parts.length; i += 2) {
    const filter = parts[i]
    const values = parts[i + 1].split(",")

    switch (filter) {
      case "category":
        // console.log("Got categories", values)
        filterState.selectedCategories = values
        break
      case "offer":
        // console.log("Got campaigns", values)
        filterState.selectedCampaigns = values
        break
      case "company":
        // console.log("Got companies", values)
        filterState.selectedCompanies = values
        break
      case "region":
        // console.log("got region", values)
        filterState.selectedRegions = values
        break
      case "date":
        // console.log("got dates", values)
        filterState.selectedDays = values
        break
      case "sort":
        // console.log("got sort", values
        const [sortBy] = values
        filterState.sortBy = sortBy
        break
      default:
        console.log("URL ERROR! not found", filter, values)
    }
  }
  return filterState
}

export const filterToUrl = filter => {
  let url = "/browse"

  if (filter.selectedCategories.length > 0) {
    url = `${url}/category/${filter.selectedCategories.sort().join()}`
  }

  if (filter.selectedCampaigns.length > 0) {
    url = `${url}/offer/${filter.selectedCampaigns.sort().join()}`
  }

  if (filter.selectedCompanies.length > 0) {
    url = `${url}/company/${filter.selectedCompanies.sort().join()}`
  }

  if (filter.selectedDays.length > 0) {
    url = `${url}/date/${filter.selectedDays.sort().join()}`
  }

  if (filter.selectedRegions.length > 0) {
    url = `${url}/region/${filter.selectedRegions.sort().join()}`
  }

  if (
    filter.sortBy
    && filter.sortBy !== DEFAULT_SORT_ORDER
  ) {
    url = `${url}/sort/${filter.sortBy}`
  }

  if (url === "/browse") return "/"
  return url
}
