import { createSelector } from "reselect"

const getTerms = state => state.terms || {}

export const getPartnerTerms = createSelector(
  getTerms,
  terms => terms.partnerTerms,
)

export const getPrivacyTerms = createSelector(
  getTerms,
  terms => terms.privacyTerms,
)

export const getCustomerTerms = createSelector(
  getTerms,
  terms => terms.customerTerms,
)
