import React from "react"
import PropTypes from "prop-types"

import { ConnectedRouter } from "connected-react-router"
import { Route, Switch } from "react-router"

import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { PRISMIC_TYPES } from "./constants"
import LoginContainer from "./containers/Login/LoginContainer"
import ResetPasswordContainer from "./containers/ResetPassword/ResetPasswordContainer"
import ChangePasswordContainer from "./containers/ResetPassword/ChangePasswordContainer"
import RegistrationContainer from "./containers/Registration/RegistrationContainer"
import MyBookingsContainer from "./containers/MyBookings/MyBookingsContainer"
import OrderViewContainer from "./containers/Order/OrderViewContainer"
import FavouritesContainer from "./containers/Favourites/FavouritesContainer"
import ListContainer from "./containers/Articles/ListContainer"
import ArticleContainer from "./containers/Articles/ArticleContainer"
import InstantBookLoadingContainer from "./containers/InstantBook/InstantBookLoadingContainer"
import InventoryViewContainer from "./containers/InventoryView/InventoryViewContainer"
import CampaignWizard from "./containers/InventoryView/CampaignWizard"
import CompanyViewContainer from "./containers/Companies/CompanyViewContainer"
import ProfileContainer from "./containers/Profile/ProfileContainer"
import PurchaseContainer from "./containers/Purchase/PurchaseContainer"
import TermsConditions from "./containers/Terms"
import FourOhFour from "./containers/FourOhFour"
import HealthContainer from "./containers/Health"
import Faqs from "./containers/Faqs"
import FaqsTopic from "./containers/Faqs/FaqsTopic"
import FaqSearchResults from "./containers/Faqs/FaqSearchResults"
import Answer from "./containers/Faqs/Answer"
import CookieBanner from "./components/banners/CookieBanner"
import { FranchiseProvider } from "./providers/franchise"

import { IntercomProvider } from "react-use-intercom"
import IntercomContainer from "./containers/Intercom"

const {
  STRIPE_API_KEY,
  STRIPE_ENABLED,
  GOOGLE_RECAPTCHA_SITE_ID,
  ENABLE_INTERCOM
} = SITE_CONFIG

class App extends React.Component {
  componentDidMount() {
    // Global function defined in html-templates/includes/animation.html specifically
    // to clear the initial loading animation on initial app render

    // eslint-disable-next-line no-undef
    clearAnimation()
  }

  renderCommon() {
    const { history } = this.props
    return (
      <div>

        <IntercomContainer />

        <FranchiseProvider>
          <CookieBanner />
          <ConnectedRouter history={history}>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL || "/login"}`}
                component={LoginContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/reset-password/required/:id"}`}
                render={(props) => <ChangePasswordContainer {...props} resetRequired />}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/reset-password/:id"}`}
                component={ChangePasswordContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/forgot-password"}`}
                component={ResetPasswordContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/register"}`}
                component={RegistrationContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL
                  || "/bookings/:id/:confirmation"}`}
                component={OrderViewContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/bookings/:id"}`}
                component={OrderViewContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/bookings"}`}
                component={MyBookingsContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/account"}`}
                component={ProfileContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/company/:id"}`}
                component={CompanyViewContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/purchase"}`}
                component={PurchaseContainer}
              />
              <Route
                exact
                path={
                  process.env.PUBLIC_URL
                    ? process.env.PUBLIC_URL
                    : [
                      "/services/:id",
                      "/services/:id/date/:date?",
                      "/services/:id/employee/:employeeSlug",
                      "/services/:id/date/:date/employee/:employeeSlug",
                    ]
                }
                component={InventoryViewContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/offer/:slug"}`}
                component={CampaignWizard}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/your-health"}`}
                component={HealthContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL
                  || "/:type(inspiration|guides)/:slug"}`}
                component={ArticleContainer}
              />
              <Route
                path={`${process.env.PUBLIC_URL
                  || "/:type(inspiration|guides)"}`}
                render={(props) => {
                  return (
                    <ListContainer
                      {...props}
                      metaTitle={SITE_CONFIG.TERMS_META_TITLE}
                      metaDescription={SITE_CONFIG.TERMS_META_DESCRIPTION}
                      documentType={PRISMIC_TYPES.CUSTOMER_TERMS}
                    />
                  )
                }}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/privacy"}`}
                render={props => (
                  <TermsConditions
                    {...props}
                    metaTitle={SITE_CONFIG.PRIVACY_META_TITLE}
                    metaDescription={SITE_CONFIG.PRIVACY_META_DESCRIPTION}
                    documentType={PRISMIC_TYPES.PRIVACY_POLICY}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL || "/terms/customer"}`}
                render={props => (
                  <TermsConditions
                    {...props}
                    metaTitle={SITE_CONFIG.TERMS_META_TITLE}
                    metaDescription={SITE_CONFIG.TERMS_META_DESCRIPTION}
                    documentType={PRISMIC_TYPES.CUSTOMER_TERMS}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/browse/*"}`}
                component={InstantBookLoadingContainer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/favourites/"}`}
                component={FavouritesContainer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/help"}`}
                component={Faqs}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/help-search/:query"}`}
                component={FaqSearchResults}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/help/:faqTopic"}`}
                component={FaqsTopic}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/help/:faqTopic/:faq"}`}
                component={Answer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL || "/"}`}
                render={(props) => (
                  <InstantBookLoadingContainer
                    metaTitle={SITE_CONFIG.HOME_META_TITLE}
                    metaDescription={SITE_CONFIG.HOME_META_DESCRIPTION}
                    {...props}
                  />
                )}
              />
              <Route render={(props) => <FourOhFour reason="not-found" {...props} />} />
            </Switch>
          </ConnectedRouter>
        </FranchiseProvider>
      </div>
    )
  }

  render() {
    const renderStripe = (children) => {
      /* Stripe is brutally slow, so only enable and include on an explicit per build basis */
      if (STRIPE_ENABLED) {
        const stripePromise = loadStripe(STRIPE_API_KEY)

        return (
          <Elements stripe={stripePromise}>
            {children}
          </Elements>
        )
      }

      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
    }

    const renderRecaptcha = (children) => {
      if (GOOGLE_RECAPTCHA_SITE_ID) {
        return (
          <GoogleReCaptchaProvider
            reCaptchaKey={GOOGLE_RECAPTCHA_SITE_ID}
            // language="[optional_language]"
            // useRecaptchaNet="[optional_boolean_value]"
            // useEnterprise="[optional_boolean_value]"
            // scriptProps={{
            //  async: false, // optional, default to false,
            //  defer: false, // optional, default to false
            //  appendTo: 'head', // optional, default to "head", can be "head" or "body",
            //  nonce: undefined // optional, default undefined
            // }}
          >
            {children}
          </GoogleReCaptchaProvider>
        )
      }

      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
    }

    const renderIntercom = (children) => {

      if (ENABLE_INTERCOM) {
        return (
          <IntercomProvider appId="fccsgukf">
            {children}
          </IntercomProvider>
        )
      }

      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
    }

    return renderStripe(renderRecaptcha(renderIntercom(this.renderCommon())))
  }
}

App.defaultProps = {
  history: {}
}

App.propTypes = {
  history: PropTypes.object
}

export default App
