import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import EmployeeCard from "../../components/cards/EmployeeCard"
import { PromoTag } from "../../components/SaleTags"
import { ThemedSpan } from "./components"
import { thumborize } from "../../components/images/thumborize"
import Pricing from "../../components/Pricing"

const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    min-height: 45px;
    padding: 10px;
    border-radius: 999px;
    background-color: ${props => props.theme.timeSelector.selectedBackground};
    border: none;
    color: ${props => props.theme.timeSelector.selectedText};
    * {
        color: ${props => props.theme.timeSelector.selectedText};
    }
    margin: 10px;
`

export const EmployeeSelectButton = ({
  employees,
  employeeId,
  role,
  company,
  price,
  currency,
  isMobile,
  hideAnyEmployee
}) => {
  const employeeOnSale = e => e.pricing.lowest.price !== e.pricing.default.price && e.pricing.lowest.sale

  if (!employees) return null

  /* Calculate price range to be displayed when nothing is yet selected */
  let priceRange = currency.symbol + price.lowest.price

  if (price.highest.price !== price.lowest.price) {
    priceRange = `${priceRange} - ${currency.symbol}${price.highest.price}`
  }

  const onSale = employees.some(employeeOnSale)

  let label = (
    <StyledLabel>
      <ThemedSpan highlighted>{`Select your ${role.trim().toLowerCase()}:`}</ThemedSpan>
      {priceRange && (<span style={{ paddingLeft: 8, fontWeight: 500 }}>{priceRange}</span>)}
      {onSale && <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left" />}
    </StyledLabel>
  )

  const getCompanyLogo = () => {
    const image = company && company.images && company.images.find(i => i.placement === "logo")
    return image && thumborize(encodeURIComponent(image.url), 240, 240)
  }

  const AnyStylistLabel = () => {
    return (
      <StyledLabel>
        <div style={{ paddingRight: 12 }}>
          <img
            src={getCompanyLogo()}
            alt=""
            style={{
              width: 45,
              height: 45,
              borderRadius: 30,
              display: "block"
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          Any {role}
          {onSale && <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left"/>}
        </div>
        <div style={{ display: "flex", alignItems: "flex-end", paddingRight: "1em" }}>
          <Pricing
            currency={currency}
            prices={price}
            style={{ marginLeft: 10 }}
            isMobile={isMobile}
          />
        </div>
      </StyledLabel>
    )
  }

  if (employeeId) {
    if (!hideAnyEmployee) {
      label = (<AnyStylistLabel />)
    }

    if (employeeId > 0) {
      const employee = employees.find(e => e.id === employeeId)
      label = (
        <StyledLabel>
          <EmployeeCard
            employee={employee}
            isMobile={isMobile}
            company={company}
            pricing={price.employees}
            currency={currency}
            hideTag
          />
          {onSale && <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left" />}
        </StyledLabel>
      )
    }
  }

  return label
}

EmployeeSelectButton.propTypes = {
  employees: PropTypes.array,
  employeeId: PropTypes.string,
  role: PropTypes.string,
  company: PropTypes.object,
  price: PropTypes.object,
  currency: PropTypes.object,
  isMobile: PropTypes.bool,
  hideAnyEmployee: PropTypes.bool,
}
