import React from "react"
import PropTypes from "prop-types"

const ErrorBar = ({ message }) => (
  <div
    style={{
      color: "red",
      fontSize: 15,
      textAlign: "center",
      marginBottom: 10,
      border: "1px solid rgba(255,0,0,0.2)",
      padding: "10px 15px",
      backgroundColor: "rgba(255,0,0,0.05)",
      borderRadius: 15,
      whiteSpace: "pre-line"
    }}
  >
    {message}
  </div>
)

ErrorBar.propTypes = {
  message: PropTypes.string
}

export default ErrorBar
