import styled from "styled-components"

export const AvedaIcon = styled.i`
  font-family: icons;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
`

export const CaretDownIcon = styled(AvedaIcon)`
  margin-top: -2px;
  &::before {
    content: ""
  }
`

export const MarkerIcon = styled(AvedaIcon)`
  margin-top: -2px;
  &::before {
    content: ""
  }
`


export const AccountIcon = styled(AvedaIcon)`
  margin-top: -2px;

  &::before {
    content: ""
  }
`

export const BagIcon = styled(AvedaIcon)`
  font-size: 17px;
  color: inherit;
  &::before {
    content: ""
  }
`

export const BurgerIcon = styled(AvedaIcon)`
  margin-top: -2px;

  &::before {
    content: ""
  }
`
