import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { useIntercom } from 'react-use-intercom'

// Small shim to use hook
const Intercom = (userProps) => {
    const { boot } = useIntercom()

    boot(userProps)

    return null
}

class IntercomContainer extends Component {
  render() {
    const {
      cookiesAllowed, userId, userEmail, displayName
    } = this.props

    if (!SITE_CONFIG.ENABLE_INTERCOM || !cookiesAllowed) return null

    const userProps = {
      user_id: userId,
      email: userEmail,
      name: userId ? displayName : undefined
    }

    return <Intercom userProps={userProps} />
  }
}

IntercomContainer.propTypes = {
  cookiesAllowed: PropTypes.bool,
  userId: PropTypes.string,
  userEmail: PropTypes.string,
  displayName: PropTypes.string
}

function mapStateToProps(state) {
  return {
    displayName: `${state.profile.profile.first_name} ${state.profile.profile.last_name}`,
    userId: state.profile.profile.id,
    userEmail: state.profile.profile.username,
    cookiesAllowed: state.navBar.cookieOk
  }
}

export default connect(
  mapStateToProps
)(IntercomContainer)
