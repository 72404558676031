import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Border = styled.div`
  border: ${({ showBorder, theme }) => (showBorder ? `2px solid ${theme.colors.primary}` : "none")};
  border-radius: ${props => (props.showBorder ? "0px 0px 5px 5px" : 0)};
`

const HighlightedBorderArea = ({ style, children, showBorder }) => (
  <Border style={style} showBorder={showBorder}>
    {children}
  </Border>
)

HighlightedBorderArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  showBorder: PropTypes.bool,
  style: PropTypes.object,
}

export default HighlightedBorderArea
