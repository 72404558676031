const getPreviousLocation = () => {
  const { localStorage } = window

  const location = localStorage.getItem("REDIRECT_TO") ? localStorage.getItem("REDIRECT_TO") : "/"

  return location
}

export const submitResetPassword = (email, googleRecaptchaToken) => ({
  type: "SUBMIT_RESET_PASSWORD",
  payload: { email, googleRecaptchaToken }
})

export const submitUpdatePassword = (password, password_again, unique_key) => ({
  type: "SUBMIT_UPDATE_PASSWORD",
  payload: { password, password_again, unique_key }
})

export const updateEmail = email => ({
  type: "RESET_PASSWORD_EMAIL_UPDATE",
  payload: { email }
})

export const setResetPasswordToken = token => ({
  type: "RESET_PASSWORD_TOKEN",
  payload: token
})

export const navToPreviousLocation = () => ({
  type: "NAVIGATE_TO_PREVIOUS_LOCATION",
  payload: { path: getPreviousLocation() },
})

export const updatePassword = password => ({
  type: "RESET_PASSWORD_VALUE",
  payload: password
})

export const updatePasswordAgain = password => ({
  type: "RESET_PASSWORD_AGAIN_VALUE",
  payload: password
})

export const resetFormProps = () => ({ type: "RESET_FORM_PROPS" })

export const resetState = () => ({ type: "RESET_PASSWORD_STATE" })
