import React from "react"
import styled from "styled-components"
import Animation from "../Animation"

const LoadingDiv = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: center;
`

export const Loading = () => (
  <div>
    <Animation style={{ width: "100vw", height: "100vh" }} />
    <LoadingDiv>Loading</LoadingDiv>
  </div>
)
