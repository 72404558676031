/* eslint-disable no-underscore-dangle */

import "polyfill-array-includes"

import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { isIE } from "react-device-detect"

import "./i18n"
import { withTranslation } from "react-i18next"

import createStore from "./redux/createStore"

import rg4js from 'raygun4js';
if (process.env.RAYGUN_API_KEY) {
  rg4js('apiKey', process.env.RAYGUN_API_KEY);
  rg4js('enableCrashReporting', true);
}

import AppComponent from "./app"

const App = withTranslation()(AppComponent)

const initialState = window.__PRELOADED_STATE__ || {}

if (!isIE) {
  const { store, history, persistor } = createStore(initialState)

  // notify segment what franchise this build is for
  store.dispatch({ type: "SEGMENT_GROUP" })

  // try to login automatically
  store.dispatch({ type: "TRY_STARTUP_AUTH" })
  store.dispatch({ type: "FETCH_REGIONS" })
  store.dispatch({ type: "FETCH_CATEGORIES" })
  store.dispatch({ type: "FETCH_COMPANIES" })
  store.dispatch({ type: "FETCH_CAMPAIGNS" })
  store.dispatch({ type: "FETCH_CART" })

  // user has handled cookie popup already, so send OK/DECLINE action based on cookie value
  if (document.cookie.includes("cookiesok")) {
    const type = (document.cookie.split(";").some((item) => item.includes("cookiesok=true"))) ? "COOKIE_OK" : "COOKIE_DECLINE"

    store.dispatch({ type })
  }

  ReactDOM.render(
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App history={history} />
        </PersistGate>
      </Provider>
    </React.Suspense>,
    document.getElementById("flossieapp")
  )
} else {
  /* Render unsupported browser message only */
  ReactDOM.render(
    <div style={{
      fontFamily: "proxima-nova, sans-serif", borderRadius: 10, backgroundColor: "#ef609f", color: "#fff", position: "fixed", width: 400, height: 300, top: "calc(50% - 200px)", left: "calc(50% - 220px)", padding: 20
    }}
    >
      <h1 style={{ textAlign: "center" }}>Hi there. You’re using an unsupported browser</h1>
      <p>For a safer and faster user experience please upgrade to a modern browser like Chrome, Firefox, Safari, Opera, or Edge.</p>
    </div>,
    document.getElementById("flossieapp")
  )
}
