export const initialState = {
  isFetchingBookings: false,
  bookings: [],

  showHistory: false,

  isFetchingOrder: false,
  order: null,
}

export default function myBookingsReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_BOOKINGS_HISTORY":
      return {
        ...state,
        showHistory: !state.showHistory
      }
    case "FETCH_BOOKINGS_SUCCESS":
      return {
        ...state,
        bookings: action.payload.response,
        isFetchingBookings: false
      }
    case "FETCH_ORDER_SUCCESS":
      return {
        ...state,
        isFetchingOrder: false,
        order: action.payload.response
      }
    case "FETCH_ORDER":
      return {
        ...state,
        isFetchingOrder: true
      }
    case "FETCH_ORDER_ERROR":
      return {
        ...state,
        isFetchingOrder: false,
        hasError: true,
        lastError: action.payload.error_response.response.data.message
      }
    case "LOG_OUT":
      return initialState
    case "CANCEL_USER_BOOKING_SUCCESS": {
      const { response, bookingId } = action.payload
      const { bookings, order } = state
      if (response.success && bookingId) {
        return {
          ...state,
          bookings: bookings.filter(b => b.id !== bookingId), // removing booking from state
          order: {
            ...order,
            bookings: (order && order.bookings) ? order.bookings.filter(b => b.id !== bookingId) : []
          }
        }
      }

      return {
        ...state,
        hasError: true,
        lastError: action.payload.response.message
      }
    }
    case "CANCEL_USER_BOOKING_ERROR": {
      return {
        ...state,
        hasError: true,
        lastError: action.payload.error_response.response.data.message
      }
    }
    default:
      return state
  }
}
