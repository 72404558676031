import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

const { LOCALES, LOCALE_NAMESPACES } = SITE_CONFIG

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: LOCALES[0],
    ns: ["translation", ...LOCALE_NAMESPACES],
    defaultNS: LOCALE_NAMESPACES[0],
    fallbackNS: "translation",
    fallbackLng: "en",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    }
  })
