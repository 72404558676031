import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import PropTypes from "prop-types"
import Carousel from "react-multi-carousel"

import {
  TagIcon
} from "../icons"

import { urlToFilter, filterToUrl } from "../../containers/InstantBook/urlFilter"


const Announcement = styled.div`
  cursor: pointer;
  min-height: 30px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
      color: ${({ theme }) => theme.colors.primaryAccent};
  }
`

const AnnouncementTag = styled(TagIcon)`
  fill: ${({ theme }) => theme.colors.primaryAccent};
  margin-right: 10px;
`

const CampaignBanner = ({
  className, campaigns, navToFilter, pathname, loggedIn
}) => {
  if (campaigns.length === 0) {
    return null
  }

  const clickCampaign = campaign_slug => {
    const filter = urlToFilter(pathname, loggedIn)
    if (filter.selectedCampaigns.includes(campaign_slug)) {
      filter.selectedCampaigns = filter.selectedCampaigns.filter(
        r => r !== campaign_slug
      )
    } else {
      filter.selectedCampaigns.push(campaign_slug)
    }

    navToFilter(filterToUrl(filter))
  }

  const renderCampaign = (campaign) => (
    <Announcement
      key={`campaign-${campaign.slug}`}
      onClick={() => { clickCampaign(campaign.slug) }}
    >
      <AnnouncementTag style={{ }} />
      <span>{campaign.banner}</span>
    </Announcement>
  )


  return (
    <Carousel
      className={`campaign-banner ${className}`}
      responsive={{
        desktop: {
          breakpoint: { max: 3500, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 1,
        }
      }}
      arrows={false}
      showDots={false}
      autoPlay={campaigns.length > 1}
      autoPlaySpeed={2500}
      infinite={campaigns.length > 1}
    >
      {campaigns.map(renderCampaign)}
    </Carousel>
  )
}

CampaignBanner.propTypes = {
  isMobile: PropTypes.bool,
  loggedIn: PropTypes.bool,
  className: PropTypes.string,
  campaigns: PropTypes.array.isRequired,
  navToFilter: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired
}

CampaignBanner.defaultProps = {
  isMobile: false,
  loggedIn: false,
  className: ""
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  isMobile: state.browser.lessThan.mobile,
  campaigns: state.inventoryFilter.campaigns,
  loggedIn: !!state.profile.profile.id
})

const mapDispatchToProps = dispatch => ({
  navToFilter: filterUrl => dispatch(push(filterUrl)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignBanner)
