import React from "react"

const defaultStyle = {
  margin: 0
}

const ParagraphText = ({ text, style, maxLength = 0 }) => {
  const renderStyles = style && Object.values(style).length >= 1 ? style : defaultStyle
  let result = null

  let displayText = text

  if (maxLength > 0 && displayText.length > maxLength) {
    displayText = text.substr(0, maxLength)
    displayText = displayText.substr(
      0,
      Math.min(displayText.length, displayText.lastIndexOf(" "))
    )
    displayText = `${displayText} ...`
  }

  if (displayText && displayText.length > 0) {
    result = (
      <p style={renderStyles}>
        {displayText.split("\n").map((l, i) => (
          <span key={i}>
            {l}
            <br />
          </span>
        ))}
      </p>
    )
  }
  return result
}

export default ParagraphText
