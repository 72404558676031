import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import moment from "moment-timezone"
import { clearCompany, fetchCompanyServices } from "./actions"
import { thumborize } from "../../components/images/thumborize"
import { navToInventory } from "../InventoryView/actions"
import buttonize from "../../components/buttonize"
import ParagraphText from "../../components/ParagraphText"
import Pricing from "../../components/Pricing"

import { RoundImage } from "../../components/images/RoundImage"
import { getServiceImage } from "../../components/images/ServiceGallery"
import GoToButton from "../../components/button/GoToButton"

class CompanyServices extends Component {
  componentDidMount() {
    const { companyId, fetchCompanyServices } = this.props

    fetchCompanyServices(companyId)
  }

  componentWillUnmount() {
    const { clearCompany } = this.props
    // reset the persisted services in companies redux store.
    clearCompany()
  }

  goToAppointments = serviceId => {
    const { navToInventory } = this.props
    navToInventory(serviceId, moment().format("YYYY-MM-DD"))
  }

  renderService = s => {
    const imageSize = isMobile ? 60 : 80

    const image = getServiceImage(s.images, s.description, { type: "resize", width: imageSize * 4, height: imageSize * 4 })

    const { isMobile, isVip } = this.props

    return (
      <div
        key={`service-${s.id}`}
        {...buttonize(() => this.goToAppointments(s.slug), {
          display: "flex",
          borderBottom: "1px dashed #ccc",
          padding: "10px 0px",
          alignItems: "stretch"
        })}
      >
        {image && (
          <div style={{ marginRight: 14 }}>
            <RoundImage
              src={image}
              size={imageSize}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <h3
            style={{
              margin: "2px auto 8px auto",
              fontSize: 16,
              fontWeight: 500
            }}
          >
            {s.description.name}
          </h3>

          <ParagraphText
            style={{
              margin: 0,
              fontSize: isMobile ? 14 : 16,
              paddingRight: 10
            }}
            text={s.description.about}
            maxLength={isMobile ? 140 : 320}
          />
        </div>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between"
          }}
        >
          <div style={{ textAlign: "right", marginBottom: 8 }}>
            {s.price && s.price.default && (
              <Pricing
                prices={s.price}
                currency={s.currency}
                isMobile={isMobile}
                userIsVip={isVip}
              />
            )}
          </div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              marginTop: 8,
              marginBottom: 8
            }}
          >
            <GoToButton variant="secondary" aLink={`/services/${s.slug}`} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { services } = this.props
    if (!services) return null
    return <div>{services.length > 0 && services.map(this.renderService)}</div>
  }
}

CompanyServices.propTypes = {
  company: PropTypes.object,
  fetchCompanyServices: PropTypes.func.isRequired,
  navToInventory: PropTypes.func.isRequired,
  clearCompany: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
  companyId: PropTypes.string
}

CompanyServices.defaultProps = {
  company: null
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    services: state.company.services,
    isMobile: state.browser.lessThan.mobile,
    isVip: !!state.profile.profile.vip
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  fetchCompanyServices: id => dispatch(fetchCompanyServices(id)),
  clearCompany: () => dispatch(clearCompany()),
  navToInventory: (serviceId, date) => dispatch(navToInventory(currentParams, { id: serviceId, date }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyServices)
