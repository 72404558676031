import moment from "moment-timezone"

/*
	INPUT => _d: Sun Apr 05 2020 12:00:00 GMT+1200 (New Zealand Standard Time), (string)"Pacific/Auckland"
	OUTPUT => 2020-01-31
*/

export const formatDate = (
	date,
	formatDate = "YYYY-MM-DD",
	timezone = "Pacific/Auckland"
) => {
	// if not date, return todays date.
	if (!date) return moment.tz(moment(), timezone).format(formatDate)
	return moment(new Date(date)).format(formatDate)
}

export function dayIsSale(price, day) {

	if (!price || !day) return null
	if (price.default || price.sale !== true) {
		return false
	}

	let sale = true

	/* If a DOW condition is set then the day must match */
	if (
		price.condition_mon ||
		price.condition_tue ||
		price.condition_wed ||
		price.condition_thu ||
		price.condition_fri ||
		price.condition_sat ||
		price.condition_sun
	) {
		switch (parseInt(day.format("d"))) {
			case 0:
				sale = price.condition_sun === true
				break
			case 1:
				sale = price.condition_mon === true
				break
			case 2:
				sale = price.condition_tue === true
				break
			case 3:
				sale = price.condition_wed === true
				break
			case 4:
				sale = price.condition_thu === true
				break
			case 5:
				sale = price.condition_fri === true
				break
			case 6:
				sale = price.condition_sat === true
				break
		}
	}

	/* If a to/from condition is set then the date must be between them */
	if (price.condition_date_from) {
		sale =
			sale &&
			(day.isSame(price.condition_date_from, "day") ||
				day.isAfter(price.condition_date_from))
	}

	if (price.condition_date_to) {
		sale =
			sale &&
			(day.isSame(price.condition_date_to, "day") ||
				day.isBefore(price.condition_date_to))
	}
	return sale
}

export function dayIsUnavailable(company, day) {
  let result = false

  /* Missing critical parameters */
  if (!company || !day) return result

  const { hours, unavailability } = company

  // if (day.isBefore(moment(), "day")) result = true

  /* Days in the past are unavailable */

  const today = moment.tz(moment(), company.timezone).startOf("day")
  if (day.isBefore(today)) result = true

  /* Salon not open on that date */
  const dayName = day.format("dddd")
  const hourDay = hours.find(h => h.day === dayName)
  if (hourDay && !hourDay.is_open) result = true

  /* Salon not available on that date */
  const u = unavailability.dates.find(un => un === day.format("YYYY-MM-DD"))
  if (u) result = true

  return result
}

/*
	INPUT =>
		d: Sun Apr 05 2019 12:00:00 GMT+1200 (New Zealand Standard Time),
		range(number) : 1,
		units(string): "year/day/hour/seconds"
		startDate(date) : 2019-12-04
		endDate(date): 2020-12-04
	OUTPUT (bool) => true
*/

export const isWithinDateRange = (
	date,
	timezone,
	range = 1,
	units = "year",
	startDate = formatDate(),
	endDate = formatDate(moment()
		.add(range, units))
) => {
	let isWithinRange = true
	const day = formatDate(date)
	if (!date || !startDate || !endDate) return isWithinRange
	isWithinRange = day ? moment(day).isBetween(startDate, endDate, null, '[]') : false
	return isWithinRange
}
