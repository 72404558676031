import React, { Component } from "react"
import PropTypes from "prop-types"
import { PrismicRichText } from '@prismicio/react'

import styled from "styled-components"

const ChapterParent = styled.div`
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

class Chapter extends Component {
  render() {
    const { title, items } = this.props
    return (
      <ChapterParent>
        <PrismicRichText field={title} />
        {items.map((item, i) => (
          <PrismicRichText key={`${title.text}${i}`} field={item.content} />
        ))}
      </ChapterParent>
    )
  }
}

Chapter.propTypes = {
  title: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
}

export default Chapter
