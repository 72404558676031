import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import React from "react"

import RadioButton from "./radio-button.svg"
import Checkbox from "./checkbox.svg"
import ToggleActive from "./toggle-active.svg"
import ToggleInactive from "./toggle-inactive.svg"
import ArrowLeft from "./arrow-left.svg"
import Close from "./close.svg"
import Plus from "./plus.svg"
import ChevronDown from "./chevron-down.svg"
import ChevronRight from "./chevron-right.svg"
import Shield from "./shield.svg"
import Search from "./search.svg"
import Heart from "./heart.svg"
import Calendar from "./calendar.svg"
import LocationPin from "./location-pin.svg"
import Cancel from "./cancel.svg"
import Bag from "./bag.svg"
import Tag from "./tag.svg"
import FacebookLogo from "./facebook.svg"
import FacebookLogoSmall from "./facebook-small.svg"
import Trolley from "./trolley.svg"
import Burger from "./burger.svg"
import StarSvg from "./full-star.svg"
import HalfStarSvg from "./half-star.svg"
import Spinner from "./spinner.svg"
import User from "./user.svg"
import QuestionMarkCircle from "./question-mark-circle-outline.svg"
export const RadioButtonIcon = styled(RadioButton)`
    height: 20px;
    width: 20px;
    #primary {
        fill: ${({ theme, selected }) => (selected ? theme.colors.primary : "none")};
    }
    #secondary {
        fill: ${({ theme }) => theme.colors.secondary};
    }
`

export const CheckBoxIcon = styled(Checkbox)`
    height: 15px;
    width: 15px;
    #primary {
        fill: ${({ theme, checked }) => (checked ? theme.colors.primary : "none")};
        stroke: ${({ theme }) => theme.colors.secondary};
        stroke-width: 100px;
    }
    #primaryAccent {
        fill: ${({ theme, checked }) => (checked ? theme.colors.primaryAccent : "none")};
    }
`

const ToggleActiveIcon = styled(ToggleActive)`
    height: 30px;
    #primary {
        fill: ${({ theme }) => theme.colors.primary};
    }
    #primaryAccent {
        fill: ${({ theme }) => theme.colors.primaryAccent};
    }
`
const ToggleInactiveIcon = styled(ToggleInactive)`
    height: 30px;
    #primary {
        fill: ${({ theme }) => theme.colors.primary};
    }
    #lightHover {
        fill: ${({ theme }) => theme.colors.inactive};
    }
`

export const ToggleIcon = ({ active }) => (active ? <ToggleActiveIcon /> : <ToggleInactiveIcon />)
ToggleIcon.defaultProps = { active: false }
ToggleIcon.propTypes = { active: PropTypes.bool }

export const ArrowLeftIcon = styled(ArrowLeft)`
    fill: ${({ theme }) => theme.colors.primary};
`
export const ArrowRightIcon = styled(ArrowLeftIcon)`
    transform: rotate(180deg);
    width: 20px;
`

export const CloseIcon = styled(Close)`
    fill: ${({ theme }) => theme.colors.primary};
    height: 20px;
`

export const PlusIcon = styled(Plus)`
    fill: ${({ theme }) => theme.colors.primary};
    height: 20px;
`

export const ChevronDownIcon = styled(ChevronDown)`
    fill: ${({ theme }) => theme.colors.primary};
    height: 20px;
    width: 23px;
`

export const ShieldIcon = styled(Shield)`
    width: 20px;
    #primary {
        fill: ${({ theme }) => theme.colors.primary};
    }
`

export const SearchIcon = styled(Search)`
    width: 18px;
    fill: ${({ theme }) => theme.colors.primary};
`

export const HeartIcon = styled(Heart)`
    width: 18px;
    path {
        stroke: ${({ theme }) => theme.colors.primary};
    }
    fill: ${({ theme, filled }) => (filled ? theme.colors.primary : "none")};
`

export const CalendarIcon = styled(Calendar)`
    fill: ${({ theme }) => theme.colors.primary};
`

export const LocationIcon = styled(LocationPin)`
    fill: ${({ theme }) => theme.colors.primary};
`

export const CancelIcon = styled(Cancel)`
    fill: ${({ theme }) => theme.colors.primary};
`

export const UserIcon = styled(User)`
    fill: ${({ theme }) => theme.colors.primary};
`

export const BagIcon = styled(Bag)`
    display: block;
    width: 20px;
    height: 20px;
    path {
        width: 20px;
        height: 20px;
    }
`

export const ChevronRightIcon = styled(ChevronRight)`
    height: 25px;
    fill: ${({ theme }) => theme.colors.primary};
`
export const ChevronLeftIcon = styled(ChevronRight)`
    height: 25px;
    fill: ${({ theme }) => theme.colors.primary};
    transform: rotate(180deg);
`

export const TagIcon = styled(Tag)`
    height: 24px;
    max-width: 24px;
    fill: ${({ theme }) => theme.colors.primary};
`

/**
 * Facebook Logo.
 * @props variant
 * "primary". Square with small case f.
 * "secondary". Only small case letter f.
 * @props fill
 * fill color for the svg by default is theme.colors.primary
 *
 */
export const FacebookIcon = ({ variant, ...rest }) => {
  const FacebookIconStyles = css`
    width: 20px;
    height: 20px;
    fill: ${({ fill, theme }) => (fill || theme.colors.primary)};
    path {
        fill: ${({ fill, theme }) => (fill || theme.colors.primary)};
    }
  `
  const FbLogo = styled(FacebookLogo)`${FacebookIconStyles}`
  const FbLogoSmall = styled(FacebookLogoSmall)`${FacebookIconStyles}`

  switch (variant) {
    case "primary": return <FbLogo {...rest} />
    case "secondary": return <FbLogoSmall {...rest} />
    default: return <div>{`${variant} not implemented`}</div>
  }
}
FacebookIcon.defaultProps = { variant: "primary" }
FacebookIcon.propTypes = { variant: PropTypes.string }

const TopNavIconStyle = css`
    display: block;
    fill: ${({ theme }) => theme.topNav.icons};
`

export const TrolleyIcon = styled(Trolley)`
    height: 26px;
    width: 26px;
    ${TopNavIconStyle}
`

export const MenuIcon = styled(Burger)`
    height: 20px;
    width: 20px;
    ${TopNavIconStyle}
`

/**
 *
 * @props variant
 * full: Full Star
 * half: Half Star
 * empty: Empty Star
 *
 * color is always theme.colors.primary and empty star with theme.colors.overlayColor
 */
export const StarIcon = ({ variant, ...rest }) => {
  const StarIconStyles = css`
    width: 22px;
    height: 22px;
    fill: ${({ theme }) => theme.colors.primary};
    path {
        fill: ${({ theme }) => theme.colors.primary};
    }
  `
  const FullStar = styled(StarSvg)`${StarIconStyles}`
  const HalfStar = styled(HalfStarSvg)`${StarIconStyles}`
  const EmptyStar = styled(StarSvg)`
    ${StarIconStyles}
    fill: ${({ theme }) => theme.colors.overlayColor};
    path {
        fill: ${({ theme }) => theme.colors.overlayColor};
    }
  `

  switch (variant) {
    case "full": return <FullStar {...rest} />
    case "half": return <HalfStar {...rest} />
    case "empty": return <EmptyStar {...rest} />
    default: return <div>{`${variant} not implemented`}</div>
  }
}
StarIcon.defaultProps = { variant: "full" }
StarIcon.propTypes = { variant: PropTypes.string }

export const SpinnerIcon = styled(Spinner)`
    width: 20px;
    height: 20px;
    display: block;
    fill: ${({ theme }) => theme.colors.primary};
    animation: rotating 2s linear infinite;
    @keyframes rotating {
        to{ transform: rotate(360deg); }
    }
`

export const QuestionMarkInCircleIcon = styled(QuestionMarkCircle)`
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.colors.primary};
`
