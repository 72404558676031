import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { CompanyLogo } from "./CompanyLogo"
import { thumborizeFitIn, thumborize } from "./thumborize"

const CompanyGalleryImage = styled.div`
  width: 100%;
  position: relative;
  height: 300px;
  background-image: url("${props => props.galleryImageUrl}");
  background-size: cover;
  background-position: center;
  background-color: #fafafa;
`

const CompanyName = styled.div`
  position: absolute;
  background-image: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 25%, rgba(0,0,0,1) 90%);
  bottom: 0;
  color: #fff;
  width: calc(100% - 100px);
  height: auto;
  padding: 20px 80px 20px 20px;
  font-size: 22px;
`
export const CompanyGallery = (
  { company, style = {} }
) => {
  const getGalleryImage = images => {
    const image = images.find(
      i => i.placement === "gallery" && i.position === "0"
    )
    return image && thumborizeFitIn(encodeURIComponent(image.url), 800, 800)
  }

  const getLogoImage = images => {
    const image = images.find(i => i.placement === "logo" && i.position === "0")
    return image && thumborize(encodeURIComponent(image.url), 240, 240)
  }

  const galleryImageUrl = getGalleryImage(company.images)
  const logoImageUrl = getLogoImage(company.images)

  if (!galleryImageUrl || !logoImageUrl) {
    return null
  }

  return (

    <CompanyGalleryImage
      style={{ ...style }}
      galleryImageUrl={galleryImageUrl}
    >
      <CompanyLogo
        company={company}
        size={40}
        style={{
          display: "block",
          position: "absolute",
          bottom: 10,
          right: 20,
          zIndex: 2,
        }}
      />
      <CompanyName>{company.name}</CompanyName>
    </CompanyGalleryImage>

  )
}

CompanyGallery.propTypes = {
  company: PropTypes.object.isRequired,
  style: PropTypes.object
}
