import { push } from "connected-react-router"

export const fetchOrder = id => ({
  type: "FETCH_ORDER",
  payload: { id }
})

export const addToCalendarTrack = () => ({
  type: "TRACK_ADD_TO_CALENDAR"
})

export const navToOrder = orderId => push(`/bookings/${orderId}`)

// export const navToBooking = bookingId => push(`/bookings/${bookingId}`)

export const navToBookings = () => push("/bookings")
