import React from "react"
import PropTypes from "prop-types"

import IconDataRow from "../datarenderers/IconDataRow"
import LocationIcon from "../icons/location-pin.svg"

const CompanyAddressBuilder = ({
  company,
  addressOne = false,
  addressTwo = false,
  district = false,
  region = false,
  postCode = false,
  companyName = false,
  inline = false,
  fontWeight = "400",
  fontSize = "15px",
  showComma = true,
  showIcon,
}) => {
  // Just supplying the company and no other fields is a shortcut for all fields
  const showAll = !addressOne && !addressTwo && !district && !region && !postCode && !companyName

  const address = []

  const {
    address_one,
    address_two,
    district: companyDistrict,
    post_code
  } = company

  // Add elements to address array as required and where available
  if ((showAll || addressOne) && address_one) address.push(address_one.trim())
  if ((showAll || addressTwo) && address_two && address_two.length > 0) address.push(address_two.trim())
  if ((showAll || district) && companyDistrict && companyDistrict.name) address.push(companyDistrict.name.trim())
  if ((showAll || region) && companyDistrict && companyDistrict.region && companyDistrict.region.name) address.push(companyDistrict.region.name.trim())
  if ((showAll || postCode) && post_code) address.push(post_code.trim())

  const showCompanyName = showAll || companyName

  const comma = showComma ? "," : ""

  if (!inline) {
    return (
      <IconDataRow icon={<LocationIcon />}>
        <div>
          {showCompanyName ? <b>{company.name}</b> : null}
          {address.map((line, i) => (
            <p key={`address-${i}`} style={{ margin: 0, fontWeight, fontSize }}>
              {line}
              {i < address.length - 1 && comma}
            </p>
          ))}
        </div>
      </IconDataRow>
    )
  }

  if (inline && showCompanyName) {
    return (
      <IconDataRow icon={<LocationIcon />}>
        <div style={{ width: "fit-content" }}>
          <div>{showCompanyName ? <b>{company.name}</b> : null}</div>
          <div>{address.join(", ")}</div>
        </div>
      </IconDataRow>
    )
  }

  if (showIcon) {
    return (
      <IconDataRow icon={<LocationIcon />}>
        {address.join(", ")}
      </IconDataRow>
    )
  }

  return <div>{address.join(", ")}</div>
}

CompanyAddressBuilder.propTypes = {
  company: PropTypes.object,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  companyName: PropTypes.bool,
  addressOne: PropTypes.bool,
  addressTwo: PropTypes.bool,
  district: PropTypes.bool,
  region: PropTypes.bool,
  postCode: PropTypes.bool,
  showComma: PropTypes.bool,
  showIcon: PropTypes.bool,
  inline: PropTypes.bool
}

export default CompanyAddressBuilder
