import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

import { submitResetPassword, updateEmail, resetState } from "./actions"

import { TextInput, SubmitInput } from "../../components/forms/Inputs"
import NavBarContainer from "../NavBar/NavBarContainer"
import HugeTitle from "../../components/HugeTitle"

const { GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { googleRecaptchaToken: false }
  }

  componentDidMount() {
    const { resetState } = this.props
    resetState()
  }


  handleReCaptchaVerify = (token) => {
    this.setState({ googleRecaptchaToken: token })
  }

  updateEmail = e => {
    e.preventDefault()
    const { updateEmail } = this.props
    updateEmail(e.target.value)
  }

  submitResetPassword = e => {
    e.preventDefault()
    const { submitResetPassword, resetpassword } = this.props
    const { googleRecaptchaToken } = this.state

    submitResetPassword(resetpassword.email, googleRecaptchaToken)
  }

  navToLogin = e => {
    e.preventDefault()
    const { navToLogin } = this.props
    navToLogin()
  }

  resetPasswordForm() {
    const { resetpassword, isMobile } = this.props
    const { hasError } = resetpassword
    if (resetpassword.passwordIsReset) return null

    return (
      <form style={{ padding: "20px" }} onSubmit={this.submitResetPassword}>
        {GOOGLE_RECAPTCHA_SITE_ID && <GoogleReCaptcha onVerify={this.handleReCaptchaVerify} />}
        <TextInput
          onChange={this.updateEmail}
          value={resetpassword.email}
          placeholder="Email address"
          style={{ marginRight: isMobile ? 0 : 20 }}
        />
        <SubmitInput
          onClick={this.submitResetPassword}
          value="Reset Password"
          style={{ marginTop: 20 }}
        />
        {hasError && <p style={{ color: "red" }}>{resetpassword.lastError}</p>}
      </form>
    )
  }

  checkYourEmailMessage() {
    const { resetpassword } = this.props
    if (!resetpassword.passwordIsReset) return null
    return (
      <div style={{ padding: "20px" }}>
        <p>
          If you have an account with us, we have sent you a link to reset your password.
          <br/>
          If you haven't received an email, you will not have an account with us <a href="/register">create one here</a>.
        </p>
      </div>
    )
  }

  render() {
    return (
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <NavBarContainer />
        <HugeTitle>Reset Password</HugeTitle>
        {this.resetPasswordForm()}
        {this.checkYourEmailMessage()}
      </div>
    )
  }
}

ResetPasswordContainer.propTypes = {
  submitResetPassword: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  navToLogin: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  resetpassword: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    resetpassword: state.resetpassword,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  updateEmail: email => dispatch(updateEmail(email)),
  submitResetPassword: (email, googleRecaptchaToken) => dispatch(submitResetPassword(email, googleRecaptchaToken)),
  navToLogin: () => dispatch(push("/login")),
  resetState: () => dispatch(resetState())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer)
