import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { CloseIcon } from "./icons"

const CloseStripe = styled.div`
  display: flex;
  background-color: #fff;
`

const CloseLink = styled.a`
  text-decoration: none;
  text-align: center;
  display: block;
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

const CloseHeader = styled.div`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  line-height: 30px;
  textAlign: center;
`

const CloseStripeHeader = ({ style, clickClose, header }) => (
  <CloseStripe style={style}>
    <CloseHeader>{header}</CloseHeader>
    <div style={{ flex: 1 }} />
    <CloseLink href="/" onClick={clickClose}>
      <CloseIcon style={{ display: "block", height: 25 }} />
    </CloseLink>
  </CloseStripe>
)

CloseStripeHeader.propTypes = {
  style: PropTypes.object,
  clickClose: PropTypes.func,
  header: PropTypes.string
}

export default CloseStripeHeader
