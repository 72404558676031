import { push } from "connected-react-router"

export const navToCompany = slug => push(`/company/${slug}`)
export const navToServices = slug => push(`/company/${slug}/services`)
// export const navToEmployee = (companyId, employeeId) => push(`/company/${companyId}/employee/${employeeId}`)

export const clearCompany = () => ({ type: "CLEAR_COMPANY" })
export const toggleMap = () => ({ type: "TOGGLE_MAP" })
export const fetchCompany = id => ({ type: "FETCH_COMPANY", payload: { id } })
export const fetchCompanyServices = id => ({
  type: "FETCH_COMPANY_SERVICES",
  payload: { id }
})
export const boundsChange = bounds => ({
  type: "BOUNDS_CHANGE",
  payload: bounds
})
export const zoomChange = zoom => ({ type: "ZOOM_CHANGE", payload: zoom })
export const clearBounds = () => ({ type: "CLEAR_BOUNDS" })
