import React from "react"
import PropTypes from "prop-types"

const RibbonSVG = ({ height = "48.47", width = "34.03" }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 34.03 48.47"
        fill="#FFF"
        stroke="#ea4a8e"
        strokeMiterlimit="10"
        strokeWidth="1.51px"
      >
        <defs />
        <title>Asset 4</title>
        <path d="M14.16,47.51,10.4,45A.47.47,0,0,0,10,45L5.68,46.38a.48.48,0,0,1-.63-.52L7.63,26.48a.49.49,0,0,1,.55-.42l8.89,1.19a.48.48,0,0,1,.42.54L14.91,47.17A.48.48,0,0,1,14.16,47.51Z" />
        <path d="M21.39,47.62,25.05,45a.48.48,0,0,1,.42-.07l4.34,1.25a.49.49,0,0,0,.62-.55L27,26.34a.49.49,0,0,0-.56-.4L17.63,27.5a.48.48,0,0,0-.39.56l3.39,19.25A.48.48,0,0,0,21.39,47.62Z" />
        <circle cx="17.01" cy="17.01" r="16.26" />
        <path d="M17.74,8.06l1.94,6a.54.54,0,0,0,.51.37h6.28a.54.54,0,0,1,.32,1l-5.08,3.69a.54.54,0,0,0-.2.6l1.94,6a.53.53,0,0,1-.82.6l-5.08-3.69a.56.56,0,0,0-.63,0l-5.08,3.69a.54.54,0,0,1-.83-.6l1.94-6a.52.52,0,0,0-.19-.6L7.68,15.37a.54.54,0,0,1,.32-1h6.27a.53.53,0,0,0,.51-.37l1.94-6A.54.54,0,0,1,17.74,8.06Z" />
      </svg>
    </div>
  )
}

RibbonSVG.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
}

export default RibbonSVG
