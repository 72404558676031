import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Link = styled.a`
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const getMarkup = (size, content) => {
  let markup
  switch (size) {
    case "h1":
      markup = <h1>{content}</h1>
      break
    case "h2":
      markup = <h2>{content}</h2>
      break
    case "h3":
      markup = <h3>{content}</h3>
      break
    default:
    case "h4":
      markup = <h4 style={{ fontWight: 300 }}>{content}</h4>
  }
  return markup
}

const commonProps = {
  topic: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4"]),
  navigateTo: PropTypes.func.isRequired,
}

const handleClick = (e, slug, navigateTo) => {
  e.preventDefault()
  e.stopPropagation()
  navigateTo(slug)
}

export const QuestionLink = ({
  faq, topic, size, navigateTo,
}) => (
  <Link href={`/help/${topic.slug}/${faq.slug}`} onClick={(e) => handleClick(e, `/help/${topic.slug}/${faq.slug}`, navigateTo)}>{getMarkup(size, faq.data.question)}</Link>
)

QuestionLink.propTypes = {
  ...commonProps,
  faq: PropTypes.object.isRequired,
}

export const TopicLink = ({
  topic, suffix = "", size, navigateTo,
}) => (
  <Link href={`/help/${topic.slug}`} onClick={(e) => handleClick(e, `/help/${topic.slug}`, navigateTo)}>{getMarkup(size, `${topic.label}${suffix}`)}</Link>
)

TopicLink.propTypes = {
  ...commonProps,
  suffix: PropTypes.string, // sometimes we need to add "FAQs" at the end of the title
}
