const AVEDA_FRANCHISE_THEME = {

  // We use this to avoid importing ANY code that requires i18n translation as that
  // provider then includes the franchise theme/config provider, which ultimately creates
  // a circular dependency
  //
  // TODO: Find a better way to handle this without breaking the dev tools language/theme switcher!
  avedaHeaderHack: true,

  colors: {
    primary: "#687233",
    primaryAccent: "#FFF",

    secondary: "#120E02",
    secondaryAccent: "#FFF",

    tertiary: "#F4F2EE",

    accent: "#BC3C3C",
    error: "#BC3C3C",

    headers: "#120E02",
    price: "#120E02",

    lightHover: "#F4F2EE",
    darkHover: "rgb(244, 242, 238)",

    overlayColor: "rgba(0, 0, 0, 0.8)",
  },
  sideNav: {
    top: "81px",
    alignment: "left",
    background: "rgb(244, 242, 238)",
    links: "#120E02",
    activeLinks: "#687233",
    legalLinks: "#120E02",
  },
  topNav: {
    background: "#FFF",
    links: "#120E02",
    whatWhenWhereText: "#120E02",
  },
  dateSelector: {
    defaultText: "#120E02",
    selectedText: "#120E02",
    selectedBackground: "#D1D2BF",
  },
  timeSelector: {
    defaultText: "#120E02",
    selectedText: "#120E02",
    selectedBackground: "#D1D2BF",
  },
  button: {
    borderRadius: "0px",
    borderWidth: "1px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "normal",
    fontSizeSmall: "13px",
    fontSizeLarge: "15px",
  },
  paymentMethod: {
    backgroundColor: "#000",
  },
  poweredByFlossie: {
    backgroundColor: "#000",
  },
  filterFromBlackToPrimary: "invert(42%) sepia(9%) saturate(2414%) hue-rotate(29deg) brightness(96%) contrast(87%)",

  pageMaxWidth: "1200px",
  instantBookMaxWidth: "1200px",

  wwwBgImageSize: "cover",
  wwwBgImage: "/aveda-bg.jpg",
  wwwBgImageBlendMode: "multiply",
  wwwBgColor: "transparent",
  wwwBgImagePosition: "center center",
  wwwBgImagePositionMobile: "center center",

  globalStyles: (theme) => (`
    * {
      color: #120E02;
      text-rendering:optimizeLegibility;
      -webkit-font-smoothing:antialiased;
      -moz-osx-font-smoothing:grayscale;
    }

    body {
      font-family: MetaWebPro;
    }
    h1 {
      font-family: MetaWebPro;
      font-size: 32px;
      letter-spacing: -0.5px;
    }
    h2 {
      font-family: MetaWebPro;
      letter-spacing: -0.5;
      font-size: 24px;
      margin: 0;
    }
    h3 {
      font-family: MetaWebPro;
      letter-spacing: normal;
      font-size: 18px;
    }
    h4 {
      // Used in service names.
      font-family: MetaWebPro;
      font-size: 16px;
      margin: 0;
    }
    p {
      font-family: MetaWebPro;
      font-weight: 100;
      font-size: 16px;
      line-height: 20px;
      b {
        font-family: MetaWebPro;
      }
    }
    small {
      font-family: MetaWebPro;
      font-size: 10pt;
      font-weight: 100;
    }

    /* Loading animation circles */
    .loading-primary-circle {
      fill: ${theme.colors.primary};
    }

    /** start: BUTTONS styles **/
    .go-to-button-primary {
      a {
        background-color: ${theme.colors.secondaryAccent};
        color: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};
      }
    }
    /** end: BUTTONS styles **/

    /* Privacy policy and T&C links */
    #menu div:first-child {
      a {
        text-transform: uppercase;
        text-decoration: none;

        margin-bottom: 4px;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    // Padding on desktop to make space for fixed footer
    #page-wrap {
      padding-bottom: 70px !important;
    }

    @media ${theme.responsive.large} {
      #page-wrap {
        padding-bottom: 0px !important;
      }
    }

    @media only screen and (max-width: ${theme.responsive.mobile}px) {
      p {
        font-size: 15px;
      }
      h1 {

      }
      h2 {
        font-size: 20px;
      }
      h4 {
        font-size: 16px;
      }
      small {
        font-size: 10pt;
      }
    }
    * {
      font-family: MetaWebPro;
    }
  `),
}

export default AVEDA_FRANCHISE_THEME
