import { createSelector } from "reselect"

export const getFaqsData = state => state.faqs

export const getFaqsTopic = createSelector(
  getFaqsData,
  data => data.faqTopics
)

export const getSearchResults = createSelector(
  getFaqsData,
  data => data.searchResults
)

export const getShowFaqs = createSelector(
  getFaqsData,
  data => data.showFaqs,
)
