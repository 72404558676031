import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import { getViewingArticleType } from "./selector"
import { setViewArticleType, navToArticles } from "./actions"
import Button from "../../components/button"

import {
  TYPE_ID_INSPIRATION,
  TYPE_SLUG_INSPIRATION,
  TYPE_ID_GUIDE,
  TYPE_SLUG_GUIDE
} from "./constants"

class ArticleTabs extends Component {
  renderTab = (typeId, typeSlug, typeName, isActive) => {
    const { navToArticles, setViewArticleType } = this.props

    const ArticleTabButton = styled(Button)`
      display: inline-block;
      margin: 0 5px;
    `
    const tabStyle = {}

    return (
      <ArticleTabButton
        variant="secondary"
        filled={isActive}
        style={tabStyle}
        href={`/${typeSlug}`}
        onClick={e => {
          e.preventDefault()
          setViewArticleType(typeId)
          navToArticles(typeSlug)
        }}
      >
        {typeName}
      </ArticleTabButton>
    )
  }

  render() {
    const { isMobile, articleType } = this.props

    const TabParent = styled.div`
      margin-bottom: 40px;
      text-align: center;
    `

    return (
      <TabParent isMobile={isMobile}>
        {this.renderTab(
          TYPE_ID_INSPIRATION,
          TYPE_SLUG_INSPIRATION,
          "Inspiration",
          articleType === TYPE_ID_INSPIRATION
        )}
        {this.renderTab(
          TYPE_ID_GUIDE,
          TYPE_SLUG_GUIDE,
          "Guides",
          articleType === TYPE_ID_GUIDE
        )}
      </TabParent>
    )
  }
}

ArticleTabs.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setViewArticleType: PropTypes.func.isRequired,
  navToArticles: PropTypes.func.isRequired,
  articleType: PropTypes.string.isRequired
}

ArticleTabs.defaultProps = {}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    articleType: getViewingArticleType(state)
  }
}

const mapDispatchToProps = dispatch => ({
  setViewArticleType: type => dispatch(setViewArticleType(type)),
  navToArticles: type => dispatch(navToArticles(type))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleTabs)
