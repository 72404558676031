// Will need to restart webpack for thse changes to take effect.
// This file is loaded at 'compile' time.

const {
  SORT_POPULARITY,
  SORT_DISTANCE,
} = require("./containers/InventoryFilter/constants")

const PRISMIC_TYPES = {
  // These are the attributes names we populate on the all_prismic_data.json file
  // add here your fields when you fetch new data from prismic
  PARTNER_TERMS: "partner_terms_of_business",
  CUSTOMER_TERMS: "customer_terms",
  PRIVACY_POLICY: "privacy_policy",
  FAQ_TOPIC: "faq_topic",
  FAQS: "faqs",
  YOUR_HEALTH_PAGE_ID: "Consumer Your Health",
}

const FLOSSIE_DEFAULT_CONFIG = {
  API_BASE: `https://${process.env.FLOSSIE_API_HOSTNAME}`,

  THUMBOR_URL: `https://${process.env.FLOSSIE_THUMBOR_HOSTNAME}`,
  THUMBOR_KEY: process.env.FLOSSIE_THUMBOR_KEY,

  SIMPLE_LOCALIZE_PROJECT_TOKEN: "6aa33d54937d412a958df1a37c99c53d",
  SIMPLE_LOCALIZE_PROJECT_ENVIRONMENT: "latest",
  FACEBOOK_ENABLED: true,
  STRIPE_ENABLED: false,
  TRIED_AND_TESTED: true,
  FRANCHISE_ID: "",
  THEME: "flossie",
  INSTANT_BOOK_IMAGE_SELECTOR: "logo",
  ENABLE_INTERCOM: true,
  DEFAULT_TIMEZONE: "GMT",
  DEFAULT_TITLE: "Book Hair and Beauty Online",
  DEFAULT_TITLE_SUFFIX: "Flossie Hair & Beauty Appointments",
  DEFAULT_META_DESCRIPTION:
    "Book the perfect hair or beauty appointment instantly for wherever you like",
  DEFAULT_PHONE_COUNTRY: "gb",
  DEFAULT_LANGUAGE: "uk",
  DEFAULT_SORT_ORDER: SORT_POPULARITY,
  // All required namespaces need to be named here so that we can pre-download them.
  // This has no bearing on how i18next traverses its fallback tree. It uses convention to determine that: https://www.i18next.com/principles/fallback
  LOCALES: ["en"],
  LOCALE_NAMESPACES: [],
  SHOW_PBF_TOP: false,
  SHOW_PBF_SIDE_MENU: false,
  SHOW_INSPIRATION: true,
  SHOW_ABOUT: true,
  SHOW_YOUR_HEALTH: true,
  SIGNUP_BANNER_ENABLED: false,

  GOOGLE_RECAPTCHA_SITE_ID: false,
  GOOGLE_ANALYTICS_ID: [],
  GOOGLE_TAG_MANAGER_ID: false,
  GOOGLE_ANALYTICS4_ID: [],
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_JAVASCRIPT_API_KEY,
  FACEBOOK_PIXEL_ID: false,

  PRISMIC_URL: "https://flossie-production.cdn.prismic.io/api/v2",

  // META TAGS
  HOME_META_TITLE: "Book Hair and Beauty Online",
  HOME_META_DESCRIPTION:
    "Book the perfect hair or beauty appointment instantly for wherever you like",
  INSPIRATION_META_TITLE: "The Flossie Dossier - Inspiration",
  INSPIRATION_META_DESCRIPTION:
    "Your one stop destination for all things hair and beauty in New Zealand",
  GUIDES_META_TITLE: "The Flossie Dossier - Guides",
  GUIDES_META_DESCRIPTION:
    "Your one stop destination for all things hair and beauty in New Zealand",
  TERMS_META_TITLE: null, // it will use default one
  TERMS_META_DESCRIPTION:
    "Flossie provides software that helps hair and beauty businesses retail their appointments and products smarter online.",
  PRIVACY_META_TITLE: null, // it will use default one
  PRIVACY_META_DESCRIPTION:
    "Flossie provides software that helps hair and beauty businesses retail their appointments and products smarter online.",
  ABOUT_CUSTOMERS_META_TITLE: "Book Hair and Beauty Online",
  ABOUT_CUSTOMERS_META_DESCRIPTION:
    "Book hair and beauty appointments from only Tried & Tested salons, spas and clinics in New Zealand and Australia. Enjoy $10 off your first appointment.",
  ABOUT_PARTNERS_META_TITLE: "Get More Hair and Beauty Bookings",
  ABOUT_PARTNERS_META_DESCRIPTION:
    "Market your hair or beauty business and get more prepaid customers in New Zealand and Australia",

  RETURN_LINK_TITLE: false,
  RETURN_LINK_URL: false,

  ...PRISMIC_TYPES,

  SHOW_THEME_SWITCHER: process.env.NODE_ENV === "development",
}

const CONFIG_FLOSSIE_COM = {
  ...FLOSSIE_DEFAULT_CONFIG,

  TEMPLATE: "./app/html-templates/flossie.html",

  STRIPE_API_KEY: "pk_test_bmltoDDGXOFOsD9VWOFFFeS90067q4m0Wr",
  ROOT_URL: "https://flossie.com",
  SHOW_PBF_TOP: true,
  SIGNUP_BANNER_ENABLED: false,

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-4" }],
  FACEBOOK_PIXEL_ID: ["721294567979268"],
}

const CONFIG_STAGING_FLOSSIE_COM = {
  ...FLOSSIE_DEFAULT_CONFIG,
  SIMPLE_LOCALIZE_PROJECT_ENVIRONMENT: "latest",

  TEMPLATE: "./app/html-templates/staging.flossie.html",

  STRIPE_API_KEY: "pk_test_bmltoDDGXOFOsD9VWOFFFeS90067q4m0Wr",

  SHOW_PBF_TOP: true,
  SIGNUP_BANNER_ENABLED: false,

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-3" }],
  FACEBOOK_PIXEL_ID: ["721294567979268"],
}

const CONFIG_TESTING_FLOSSIE_COM = {
  ...FLOSSIE_DEFAULT_CONFIG,
  SIMPLE_LOCALIZE_PROJECT_ENVIRONMENT: "latest",

  TEMPLATE: "./app/html-templates/testing.flossie.html",

  STRIPE_API_KEY: "",

  ROOT_URL: "https://testing.flossie.com",
  SHOW_PBF_TOP: true,
  SIGNUP_BANNER_ENABLED: false,

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-3" }],
  FACEBOOK_PIXEL_ID: ["721294567979268"],
}

const CONFIG_DEMO_FLOSSIE_COM = {
  ...FLOSSIE_DEFAULT_CONFIG,

  SIGNUP_BANNER_ENABLED: false,

  TEMPLATE: "./app/html-templates/demo.flossie.html",

  STRIPE_API_KEY: "pk_test_bmltoDDGXOFOsD9VWOFFFeS90067q4m0Wr",

  ROOT_URL: "https://demo.flossie.com",

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-3" }],
  FACEBOOK_PIXEL_ID: ["721294567979268"],
}

// const CONFIG_DEMO_POWEREDBYFLOSSIE_COM = {
//   ...CONFIG_DEMO_FLOSSIE_COM,
//   TRIED_AND_TESTED: false,
//   DEFAULT_TITLE: "Book Hair and Beauty Online",
//   DEFAULT_TITLE_SUFFIX: "DEMO - Powered By Flossie",
//   DEFAULT_META_DESCRIPTION:
//     "Book the perfect hair or beauty appointment instantly",
//   ENABLE_INTERCOM: false,
//   TEMPLATE: "./app/html-templates/demo.poweredbyflossie.html",
//   THEME: "demo",
//   FACEBOOK_ENABLED: false,
//   FRANCHISE_ID: 13,
//   INSTANT_BOOK_IMAGE_SELECTOR: "list",
//   SHOW_PBF_TOP: true,
//   ROOT_URL: "https://demo.poweredbyflossie.com"
// }

// const CONFIG_WELLA_POWEREDBYFLOSSIE_COM = {
//   ...CONFIG_DEMO_FLOSSIE_COM,
//   TRIED_AND_TESTED: false,
//   DEFAULT_TITLE: "Book Hair and Beauty Online",
//   DEFAULT_TITLE_SUFFIX: "Wella - Powered By Flossie",
//   DEFAULT_META_DESCRIPTION:
//     "Book the perfect hair or beauty appointment instantly",
//   ENABLE_INTERCOM: false,
//   TEMPLATE: "./app/html-templates/wella.poweredbyflossie.html",
//   THEME: "wella",
//   FACEBOOK_ENABLED: false,
//   FRANCHISE_ID: 15,
//   INSTANT_BOOK_IMAGE_SELECTOR: "logo",
//   SHOW_PBF_TOP: true,
//   ROOT_URL: "https://wella.poweredbyflossie.com"
// }

// const CONFIG_AVEDA_US_POWEREDBYFLOSSIE_COM = {
//   ...CONFIG_DEMO_FLOSSIE_COM,
//   FACEBOOK_ENABLED: false,
//   TRIED_AND_TESTED: false,
//   DEFAULT_TITLE: "Book Hair and Beauty Online",
//   DEFAULT_TITLE_SUFFIX: "AVEDA - Powered By Flossie",
//   DEFAULT_META_DESCRIPTION:
//     "Book the perfect hair or beauty appointment instantly",
//   TEMPLATE: "./app/html-templates/avedapbf.html",
//   THEME: "aveda",
//   FRANCHISE_ID: "14",
//   ENABLE_INTERCOM: false,
//   INSTANT_BOOK_IMAGE_SELECTOR: "logo",
//   ROOT_URL: "https://aveda-us.poweredbyflossie.com",
//   LOCALES: ["en-US", "en"],
//   LOCALE_NAMESPACES: ["aveda"],
// }

// NEW NZ Production Franchises

const CONFIG_DEFAULT_FRANCHISE_PRODUCTION = {
  ...CONFIG_FLOSSIE_COM,
  TRIED_AND_TESTED: false,
  ENABLE_INTERCOM: true,
  FACEBOOK_ENABLED: true,
  INSTANT_BOOK_IMAGE_SELECTOR: "list",
  SHOW_PBF_TOP: true,
  SHOW_INSPIRATION: false,
  SHOW_ABOUT: false,
  SIGNUP_BANNER_ENABLED: false,
}

const CONFIG_DEFAULT_FRANCHISE_STAGING = {
  ...CONFIG_STAGING_FLOSSIE_COM,
  TRIED_AND_TESTED: false,
  ENABLE_INTERCOM: true,
  FACEBOOK_ENABLED: true,
  INSTANT_BOOK_IMAGE_SELECTOR: "list",
  SHOW_PBF_TOP: true,
  SHOW_INSPIRATION: false,
  SHOW_ABOUT: false,
  SIGNUP_BANNER_ENABLED: false,
}

const CONFIG_DEFAULT_FRANCHISE_TESTING = {
  ...CONFIG_TESTING_FLOSSIE_COM,
  TRIED_AND_TESTED: false,
  ENABLE_INTERCOM: true,
  FACEBOOK_ENABLED: true,
  INSTANT_BOOK_IMAGE_SELECTOR: "list",
  SHOW_PBF_TOP: true,
  SHOW_INSPIRATION: false,
  SHOW_ABOUT: false,
  SIGNUP_BANNER_ENABLED: false,
}

const CONFIG_AVEDA_NETWORK = {
  ...CONFIG_DEFAULT_FRANCHISE_PRODUCTION,
  LOCALE_NAMESPACES: ["aveda"],
  LOCALES: ["en-GB", "en"],
  FACEBOOK_ENABLED: false,
  TRIED_AND_TESTED: false,

  DEFAULT_TITLE: "Schedule an appointment at an Aveda salon",
  DEFAULT_TITLE_SUFFIX: "AVEDA - Powered By Flossie",
  DEFAULT_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",
  HOME_META_TITLE: "Schedule an appointment at an Aveda salon",
  HOME_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",

  TEMPLATE: "./app/html-templates/avedapbf.html",
  THEME: "aveda",
  // FRANCHISE_ID: "11",
  ENABLE_INTERCOM: false,
  SHOW_YOUR_HEALTH: false,
  INSTANT_BOOK_IMAGE_SELECTOR: "logo",
  // ROOT_URL: "https://avedapbf.com",

  RETURN_LINK_TITLE: "Return to Aveda",
  RETURN_LINK_URL: "https://www.aveda.co.uk",

  SHOW_PBF_TOP: false,
  SHOW_PBF_SIDE_MENU: true,

  // GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-12" }],
  // GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-FLP8V4ZZCT" }],

  FACEBOOK_PIXEL_ID: ["140309397249742", "663133687186724"],
}

const CONFIG_STAGING_AVEDA_NETWORK = {
  ...CONFIG_DEFAULT_FRANCHISE_STAGING,
  LOCALES: ["en-US", "en"],
  LOCALE_NAMESPACES: ["aveda"],
  FACEBOOK_ENABLED: false,
  TRIED_AND_TESTED: false,

  DEFAULT_TITLE: "Schedule an appointment at an Aveda salon",
  DEFAULT_TITLE_SUFFIX: "AVEDA STAGING - Powered By Flossie",
  DEFAULT_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",
  HOME_META_TITLE: "Schedule an appointment at an Aveda salon",
  HOME_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",

  TEMPLATE: "./app/html-templates/staging.avedapbf.html",
  THEME: "aveda",
  // FRANCHISE_ID: "11",
  ENABLE_INTERCOM: false,
  SHOW_YOUR_HEALTH: false,
  INSTANT_BOOK_IMAGE_SELECTOR: "logo",
  // ROOT_URL: "https://staging.avedapbf.com",

  RETURN_LINK_TITLE: "Return to Aveda",
  RETURN_LINK_URL: "https://www.aveda.co.uk",

  SHOW_PBF_TOP: false,
  SHOW_PBF_SIDE_MENU: true,

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-3" }],
  GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-SY1K8RQ3GX" }],
}

const CONFIG_TESTING_AVEDA_NETWORK = {
  ...CONFIG_DEFAULT_FRANCHISE_TESTING,
  LOCALES: ["en-US", "en"],
  LOCALE_NAMESPACES: ["aveda"],
  FACEBOOK_ENABLED: false,
  TRIED_AND_TESTED: false,

  DEFAULT_TITLE: "Schedule an appointment at an Aveda salon",
  DEFAULT_TITLE_SUFFIX: "AVEDA TESTING - Powered By Flossie",
  DEFAULT_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",
  HOME_META_TITLE: "Schedule an appointment at an Aveda salon",
  HOME_META_DESCRIPTION: "Schedule an appointment at an Aveda salon",

  TEMPLATE: "./app/html-templates/testing.avedapbf.html",
  THEME: "aveda",
  FRANCHISE_ID: "11",
  ENABLE_INTERCOM: false,
  SHOW_YOUR_HEALTH: false,
  INSTANT_BOOK_IMAGE_SELECTOR: "logo",
  ROOT_URL: "https://testing.flossie.com",

  RETURN_LINK_TITLE: "Return to Aveda",
  RETURN_LINK_URL: "https://www.aveda.co.uk",

  SHOW_PBF_TOP: false,
  SHOW_PBF_SIDE_MENU: true,

  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-3" }],
  GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-SY1K8RQ3GX" }],
}

const CONFIG_STAGING_AVEDAPBF_COM = {
  ...CONFIG_STAGING_AVEDA_NETWORK,

  FRANCHISE_ID: "11",
  ROOT_URL: "https://staging.avedapbf.com",
  LOCALES: ["en-US", "en"],
}

const CONFIG_AVEDAPBF_COM = {
  ...CONFIG_AVEDA_NETWORK,

  FRANCHISE_ID: "11",
  ROOT_URL: "https://avedapbf.com",
  LOCALES: ["en-GB", "en"],

  DEFAULT_PHONE_COUNTRY: "gb",

  RETURN_LINK_URL: "https://www.aveda.co.uk",

  // Append Aveda Google Analytics and Google Analytics4 IDs
  GOOGLE_ANALYTICS_ID: [{ trackingId: "UA-27647422-12" }],
  GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-FLP8V4ZZCT" }],
}

const CONFIG_UK_AVEDA_NETWORK = {
  ...CONFIG_AVEDA_NETWORK,

  FRANCHISE_ID: "11",
  ROOT_URL: "https://uk.aveda.network",
  LOCALES: ["en-GB", "en"],

  DEFAULT_PHONE_COUNTRY: "gb",

  RETURN_LINK_URL: "https://www.aveda.co.uk",

  // Append Aveda Google Analytics and Google Analytics4 IDs
  GOOGLE_ANALYTICS_ID: [],
  GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-FLP8V4ZZCT" }],
}

const CONFIG_US_AVEDA_NETWORK = {
  ...CONFIG_AVEDA_NETWORK,

  FRANCHISE_ID: "12",
  ROOT_URL: "https://us.aveda.network",
  LOCALES: ["en-US", "en"],

  DEFAULT_SORT_ORDER: SORT_DISTANCE,
  DEFAULT_PHONE_COUNTRY: "us",

  RETURN_LINK_URL: "https://www.aveda.com",

  // Append Aveda Google Analytics and Google Analytics4 IDs
  GOOGLE_ANALYTICS_ID: [],
  GOOGLE_ANALYTICS4_ID: [{ trackingId: "G-5Y8C2MD3SR" }],
}

const CONFIG_STAGING_UK_AVEDA_NETWORK = {
  ...CONFIG_STAGING_AVEDA_NETWORK,

  FRANCHISE_ID: "11",
  ROOT_URL: "https://staging.uk.aveda.network",

  LOCALES: ["en-GB", "en"],

  DEFAULT_PHONE_COUNTRY: "gb",

  RETURN_LINK_URL: "https://www.aveda.co.uk",
}

const CONFIG_STAGING_US_AVEDA_NETWORK = {
  ...CONFIG_STAGING_AVEDA_NETWORK,

  FRANCHISE_ID: "12",
  ROOT_URL: "https://staging.us.aveda.network",

  DEFAULT_SORT_ORDER: SORT_DISTANCE,
  DEFAULT_PHONE_COUNTRY: "us",
  DEFAULT_LANGUAGE: "us",

  RETURN_LINK_URL: "https://www.aveda.com",
}

const CONFIG_TESTING_UK_AVEDA_NETWORK = {
  ...CONFIG_TESTING_AVEDA_NETWORK,
  ROOT_URL: "https://testing.uk.aveda.network",

  LOCALES: ["en-GB", "en"],

  DEFAULT_PHONE_COUNTRY: "gb",

  RETURN_LINK_URL: "https://www.aveda.co.uk",
}

const CONFIG_TESTING_US_AVEDA_NETWORK = {
  ...CONFIG_TESTING_AVEDA_NETWORK,
  FRANCHISE_ID: "12",
  ROOT_URL: "https://testing.us.aveda.network",

  DEFAULT_SORT_ORDER: SORT_DISTANCE,
  DEFAULT_PHONE_COUNTRY: "us",
  DEFAULT_LANGUAGE: "us",

  RETURN_LINK_URL: "https://www.aveda.com",
}

const SITE_CONFIG = {
  "flossie.com": CONFIG_FLOSSIE_COM,
  "staging.flossie.com": CONFIG_STAGING_FLOSSIE_COM,

  "avedapbf.com": CONFIG_AVEDAPBF_COM,
  "staging.avedapbf.com": CONFIG_STAGING_AVEDAPBF_COM,

  "us.aveda.network": CONFIG_US_AVEDA_NETWORK,
  "uk.aveda.network": CONFIG_UK_AVEDA_NETWORK,

  "staging.us.aveda.network": CONFIG_STAGING_US_AVEDA_NETWORK,
  "staging.uk.aveda.network": CONFIG_STAGING_UK_AVEDA_NETWORK,

  "testing.us.aveda.network": CONFIG_TESTING_US_AVEDA_NETWORK,
  "testing.uk.aveda.network": CONFIG_TESTING_UK_AVEDA_NETWORK,

  // "aveda-us.poweredbyflossie.com": CONFIG_AVEDA_US_POWEREDBYFLOSSIE_COM,
  // "demo.poweredbyflossie.com": CONFIG_DEMO_POWEREDBYFLOSSIE_COM,
  // "wella.poweredbyflossie.com": CONFIG_WELLA_POWEREDBYFLOSSIE_COM
}

const config = SITE_CONFIG[process.env.DOMAIN]

module.exports = {
  PRISMIC_TYPES,
  ...config,
}
