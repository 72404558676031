import {
  apiGetCompany,
  apiGetCompanyServices,
  apiGetCompanyReviews,
  handleXhrError
} from "../../api/api"

const fetchCompanyFulfilled = (response, action) => ({
  type: "FETCH_COMPANY_SUCCESS",
  payload: { response, action }
})

export const fetchCompanyEpic = action$ => action$.ofType("FETCH_COMPANY").mergeMap(action => apiGetCompany(action.payload.id)
  .map(response => fetchCompanyFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_COMPANY_FAILURE")))

const fetchCompanyServicesFulfilled = (response, action) => ({
  type: "FETCH_COMPANY_SERVICES_SUCCESS",
  payload: { response, action }
})

export const fetchCompanyServicesEpic = action$ => action$.ofType("FETCH_COMPANY_SERVICES").mergeMap(action => apiGetCompanyServices(action.payload.id)
  .map(response => fetchCompanyServicesFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_COMPANY_SERVICES_FAILURE")))

const getCompanyReviewsFulfilled = (response, action) => ({
  type: "FETCH_COMPANY_PROFILE_REVIEWS_SUCCESS",
  payload: { response, action }
})

export const getCompanyProfileReviewsEpic = action$ => action$.ofType("FETCH_COMPANY_SUCCESS").mergeMap(action => apiGetCompanyReviews({
  company_id: action.payload.response.id,
  has_comment: 1
})
  .map(response => getCompanyReviewsFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_COMPANY_PROFILE_REVIEWS_FAILURE")))
