const styledMap = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#686868"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  }
]

// converter: https://codepen.io/oryan/pen/VLGeLB

export const staticMapTheme = "&style=feature%3Aall%7Celement%3Ageometry%7Ccolor%3A0xf5f5f5%7C&style=feature%3Aall%7Celement%3Alabels.icon%7Cvisibility%3Aoff%7C&style=feature%3Aall%7Celement%3Alabels.text.fill%7Ccolor%3A0x616161%7C&style=feature%3Aall%7Celement%3Alabels.text.stroke%7Ccolor%3A0xf5f5f5%7C&style=feature%3Aadministrative.land_parcel%7Celement%3Alabels.text.fill%7Ccolor%3A0xbdbdbd%7C&style=feature%3Apoi%7Celement%3Ageometry%7Ccolor%3A0xeeeeee%7C&style=feature%3Apoi%7Celement%3Alabels.text.fill%7Ccolor%3A0x757575%7C&style=feature%3Apoi.park%7Celement%3Ageometry%7Ccolor%3A0xe5e5e5%7C&style=feature%3Apoi.park%7Celement%3Alabels.text.fill%7Ccolor%3A0x9e9e9e%7C&style=feature%3Aroad%7Celement%3Ageometry%7Ccolor%3A0xffffff%7C&style=feature%3Aroad.arterial%7Celement%3Alabels.text.fill%7Ccolor%3A0x757575%7C&style=feature%3Aroad.highway%7Celement%3Ageometry%7Ccolor%3A0xdadada%7C&style=feature%3Aroad.highway%7Celement%3Alabels.text.fill%7Ccolor%3A0x616161%7C&style=feature%3Aroad.local%7Celement%3Alabels.text.fill%7Ccolor%3A0x9e9e9e%7C&style=feature%3Atransit.line%7Celement%3Ageometry%7Ccolor%3A0xe5e5e5%7C&style=feature%3Atransit.station%7Celement%3Ageometry%7Ccolor%3A0xeeeeee%7C&style=feature%3Awater%7Celement%3Ageometry%7Ccolor%3A0x686868%7C&style=feature%3Awater%7Celement%3Alabels.text.fill%7Ccolor%3A0x9e9e9e%7C"

export default styledMap
