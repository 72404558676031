import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import RibbonSVG from "./RibbonSVG"

import { removeFromCart } from "../../Cart/actions"
import { CancelIcon } from "../../../components/icons"
import { Link } from "../../../components/button"
import IconDataRow from "../../../components/datarenderers/IconDataRow"

const StyledPaymentDetails = styled.div`
  padding-top: 25px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-weight: 200;
  p {
    font-size: 16px;
  }
`

export const StyledBookingTitle = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
`

export const StyledPrice = styled.div`
  text-align: right;
  margin: 0px;
  display: flex;
  margin-bottom: 4px;
`

export const StyledLine = styled.div`
  font-size: 16px;
  color: #868686;
  font-weight: 400;
`

const CartSection = styled.div`
  border-bottom: ${props => `solid 2px red${props.theme.sectionDividerColor}`};
`

class PaymentDetails extends Component {
  formatPrice = (symbol, price) => {
    if (price >= 0) {
      return (
        symbol
        + parseFloat(price)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      )
    }

    return (
      `-${
        symbol
      }${Math.abs(parseFloat(price))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
    )
  }

  renderDiscounts() {
    const { cart } = this.props

    const discounts = cart.items.filter(i => i.type === "discount")

    if (!discounts.length) {
      return null
    }

    return (
      <React.Fragment>
        {discounts.map(discount => (
          <StyledPrice key={discount.id}>
            <StyledLine style={{ textAlign: "left" }}>
              {discount.name}
            </StyledLine>
            <div style={{ flex: 1 }}>&nbsp;</div>
            <StyledLine style={{ textAlign: "right" }}>
              <div>
                {this.formatPrice(cart.currency.symbol, discount.price)}
              </div>
            </StyledLine>
          </StyledPrice>
        ))}
      </React.Fragment>
    )
  }

  renderPromotionCodes() {
    const { cart, removeFromCart } = this.props

    const codes = cart.items.filter(i => i.type === "code")

    if (!codes.length) {
      return null
    }

    return (
      <React.Fragment>
        {codes.map(code => (
          <StyledPrice key={code.id}>
            <StyledLine style={{ textAlign: "left" }}>
              {code.code.toUpperCase()}
            </StyledLine>

            <div style={{ flex: 1 }}>&nbsp;</div>
            <Link
              style={{
                marginRight: 16,
                display: "flex",
                alignItems: "center",
                fontSize: 14,
              }}
              href
              onClick={() => {
                removeFromCart(code.identifier)
              }}
            >
              <IconDataRow icon={<CancelIcon />}>Remove</IconDataRow>
            </Link>
            <StyledLine style={{ textAlign: "right" }}>
              <div>{this.formatPrice(cart.currency.symbol, code.price)}</div>
            </StyledLine>
          </StyledPrice>
        ))}
      </React.Fragment>
    )
  }

  renderDollars() {
    const { cart } = this.props

    const dollars = cart.items.filter(i => i.type === "dollars")

    if (!dollars.length) {
      return null
    }

    const value = dollars.reduce((total, d) => total + parseFloat(d.price), 0)

    return (
      <StyledPrice>
        <StyledLine style={{ textAlign: "left" }}>Flossie Dollars</StyledLine>
        <StyledLine style={{ textAlign: "right", flex: 1 }}>
          <div>{this.formatPrice(cart.currency.symbol, value)}</div>
        </StyledLine>
      </StyledPrice>
    )
  }

  render() {
    const { cart, selectedPurchaseMethod } = this.props

    if (!cart || !cart.currency) {
      return null
    }

    const buyNowPriceEnabled = cart.items.filter(
      i => i.buy_now_discount_percent
          && parseFloat(i.buy_now_discount_percent) > 0
    ).length > 0
    const buyNowDiscount = cart.items.reduce(
      (total, i) => (i.buy_now_discount_percent
        && parseFloat(i.buy_now_discount_percent) > total
        ? i.buy_now_discount_percent
        : total),
      0
    )

    // If any items in cart are for any employee the price is not final, so add "up to" qualifier
    const paymentQualifier = cart.items.filter(i => i.type === "appointment" && !i.employee).length > 0 ? "up to " : ""

    const paymentRequired = `${cart.currency.symbol}${cart.total} ${cart.currency.code}`

    return (
      <div>
        <StyledPaymentDetails>
          {(selectedPurchaseMethod === "Buy Now"
            || selectedPurchaseMethod === "Pay In Salon") && (
            <div>
              {buyNowPriceEnabled && (
                <div style={{ marginBottom: 24 }}>
                  <RibbonSVG />
                  <p style={{ margin: 0, padding: 0, paddingTop: 4 }}>
                    {`Get rewarded! Up to ${Math.round(
                      buyNowDiscount
                    )}% off when you pay up front`}
                  </p>
                </div>
              )}

              {selectedPurchaseMethod === "Pay In Salon" && (
                <React.Fragment>
                  <p style={{ margin: 0 }}>
                    You will be charged
                    {" "}
                    {paymentQualifier}
                    <span style={{ fontWeight: 600 }}>{paymentRequired}</span>
                    {" "}
                    at the time of your appointment.
                  </p>
                </React.Fragment>
              )}
            </div>
          )}
        </StyledPaymentDetails>

        {selectedPurchaseMethod !== "Pay In Salon" && (
          <CartSection>
            <StyledPrice style={{ marginBottom: 10 }}>
              <StyledBookingTitle>Total to pay</StyledBookingTitle>
              <div style={{ textAlign: "right" }}>
                {this.formatPrice(cart.currency.symbol, cart.total_gross)}
              </div>
            </StyledPrice>

            {this.renderDiscounts()}
            {this.renderPromotionCodes()}
            {this.renderDollars()}

            {/*  <StyledPrice small>
                {buyNowPriceEnabled &&
                  selectedPurchaseMethod === "Buy Now" &&
                  `Less ${buyNowDiscount}% `}
                <strike>{cart.total}</strike> <StyledCurrency>{cart.currency.code}</StyledCurrency>
              </StyledPrice> */}

            {cart.total !== cart.total_gross && (
              <StyledPrice
                style={{
                  borderTop: "1px dashed #c2c2c3",
                  paddingTop: 10,
                  marginTop: 16
                }}
              >
                <StyledBookingTitle>Remaining</StyledBookingTitle>
                <div style={{ textAlign: "right" }}>
                  {this.formatPrice(cart.currency.symbol, cart.total)}
                </div>
              </StyledPrice>
            )}
          </CartSection>
        )}
      </div>
    )
  }
}

PaymentDetails.propTypes = {
  cart: PropTypes.object,
  removeFromCart: PropTypes.func,
  selectedPurchaseMethod: PropTypes.string
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    paymentMethods: state.cart.cart.payment_methods,
    selectedPaymentMethod: state.purchase.selectedPaymentMethod,
    selectedPurchaseMethod: state.purchase.selectedPurchaseBtn
  }
}

const mapDispatchToProps = dispatch => ({
  removeFromCart: identifier => dispatch(removeFromCart(identifier))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetails)
