export const initialState = {
  company: null,
  services: [],
  reviews: [],
  showMap: false,
  bounds: false,
  mapZoom: 12,
  loadingCompany: false
}

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case "ZOOM_CHANGE":
      return {
        ...state,
        mapZoom: action.payload
      }
    case "CLEAR_BOUNDS":
      return {
        ...state,
        bounds: false
      }
    case "BOUNDS_CHANGE":
      return {
        ...state,
        bounds: action.payload
      }
    case "TOGGLE_MAP":
      return {
        ...state,
        showMap: !state.showMap
      }
    case "FETCH_COMPANY":
      return {
        ...state,
        loadingCompany: true,
        company: null,
        reviews: [],
        services: []
      }
    case "FETCH_COMPANY_SUCCESS":
      return {
        ...state,
        company: action.payload.response,
        loadingCompany: false
      }
    case "FETCH_COMPANY_PROFILE_REVIEWS_SUCCESS":
      return {
        ...state,
        reviews: action.payload.response
      }
    case "FETCH_COMPANY_SERVICES_SUCCESS":
      return {
        ...state,
        services: action.payload.response
      }
    case "CLEAR_COMPANY":
      return {
        ...state,
        company: null,
        services: []
      }
    default:
      return state
  }
}
