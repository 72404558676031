import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import PhoneIcon from "../icons/phone.svg"
import IconDataRow from "./IconDataRow"
import { GoToButton } from "../button"

const PhoneRow = styled(IconDataRow)`
  align-items: center;
`

export const Phone = ({ number }) => (
  <PhoneRow icon={<PhoneIcon />}>
    <a style={{ color: "inherit", textDecoration: "none" }} onClick={(e) => { e.stopPropagation() }} href={`tel:${number}`}>
      {number}
    </a>
  </PhoneRow>
)
Phone.propTypes = {
  number: PropTypes.string.isRequired,
}

export const ButtonPhone = ({ number, children }) => (
  <GoToButton
    onClick={(e) => { e.stopPropagation() }}
    href={`tel:${number}`}
  >
    {children}
  </GoToButton>
)

ButtonPhone.propTypes = {
  number: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default Phone
