import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment-timezone"

import { CalendarIcon } from "../icons"
import IconDataRow from "./IconDataRow"


const StyledP = styled(IconDataRow)`
  margin: 0;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  display: flex;
`

export const ListAppointmentTime = ({ appointmentTime, timezone, children }) => {
  // eslint-disable-line
  if (!appointmentTime || !timezone) return null
  return (
    <StyledP icon={<CalendarIcon />}>
      <div>
        <b>
          {moment(appointmentTime)
            .tz(timezone)
            .format("ddd D MMM [at] h:mm a")}
        </b>
        {children}
      </div>
    </StyledP>
  )
}

ListAppointmentTime.propTypes = {
  appointmentTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  children: PropTypes.element,
}
