import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import ClickableLabel from "../../../components/ClickableLabel"
import buttonize from "../../../components/buttonize"

import { removeFromCart } from "../../Cart/actions"

const StyledParentDiv = styled.div`
  flex: 5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  display: block;
`

class PromotionCodeButton extends Component {
  render() {
    const { openPromotionCodeSelector } = this.props

    return (
      <div>
        <ClickableLabel {...buttonize(openPromotionCodeSelector)}>
          <StyledParentDiv>
            <div>Add Promo Code</div>
          </StyledParentDiv>
        </ClickableLabel>
      </div>
    )
  }
}

PromotionCodeButton.propTypes = {
  openPromotionCodeSelector: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  promotionCodes: PropTypes.array.isRequired,
  activePromotionCodes: PropTypes.array.isRequired
}

PromotionCodeButton.default = {
  promotionCodes: [],
  activePromotionCodes: []
}

function mapStateToProps(state) {
  return {
    currency: state.cart.cart.currency,
    promotionCodes: state.cart.cart.codes,
    activePromotionCodes: state.cart.cart.items.filter(i => i.type === "code")
  }
}

const mapDispatchToProps = dispatch => ({
  removeFromCart: identifier => dispatch(removeFromCart(identifier))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCodeButton)
