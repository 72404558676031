import demo from "./franchises/demo"
import flossie from "./franchises/flossie"
import aveda from "./franchises/aveda"
import wella from "./franchises/wella"

export const themes = {
  flossie,
  demo,
  aveda,
  wella
}

const franchiseTheme = () => themes[SITE_CONFIG.THEME]

export default franchiseTheme
