import { push } from "connected-react-router"

export const fetchBookings = () => ({
  type: "FETCH_BOOKINGS"
})

export const toggleHistory = () => ({
  type: "TOGGLE_BOOKINGS_HISTORY"
})

/**
* @param bookingId id of the booking to cancel
* @param shouldRedirect sometimes after the booking is cancelled we want to redirect to another page
* redirect logic it's on './epic.js'
* */
export const cancelBooking = (bookingId, shouldRedirect = false) => ({
  type: "CANCEL_USER_BOOKING",
  payload: { bookingId, shouldRedirect }
})

export const bookingCancelled = (response, bookingId) => ({
  type: "CANCEL_USER_BOOKING_SUCCESS",
  payload: { response, bookingId }
})

export const navToBooking = bookingId => push(`/bookings/${bookingId}`)
export const navToBookings = () => push("/bookings")
export const navToHome = () => push("/")
