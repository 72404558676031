
const DEMO_FRANCHISE_THEME = {
  wwwBgImageSize: "cover",
  wwwBgImage: "/demo-bg.jpg",
  wwwBgImageBlendMode: "multiply",
  wwwBgColor: "#00000010",

  logoImage: "/demo-logo.png",

  topNav: {
    background: "#FFF",
    links: "#000",
  },
}

export default DEMO_FRANCHISE_THEME
