import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment-timezone"

import buttonize from "../../../components/buttonize"
import Animation from "../../../components/Animation"
import { VipTag, PromoTag } from "../../../components/SaleTags"
import { calculatePrice } from "../lib"
import { dayIsUnavailable, dayIsSale } from "../../../../utils/momentHelpers"
import Button from "../../../components/button"
import CalendarMessage from "./CalendarMessage"
import {
  CalendarIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon
} from "../../../components/icons"
import { EMPLOYEE_ID_ANY } from "../reducer"

const ScrapeStatusParent = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  top: calc(50% - 20px);
  text-align: center;
  background-color: transparent;
  z-index: 5;
`
const ScrapeStatus = styled.div`
  display: inline-block;
  margin: 0;
  padding: 6px 12px;
  background-color: #575757;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`

const FindBestPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  svg {
    margin-right: 4px;
  }
`

const FindBestPriceText = styled.span`
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`

const TimeSelectDiv = styled.div`
    cursor: pointer;
    text-align: center;
    padding: 10px;
    background-color: ${props => (props.selected ? props.theme.timeSelector.selectedBackground : "transparent")};
    color: ${props => (props.selected ? props.theme.timeSelector.selectedText : props.theme.timeSelector.defaultText)};
    border-radius: 999px;
    border: 1px solid ${props => props.theme.timeSelector.selectedBackground};

    &:hover {
        background-color: ${props => (props.selected ? props.theme.timeSelector.selectedBackground : props.theme.timeSelector.hoverBackground)};
        color: ${props => (props.selected ? props.theme.timeSelector.selectedText : props.theme.timeSelector.hoverText)};
    }
`

const DaySelectDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  max-width: 50px;
  padding: 5px;
  box-sizing: border-box;
  color: ${props => (props.selected
    ? props.theme.dateSelector.selectedText
    : props.disabled
      ? props.theme.dateSelector.disabledText
      : props.sale
        ? props.theme.dateSelector.saleText
        : props.theme.dateSelector.defaultText)};
  background-color: ${props => (props.selected ? props.theme.dateSelector.selectedBackground : "")};
  /* &:hover {
    background-color: #ccc;
  } */

  & div {
    color: inherit;
  }
`

const WrapperDiv = styled.div`
  background-color: ${props => props.theme.colors.tertiary};
`

class dateSelectorSelector extends Component {
  constructor(props) {
    super(props)
    this.timeSelector = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { selectedAvailability } = this.props

    if (selectedAvailability && selectedAvailability !== prevProps.selectedAvailability) {
      const selectedElement = this.timeSelector.current.querySelector("[data-selected=\"true\"]")

      if (selectedElement
        && (selectedElement.offsetTop + selectedElement.offsetHeight < this.timeSelector.current.scrollTop
        || selectedElement.offsetTop > this.timeSelector.current.scrollTop + this.timeSelector.current.clientHeight)) {
        selectedElement.scrollIntoView({ block: "center" })
      }
    }
  }

  getTimes() {
    const {
      availabilities,
      inventoryItem,
      isVip,
      selectedAvailability,
      selectAvailability,
      selectedEmployeeId
    } = this.props
    const { currency, company: { timezone } } = inventoryItem

    const getDisplayPrice = (a) => {
      if (selectedEmployeeId === EMPLOYEE_ID_ANY) {
        return (
          <React.Fragment>
            {currency.symbol}
            {calculatePrice(inventoryItem.pricing.lowest, isVip)}
            &nbsp;-&nbsp;
            {currency.symbol}
            {calculatePrice(inventoryItem.pricing.highest, isVip)}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          {currency.symbol}
          {Number(calculatePrice(a, isVip)).toFixed(2)}
        </React.Fragment>
      )
    }

    const times = availabilities.map(a => ({
      id: a.id,
      time: moment(a.time.start_time)
        .tz(timezone)
        .format("h:mm a"),
      vip: !!(isVip && a.discount_vip),
      sale: a.sale,
      default: a.default,
      sortKey: moment(a.time.start_time)
        .tz(timezone)
        .valueOf(),
      selected: a.id === selectedAvailability,
      clickTime: () => selectAvailability(a.id),
      displayPrice: getDisplayPrice(a)

    }))
    return times
  }

  getDays() {
    const { selectedDate, inventoryItem, clickDay } = this.props
    const selected = moment(selectedDate)
    const cur = moment(selected).startOf("week")
    const days = []
    for (let i = 0; i < 7; i += 1) {
      const dupDay = moment.tz(cur.format("YYYY-MM-DD"), inventoryItem.company.timezone)
      days.push({
        date: dupDay,
        selected: moment(selectedDate).isSame(dupDay, "day"),
        disabled: dayIsUnavailable(inventoryItem.company, dupDay),
        sale: dayIsSale(inventoryItem.pricing.lowest, dupDay),
        clickDay: () => clickDay(dupDay)
      })
      cur.add(1, "day")
    }
    return days
  }

  renderDay = day => (
    <DaySelectDiv
      selected={day.selected}
      onClick={day.disabled ? undefined : day.clickDay}
      disabled={day.disabled}
      sale={day.sale}
      key={day.date.format("YYYY-MM-DD")}
    >
      <div
        style={{
          flex: 1,
          marginTop: 1,
          textAlign: "center",
          padding: 3,
          fontSize: 12,
          paddingBottom: 8
        }}
      >
        {day.date.format("ddd")}
      </div>
      <div
        style={{
          flex: 1,
          marginTop: -12,
          textAlign: "center",
          padding: 3,
          paddingTop: 0,
          paddingBottom: 7
        }}
      >
        {day.date.format("D")}
      </div>
    </DaySelectDiv>
  )

  renderTime = time => (
    <TimeSelectDiv
      data-selected={time.selected}
      selected={time.selected}
      onClick={time.clickTime}
      key={time.id}
    >
      <div style={{
        whiteSpace: "nowrap", flex: 1
      }}
      >
        {time.time}
      </div>

      {!time.sale && time.vip && <VipTag style={{ marginTop: 13 }} />}
      {time.sale && !time.vip && <PromoTag style={{ marginTop: 13 }} />}
    </TimeSelectDiv>
  )

  compareTime = (timea, timeb) => timea.sortKey - timeb.sortKey

  renderNoAvailability = () => {
    const { showFindNextDate, clickFindNextDate, inventoryItem } = this.props
    const role = inventoryItem.description.category.default_role.trim().toLowerCase()
    return (
      <CalendarMessage title="Sorry!">
        <div>
          <p>
            {`The ${role} you have selected is`}
            <b> booked out today.</b>
          </p>
          <p>{`Select next available date or choose another ${role}.`}</p>
        </div>
        {showFindNextDate && (
          <Button onClick={clickFindNextDate}>
            Find next available date
          </Button>
        )}
      </CalendarMessage>
    )
  }

  /*
  renderNoEmployee = () => {
    const {
      inventoryItem,
      toggleEmployeeSelect
    } = this.props
    return (
      <CalendarMessage>
        <p style={{ maxWidth: "260px" }}>
          <a onClick={toggleEmployeeSelect}>
            Select your
            {" "}
            {inventoryItem.description.category.default_role.trim().toLowerCase()}
          </a>
          {" "}
          above to see available dates and times.
        </p>
      </CalendarMessage>
    )
  } */

  renderNextAvailabilityError = () => {
    const {
      inventoryItem,
      toggleEmployeeSelect
    } = this.props

    const role = inventoryItem.description.category.default_role.trim().toLowerCase()

    return (
      <CalendarMessage title="Sorry!">
        <div>
          <p>
            {`The ${role} you have selected is `}
            <b>unavailable for booking.</b>
          </p>
          <p>{`Please choose another ${role}.`}</p>
        </div>
        <Button filled onClick={toggleEmployeeSelect}>
          {`Choose another ${role}`}
        </Button>
      </CalendarMessage>
    )
  }

  renderBestPriceError = () => {
    const {
      resetBestPriceError,
    } = this.props

    return (
      <CalendarMessage title="Sorry!">
        <div>
          <p>
            {"We couldn't find a better price "}
            <b>for this time</b>
          </p>
          <p>Please try selecting a different time</p>
        </div>
        <Button filled onClick={resetBestPriceError}>Select Time</Button>
      </CalendarMessage>
    )
  }

  renderLoading = () => {
    const {
      scrapeStatus
    } = this.props

    return (
      <div style={{ flex: 1, position: "relative" }}>
        {scrapeStatus && (
          <ScrapeStatusParent>
            <ScrapeStatus>{scrapeStatus}</ScrapeStatus>
          </ScrapeStatusParent>
        )}
        <Animation height={230} />
      </div>
    )
  }

  renderCalendarBody = () => {
    const {
      finishedScrape,
      nextAvailabilityError,
      findBestPriceError,
      isFetchingBestPrice
    } = this.props

    // Still scraping something so show loading animation
    if (isFetchingBestPrice) {
      return this.renderLoading()
    }

    if (nextAvailabilityError) {
      return this.renderNextAvailabilityError()
    }

    if (findBestPriceError) {
      return this.renderBestPriceError()
    }

    const times = this.getTimes()

    const TimeSelectorContainer = styled.div`
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        height: 100%;
        padding: 30px 2.5em 0;
    `

    if (times.length > 0) {
      // available times :)
      return (
        <TimeSelectorContainer ref={this.timeSelector} numberOfTimes={times.length}>
          {times.sort(this.compareTime).map(this.renderTime)}
        </TimeSelectorContainer>
      )
    }

    if (!finishedScrape) {
      return this.renderLoading()
    }

    // none of the above show no availability
    return this.renderNoAvailability()
  }

  render = () => {
    const days = this.getDays()

    const {
      selectedDate,
      clickNextWeek,
      clickPrevWeek,
      clickFindBestPrice,
      showServiceCalendar,
      showFindBestPrice,
    } = this.props

    return (
      <WrapperDiv>
        <div
          style={{
            height: 50,
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            fontWeight: 300,
            padding: "10px 0px 0px 0px"
          }}
        >
          {clickPrevWeek && (
            <div
              {...buttonize(clickPrevWeek, { flex: 1, padding: 10 })}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {!clickPrevWeek && (
            <div style={{ flex: 1, padding: 10 }} />
          )}
          <div
            role="button"
            tabIndex={0}
            style={{
              flex: 3,
              padding: 10,
              fontWeight: 700,
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => showServiceCalendar(true)}
            onKeyDown={() => showServiceCalendar(true)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CalendarIcon style={{ height: "20px" }} />
              <span style={{ paddingLeft: "5px" }}>{moment(selectedDate).format("MMMM YYYY")}</span>
            </div>
          </div>
          <div
            {...buttonize(clickNextWeek, {
              flex: 1,
              padding: 10,
              textAlign: "right"
            })}
          >
            <ChevronRightIcon />
          </div>
        </div>
        <div
          style={{
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: 3,
            borderBottom: "1px solid #ccc"
          }}
        >
          {days.map(this.renderDay)}
        </div>
        <div style={{ height: 240, position: "relative", overflow: "auto" }}>
          <div style={{ position: "relative", height: "auto" }}>
            {this.renderCalendarBody()}
            {showFindBestPrice && (
              <FindBestPrice onClick={clickFindBestPrice}>
                <SearchIcon />
                <FindBestPriceText>Find Best Price</FindBestPriceText>
              </FindBestPrice>
            )}
          </div>
        </div>
      </WrapperDiv>
    )
  }
}

dateSelectorSelector.propTypes = {
  showFindNextDate: PropTypes.bool.isRequired,
  showFindBestPrice: PropTypes.bool,
  clickFindNextDate: PropTypes.func.isRequired,
  clickFindBestPrice: PropTypes.func,
  clickPrevWeek: PropTypes.func,
  clickNextWeek: PropTypes.func.isRequired,
  clickDay: PropTypes.func.isRequired,

  selectedAvailability: PropTypes.string,
  selectedDate: PropTypes.string.isRequired,
  inventoryItem: PropTypes.object.isRequired,
  availabilities: PropTypes.array.isRequired,
  toggleEmployeeSelect: PropTypes.func.isRequired,
  isVip: PropTypes.bool,
  selectAvailability: PropTypes.func,
  selectedEmployeeId: PropTypes.string,
  finishedScrape: PropTypes.bool,
  nextAvailabilityError: PropTypes.bool,
  showServiceCalendar: PropTypes.func,

  isFetchingBestPrice: PropTypes.bool,
  findBestPriceError: PropTypes.bool,
  scrapeStatus: PropTypes.string,
  resetBestPriceError: PropTypes.func, // cleans best price error to allow user select a different time
}

dateSelectorSelector.defaultProps = {
  selectedAvailability: null,
  showFindBestPrice: false,
}

export default dateSelectorSelector
