import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { navToRegister } from "../../containers/NavBar/actions"

const PromoDiv = styled.div`
  display: ${props => (props.isMobile ? "block" : "flex")};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #fac8ce;
  cursor: pointer;
`

const PromoText = styled.p`
  margin: 0;
  font-weight: 350;
  font-size: ${props => (props.isMobile ? "12px" : "14px")};
  text-align: center;
  padding: 6px 10px;
  letter-spacing: 2px;
`

const PromoBanner = ({ isMobile, loggedIn, navToRegister = null }) => {
  const showBanner = SITE_CONFIG.SIGNUP_BANNER_ENABLED
  let payload = null
  if (showBanner && !loggedIn) {
    payload = (
      <PromoDiv
        isMobile={isMobile}
        onClick={() => (navToRegister ? navToRegister() : null)}
      >
        <PromoText isMobile={isMobile}>
          WELCOME! ENJOY
          {" "}
          <b>$10 OFF YOUR FIRST BOOKING</b>
          {" "}
          WHEN YOU SIGN-UP
        </PromoText>
      </PromoDiv>
    )
  }
  return payload
}

PromoBanner.propTypes = {
  isMobile: PropTypes.bool,
  showBanner: PropTypes.bool,
  loggedIn: PropTypes.bool,
  navToRegister: PropTypes.func
}

PromoBanner.defaultProps = {
  isMobile: false,
  showBanner: false,
  loggedIn: false,
  navToRegister: null
}

const mapStateToProps = state => ({
  loggedIn: !!state.profile.profile.id,
  isMobile: state.browser.lessThan.mobile
})

const mapDispatchToProps = dispatch => ({
  navToRegister: () => dispatch(navToRegister())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoBanner)
