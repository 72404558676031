import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { goBack } from "connected-react-router"
import styled from "styled-components"
import { PrismicRichText } from '@prismicio/react'

import WrapContainer from "../../containers/WrapContainer"
import MetaSetter from "../../components/MetaSetter"
import { BackLink } from "../../components/nav/Links"
import { getPageContent } from "./selectors"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;

  div > h1 + div {
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const HealthContainer = ({ navToPrevious, isMobile, isLarge, pageContent }) => {
  return (
    <WrapContainer loginRequired={false}>
      <MetaSetter
        metaTitle="Your Health"
        metaDescription="It’s now even more important to maintain a high standard of hygiene and safety."
      />
      <ParentDiv>
        <BackLink
          href="/"
          onClick={e => {
            e.preventDefault()
            navToPrevious()
          }}
          style={{ left: isLarge ? 0 : 20, top: 16 }}
        />

        <div>
          <div style={{
            margin: 0, padding: 0, textAlign: "center"
          }}
          >
            <PrismicRichText field={pageContent.title} />
          </div>
          <div style={{ padding: !isLarge ? "0 20px" : 0, textAlign: "left" }}>
            <PrismicRichText field={pageContent.textContent} />
          </div>
        </div>
      </ParentDiv>


    </WrapContainer>
  )
}

HealthContainer.propTypes = {
  navToPrevious: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  pageContent: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    isLarge: state.browser.greaterThan.large,
    pageContent: getPageContent(),
  }
}


const mapDispatchToProps = dispatch => ({
  navToPrevious: () => dispatch(goBack())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthContainer)
