import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledP = styled.p`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
`

export const ListCompanyHeading = ({ text, location }) => (
  <React.Fragment>
    <StyledP>{text}</StyledP>
    {location && <StyledP>{location}</StyledP>}
  </React.Fragment>
)

ListCompanyHeading.propTypes = {
  text: PropTypes.string.isRequired,
  location: PropTypes.string
}

ListCompanyHeading.defaultProps = {
  location: null
}
