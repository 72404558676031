// import { push } from "connected-react-router"

export const loadAllFaqs = () => ({
  type: "LOAD_ALL_FAQS"
})

export const searchFaqs = (query) => ({
  type: "SEARCH_FAQS",
  payload: { query }
})
