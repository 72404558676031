import styled from "styled-components"

const ClickableLabel = styled.div`
  flex: 4;
  display: flex;
  align-items: center;
  cursor: ${props => (props.noArrow ? "default" : "pointer")};
  background-color: ${props => (props.white ? "" : props.theme.colors.tertiary)};
  border: ${props => (props.white ? "" : "1px solid #eee")};
  padding: 10px;
  border-bottom: ${props => (props.white ? "1px solid #ccc" : "1px solid #eee")};
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

export default ClickableLabel
