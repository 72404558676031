import React from "react"
import PropTypes from "prop-types"

import buttonize from "./buttonize"
import { ToggleIcon } from "./icons"

const FlossieCheckBox = ({
  children,
  onClick,
  selected,
  labelContent,
  extraStyle = {},
  buttonStyle = {}
}) => (
  <div style={{ display: "flex", alignItems: "center", ...extraStyle }}>
    <div
      {...buttonize(onClick, {
        display: "flex",
        alignItems: "center",
        ...buttonStyle
      })}
    >
      <ToggleIcon active={selected} />
      {labelContent}
    </div>
    {children}
  </div>
)

FlossieCheckBox.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  labelContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  extraStyle: PropTypes.object,
  buttonStyle: PropTypes.object
}

export default FlossieCheckBox
