import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import styled from "styled-components"

import { useTranslation } from "react-i18next"
import { IconDataRow, ButtonPhone } from "../../components/datarenderers"
import { Link } from "../../components/button"
import { CancelIcon } from "../../components/icons"
import ShadowBox from "../../components/ShadowBox"
import { SubmitInput } from "../../components/forms/Inputs"

// If a booking can't be cancelled then color differently
const CancelLink = styled(IconDataRow)`
    div {
        color: ${props => (props.cannotCancel ? "#aaa" : "inherit")};
    }
`

const ConfirmationPopUp = ({
  header, message, button, onClose, isMobile,
}) => (
  <ShadowBox
    containerStyle={{
      minWidth: 0,
      minHeight: 0,
      maxWidth: 400,
      marginLeft: 10,
      marginRight: 10,
      textAlign: "left"
    }}
    isFullscreen={isMobile}
    header={header}
    closeBox={onClose}
  >
    <div>
      <p>{message}</p>
      <div style={{ textAlign: "center", margin: "0 auto", display: "block" }}>{button}</div>
    </div>
  </ShadowBox>
)

ConfirmationPopUp.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  button: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

const CancelBookingLink = ({
  company, onCancel, bookingId, appointmentTime, style, isMobile, appointmentSynced
}) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [showRetryLater, setShowRetryLater] = React.useState(false)

  const appointmentTooSoon = moment(appointmentTime).isBefore(moment().add(48, "hours"))

  const { t } = useTranslation()

  const handleClick = (e) => {
    // in some places we show this inside another clickable component
    // thats why we need to stop the event default behaviour
    e.preventDefault()
    e.stopPropagation()

    // Outside cancellation period, so sync state doesn't matter
    if (appointmentTooSoon) {
      setShowConfirmation(true)
      return
    }

    // Not synced so cannot cancel
    if (!appointmentSynced) {
      setShowRetryLater(true)
      return
    }

    // You may proceed
    setShowConfirmation(true)
  }

  const handleCancel = () => {
    onCancel(bookingId)
    setShowConfirmation(false)
  }

  // If after 48hs period cancallation available
  let message = "Are you sure you want to cancel your appointment?"
  let button = <SubmitInput onClick={handleCancel} value="Yes, please cancel" />
  if (appointmentTooSoon) {
    // If before 48hs period cancellation has to be made calling the salon
    const { phone_number } = company
    message = "You are outside the cancellation period. Please call the salon directly."
    button = <ButtonPhone number={phone_number}>Call salon now</ButtonPhone>
  }

  // Color link differently depending on state
  const cannotCancel = appointmentTooSoon || !appointmentSynced

  // We show the cancel link only if these conditions are met
  // - Company allows cancellations
  // - Booking time is in the future
  if (company.allow_cancel_booking && onCancel && moment(appointmentTime).isAfter()) {
    return (
      <div style={style}>
        <Link href={`/bookings/${bookingId}/cancel`} onClick={handleClick}>
          <CancelLink cannotCancel={cannotCancel} icon={<CancelIcon />}>{t("cancel_booking")}</CancelLink>
        </Link>
        {showConfirmation && (
          <ConfirmationPopUp
            header={t("cancel_booking")}
            message={message}
            button={button}
            onClose={() => setShowConfirmation(false)}
            isMobile={isMobile}
          />
        )}
        {showRetryLater && (
          <ConfirmationPopUp
            header="Unable to cancel"
            message="We are unable to cancel your booking at this time. Please try again in a moment."
            button={(
              <SubmitInput onClick={() => setShowRetryLater(false)} value="OK" />
            )}
            onClose={() => setShowRetryLater(false)}
            isMobile={isMobile}
          />
        )}
      </div>

    )
  }

  // either company doesnt allow cancellations
  // or we are showing the booking details before payment so the onCancel function is undefined
  return null
}
CancelBookingLink.defaults = {
  appointmentSynced: false
}

CancelBookingLink.propTypes = {
  style: PropTypes.object,
  company: PropTypes.object.isRequired, // used for checking if company allows cancellations
  onCancel: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired,
  appointmentSynced: PropTypes.bool.isRequired,
  appointmentTime: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
}

export default CancelBookingLink
