import { push } from "connected-react-router"
import rg4js from "raygun4js"

export const navToFourOhFour = (reason = 'unknown reason') => {

 // rg4js('send', {
 //   error: new Error(`404: ${reason}`),
 //   tags: [
 //     '404',
 //     'redirect_to_404',
 //   ]
 // })

  return push("/404-page-not-found")
}
