import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { TextInput, SubmitInput } from "../../components/forms/Inputs"

const SearchBox = styled.form`
  width: 100%;
  display: flex;
  margin: 0px 0px 30px 0px;
  input[type="text"] {
    width: 100%;
    margin-right: 15px;
  }
`

const SearchInput = ({
  navigateTo, match,
}) => {
  const { query } = match.params

  // If query is undefined (as is the case when loading /help) then the default value
  // for the input must be a string or React assumes the input is uncontrolled and errors
  // when the value changes later
  const defaultValue = typeof query !== "undefined" ? query : ""

  const [value, setValue] = React.useState(defaultValue)

  const handleChange = e => setValue(e.target.value)

  const handleClick = () => {
    if (value && value.trim() !== "") {
      navigateTo(`/help-search/${value}`)
    } else {
      navigateTo("/help")
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    handleClick()
  }

  return (
    <SearchBox onSubmit={handleSubmit}>
      <TextInput placeholder="Type keywords to find answers" value={value} onChange={handleChange} />
      <SubmitInput onClick={handleClick} value="Search" />
    </SearchBox>
  )
}

SearchInput.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

export default SearchInput
