import { createStore, compose, applyMiddleware } from "redux" // applyMiddleware, compose,
import { persistStore, persistReducer, createMigrate } from "redux-persist"

import { responsiveStoreEnhancer } from "redux-responsive"
import { routerMiddleware } from "connected-react-router"
import { createEpicMiddleware } from "redux-observable"
import { createBrowserHistory } from "history"
import { createTracker } from "redux-segment"

import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import createRootReducer from "./rootReducer"
import segmentMapping from "./segmentMapping"

import rootEpics from "./rootEpics"
import loadingBarMiddleware from "./loadingBarMiddleware"
import loggedInMiddleware from "./loggedInMiddleware"
import pusherSubscriptionMiddleware from "./pusherSubscriptionMiddleware"

import { migrations } from "./migrations"


const persistConfig = {
  key: "root",
  version: 2,
  storage,
  whitelist: ["credentials", "cart", "profile", "purchase"],
  migrate: createMigrate(migrations, { debug: false })
}


export default () => {
  const history = createBrowserHistory()
  const reduxRouterMiddleware = routerMiddleware(history)
  const epicMiddleware = createEpicMiddleware(rootEpics)
  const rootReducer = createRootReducer(history)
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  // segment.io
  const trackerMiddleware = createTracker({
    // skipDefaultMapping: true,
    mapper: segmentMapping
  })

  const store = createStore(
    persistedReducer,
    {},
    compose(
      responsiveStoreEnhancer,
      applyMiddleware(
        loggedInMiddleware,
        pusherSubscriptionMiddleware,
        epicMiddleware,
        reduxRouterMiddleware,
        trackerMiddleware,
        loadingBarMiddleware
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
        : f => f
    )
  )

  const persistor = persistStore(store)
  return {
    store,
    persistor,
    history
  }
}
