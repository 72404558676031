import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import moment from "moment-timezone"

import buttonize from "../../components/buttonize"
import ClickableLabel from "../../components/ClickableLabel"
import { PromoTag } from "../../components/SaleTags"
import ArrowDownIcon from "../../components/icons/chevron-down.svg"

import { StarIcon } from "../../components/icons"

export const ThemedSpan = styled.span`
  color: ${({ theme, highlighted }) => (highlighted ? theme.colors.primary : theme.colors.secondary)};
`

const ThemedArrowDownIcon = styled(ArrowDownIcon)`
  width: 20px;
  margin-left: 5px;
  fill: ${({ theme }) => theme.colors.primary};
`

export const CompanyLink = styled.a`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 18px;

  &:hover {
    text-decoration: underline;
  }
`

export const SquareBgImage = styled.div`
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`

// TODO can we remove this? its not being used anywhere
export const AvailableToday = styled.div`
  position: absolute;
  bottom: ${props => (props.active ? (props.isMobile ? "-17px" : "-22px") : "50px")};
  background-color: ${props => (props.active ? "#EF609F" : "#fff")};
  color: #fff;
  line-height: ${props => (props.isMobile ? "17px" : "20px")}
  font-size: ${props => (props.isMobile ? "11px" : "13px")};
  padding: ${props => (props.isMobile ? "3px" : "5px")};
  padding-left: ${props => (props.isMobile ? "5px" : "10px")};
  padding-right: ${props => (props.isMobile ? "5px" : "10px")};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 4;
  transition: bottom 0.6s;
  transition-timing-function: ease-out;
  transition-delay: 0.5s;

  left: ${props => (props.isMobile ? "0%" : "50%")};
  width: ${props => (props.isMobile ? "170px" : "200px")};
  text-align: center;
  margin-left:  ${props => (props.isMobile ? "10px" : "-110px")};
`

export const ClickableLabelWithArrow = ({
  onClick,
  children,
  style,
  noArrow,
  extraLabel
}) => (
  <ClickableLabel {...buttonize(onClick, style)} noArrow={noArrow}>
    <div
      style={{
        flex: 5, textAlign: "left", fontWeight: 700, paddingLeft: 12
      }}
    >
      {children}
    </div>
    <div style={{ textAlign: "right", alignItems: "center", display: "flex" }}>
      {extraLabel && (
        <span
          style={{ textTransform: "uppercase", fontSize: 13, paddingRight: 4 }}
        >
          {extraLabel}
        </span>
      )}
      {!noArrow && (
        <ThemedArrowDownIcon />
      )}
    </div>
  </ClickableLabel>
)

ClickableLabelWithArrow.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  style: PropTypes.object,
  noArrow: PropTypes.bool,
  extraLabel: PropTypes.string
}

/* Reviews */

export const RenderStarRatings = withTheme(({
  clickStars, rating, ratingCount, theme,
}) => (
  <React.Fragment>
    {ratingCount > 0 && (
      <div
        {...buttonize(clickStars, {
          display: "flex",
          alignItems: "center",
          width: "auto",
          fontSize: 17,
          textAlign: "left",
          color: theme.colors.primary,
        })}
      >
        <StarRating rating={rating} />
        <div style={{ marginLeft: 4, fontSize: 15, color: theme.colors.primary }}>
          {" "}
          (
          {ratingCount}
          {" "}
          ratings)
        </div>
      </div>
    )}
  </React.Fragment>
))

RenderStarRatings.propTypes = {
  clickStars: PropTypes.func,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  theme: PropTypes.object.isRequired,
}

export const StarRating = ({ rating }) => {
  return (
    <div>
      {[0, 2, 4, 6, 8].map(n => {
        const rounded = Math.round(rating * 2)
        if (rounded >= n + 2) return <StarIcon variant="full" />
        if (rounded >= n + 1) return <StarIcon variant="half" />
        return <StarIcon variant="empty" />
      })}
    </div>
  )
}

StarRating.propTypes = {
  rating: PropTypes.number,
}

export const Review = ({ style, review }) => (
  <div key={review.id} style={{ ...style }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <StarRating rating={parseInt(review.rating)} />
      {review.is_resolved && (
        <ThemedSpan
          highlighted
          style={{
            display: "inline-block",
            marginTop: -2,
            marginLeft: 10,
            fontSize: 14
          }}
        >
          <span style={{ fontSize: 20 }}>&#x2714;</span>
          {" "}
          Resolved
        </ThemedSpan>
      )}
    </div>
    <p style={{ margin: 0, fontStyle: "italic" }}>{review.comment}</p>
    <p style={{ margin: 0 }}>
      {review.profile.first_name}
      ,
      {moment(review.created_at).format("MMM YY")}
    </p>

    {review.response && (
      <div style={{ display: "flex" }}>
        <div>&#x21b3;</div>
        <div style={{ fontSize: 12, marginLeft: 6, marginTop: 2 }}>
          <ThemedSpan highlighted>Flossie Response</ThemedSpan>
          <div style={{ fontStyle: "italic" }}>{review.response}</div>
        </div>
      </div>
    )}
  </div>
)

Review.propTypes = {
  style: PropTypes.object,
  review: PropTypes.object
}
