import {
  apiFetchService,
  handleXhrError,
  apiGetCompaniesForService,
  apiGetCompanyReviews,
  apiGetCampaignsForService,
  apiFetchCampaign,
  apiFetchCampaigns,
  apiGetSimilarServices,
  apiGetRelatedServices
} from "../../api/api"

import {
  navToFourOhFour
} from "../FourOhFour/actions"

export const fetchInventoryErrorEpic = (action$, { getState }) => action$.ofType("FETCH_INVENTORY_ERROR", "FETCH_CAMPAIGN_ERROR", "FETCH_COMPANY_FAILURE")
  .map(response => navToFourOhFour(response.type))

const fetchInventoryFulfilled = (response, action) => ({
  type: "FETCH_INVENTORY_SUCCESS",
  payload: { response, action }
})

export const fetchInventoryEpic = (action$, { getState }) => action$.ofType("FETCH_INVENTORY", "SELECT_SERVICE_ID").mergeMap(action => apiFetchService(getState().credentials.credentials, action.payload.id)
  .map(response => fetchInventoryFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_INVENTORY_ERROR")))

const fetchCampaignFulfilled = (response, action) => ({
  type: "FETCH_CAMPAIGN_SUCCESS",
  payload: { response, action }
})
export const fetchCampaignEpic = (action$) => action$.ofType("FETCH_CAMPAIGN").mergeMap(action => apiFetchCampaign(action.payload.campaignSlug)
  .map(response => fetchCampaignFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_CAMPAIGN_ERROR")))

const fetchCampaignsFulfilled = (response, action) => ({
  type: "FETCH_CAMPAIGNS_SUCCESS",
  payload: { response, action }
})
export const fetchCampaignsEpic = (action$) => action$.ofType("FETCH_CAMPAIGNS").mergeMap(action => apiFetchCampaigns(true)
  .map(response => fetchCampaignsFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_CAMPAIGNS_ERROR")))

const fetchCompaniesForServiceFulfilled = (response, action) => ({
  type: "FETCH_COMPANIES_FOR_SERVICE_SUCCESS",
  payload: { response, action }
})
export const getCompaniesForServiceEpic = (action$) => action$.ofType("FETCH_COMPANIES_FOR_SERVICE").mergeMap(action => apiGetCompaniesForService(action.payload.serviceId)
  .map(response => fetchCompaniesForServiceFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_COMPANIES_FOR_SERVICE_ERROR")))

const fetchCampaignsForServiceFulfilled = (response, action) => ({
  type: "FETCH_CAMPAIGNS_FOR_SERVICE_SUCCESS",
  payload: { response, action }
})

export const getCampaignsForServiceEpic = (action$) => action$
  .ofType("FETCH_INVENTORY_SUCCESS")
  .filter(
    action => action.payload.response.campaigns
        && action.payload.response.campaigns.length > 0
  )
  .mergeMap(action => apiGetCampaignsForService(
    action.payload.response.campaigns[0].id,
    action.payload.response.company.id
  )
    .map(response => fetchCampaignsForServiceFulfilled(response, action))
    .catch(error => handleXhrError(error, "FETCH_CAMPAIGNS_FOR_SERVICE_ERROR")))

const getCompanyReviewsFulfilled = response => ({
  type: "LOAD_COMPANY_REVIEWS_SUCCESS",
  payload: { response }
})

const getServiceReviewsFulfilled = response => ({
  type: "LOAD_SERVICE_REVIEWS_SUCCESS",
  payload: { response }
})

export const getServiceReviewsEpic = action$ => action$
  .ofType("FETCH_INVENTORY_SUCCESS")
  .filter(action => parseInt(action.payload.response.rating_count, 10) > 0)
  .mergeMap(action => apiGetCompanyReviews({
    service_id: action.payload.response.id,
    has_comment: 1
  })
    .map(response => getServiceReviewsFulfilled(response, action))
    .catch(error => handleXhrError(error, "LOAD_REVIEWS_ERROR")))

export const getCompanyReviewsEpic = action$ => action$
  .ofType("FETCH_INVENTORY_SUCCESS", "SELECT_COMPANY_ID")
  .filter(
    action => (action.type === "FETCH_INVENTORY_SUCCESS"
          && parseInt(action.payload.response.rating_count, 10) === 0)
        || action.type === "SELECT_COMPANY_ID"
  )
  .mergeMap(action => apiGetCompanyReviews({
    company_id:
          action.type === "SELECT_COMPANY_ID"
            ? action.payload.id
            : action.payload.response.company.id,
    has_comment: 1
  })
    .map(response => getCompanyReviewsFulfilled(response, action))
    .catch(error => handleXhrError(error, "LOAD_REVIEWS_ERROR")))

const fetchSimilarServicesFulfilled = (response, action) => ({
  type: "FETCH_SIMILAR_SERVICES_SUCCESS",
  payload: { response, action }
})

const fetchRelatedServicesFulfilled = (response, action) => ({
  type: "FETCH_RELATED_SERVICES_SUCCESS",
  payload: { response, action }
})

export const fetchServicesByServiceDescriptionIdEpic = (action$) => action$.ofType("SELECT_SERVICE_DESCRIPTION_ID").mergeMap(action => apiGetSimilarServices(action.payload.serviceDescriptionId, action.payload.campaignId)
  .map(response => fetchSimilarServicesFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_SIMILAR_SERVICES_ERROR")))

export const fetchRelatedServicesEpic = (action$, { getState }) => action$.ofType("SELECT_SERVICE_DESCRIPTION_ID").mergeMap(action => apiGetRelatedServices(
  getState().credentials.credentials,
  action.payload.serviceDescriptionId,
  action.payload.campaignId
)
  .map(response => fetchRelatedServicesFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_RELATED_SERVICES_ERROR")))
