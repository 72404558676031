import React from "react"

import { Trans } from "react-i18next"

const AdditionalSignup = () => {
  return (
    <React.Fragment>
      <Trans
        i18nKey="additional_signup_content"
        components={{ bottom_margin_container: <div style={{ marginBottom: 8 }} /> }}
      />
    </React.Fragment>
  )
}
AdditionalSignup.propTypes = {

}

export default AdditionalSignup
