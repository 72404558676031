import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { goBack } from "connected-react-router"
import styled from "styled-components"

import { PRISMIC_TYPES } from "../../constants"

import { navToFourOhFour } from "../FourOhFour/actions"

import WrapContainer from "../../containers/WrapContainer"

import MetaSetter from "../../components/MetaSetter"
import { BackLink } from "../../components/nav/Links"

import { getPartnerTerms, getCustomerTerms, getPrivacyTerms } from "./selectors"

import Chapter from "./Chapter"

import { loadLegalsContent } from "./actions"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;

  div > h1 + div {
    text-align: center;
  }
`

class TermsConditions extends Component {
  componentDidMount() {
    const { loadLegalsContent } = this.props
    loadLegalsContent()

    const termsContent = this.getTermsContent()
    if (!termsContent) {
      const { navToFourOhFour } = this.props
      navToFourOhFour(`Terms content`)
    }
    window.scrollTo(0, 0)
  }

  getTermsContent() {
    const {
      documentType, privacyTerms, partnerTerms, customerTerms,
    } = this.props

    switch (documentType) {
      case PRISMIC_TYPES.PRIVACY_POLICY:
        return privacyTerms
      case PRISMIC_TYPES.PARTNER_TERMS:
        return partnerTerms
      case PRISMIC_TYPES.CUSTOMER_TERMS:
        return customerTerms
      default:
        return {}
    }
  }

  render() {
    const {
      isMobile, navToPrevious, metaTitle, metaDescription,
    } = this.props

    const termsContent = this.getTermsContent()

    if (!termsContent || !termsContent.title) return null

    return (
      <WrapContainer loginRequired={false}>
        <MetaSetter
          metaTitle={metaTitle || termsContent.title}
          metaDescription={metaDescription}
        />
        <ParentDiv>
          <BackLink
            href="/"
            onClick={e => {
              e.preventDefault()
              navToPrevious()
            }}
            style={{ left: isMobile ? 20 : -60, top: 20 }}
          />
          <div style={{ paddingTop: isMobile ? 60 : 20 }}>
            <h1 style={{
              margin: 0, padding: 0, textAlign: "center", fontStyle: "italic"
            }}
            >
              {termsContent.title}
            </h1>
            <div style={{ padding: isMobile ? "0 8px" : 0, textAlign: "left" }}>
              {termsContent.body.map((section, i) => (
                <Chapter
                  key={`section${i}`}
                  title={section.primary.title}
                  items={section.items}
                />
              ))}
            </div>
          </div>
        </ParentDiv>
      </WrapContainer>
    )
  }
}

TermsConditions.propTypes = {
  navToFourOhFour: PropTypes.func.isRequired,
  navToPrevious: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  loadLegalsContent: PropTypes.func.isRequired,
  privacyTerms: PropTypes.object,
  partnerTerms: PropTypes.object,
  customerTerms: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    partnerTerms: getPartnerTerms(state),
    privacyTerms: getPrivacyTerms(state),
    customerTerms: getCustomerTerms(state),
  }
}

const mapDispatchToProps = dispatch => ({
  navToFourOhFour: reason => dispatch(navToFourOhFour(reason)),
  navToPrevious: () => dispatch(goBack()),
  loadLegalsContent: () => dispatch(loadLegalsContent()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsConditions)
