import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const PBFDiv = styled.div`
  z-index: 1000;
  font-size: 9px;
  line-height: 10px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: fixed;
  top: 0px;
  height: 10px;
  width: 114px;
  right: calc(50% - 57px);
  background-color: ${({ theme }) => theme.poweredByFlossie.backgroundColor};
  color: ${({ theme }) => theme.poweredByFlossie.text};
  padding-top: 4px;
  padding-bottom: 6px;
`

const Link = styled.a`
  color: #fff;
  font-weight: 300;
  text-decoration: none;
  text-transform: none;
  font-family: proxima-nova, sans-serif;

  &:hover {
    text-decoration: underline;
  }
  span {
    color: #fff;
    font-family: proxima-nova, sans-serif;
  }
`

const PoweredByFlossie = ({ style, className }) => (
  <PBFDiv className={className} style={style}>
    <Link href="https://poweredbyflossie.com/">
      Powered by
      {" "}
      <span style={{ fontWeight: 600 }}>FLOSSIE</span>
    </Link>
  </PBFDiv>
)

PoweredByFlossie.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
}

export default PoweredByFlossie
