import styled from "styled-components"

export const FixedDiv = styled.div`
  background-color: ${({ theme }) => theme.topNav.background};
  padding: 0px;
  display: flex;
  height: 70px;
  align-items: center;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`
