import React from "react"

import PropTypes from "prop-types"
import FreeProduct from "./images/FreeProduct"


const ProductDescription = ({ product }) => {
  return (
    <div>
      <h4 style={{ marginBottom: 8 }}>{product.name}</h4>
      <div style={{ display: "flex", alignItems: "top", marginBottom: 20 }}>
        <p style={{ margin: 0 }}>{product.description}</p>
        <div style={{ paddingLeft: "15px" }}>
          <FreeProduct product={product} />
        </div>
      </div>
    </div>
  )
}

ProductDescription.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductDescription
