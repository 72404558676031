import * as prismicH from '@prismicio/helpers'

import PrismicContent from "../../../dist/all_prismic_data.json"

const hasFaqs = PrismicContent && PrismicContent[SITE_CONFIG.FAQS] && PrismicContent[SITE_CONFIG.FAQS].length > 0
const getFaqsContent = () => PrismicContent && PrismicContent[SITE_CONFIG.FAQS]

export const initialState = {
  faqTopics: null,
  searchResults: null,
  searchQuery: null,
  showFaqs: hasFaqs, // if there is at least 1 faq in prismic for process.env.DOMAIN then this is set to true
}

export default function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_ALL_FAQS":
      return {
        ...state,
        faqTopics: getFaqsContent(),
      }
    case "SEARCH_FAQS":
      const { query } = action.payload
      // split query by words
      let searchWords = query.split(" ")
      // remove short words (< 3 letters)
      searchWords = searchWords.filter(w => w.length > 3)
      const allFaqs = state.faqTopics
      if (searchWords.length > 0) {
        // we only perform a search if there are words bigger than 3 letters
        let results = []
        allFaqs.forEach((topic) => {
          const faqs = topic.faqs.filter((faq) => {
            let includesWords = false
            const { question, answer } = faq.data
            const plainTextAnswer = prismicH.asText(answer)
            searchWords.forEach((w) => {
              const lowerCasedWord = w.toLowerCase()
              if (question.toLowerCase().includes(lowerCasedWord) || plainTextAnswer.toLowerCase().includes(lowerCasedWord)) {
                includesWords = true
              }
            })
            return includesWords
          })
          results = results.concat(faqs)
        })

        return ({
          ...state,
          searchQuery: query,
          searchResults: results,
        })
      }
      return state
    default:
      return state
  }
}
