import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import { SITE_CONFIG as ALL_CONFIGS } from "../../constants"
import { themes } from "../../franchise"
import { GlobalStyles, theme } from "../../globalStyles"

const defaultFranchiseTheme = themes[SITE_CONFIG.THEME]

const initialState = {
  domain: null,
  themeName: SITE_CONFIG.theme,
}

export const FranchiseContext = React.createContext(initialState)

const FranchiseProvider = ({ children }) => {
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case "setDomain": {
        const domain = action.payload !== "default" ? action.payload : null
        const franchiseConfig = domain ? ALL_CONFIGS[action.payload] : ALL_CONFIGS[process.env.DOMAIN]
        return { ...state, domain, themeName: franchiseConfig.THEME }
      }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const getFranchiseConfig = () => (state.domain ? ALL_CONFIGS[state.domain] : ALL_CONFIGS[process.env.DOMAIN])

  const getAllFranchiseDomains = () => Object.keys(ALL_CONFIGS)

  const setDomain = domain => dispatch({ type: "setDomain", payload: domain })

  const franchiseTheme = themes[state.themeName]
    ? theme(themes[state.themeName])
    : theme(defaultFranchiseTheme)

  return (
    <FranchiseContext.Provider value={{
      ...state,
      getFranchiseConfig,
      getAllFranchiseDomains,
      setDomain,
    }}
    >
      <ThemeProvider theme={franchiseTheme}>
        <GlobalStyles franchiseTheme={franchiseTheme} />
        {children}
      </ThemeProvider>
    </FranchiseContext.Provider>
  )
}

FranchiseProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
}

export default FranchiseProvider
