import { randomString32 } from "./lib"
import {
  apiScrapeAvailability,
  apiNextAvailability,
  handleXhrError,
  apiFindBestPrice
} from "../../api/api"

export const bestPriceTriggerEpic = (action$, { getState }) => action$.ofType("TRIGGER_BEST_PRICE").mergeMap(action => {
  const token = randomString32()

  const {
    inventoryItem,
    inventoryId,
    availabilities,
    selectedAvailability
  } = getState().inventoryView

  const availability = availabilities.find(a => a.id === selectedAvailability)

  const payload = {
    type: action.type,
    token,
    serviceId: (inventoryItem && inventoryItem.id) || inventoryId,
    date: availability.time.start_time
  }

  return [{ type: "TRIGGER_BEST_PRICE_SOCKET", payload }, { type: "TRIGGER_BEST_PRICE_SUCCESS" }]
})


export const nextAvailabilityTriggerEpic = (action$, { getState }) => action$.ofType("TRIGGER_NEXT").mergeMap(action => {
  const token = randomString32()

  const {
    inventoryItem,
    inventoryDate,
    inventoryId,
    employeeId
  } = getState().inventoryView

  const payload = {
    type: action.type,
    token,
    serviceId: (inventoryItem && inventoryItem.id) || inventoryId,
    date: inventoryDate
  }

  if (employeeId && employeeId > 0) {
    payload.employeeId = employeeId
  }

  return [{ type: "TRIGGER_NEXT_SOCKET", payload }, { type: "TRIGGER_NEXT_SUCCESS" }]
})

export const scrapeAvailabilityTriggerEpic = (
  action$,
  { getState }
) => action$
  .ofType("TRIGGER_SCRAPE", "FORCE_SCRAPE")
  .mergeMap(action => {
    const force = action.type === "FORCE_SCRAPE"
    const token = randomString32()

    const {
      inventoryItem,
      inventoryDate,
      employeeId
    } = getState().inventoryView

    const payload = {
      type: action.type,
      token,
      serviceId: inventoryItem.id,
      date: inventoryDate,
      force
    }

    if (employeeId && employeeId > 0) {
      payload.employeeId = employeeId
    }

    return [{ type: "TRIGGER_SCRAPE_SOCKET", payload }, { type: "TRIGGER_SCRAPE_SUCCESS" }]
  })

const scrapeAvailabilityFulfilled = (response, action) => ({
  type: "SCRAPE_AVAILABILITY_SUCCESS",
  payload: { response, action }
})

export const scrapeAvailabilityEpic = (action$, { getState }) => action$.ofType("SCRAPE_AVAILABILITY").mergeMap(action => apiScrapeAvailability(
  getState().credentials.credentials,
  action.payload.serviceId,
  action.payload.date,
  action.payload.token,
  action.payload.employeeId,
  action.payload.force
)
  .map(response => scrapeAvailabilityFulfilled(response, action))
  .catch(error => handleXhrError(error, "SCRAPE_AVAILABILITY_ERROR")))

const nextAvailabilityFulfilled = (response, action) => ({
  type: "NEXT_AVAILABILITY_SUCCESS",
  payload: { response, action }
})

export const nextAvailabilityEpic = (action$) => action$.ofType("SCRAPE_NEXT").mergeMap(action => apiNextAvailability(
  action.payload.serviceId,
  action.payload.date,
  action.payload.token,
  action.payload.employeeId
)
  .map(response => nextAvailabilityFulfilled(response, action))
  .catch(error => handleXhrError(error, "NEXT_AVAILABILITY_ERROR")))


const bestPriceFulfilled = (response, action) => ({
  type: "BEST_PRICE_SUCCESS",
  payload: { response, action }
})

export const bestPriceEpic = (action$) => action$.ofType("SCRAPE_BEST_PRICE").mergeMap(action => apiFindBestPrice(
  null,
  action.payload.token,
  action.payload.serviceId,
  action.payload.date
)
  .map(response => bestPriceFulfilled(response, action))
  .catch(error => handleXhrError(error, "BEST_PRICE_ERROR")))
