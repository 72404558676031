import React, { Component } from "react"
import { connect } from "react-redux"
import moment from "moment-timezone"
import PropTypes from "prop-types"

import CalendarMonth from "../../components/calendar/CalendarMonth"
import getMonths from "../../components/calendar/getMonths"
import ShadowBox from "../../components/ShadowBox"

import { showServiceCalendar, setInventoryDate, triggerScrape } from "./actions"

import {
  dayIsSale,
  dayIsUnavailable,
  formatDate,
  isWithinDateRange
} from "../../../utils/momentHelpers"

import { getServiceSlug, getServicePrice } from "./selectors"

class CalendarContainer extends Component {
  componentDidUpdate(prevProps) {
    /* Force update on every render to ensure calendar displays sale/unavailable dates correctly despite parent component props not changing */
    const { showModal } = this.props
    if (showModal !== prevProps.showModal) {
      this.forceUpdate()
    }
  }

  renderCalendarModal = ({
    selectedDays,
    isMobile,
    showServiceCalendar,
    setInventoryDate,
    inventoryItem,
    triggerScrape,
    clickDay
  }) => {
    if (!inventoryItem) return null

    const calendarStyle = isMobile ? { marginBottom: "20px" } : {}

    const timezone = (inventoryItem.company && inventoryItem.company.timezone) || null

    const wrapperStyle = isMobile
      ? { marginLeft: "auto", marginRight: "auto" }
      : {
        marginLeft: "auto",
        marginRight: "auto",
        display: "grid",
        gridGap: "20px",
        gridTemplateColumns: "repeat(2, calc(50% - 10px))"
      }

    return (
      <ShadowBox
        header="When?"
        closeBox={() => showServiceCalendar(false)}
        isFullscreen={isMobile}
      >
        <div
          style={{
            overflowY: "scroll",
            marginLeft: isMobile ? "-10px" : 0,
            marginRight: isMobile ? "-20px" : "-10px",
            maxHeight: isMobile ? "calc(100vh - 130px)" : "calc(100vh - 250px)"
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, textAlign: "center" }}>
              <div style={wrapperStyle}>
                {getMonths().map(month => (
                  <CalendarMonth
                    style={calendarStyle}
                    isDisabled={day => dayIsUnavailable(inventoryItem.company, moment(day))
                      || !isWithinDateRange(moment(day), timezone)
                    }
                    isOnSale={day => dayIsSale(inventoryItem.pricing.lowest, moment(day))
                    }
                    key={`calmonth${month.format()}`}
                    month={month}
                    clickDay={day => {
                      // close the modal
                      showServiceCalendar(false)

                      // format the date and timezone
                      const dateStr = formatDate(day, "YYYY-MM-DD", timezone)
                      // set the inventory date
                      setInventoryDate(dateStr)
                      // trigger the scrape
                      triggerScrape()

                      // Call callback if supplied
                      if (clickDay) {
                        clickDay(day)
                      }
                    }}
                    selectedDays={[selectedDays]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ShadowBox>
    )
  }

  render() {
    const { showModal, ...rest } = this.props
    return showModal ? this.renderCalendarModal(rest) : null
  }
}

CalendarContainer.propTypes = {
  selectedDays: PropTypes.string,
  isMobile: PropTypes.bool,
  showModal: PropTypes.bool,
  showServiceCalendar: PropTypes.func,
  setInventoryDate: PropTypes.func,
  inventoryItem: PropTypes.object,
  triggerScrape: PropTypes.func,
  clickDay: PropTypes.func
}

CalendarContainer.defaultProps = {}

const mapStateToProps = state => {
  return {
    selectedDays: state.inventoryView.inventoryDate,
    isMobile: state.browser.lessThan.mobile,
    inventoryItem: state.inventoryView.inventoryItem,
    // company: state.inventoryView.inventoryItem.company,
    price: getServicePrice(state),
    slug: getServiceSlug(state)
  }
}

const mapDispatchToProps = dispatch => ({
  showServiceCalendar: bool => dispatch(showServiceCalendar(bool)),
  setInventoryDate: date => dispatch(setInventoryDate(date)),
  triggerScrape: () => dispatch(triggerScrape())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer)
