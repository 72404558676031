export const initialState = {
  favouritesViewingServiceId: null,
  favouritesList: [],
  isFetchingFavourites: false
}

export default function favouriteServiceReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_USER_FAVORITES":
      return {
        ...state,
        isFetchingFavourites: true
      }
    case "USER_FAVORITES_SERVICE_ID":
      return {
        ...state,
        favouritesViewingServiceId: action.payload
      }
    case "FETCH_USER_FAVORITES_SUCCESS":
      return {
        ...state,
        favouritesList: [...action.payload.response],
        isFetchingFavourites: false
      }
    default:
      return state
  }
}
