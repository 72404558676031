import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import WrapContainer from "../WrapContainer"
import { InstantBookService } from "../InstantBook/InstantBookService"
import { navToInventory } from "../InventoryView/actions"
import { getListUserFavourites, getIsFetchingFavourites } from "./selectors"
import { fetchFavourites, navToHome } from "./actions"
import Button from "../../components/button"
import { HeartIcon } from "../../components/icons"

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.secondary};
  padding: 20px 0px 20px 0px;
  margin-top: 0px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 0px;
  border-bottom-length: 80px;
  text-align: center;
`

const FavouritesWrapper = styled.div`
  margin: auto;
  padding: 0 10 0 10;
`

const NoFavsWrapperDiv = styled.div`
  text-align: center;
`
const NoFavsStyled = styled.p`
  color: #444;
  font-weight: 400;
  font-size: 18px;
`
const NoFavsStyledCTA = styled.p`
  color: #444;
  font-weight: 400;
  font-size: 18px;
`

const renderNoFavorites = (
  userFavourites,
  userPastBookings,
  isMobile,
  navToHome,
  t
) => {
  let res = null
  const favoritesCount = (userFavourites && userFavourites.length) || 0
  const userPastBookingsCount = (userPastBookings && userPastBookings.length) || 0
  const bookingCount = favoritesCount + userPastBookingsCount

  if (bookingCount === 0) {
    res = (
      <NoFavsWrapperDiv>
        <NoFavsStyled>{t("favourites_none")}</NoFavsStyled>
        <NoFavsStyledCTA>
          Either make a booking or tap the
          {" "}
          <HeartIcon $filled onClick={() => null} />
          {" "}
          icon
          on any service and we'll save them for you here!
        </NoFavsStyledCTA>
        <Button onClick={() => navToHome()}>Find an Appointment</Button>
      </NoFavsWrapperDiv>
    )
  }
  return res
}

const renderUserFavourites = (
  userFavourites,
  isMobile,
  heading = "",
  navToInventory,
  isVip
) => {
  if (!userFavourites.length) return null
  const serviceContent = []

  userFavourites.forEach(favourite => {
    const today = moment().format("YYYY-MM-DD")
    const service = (favourite && favourite.service) || {}
    const rating = (favourite
        && favourite.service
        && favourite.service.company
        && favourite.service.company.rating_customer)
      || false
    const ratingCount = (favourite
        && favourite.service
        && favourite.service.company
        && favourite.service.company.rating_count)
      || false

    const showRating = rating && ratingCount

    const slug = (favourite && favourite.service && favourite.service.slug) || ""
    const linkUrl = favourite.service.date === today
      ? `/services/${slug}`
      : `/services/${slug}/date/${today}`
    const serviceId = (favourite && favourite.service && favourite.service.id)
      || Math.round(Math.random() * 10)
    serviceContent.push(
      <InstantBookService
        isMobile={isMobile}
        key={`service-${serviceId}`}
        {...service}
        userIsVip={isVip}
        aLink={linkUrl}
        date={today}
        showRating={showRating}
        rating={rating}
        ratingCount={ratingCount}
        onClickRating={() => navToInventory(slug, today)}
        has_extras={false} // steve is this correct?
        clickAction={() => navToInventory(slug, today)}
      />
    )
  })

  return (
    <React.Fragment>
      <Heading>{heading}</Heading>
      {serviceContent}
    </React.Fragment>
  )
}

const FavouritesContainer = props => {
  const { t } = useTranslation()

  const {
    userPastBookings,
    navToInventory,
    navToHome,
    userFavourites,
    fetchFavourites,
    isMobile,
    isFetchFavourites,
    isVip
  } = props
  return (
    <div>
      <WrapContainer loginRequired onAuth={fetchFavourites}>
        {!isFetchFavourites && (
          <FavouritesWrapper>
            <div>
              {renderUserFavourites(
                userFavourites,
                isMobile,
                t("book_your_favourite_service"),
                navToInventory,
                isVip
              )}
            </div>
            <div>
              {renderUserFavourites(
                userPastBookings,
                isMobile,
                "Rebook a past appointment",
                navToInventory,
                isVip
              )}
            </div>
            <div>
              {renderNoFavorites(
                userFavourites,
                userPastBookings,
                isMobile,
                navToHome,
                t
              )}
            </div>
          </FavouritesWrapper>
        )}
      </WrapContainer>
    </div>
  )
}

FavouritesContainer.propTypes = {
  userFavourites: PropTypes.array,
  userPastBookings: PropTypes.array,
  fetchFavourites: PropTypes.func,
  navToInventory: PropTypes.func,
  isVip: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFetchFavourites: PropTypes.bool,
  navToHome: PropTypes.func.isRequired
}

FavouritesContainer.defaultProps = {
  fetchFavourites: null,
  userFavourites: [],
  userPastBookings: [],
  isVip: false,
  isMobile: false,
  isFetchFavourites: true
}

function mapStateToProps(state) {
  return {
    userFavourites: getListUserFavourites(state),
    userPastBookings: getListUserFavourites(state, 1),
    isFetchFavourites: getIsFetchingFavourites(state),
    // this should be in selector since it is used everywhere
    isVip: state.profile.profile.vip,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = (dispatch, { match: { params: currentParams } }) => ({
  fetchFavourites: () => dispatch(fetchFavourites()),
  navToHome: () => dispatch(navToHome()),
  navToInventory: (slug, date) => dispatch(navToInventory(currentParams, { id: slug, date }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouritesContainer)
