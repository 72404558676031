import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  submitUpdatePassword,
  updatePassword,
  setResetPasswordToken,
  updatePasswordAgain,
  resetFormProps,
  navToPreviousLocation,
} from "./actions"

import NavBarContainer from "../NavBar/NavBarContainer"
import { PasswordInput, SubmitInput } from "../../components/forms/Inputs"
import ErrorBar from "../../components/ErrorBar"
import Button from "../../components/button"

const StyledParentDiv = styled.div`
  margin-left: auto;
  flex: 1;
  margin-right: auto;
  max-width: 600px;
`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.isMobile ? "column-reverse" : "row")};
  text-align: ${props => (props.isMobile ? "center" : "left")};
`

const StyledSpan = styled.span`
  flex: 1;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  margin: 0;
`

const StyledInputDiv = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.isMobile ? "column-reverse" : "row")};
`

const StyledPasswordDiv = styled.div`
  margin-bottom: 14px;
  display: flex;
`

const SubTitle = styled.div`
  margin-bottom: 1rem;
`

class ChangePasswordContainer extends Component {
  componentDidMount() {
    const { match, resetFormProps, setResetPasswordToken } = this.props
    const token = (match && match.params && match.params.id) || null
    // reset any persisted form errors
    resetFormProps()
    // set the token from the url
    setResetPasswordToken(token)
  }

  navToPreviousLocation = e => {
    const { navToPreviousLocation } = this.props
    e.preventDefault()
    navToPreviousLocation()
  }

  passwordMatch = () => {
    let match = false
    const { password, passwordAgain } = this.props
    if (
      !password
      && !password.length
      && !passwordAgain
      && !passwordAgain.length
    ) return match
    if (password === passwordAgain) match = true
    return match
  }

  renderError = () => {
    let errorMsg = null
    const { lastErrorCode, lastError, hasError } = this.props
    if (hasError && lastErrorCode !== 412) {
      errorMsg = (
        // THIS SHOULD BE A CONSTANT - OFTEN RE-USED TEXT
        <ErrorBar message="Something went wrong! Please try again later." />
      )
    }

    if (hasError && lastErrorCode === 412) {
      errorMsg = <ErrorBar message={lastError} />
    }
    return errorMsg
  }

  renderSuccess = (passwordUpdated, hasError) => {
    let successMsg = null
    if (passwordUpdated && !hasError) {
      successMsg = (
        <div style={{ textAlign: "center" }}>
          <h2 style={{ textAlign: "center" }}>
            Success. Your Password has been updated
          </h2>
          <Button
            onClick={this.navToPreviousLocation}
            style={{
              textAlign: "center",
              marginTop: 10,
            }}
          >
            Continue
          </Button>
        </div>
      )
    }
    return successMsg
  }

  renderPasswordMismatch = () => {
    let errorMsg = null
    const { password, passwordAgain } = this.props
    // don't show error if one textbox is empty.
    if (
      password
      && password.length > 0
      && passwordAgain
      && passwordAgain.length > 0
      && !this.passwordMatch()
    ) {
      errorMsg = <ErrorBar message="Passwords do not match" />
    }
    return errorMsg
  }

  updatePassword = e => {
    const { updatePassword } = this.props
    e.preventDefault()
    updatePassword(e.target.value)
  }

  updatePasswordAgain = e => {
    const { updatePasswordAgain } = this.props
    e.preventDefault()
    updatePasswordAgain(e.target.value)
  }

  submitUpdatePassword = e => {
    const {
      password, passwordAgain, token, submitUpdatePassword
    } = this.props
    e.preventDefault()
    submitUpdatePassword(password, passwordAgain, token)
  }

  resetPasswordForm() {
    const {
      isMobile,
      password,
      passwordAgain,
      passwordUpdated,
      token,
      hasError,
      resetRequired,
    } = this.props
    // hide the form when password updated.
    if (passwordUpdated) {
      return this.renderSuccess(passwordUpdated, hasError)
    }
    if (!token && !passwordUpdated && hasError) {
      return (
        <div>
          <p style={{ margin: 0, textAlign: "center" }}>Token not found.</p>
        </div>
      )
    }
    return (
      <form onSubmit={this.submitUpdatePassword}>
        <StyledParentDiv>
          <StyledDiv isMobile={isMobile}>
            <StyledSpan>Confirm new password</StyledSpan>
          </StyledDiv>
          {resetRequired && (
            <SubTitle>
              <small>Sorry, we just need you to update your password quickly before logging you in.</small>
            </SubTitle>
          )}
          {this.renderError()}
          {this.renderPasswordMismatch()}
          <StyledPasswordDiv>
            <PasswordInput
              onKeyPress={this.passwordKeyPress}
              onChange={this.updatePassword}
              value={password}
              placeholder="New Password"
              style={{ flexGrow: 1 }}
            />
          </StyledPasswordDiv>
          <StyledPasswordDiv>
            <PasswordInput
              onKeyPress={this.passwordKeyPress}
              onChange={this.updatePasswordAgain}
              value={passwordAgain}
              placeholder="New Password Confirm"
              style={{ flexGrow: 1 }}
            />
          </StyledPasswordDiv>
          <StyledInputDiv isMobile={isMobile}>
            <div style={{ flex: 1 }} />
            <SubmitInput
              value="Update Password"
              disabled={!this.passwordMatch()}
              style={{ marginBottom: 10 }}
            />
          </StyledInputDiv>
        </StyledParentDiv>
      </form>
    )
  }

  render() {
    return (
      <div style={{ paddingTop: 30 }}>
        <NavBarContainer />

        <div style={{ margin: 15 }}>{this.resetPasswordForm()}</div>
      </div>
    )
  }
}

ChangePasswordContainer.propTypes = {
  submitUpdatePassword: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updatePasswordAgain: PropTypes.func.isRequired,
  setResetPasswordToken: PropTypes.func.isRequired,
  resetFormProps: PropTypes.func.isRequired,
  token: PropTypes.string,
  isMobile: PropTypes.bool,
  hasError: PropTypes.bool,
  passwordUpdated: PropTypes.bool,
  match: PropTypes.object,
  password: PropTypes.string,
  passwordAgain: PropTypes.string,
  lastErrorCode: PropTypes.string,
  lastError: PropTypes.string,
  resetRequired: PropTypes.bool, // this is true when the reset is required from the admin dashboard.
  navToPreviousLocation: PropTypes.func.isRequired,
}

ChangePasswordContainer.defaultProps = {
  isMobile: false,
  hasError: false,
  passwordUpdated: false,
  resetRequired: false,
  match: {},
  password: "",
  passwordAgain: "",
  lastErrorCode: "",
  token: "",
  lastError: null
}

function mapStateToProps(state) {
  return {
    password: state.resetpassword.password,
    passwordAgain: state.resetpassword.passwordAgain,
    passwordUpdated: state.resetpassword.passwordUpdated,
    token: state.resetpassword.resetPasswordToken,
    lastErrorCode: state.resetpassword.lastErrorCode,
    lastError: state.resetpassword.lastError,
    hasError: state.resetpassword.hasError,
    isMobile: state.browser.lessThan.mobile
  }
}

const mapDispatchToProps = dispatch => ({
  navToPreviousLocation: () => dispatch(navToPreviousLocation()),
  resetFormProps: () => dispatch(resetFormProps()),
  setResetPasswordToken: token => dispatch(setResetPasswordToken(token)),
  updatePassword: password => dispatch(updatePassword(password)),
  updatePasswordAgain: password => dispatch(updatePasswordAgain(password)),
  submitUpdatePassword: (password, passwordAgain, token) => dispatch(submitUpdatePassword(password, passwordAgain, token))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer)
