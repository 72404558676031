import React from "react"

const renderLogo = (logoImage, logoSize, onClick) => (
  <div style={{ paddingLeft: 20 }}>
    <a href="/" onClick={onClick}>
      <img
        style={{ display: "block" }}
        alt="logo"
        src={logoImage}
        height={logoSize}
      />
    </a>
  </div>
)

const WELLA_FRANCHISE_THEME = {
  wwwBgImageSize: "cover",
  wwwBgImage: "/wella-bg.jpg",
  wwwBgImageBlendMode: "multiply",
  wwwBgColor: "transparent",

  logoImage: "/wella-logo.jpg",
  logoSize: 60,
  logo: renderLogo,

  topNav: {
    background: "#FFF",
    links: "#000",
  },
}

export default WELLA_FRANCHISE_THEME
