import PrismicContent from "../../../dist/all_prismic_data.json"

const { YOUR_HEALTH_PAGE_ID } = SITE_CONFIG

export const getPageContent = () => {
  if (PrismicContent && PrismicContent[YOUR_HEALTH_PAGE_ID]) {
    const { title, text_content } = PrismicContent[YOUR_HEALTH_PAGE_ID].body[0].primary
    return { title, textContent: text_content }
  }
  return {}
}
