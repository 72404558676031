export const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  code: "",
  enterCode: false,
  hasError: false,
  lastError: "",
  registered: false,
  isProcessing: false
}

export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBMIT_FACEBOOK_TOKEN":
      return {
        ...state,
        hasError: false,
        lastError: ""
      }
    case "SUBMIT_REGISTRATION":
      return {
        ...state,
        hasError: false,
        lastError: "",
        isProcessing: true
      }
    case "REGISTRATION_FIRST_NAME_UPDATE":
      return {
        ...state,
        hasError: false,
        firstName: action.payload.firstName
      }
    case "REGISTRATION_LAST_NAME_UPDATE":
      return {
        ...state,
        hasError: false,
        lastName: action.payload.lastName
      }
    case "REGISTRATION_EMAIL_UPDATE":
      return {
        ...state,
        hasError: false,
        email: action.payload.email
      }
    case "REGISTRATION_PHONE_UPDATE":
      return {
        ...state,
        hasError: false,
        phone: action.payload.phone
      }
    case "REGISTRATION_PASSWORD_UPDATE":
      return {
        ...state,
        hasError: false,
        password: action.payload.password
      }
    case "REGISTRATION_CODE_UPDATE":
      return {
        ...state,
        hasError: false,
        code: action.payload.code
      }
    case "REGISTRATION_SUCCESS":
      return {
        ...state,
        hasError: false,
        isProcessing: false
      }
    case "REGISTRATION_FAILURE":
      return {
        ...state,
        hasError: true,
        lastError: action.payload.error_response.response.data.message,
        isProcessing: false
      }
    case "REGISTRATION_CODE_REQUEST_SUCCESS":
      return {
        ...state,
        hasError: false,
        enterCode: true
      }
    case "REGISTRATION_CODE_HIDE":
      return {
        ...state,
        hasError: false,
        enterCode: false
      }
    case "FETCH_PROFILE_SUCCESS":
      return {
        ...state,
        hasError: false,
        enterCode: false,
        code: "",
        isProcessing: false
      }
    default:
      return state
  }
}
