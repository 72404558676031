export const submitLogin = (email, password, googleRecaptchaToken, noRedirect) => ({
  type: "SUBMIT_LOGIN_FORM",
  payload: {
    email, password, googleRecaptchaToken, noRedirect
  }
})
export const submitFacebookLogin = (token, googleRecaptchaToken, noRedirect, newsletter) => ({
  type: "SUBMIT_FACEBOOK_TOKEN",
  payload: {
    token, googleRecaptchaToken, noRedirect, newsletter
  }
})
export const updateEmail = email => ({
  type: "LOGIN_FORM_EMAIL_UPDATE",
  payload: { email }
})
export const updatePassword = password => ({
  type: "LOGIN_FORM_PASSWORD_UPDATE",
  payload: { password }
})
export const clickedFacebookLogin = () => ({ type: "CLICKED_FACEBOOK_LOGIN" })
