import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

import { VipTag, PromoTag, InactiveVipTag } from "./SaleTags"

const StyledPriceP = styled.div`
  font-weight: ${props => (props.small ? 400 : 500)};
  color: ${({ theme, highlight }) => (highlight ? theme.colors.price : "inherit")};
  margin: 0px;
  font-size: ${props => (props.small ? "12px" : "20px")};
  height: ${props => (props.small ? "12px" : "20px")};
  line-height: ${props => (props.small ? "12px" : "20px")};
  white-space: nowrap;
  padding: ${({ highlight }) => (highlight ? "5px 0" : 0)};
`

const Pricing = ({
  style,
  prices,
  currency,
  userIsVip,
  hideTag = false
}) => {
  const price = prices.lowest ? prices.lowest : prices.default

  const onSale = price.sale

  const differentLowestAndHighestPrices = () => prices.lowest && prices.highest
    && parseFloat(prices.lowest.price) !== parseFloat(prices.highest.price)

  const getDisplayPrice = (symbol) => {
    if (differentLowestAndHighestPrices()) {
      return `${symbol}${prices.lowest.price} - ${symbol}${prices.highest.price}`
    }

    return `${symbol}${price.price}`
  }

  const displayPrice = getDisplayPrice(currency.symbol)

  /* const hasVipPrice = prices && prices.lowest && prices.lowest.discount_vip

  const vipPrice = hasVipPrice
    && (
      parseFloat(prices.lowest.price) - parseFloat(prices.lowest.discount_vip)
    ).toFixed(2)

  const vipActive = userIsVip && hasVipPrice

  if (vipActive) {
    displayPrice = vipPrice
  } */

  const tagStyle = { float: "right", height: 12 }

  return (
    <div style={{ textAlign: "right", marginRight: "-6px", ...style }}>
      <div style={{ paddingRight: 6, marginBottom: hideTag ? 0 : 8 }}>
        <StyledPriceP highlight>
          {displayPrice}
        </StyledPriceP>
        <StyledPriceP style={{ marginTop: -4 }} small>
          {(onSale && price.price_original) && (
            <span
              style={{
                textDecoration: "line-through",
                display: "inline-block",
                marginRight: "4px"
              }}
            >
              {currency.symbol}
              {price.price_original}
            </span>
          )}
          {currency.code}
        </StyledPriceP>
      </div>
      {/*
      {!hideTag && (vipPrice && !vipActive) && (
        <InactiveVipTag style={tagStyle} price={currency.symbol + vipPrice} />
      )}
      {!hideTag && vipActive && <VipTag style={tagStyle} />}
      */}
      {!hideTag && onSale && <PromoTag style={tagStyle} />}
    </div>
  )
}

Pricing.propTypes = {
  style: PropTypes.object,
  prices: PropTypes.object,
  currency: PropTypes.object,
  userIsVip: PropTypes.bool,
  hideTag: PropTypes.bool
}

export default Pricing
