import React from "react"
import styled from "styled-components"
import moment from "moment-timezone"

import PropTypes from "prop-types"
// EF609F
//
const StyledDayButton = styled.div`
  padding: 5px;
  text-align: center;
  width: 23px;
  height: 23px;
  display: inline-block;
  cursor: ${props => (props.isPast || props.isDisabled ? "default" : "pointer")};
  background-color: ${props => (props.selected ? "#444" : "#fff")};
  color: ${props => (props.isDisabled
    ? "#ccc"
    : props.selected
      ? "#fff"
      : props.isOnSale
        ? props.theme.colors.accent
        : "#000")};
  border-radius: 50%;
  line-height: 23px;
`

const DayButton = ({
  selected, onClick, day, isDisabled, isOnSale
}) => {
  const mDay = moment(day)
  const isPast = mDay.isBefore(moment().startOf("day"))

  const click = e => {
    e.stopPropagation()
    e.cancelBubble = true
    if (!isPast && !isDisabled) onClick(mDay)
  }

  return (
    <StyledDayButton
      selected={selected}
      isDisabled={isPast || isDisabled}
      isOnSale={isOnSale}
      onClick={click}
    >
      {mDay.format("D")}
    </StyledDayButton>
  )
}

DayButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  day: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isOnSale: PropTypes.bool
}

export default DayButton
