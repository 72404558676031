import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { HeartIcon } from "../icons"

export const StyledHeartIcon = styled(HeartIcon)`
  cursor: pointer;
  height: 21px;
  position: justify;
`

const FavouritesBtn = ({
  isFavourited = false,
  onClick = null
}) => {
  // filled is a standard HTML attribute for SVG and must be either set or not exist to enable/disable
  return <StyledHeartIcon filled={isFavourited ? "true" : undefined} onClick={() => onClick()} />
}

FavouritesBtn.propTypes = {
  onClick: PropTypes.func,
  isFavourited: PropTypes.bool
}

FavouritesBtn.defaultProps = {
  onClick: null,
  isFavourited: false
}

export default FavouritesBtn
