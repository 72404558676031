import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"
import styled from "styled-components"

import { fetchCompany } from "../Companies/actions"
import { selectStep } from "./actions"
import ParagraphText from "../../components/ParagraphText"
import { thumborizeFitIn } from "../../components/images/thumborize"
import Animation from "../../components/Animation"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { ServiceGallery } from "../../components/images/ServiceGallery"
import { CompanyGallery } from "../../components/images/CompanyGallery"
import ProductDescription from "../../components/ProductDescription"
import Seperator from "../../components/Seperator"
import CompanySelector from "../../components/CompanySelector"
import CalendarBookingModule from "./CalendarBookingModule"
import CalendarContainer from "./CalendarContainer"
import { navToFourOhFour } from "../FourOhFour/actions"
import SocialMediaLinks from "../Companies/SocialMediaLinks"

import { AccordianHeader, AccordianContent } from "../../components/accordians"
import { Review } from "./components"

import PromoBanner from "../../components/banners/PromoBanner"
import Carousel from "../../components/carousel/Carousel"
import ServiceCard from "../../components/cards/ServiceCard"
import HighlightedBorderArea from "../../components/sections/HighlightedBorderArea"
import Button, { SelectButton, Link } from "../../components/button"

import { getRelatedServices } from "./campaignSelectors"

import {
  setInventoryDate,
  setEmployee,
  fetchCampaign
} from "./actions"

import WrapContainer from "../WrapContainer"

import { Phone } from "../../components/datarenderers"

import * as inventoryFilterSelectors from "../InventoryFilter/selector"
import { ArrowRightIcon } from "../../components/icons"

const ServiceDescription = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.responsive.mobile}px) {
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.tertiary};
  }
`
class CampaignWizard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    const { fetchCampaign, match } = this.props
    fetchCampaign(match.params.slug)
  }

  componentDidUpdate() {
    const { isFetchingCampaign, campaign, navToFourOhFour } = this.props

    if (!isFetchingCampaign && !campaign) {
      // campaign doesnt exist -> go to 404
      navToFourOhFour('Campaign does not exist')
    }
  }

  selectedServiceDescriptionId = () => {
    const { selectedServiceDescriptionId } = this.props
    return selectedServiceDescriptionId
  }

  scrollToStep = (step, depth = 20) => {
    if (step < 1 || step > 5) {
      return
    }

    setTimeout(() => {
      const stepElement = document.getElementById(`step-${step}`)

      if (stepElement) {
        window.scrollTo({
          top: document.getElementById(`step-${step}`).offsetTop - depth,
          behavior: "smooth"
        })
      }
    }, 250)
  }

  selectCategory = category => {
    const {
      selectStep, selectCategory, unSelectServiceDescription, unSelectLocation
    } = this.props
    /* Reset service selection */
    unSelectLocation()
    unSelectServiceDescription()
    /* Select the category the user clicked on  */
    selectCategory(category)
    /* Explicitly set step 2 on service category selection */
    selectStep(2)
    /* Scroll to bottom after content has loaded */
    this.scrollToStep(2)
  }

  selectServiceDescription = serviceDescriptionId => {
    const {
      selectServiceDescription, unSelectLocation, campaign
    } = this.props
    const { selectedTab } = this.state
    /* Reset service selection */
    unSelectLocation()
    /* Select the category of services the user clicked on  */
    selectServiceDescription(serviceDescriptionId, campaign.id)
    /* Inside the service description we open the love-it accordion by default */
    if (selectedTab === "love-it") {
      // workaround to avoid tab to be closed when user jumps between services
      this.setState({ selectedTab: "dummy" })
    }
    this.setState({ selectedTab: "love-it" })

    /* We scroll a bit down to show the service selected */
    this.scrollToStep(2, 40)
  }

  selectCompany = (companyId) => {
    /* Set the selected company so we can load company related data like reviews */
    const { selectCompany } = this.props
    selectCompany(companyId)

    this.setState({ selectedTab: "love-salon" })
  }

  selectService = (companyId, serviceId) => {
    const {
      selectStep, selectService, selectCompany, setEmployee, setInventoryDate
    } = this.props
    /* Set the selected company so we can load company related data like reviews */
    selectCompany(companyId)

    /* Select current date and display inventory selector */
    setEmployee(null)
    setInventoryDate(moment().format("YYYY-MM-DD"))
    selectService(serviceId)

    /* Explicitly move onto the next step */
    selectStep(5)

    /* Scroll to bottom after content has loaded */
    this.scrollToStep(5)
  }

  renderCategorySelector = category => {
    const {
      isMobile, isVip, selectedCategory,
    } = this.props

    /* Is the currently selected category of similar services */
    const selectedCategoryId = selectedCategory ? selectedCategory.id : null
    const isCurrent = category.id === selectedCategoryId

    const buttonContent = isCurrent ? "Selected" : "See more"
    return (
      <ServiceCard
        highlight={isCurrent}
        buttonContent={buttonContent}
        key={`category-card-${category.id}`}
        onClick={() => this.selectCategory(category)}
        showPrice={false}
        isMobile={isMobile}
        isVip={isVip}
        name={category.name}
        description={category.description}
        serviceImages={category.images}
        centeredContent
      />
    )
  }

  renderCategoryOptions = services => {
    const { isMobile } = this.props

    const uniqueCategoriesId = []
    let categories = []

    services
      .map(service => service.description)
      .filter(description => {
        const category = description.category
        category.images = description.images
        if (!uniqueCategoriesId.includes(category.id)) {
          uniqueCategoriesId.push(category.id)
          categories.push(category)
          return true
        }
        return false
      })

    categories = categories.filter(category => typeof category !== "undefined").sort((a, b) => {
      /* Sort by position first */
      if (parseInt(a.position, 10) > parseInt(b.position, 10)) {
        return -1
      }
      if (parseInt(a.position, 10) < parseInt(b.position, 10)) {
        return 1
      }

      /* Then by name second */
      if (a.name < b.name) {
        return -1
      }

      if (a.name > b.name) {
        return 1
      }
      // a must be equal to b
      return 0
    })

    return (
      <div>
        <Carousel
          isMobile={isMobile}
        >
          {categories.map(category => (
            <div key={`category-${category.id}`} style={{ height: "100%", padding: "0 5px" }}>
              {this.renderCategorySelector(category)}
            </div>
          ))}
        </Carousel>
      </div>
    )
  }


  clickTab = tab => {
    const { selectedTab } = this.state
    if (selectedTab !== tab) {
      this.setState({ selectedTab: tab })
    } else {
      this.setState({ selectedTab: false })
    }
  }

  renderServiceExtra = serviceExtra => {
    return (<ProductDescription product={serviceExtra} />)
  }

  renderServiceButtons = service => {
    const {
      fetchCompany,
      selectStep,
      selectedCategory,
      campaign,
      campaignCompanies
    } = this.props

    const options = campaign.similar.filter(service => selectedCategory.id === service.description.category.id).sort((a, b) => {
      if (a.description.name === b.description.name) {
        return 0
      }

      return (a.description.name < b.description.name) ? -1 : 1
    }).map(option => ({ label: option.description.name, value: option.description.id }))

    return (
      <div style={{
        margin: "30px auto",
        marginTop: 0,
        display: "flex",
        flexDirection: "horizontal",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "300px"
      }}
      >
        <div style={{ paddingRight: 10 }}>
          <SelectButton
            variant="secondary"
            options={options}
            selectedValue={service.description.id}
            defaultValue={options.find(option => option.value === service.description.id)}
            isSearchable={false}
            controlShouldRenderValue={false}
            placeholder="Change service"
            onChange={(option) => {
              selectStep(2)
              // first we close the select service tab
              this.clickTab("select-service")
              // then we dispatch action to select service
              this.selectServiceDescription(option.value)
            }}
            minWidth="150px"
            menuWidth="380px"
          />


        </div>

        <div style={{ textAlign: "right" }}>
          <Button
            filled
            variant="secondary"
            onClick={() => {
              selectStep(3)
              this.scrollToStep(3)

              // If there's only one company auto-select it and skip this step
              if (campaignCompanies.length === 1) {
                fetchCompany(campaignCompanies[0].id)

                this.selectService(
                  campaignCompanies[0].id,
                  campaignCompanies[0].service.id
                )
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  renderService = service => {
    const {
      isMobile,
      isVip,
      campaign
    } = this.props

    return (
      <React.Fragment>

        {this.renderServiceButtons(service)}

        <HighlightedBorderArea style={{ marginBottom: (isMobile) ? 30 : 0 }} showBorder={isMobile}>
          <div
            style={{
              display: isMobile ? "block" : "grid",
              gridGap: "20px",
              gridTemplateRows: isMobile ? "repeat(2, auto)" : "none",
              gridTemplateColumns: isMobile
                ? "none"
                : "repeat(2, calc(50% - 10px))"
            }}
          >
            {service && (
              <div>
                <ServiceGallery service={service} />
              </div>
            )}

            <ServiceDescription>
              <div>
                {campaign.related_url && (
                  <div>
                    <Link
                      href={campaign.related_url}
                      style={{
                        display: "flex",
                        position: "relative"
                      }}
                    >
                      More services you may like
                      {" "}
                      <ArrowRightIcon style={{ marginLeft: 5 }} />
                    </Link>
                  </div>
                )}

                {/* <p style={{ margin: "4px 0 20px 0" }}>{service.summary}</p> */}

                {service.extras && service.extras.map(this.renderServiceExtra)}
              </div>

              <Seperator />

              {this.renderInfoAccordion(
                service.description.about,
                "About This Service",
                "love-it"
              )}
              {service.description.about && <Seperator />}
              {this.renderInfoAccordion(
                service.description.preparation,
                "How you can prepare",
                "prepare"
              )}
              {service.description.preparation && <Seperator />}
              {this.renderInfoAccordion(
                service.description.terms_and_conditions,
                "Booking Terms",
                "terms"
              )}
              {service.description.terms_and_conditions && <Seperator />}

            </ServiceDescription>
          </div>
        </HighlightedBorderArea>

        {isMobile && this.renderServiceButtons(service)}
      </React.Fragment>
    )
  }

  renderInfoAccordion = (content, title, tabName) => {
    const { selectedTab } = this.state
    return (
      <div>
        {content && (
          <AccordianHeader
            title={title}
            open={selectedTab === tabName}
            onClick={() => this.clickTab(tabName)}
          />
        )}
        {content && (
          <AccordianContent
            open={selectedTab === tabName}
            style={{ marginBottom: 20 }}
          >
            <ParagraphText
              text={content}
            />
          </AccordianContent>
        )}
      </div>
    )
  }

  renderReview = review => (
    <Review style={{ marginBottom: 14 }} review={review} key={review.id} />
  )

  renderReviewContent = () => {
    const { companyReviews } = this.props
    const { selectedTab } = this.state
    const tab = selectedTab

    return (
      <React.Fragment>
        <AccordianHeader
          title="Reviews"
          open={tab === "reviews"}
          onClick={() => this.clickTab("reviews")}
        />
        <AccordianContent open={tab === "reviews"}>
          <div style={{ maxHeight: 300, overflowY: "scroll" }}>
            {companyReviews.map(this.renderReview)}
          </div>
        </AccordianContent>
      </React.Fragment>
    )
  }

  renderCompanyButtons = company => {
    const {
      campaignCompanies, selectStep, unSelectLocation
    } = this.props

    const canChangeSalon = campaignCompanies.length > 1

    return (
      <div style={{
        margin: "0px auto 20px auto",
        display: "flex",
        flexDirection: "horizontal",
        justifyContent: canChangeSalon ? "space-between" : "center",
        alignItems: "center",
        maxWidth: "280px"
      }}
      >
        {canChangeSalon && (
          <Button
            variant="secondary"
            onClick={() => {
              unSelectLocation()
              selectStep(3)
            }}
          >
            Change Salon
          </Button>
        )}
        <Button
          variant="secondary"
          filled
          onClick={() => {
            this.selectService(company.id, company.service.id)
          }}
        >
          Next
        </Button>
      </div>
    )
  }

  renderCompany = company => {
    const {
      isMobile, companyReviews
    } = this.props

    return (
      <React.Fragment>

        {this.renderCompanyButtons(company)}

        <HighlightedBorderArea style={{ marginBottom: (isMobile) ? 20 : 0 }} showBorder={isMobile}>

          <div
            style={{
              height: "100%",
              display: isMobile ? "block" : "grid",
              gridGap: "20px",
              gridTemplateColumns: !isMobile && "repeat(2, calc(50% - 10px))"
            }}
          >
            <CompanyGallery company={company} />

            <div style={{ padding: isMobile ? 10 : 0 }}>

              <div style={{ margin: "15px 0" }}>
                <CompanyAddressBuilder
                  company={company}
                  addressOne
                  addressTwo
                  district
                  region
                  postCode
                  inline
                  showIcon
                />
                <div style={{ marginTop: "4px" }}>
                  <Phone number={company.phone_number} />
                </div>
                <SocialMediaLinks company={company} isMobile={isMobile} />
              </div>

              <Seperator style={{ marginTop: 25 }} />
              {this.renderInfoAccordion(
                company.description,
                "About This Salon",
                "love-salon"
              )}
              <Seperator />

              {companyReviews.length > 0 && (
                <React.Fragment>
                  {this.renderReviewContent()}
                  <Seperator />
                </React.Fragment>
              )}


            </div>
          </div>
        </HighlightedBorderArea>

        {isMobile && this.renderCompanyButtons(company)}
      </React.Fragment>
    )
  }

  renderFullCalendar = () => {
    const { showServiceCalendar, inventoryItem } = this.props
    return (
      <CalendarContainer showModal={showServiceCalendar && inventoryItem} />
    )
  }

  renderCalendar = () => {
    const {
      campaign, match, inventoryItem, selectedServiceId,
    } = this.props

    /* CalendarBookingModule triggers a scrape which requires that inventoryItem be in place */
    if (!selectedServiceId || !inventoryItem) {
      return null
    }

    return (
      <div style={{ flex: 1 }}>
        {this.renderFullCalendar()}
        <CalendarBookingModule
          match={match}
          campaignId={campaign.id}
          serviceId={selectedServiceId}
        />
      </div>
    )
  }

  renderCampaignHeader = () => {
    const {
      isMobile,
      campaign,
    } = this.props

    const TextContainer = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: ${({colour}) => colour};
      @media (max-width: 570px) {
        position: relative;
    }
    `

    const InnerTextContainer = styled.div`
        @media (max-width: 570px) {
            position: absolute;
            padding-top: 10px;
            top: 0;
        }
        color: inherit;
    `

    const Headline = styled.h1`
      text-transform: uppercase;
      font-weight: bolder;
      line-height: 30px;
      color: inherit;
      font-size: ${props => (props.isMobile ? "24px" : "32px")};
      margin: 0 0 0 ${props => (props.isMobile ? "20px" : "34px")};
      width: ${props => (props.isMobile ? "50%" : "66%")};
    `

    const Description = styled.div`
      color: inherit;
      font-size: ${props => (props.isMobile ? "16px" : "25px")};
      margin-top: ${props => (props.isMobile ? "10px" : "34px")};
      margin-left: ${props => (props.isMobile ? "20px" : "34px")};
      width: ${props => (props.isMobile ? "50%" : "66%")};
    `

    const campaignPlacement = isMobile ? "main" : "main_desktop"
    const campaignImage = campaign.images.find(
      i => i.placement === campaignPlacement
    )

    if (!campaignImage) {
      return null
    }

    return (
      <div
        title={campaign.headline}
        style={{
          width: "100%", minHeight: 200, display: "block", position: "relative"
        }}
      >
        <div
          style={{ position: "absolute", width: "100%", height: "100%" }}
        >
          <TextContainer colour={campaign.headline_color}>
            <InnerTextContainer>
              <Headline isMobile={isMobile}>
                {campaign.headline}
              </Headline>
              <Description isMobile={isMobile}>{campaign.description}</Description>
            </InnerTextContainer>
          </TextContainer>
        </div>
        <img
          alt="campaign"
          src={thumborizeFitIn(
            encodeURIComponent(campaignImage.url),
            1080,
            1080
          )}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    )
  }

  renderCampaign = () => {
    const {
      isFetchingCampaign,
      isMobile,
      campaign,
      campaignCompanies,
      searchCompanies,
      categories,
      selectStep,
      selectedStep,
      selectedServiceDescriptionId,
      selectedCompanyId,
      selectedServiceId,
      selectedCategory,
      fetchCompany,
      companySelectedData,
    } = this.props

    // Ensure everything required to render first step is loaded before proceeding
    if (isFetchingCampaign || !campaign || !categories) return <Animation />

    const StepParent = styled.div`
      padding: 20px 0px 12px 0px;
      margin: 20px auto 15px auto;
      border-top: 1px solid #ececec;
    `

    const Step = styled.small`
      display: block;
      text-align: center;
    `

    const StepHeader = styled.h2`
      text-align: center;
      font-weight: bold;
    `


    let serviceSelected
    if (selectedServiceDescriptionId) {
      serviceSelected = campaign.similar.find(s => s.description.id === selectedServiceDescriptionId)
    } else if (selectedCategory) {
      // Select first service of the category selected by default
      this.selectServiceDescription(campaign.similar.find(service => selectedCategory.id === service.description.category.id).description.id)
    }

    /* Available companies list is full list of campaign companies filtered by companies returned in search results */
    const companies = campaignCompanies.filter(c => searchCompanies.find(s => s.id === c.id))

    return (
      <div
        style={{
          maxWidth: 1078,
          minWidth: isMobile ? 0 : 1078,
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative"
        }}
      >
        {this.renderCampaignHeader()}

        <div style={{ padding: isMobile ? "0 14px" : 0, minHeight: 300 }}>
          <StepParent style={{ marginTop: 0, border: "none" }}>
            <Step id="step-1">Step 1 of 5</Step>
            <StepHeader>{campaign.title_service_select}</StepHeader>
          </StepParent>

          <div style={{ marginBottom: "20px" }}>
            {this.renderCategoryOptions(campaign.similar)}
          </div>

          {selectedStep > 1 && selectedCategory && (
            <div style={{ marginBottom: "20px" }}>
              <StepParent>
                <Step id="step-2">Step 2 of 5</Step>
                <StepHeader>{campaign.title_service_confirm}</StepHeader>
              </StepParent>

              {selectedServiceDescriptionId && (
                <div>
                  {this.renderService(serviceSelected)}
                </div>
              )}
            </div>
          )}

          {selectedStep > 2 && selectedServiceDescriptionId && (
            <div style={{ marginBottom: "40px", minHeight: 400 }}>
              <StepParent>
                <Step id="step-3">Step 3 of 5</Step>
                <StepHeader>{campaign.title_company_select}</StepHeader>
              </StepParent>

              {campaignCompanies.length > 0 && (
                <div>
                  <CompanySelector
                    allowMultipleSelection={false}
                    hideTextSearch={false}
                    companies={companies}
                    currency={serviceSelected.currency}
                    scrollContainerStyle={{
                      overflowY: "auto",
                      margin: 0,
                      maxHeight: "none"
                    }}
                    showRegionSelector={false}
                    onSelectCompany={companySlug => {
                      const company = campaignCompanies.find(c => {
                        return c.slug === companySlug
                      })

                      this.selectCompany(company.id)

                      // we fetch company data to show social networks links and phone.
                      // they werent available on the campaign -> company
                      fetchCompany(company.id)

                      selectStep(4)
                      /* Scroll to bottom after content has loaded */
                      this.scrollToStep(4)
                    }}
                  />
                </div>
              )}

            </div>
          )}

          {selectedStep > 3 && (
            <div style={{ marginBottom: "20px" }}>
              <StepParent>
                <Step id="step-4">Step 4 of 5</Step>
                <StepHeader>{campaign.title_company_confirm}</StepHeader>
              </StepParent>
              <div style={{ minHeight: "500px" }}>
                {selectedCompanyId && companySelectedData && this.renderCompany({
                  ...companySelectedData,
                  service: campaignCompanies
                    .find(c => c.id === selectedCompanyId)
                    .service,
                })}
              </div>
            </div>
          )}

          {selectedStep > 4 && selectedServiceId && (
          <div style={{ marginBottom: "20px" }}>
            <StepParent>
              <Step id="step-5">Step 5 of 5</Step>
              <StepHeader>
                {campaign.title_time_select}
              </StepHeader>
            </StepParent>

            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  textAlign: "left",
                  minHeight: 520,
                  width: isMobile ? "100%" : "auto",
                  minWidth: isMobile ? 0 : 500
                }}
              >
                {this.renderCalendar()}
              </div>
            </div>

          </div>
          )}
          {/* Related services currently hidden until API completion */}
          {/* relatedServices && (
            <div style={{ marginTop: "50px" }}>
              <h4 style={{ textTransform: "uppercase", textAlign: "center" }}>
                You might also like
              </h4>
              <Carousel
                isMobile={isMobile}
                overrideResponsive={{
                  desktop: {
                    items: 3
                  },
                  mobile: {
                    breakpoint: { max: 768, min: 0 },
                    items: 2
                  }
                }}
              >
                {relatedServices.map(service => (
                  <div
                    key={`related-${service._id}`}
                    style={{ maxWidth: "200px" }}
                  >
                    <ServiceCard
                      showCompanyName={false}
                      logoPosition="hide"
                      onClick={() => navToInventory(
                        service.slug,
                        moment().format("YYYY-MM-DD"),
                        false
                      )
                      }
                      company={service.company}
                      price={service.price}
                      currency={service.currency}
                      isMobile={isMobile}
                      isVip={false}
                      showPriceRange
                      name={service.description.name}
                      serviceImages={service.images}
                      categoryImages={service.description.category.images}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          ) */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <WrapContainer loginRequired={false} showNavBack navTitle="Promo Detail">
        <PromoBanner />
        {this.renderCampaign()}
      </WrapContainer>
    )
  }
}

CampaignWizard.propTypes = {
  fetchCampaign: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  selectServiceDescription: PropTypes.func.isRequired,
  setInventoryDate: PropTypes.func.isRequired,
  setEmployee: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  selectCompany: PropTypes.func.isRequired,
  selectStep: PropTypes.func.isRequired,
  unSelectLocation: PropTypes.func.isRequired,
  unSelectServiceDescription: PropTypes.func.isRequired,

  isFetchingCampaign: PropTypes.bool.isRequired,
  selectedServiceDescriptionId: PropTypes.string,
  selectedServiceId: PropTypes.string,
  selectedCompanyId: PropTypes.string,
  selectedStep: PropTypes.number.isRequired,

  isMobile: PropTypes.bool.isRequired,
  showServiceCalendar: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  inventoryItem: PropTypes.object,
  campaign: PropTypes.object,
  campaignCompanies: PropTypes.array,
  searchCompanies: PropTypes.array,

  isVip: PropTypes.bool.isRequired,

  companyReviews: PropTypes.array.isRequired,

  relatedServices: PropTypes.array,
  navToFourOhFour: PropTypes.func.isRequired,

  selectedCategory: PropTypes.object,
  companySelectedData: PropTypes.object,
  categories: PropTypes.array,
  selectCategory: PropTypes.func.isRequired,
}

CampaignWizard.defaultProps = {
  inventoryItem: null,
  campaign: null,
  campaignCompanies: [],
  relatedServices: []
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.small,
    isFetchingCampaign: state.campaignView.isFetchingCampaign,
    campaign: state.campaignView.campaign,
    selectedServiceDescriptionId: state.campaignView.selectedServiceDescriptionId,
    selectedServiceId: state.campaignView.selectedServiceId,
    selectedCategory: state.campaignView.selectedCategory,
    selectedCompanyId: state.campaignView.selectedCompanyId,
    selectedStep: state.campaignView.selectedStep,
    campaignCompanies: state.campaignView.companies,
    searchCompanies: state.inventoryFilter.companies,
    mapZoom: state.campaignView.mapZoom,
    isVip: !!state.profile.profile.vip,
    companyReviews: state.inventoryView.companyReviews,
    showServiceCalendar: state.inventoryView.showServiceCalendar,

    inventoryDate: state.inventoryView.inventoryDate,
    inventoryItem: state.inventoryView.inventoryItem,

    relatedServices: getRelatedServices(state),

    companySelectedData: state.company ? state.company.company : null,
    categories: inventoryFilterSelectors.getCatGroups(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCampaign: campaignId => dispatch(fetchCampaign(campaignId)),
  fetchCompany: companyId => dispatch(fetchCompany(companyId)),
  selectServiceDescription: (serviceDescriptionId, campaignId) => dispatch({ type: "SELECT_SERVICE_DESCRIPTION_ID", payload: { serviceDescriptionId, campaignId } }),
  selectService: id => dispatch({ type: "SELECT_SERVICE_ID", payload: { id } }),
  selectCategory: category => dispatch({ type: "SELECT_CATEGORY", payload: { category } }),
  selectCompany: id => dispatch({ type: "SELECT_COMPANY_ID", payload: { id } }),
  selectStep: step => dispatch(selectStep(step)),
  setEmployee: employeeId => dispatch(setEmployee(employeeId)),
  setInventoryDate: date => dispatch(setInventoryDate(date)),
  unSelectLocation: () => dispatch({ type: "UNSELECT_WIZARD_LOCATION" }),
  unSelectServiceDescription: () => dispatch({ type: "UNSELECT_WIZARD_SERVICE_DESCRIPTION" }),
  navToFourOhFour: reason => dispatch(navToFourOhFour(reason)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignWizard)
