import React from "react"
import PropTypes from "prop-types"

import { RoundImage } from "./RoundImage"
import { thumborize } from "./thumborize"

export const CompanyLogo = (
  { company, size, style = {} }
) => (
  <div style={{ ...style }}>
    <div style={{ position: "relative" }}>
      {/* <img src="/shield-icon.png" width="20" alt="shield" style={{ position: "absolute", right: 4, bottom: 4 }} /> */}
      <RoundImage
        style={{ backgroundColor: "#fff" }}
        src={thumborize(
          company.images.find(i => i.placement === "logo").url,
          (size || 48) * 4,
          (size || 48) * 4
        )}
        alt={company.name}
        size={size || 48}
      />
    </div>
  </div>
)

CompanyLogo.propTypes = {
  company: PropTypes.object,
  size: PropTypes.number,
  style: PropTypes.object
}
