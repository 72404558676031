import { apiGetEmployee, handleXhrError } from "../../api/api"

const fetchEmployeeFulfilled = (response, action) => ({
  type: "FETCH_EMPLOYEE_SUCCESS",
  payload: { response, action }
})

export const fetchEmployeeEpic = action$ => action$.ofType("FETCH_EMPLOYEE").mergeMap(action => apiGetEmployee(action.payload.id)
  .map(response => fetchEmployeeFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_EMPLOYEE_FAILURE")))
